export const handleFile = (event) => {
  return new Promise((resolve, reject) => {
    const file = event.target.files[0];
    if (!file) {
      reject("Nenhum arquivo selecionado.");
      return;
    }

    const reader = new FileReader();

    reader.onload = function (e) {
      const content = e.target.result;

      function extractData(tag) {
        const regex = new RegExp(`<${tag}>(.*?)</${tag}>`, "s");
        const match = content.match(regex);
        return match ? match[1].trim() : null;
      }

      function splitData(data) {
        return data
          .split("\n")
          .map((item) => item.trim())
          .filter((item) => item !== "");
      }

      const licitacaoData = extractData("licitacao");
      const licitacao = licitacaoData
        ? splitData(licitacaoData).map((data) => ({
            codigoUnidadeGestora: data.slice(0, 6),
            numeroLicitacao: data.slice(6, 15),
            modalidadeLicitacao: data.slice(15, 17),
            dataLicitacao: data.slice(17, 25),
            quantidadeProposta: data.slice(25, 28),
            tipoObjeto: data.slice(28, 29),
            valor: data.slice(29, 45),
            descricao: data.slice(45, -6).trim(),
            mesAno: data.slice(-6),
          }))
        : [];

      const propostasData = extractData("propostas");
      const propostas = propostasData
        ? splitData(propostasData).map((data) => ({
            codigoUnidadeGestora: data.slice(0, 6),
            numeroLicitacao: data.slice(6, 15),
            modalidadeLicitacao: data.slice(15, 17),
            licitante: data.slice(17, 31),
            valor: data.slice(31, 76),
            situacaoProposta: data.slice(77, 78),
            mesAno: data.slice(-6),
          }))
        : [];

      const participantesData = extractData("participantes");
      const participantes = participantesData
        ? splitData(participantesData).map((data) => ({
            codigoUnidadeGestora: data.slice(0, 6),
            numeroLicitacao: data.slice(6, 15),
            modalidadeLicitacao: data.slice(15, 17),
            licitante: data.slice(17, 31),
            mesAno: data.slice(-6),
          }))
        : [];

      const fornecedoresData = extractData("fornecedores");
      const fornecedores = fornecedoresData
        ? splitData(fornecedoresData).map((data) => ({
            codigoUnidadeGestora: data.slice(0, 6),
            fornecedor: data.slice(6, 20),
            nome: data.slice(20, -7).trim(),
            tipoFornecedor: data.slice(-7, -6),
            mesAno: data.slice(-6),
          }))
        : [];

      const contratosData = extractData("contratos");
      const contratos = contratosData
        ? splitData(contratosData).map((data) => ({
            codigoUnidadeGestora: data.slice(0, 6),
            numeroContrato: data.slice(6, 18),
            dataInicial: data.slice(18, 26),
            dataFinal: data.slice(26, 34),
            fornecedor: data.slice(34, 48),
            numeroLicitacao: data.slice(48, 57),
            modalidadeLicitacao: data.slice(57, 59),
            valor: data.slice(59, 75),
            descricao: data.slice(75, -6).trim(),
            mesAno: data.slice(-6),
          }))
        : [];

      const aditivosData = extractData("aditivos");
      const aditivos = aditivosData
        ? splitData(aditivosData).map((data) => ({
            codigoUnidadeGestora: data.slice(0, 6),
            numeroContrato: data.slice(6, 18),
            numeroAditivo: data.slice(18, 26),
            dataAssinatura: data.slice(26, 34),
            descricao: data.slice(34, -30).trim(),
            valor: data.slice(-14, -6).trim(),
            mesAno: data.slice(-6),
          }))
        : [];

      const controle = extractData("controle");

      const dados = {
        licitacao,
        propostas,
        participantes,
        fornecedores,
        contratos,
        aditivos,
        controle,
      };

      resolve(dados);
    };

    reader.onerror = function () {
      reject("Erro ao ler o arquivo.");
    };

    reader.readAsText(file);
  });
};

export default handleFile;
