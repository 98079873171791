export function FormatCPForCNPJ(numero) {
  const apenasNumeros = numero.replace(/\D/g, ""); // Remove quaisquer caracteres não numéricos

  if (apenasNumeros.length === 11) {
    // Formatar como CPF: XXX.XXX.XXX-XX
    return apenasNumeros.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
  } else if (apenasNumeros.length === 14) {
    // Formatar como CNPJ: XX.XXX.XXX/XXXX-XX
    return apenasNumeros.replace(
      /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
      "$1.$2.$3/$4-$5"
    );
  } else {
    // Caso o número não tenha 11 ou 14 dígitos, retorna como está
    return numero;
  }
}
