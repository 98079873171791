import React, { useContext, useEffect, useState } from "react";
import { Col, Container, Row, Table } from "react-bootstrap";
import { Icon } from "@iconify/react";
import outlineSearch from "@iconify/icons-ic/outline-search";
import {
  BodyTable,
  FilterContainer,
  FilterLabel,
  FilterSelect,
  PassadorPage,
  TituloTable,
  TituloTableAction,
} from "../../../../components/TableConsulta/TableConsulta";
import Pagination from "../../../../components/PaginationComponent/Pagination";
import HeaderOptions from "../../../../components/HeaderOptions/HeaderOptions";
import InputSvg from "../../../../components/Grid/InputSvg";
import api from "../../../../utils/api";
import ButtonsAcoesListas from "../../../../styles/StyledComponents/ButtonsAcoesListas";
import { ToastContainer } from "react-toastify";
import { CurrentClientContext } from "../../../../contexts/CurrentClientContext";

function ProdutosAquisicaoConsulta() {
  const { currentClient } = useContext(CurrentClientContext);

  const [produtosAquisicoes, setProdutosAquisicoes] = useState([]);
  const [itensPorPagina, setItensPorPagina] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [produtosAquisicoesFiltrados, setProdutosAquisicoesFiltrados] =
    useState([]);
  const [loading, setLoading] = useState(false);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await api.get(
        `/produtoAquisicao/${currentClient.clienteId}`
      );
      const responseProdutosAquisicoes = response.data;
      setProdutosAquisicoes(responseProdutosAquisicoes);
      console.log(responseProdutosAquisicoes);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  const handleSearch = (event) => {
    const { value } = event.target;
    setSearchTerm(value);
    const filteredItems = produtosAquisicoes.filter((item) => {
      return (
        item.numero.toLowerCase().includes(value.toLowerCase()) ||
        item.produtoAquisicao.includes(value) ||
        item.codigoItemContaPcasp.includes(value) ||
        item.codigoItemContaPcasp.replace(/\./g, "").includes(value)
      );
    });
    setProdutosAquisicoesFiltrados(filteredItems);
    setCurrentPage(0);
  };

  const startIndex = currentPage * itensPorPagina;
  const endIndex = startIndex + itensPorPagina;
  const currentItens = produtosAquisicoes.slice(startIndex, endIndex);
  const currentFilteredItens = produtosAquisicoesFiltrados.slice(
    startIndex,
    endIndex
  );

  const pages = Math.ceil(produtosAquisicoes.length / itensPorPagina);
  const filteredPages = Math.ceil(
    produtosAquisicoesFiltrados.length / itensPorPagina
  );

  return (
    <Container
      id="pags_responsividade_padding_geral"
      fluid
      style={{ color: "#515C70" }}
      className="container_conteudo conteudo_pagina"
    >
      <HeaderOptions
        withRow={true}
        TituloPagina={"Protudos e Aquisições"}
        SubTituloPagina={"Consulta"}
        ToPag={"/contabilidade/cadastros/produtos-aquisicao"}
      />
      {/* <ModalDeleteData
        modalOpened={modalOpened}
        closeModal={closeModal}
        setDataDelete={setDataDelete}
        deleteDataFunction={deleteObra}
      /> */}
      <ToastContainer />
      <Row className="row_form mt-1">
        <Col md={8}>
          <FilterContainer>
            <FilterLabel>Itens por página:</FilterLabel>
            <FilterSelect
              value={itensPorPagina}
              onChange={(event) =>
                setItensPorPagina(Number(event.target.value))
              }
            >
              <option value={10}>10</option>
              <option value={15}>15</option>
              <option value={20}>20</option>
            </FilterSelect>
            {/* <div style={{ marginLeft: "15px" }}>
              Não encontrou a obra que buscava?{" "}
              <button
                onClick={() => getTCEData()}
                style={{
                  all: "unset",
                  fontWeight: "500",
                  cursor: "pointer",
                }}
              >
                clique aqui.
              </button>
            </div> */}
          </FilterContainer>
        </Col>
        <Col md={4}>
          <InputSvg
            icon={outlineSearch}
            height={"23px"}
            color={"#6C7585"}
            placeholder={"Pesquise"}
            onChange={handleSearch}
          />
        </Col>
      </Row>
      <Row className="row_form mt-1">
        {loading ? (
          <Col
            md={12}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Icon height={50} icon="line-md:loading-loop" color="#515c70" />
          </Col>
        ) : null}
        <Table
          responsive
          style={loading ? { display: "none" } : { marginTop: "-10px" }}
        >
          <thead>
            <tr>
              <TituloTable>Número</TituloTable>
              <TituloTable>Nome</TituloTable>
              <TituloTable>Código do Item</TituloTable>
              {/* <TituloTable>Local</TituloTable> */}
              <TituloTableAction>Ação</TituloTableAction>
            </tr>
          </thead>
          <BodyTable>
            {searchTerm
              ? currentFilteredItens.map((item, index) => (
                  <tr key={index}>
                    <td style={{ whiteSpace: "nowrap" }} title={item.numero}>
                      {item.numero}
                    </td>
                    <td
                      style={{ whiteSpace: "nowrap" }}
                      title={item.produtoAquisicao}
                    >
                      {item.produtoAquisicao}
                    </td>
                    <td
                      style={{ whiteSpace: "nowrap" }}
                      title={item.codigoItemContaPcasp}
                    >
                      {item.codigoItemContaPcasp}
                    </td>
                    {/* <td style={{ whiteSpace: "nowrap" }}>
                      {item.tce ? "TCE" : "SAVE"}
                    </td> */}
                    <td>
                      <ButtonsAcoesListas
                        // FuncaoIconDelete={() => {
                        //   openModal();
                        //   setDataDelete(item);
                        // }}
                        CaminhoPagUpdate={`/contabilidade/editar/produtos-aquisicao/${item.numero}`}
                      />
                    </td>
                  </tr>
                ))
              : currentItens.map((item, index) => (
                  <tr key={index}>
                    <td style={{ whiteSpace: "nowrap" }} title={item.numero}>
                      {item.numero}
                    </td>
                    <td
                      style={{ whiteSpace: "nowrap" }}
                      title={item.produtoAquisicao}
                    >
                      {item.produtoAquisicao}
                    </td>
                    <td
                      style={{ whiteSpace: "nowrap" }}
                      title={item.codigoItemContaPcasp}
                    >
                      {item.codigoItemContaPcasp}
                    </td>
                    {/* <td style={{ whiteSpace: "nowrap" }}>
                      {item.tce ? "TCE" : "SAVE"}
                    </td> */}
                    <td>
                      <ButtonsAcoesListas
                        // FuncaoIconDelete={() => {
                        //   openModal();
                        //   setDataDelete(item);
                        // }}
                        CaminhoPagUpdate={`/contabilidade/editar/produtos-aquisicao/${item.numero}`}
                      />
                    </td>
                  </tr>
                ))}
          </BodyTable>
        </Table>
      </Row>
      <Row
        className="row_form mt-1"
        style={{
          borderBottomLeftRadius: "5px",
          borderBottomRightRadius: "5px",
        }}
      >
        <Col md={4}>
          <span>
            Mostrando de {startIndex + 1} a {endIndex} de{" "}
            {searchTerm
              ? produtosAquisicoesFiltrados.length
              : produtosAquisicoes.length}{" "}
            registros
          </span>
        </Col>
        <Col md={8}>
          <PassadorPage>
            <span>
              {currentPage + 1} de {searchTerm ? filteredPages : pages} páginas
            </span>
            <Pagination
              pages={searchTerm ? filteredPages : pages}
              itemsPerPage={itensPorPagina}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          </PassadorPage>
        </Col>
      </Row>
      <div style={{ padding: "11px" }}></div>
    </Container>
  );
}

export default ProdutosAquisicaoConsulta;
