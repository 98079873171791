import React from "react";
import { RowTituloAba } from "../../../PagesAcessoInterno/PagContaPerfil/StyledComponentsContaPerfil";
import { CCol, RowForm } from "../../../../components/Grid/CCol";
import MyAutoComplete from "../../../../components/Inputs/MyAutoComplete";

const ComplementoAba = ({
  optionsObras,
  inputValueObras,
  setInputValueObras,
  optionsConvenios,
  inputValueConvenios,
  setInputValueConvenios,
}) => {
  return (
    <>
      <RowTituloAba style={{ marginTop: "15px" }}>
        <div>
          <span>Complementação</span>
        </div>
      </RowTituloAba>

      <RowForm className="mt-1">
        <CCol>
          <MyAutoComplete
            style={{ height: "37px" }}
            inputValue={inputValueObras}
            setInputValue={setInputValueObras}
            labelInput="Obra"
            options={optionsObras}
            labelFormat={(item) => `${item.numeroObra} - ${item.tipoObra.nome}`}
          />
        </CCol>
        <CCol>
          <MyAutoComplete
            style={{ height: "37px" }}
            labelInput="Convênio"
            inputValue={inputValueConvenios}
            setInputValue={setInputValueConvenios}
            options={optionsConvenios}
            labelFormat={(item) =>
              `${item.CODIGO_CONVENIO} - ${item.OBJETIVO_CONVENIO}`
            }
          />
        </CCol>
      </RowForm>

      <RowForm style={{ marginTop: "-14px" }} $borderRadiusType={2}>
        <CCol md={6}>
          <MyAutoComplete
            style={{ height: "37px" }}
            labelInput="Dívida Pública"
            options={[{ label: "teste", value: "teste" }]}
            labelFormat={(item) => `${item.value} - ${item.label}`}
          />
        </CCol>
      </RowForm>
    </>
  );
};

export default ComplementoAba;
