import React, { useCallback, useContext, useEffect, useState } from "react";
import { Container } from "react-bootstrap";

import styled from "styled-components";

import HeaderCadastros from "../../../../components/HeaderCadastros/HeaderCadastros";
import { CCol, RowForm } from "../../../../components/Grid/CCol";
import { RowInfosAcao } from "../../../../components/RowInfosAcao/RowInfosAcao";
import FloatingLabelInput from "../../../../components/Grid/FloatingLabelInput";
import MyAutoComplete from "../../../../components/Inputs/MyAutoComplete";
import InputData from "../../../../components/Grid/InputData";
import InputFormatRealBrasileiro from "../../../../components/Grid/InputFormatRealBrasileiro";
import RowSelecaoAbasInferior from "../../../../components/Grid/RowSelecaoAbasInferior";

import { HistoricoAba } from "./HistoricoAba";
import { FonteDeRecursosAba } from "./FonteDeRecursosAba";
import { TransferenciaRecebidaAba } from "./TransferenciaRecebidaAba";
import { RetencaoAba } from "./RetencaoAba";

import { ReturnDataAtual } from "../../../../utils/Gerais/ReturnDataAtual";
import api from "../../../../utils/api";
import { FormatCpfPaste } from "../../../../utils/FormatacaoDeDados/FormtCpfPaste";

import { CurrentClientContext } from "../../../../contexts/CurrentClientContext";

import { useCurrentYear } from "../../../../hooks/useCurrentYear";
import { AuthContext } from "../../../../contexts/AuthContext";
import { CurrentCityContext } from "../../../../contexts/CurrentCityContext";

const CColNumero = styled(CCol)`
  @media (min-width: 768px) {
    width: 10%;
  }
`;

const GreReceita = () => {
  const dataAtual = ReturnDataAtual()
    .replace(/\//g, "-")
    .split("-")
    .reverse()
    .join("-");

  const { currentClient } = useContext(CurrentClientContext);
  const { currentYear } = useCurrentYear();
  const { userAuthData } = useContext(AuthContext);
  const { currentCity } = useContext(CurrentCityContext);

  const [abaSelecionada, setAbaSelecionada] = useState({
    nomeAba: "Histórico",
    numeroAba: 0,
  });

  const [optionsContribuinte, setOptionsContribuintes] = useState([
    { id: "", name: "", cpf: "" },
  ]);
  const [optionsContaBancariaCaixa, setOptionsContaBancariaCaixa] = useState([
    { id: "", nameConta: "", agencia: "" },
  ]);

  const [optionsQdrde, setOptionsQdrde] = useState([
    {
      id: "",
      titulo: "",
      funcao: "",
      qdrdeIsTranferencia: false,
      fontes: [
        { fonteId: null, valor: "", data: "", label: "", percentage: "" },
      ],
    },
  ]);

  const [optionsFonteDeRecursos, setOptionsFonteDeRecurso] = useState([
    { label: "", value: null },
  ]);

  const [optionsUnidadesGestoras, setOptionsUnidadesGestoras] = useState([
    { label: "", value: null, tipoNaturezaJuridica: "" },
  ]);

  const [infosCadastro, setInfosCadastro] = useState({
    num: "",
    evb: "",
    qdrdeId: null,
    qdrdeIsTranferencia: false,
    funcao: "",
    data: dataAtual,
    remessa: "",
    cpfContribuinteId: null,
    infoCaixaId: null,
    docFinanceiro: "",
    valor: 0,
    tipoTransferencia: "",
    unidadeGestoraId: null,
    tipoNaturezaJuridica: "",
    fontes: [{ fonteId: null, valor: "", data: "", label: "", percentage: "" }],
  });

  const [inputContribuinte, setInputContribuinte] = useState("");
  const [inputContaBancaria, setInputContaBancaria] = useState("");
  const [inputQdrde, setInputQdrde] = useState("");

  const handleSelectQdrde = (option) => {
    const { fontes, funcao, qdrdeIsTranferencia } = option;
    const optionsFontes = formattedOptionsFonteDeRecursos(option.fontes);
    setOptionsFonteDeRecurso(optionsFontes);
    setInfosCadastro((prev) => ({
      ...prev,
      qdrdeId: option.id,
      funcao,
      fontes,
      qdrdeIsTranferencia,
    }));
    if (
      !qdrdeIsTranferencia &&
      abaSelecionada.nomeAba === "Transferência Recebida"
    )
      setAbaSelecionada({ nomeAba: "Histórico", numeroAba: 0 });
  };

  const handleSelectContribuinte = (optionContrubuinte) => {
    setInfosCadastro((prev) => ({
      ...prev,
      cpfContribuinteId: optionContrubuinte.id,
    }));
  };

  const handleSelectContaBancaria = (optionContaBancaria) => {
    setInfosCadastro((prev) => ({
      ...prev,
      infoCaixaId: optionContaBancaria.id,
    }));
  };

  const handleChangeValorReceita = (_, valor) => {
    const fontesDeRecursos = infosCadastro.fontes.filter(
      (item) => item.fonteId !== null
    );

    if (fontesDeRecursos.length === 1) {
      const fontes = fontesDeRecursos.map((item) => {
        return {
          ...item,
          valor,
        };
      });

      setInfosCadastro((prevState) => ({
        ...prevState,
        fontes,
        valor,
      }));

      return;
    }

    console.log(valor);
    setInfosCadastro((prev) => ({
      ...prev,
      valor,
    }));
  };

  const handleRegisterGre = () => {
    console.log(infosCadastro);
  };

  const formattedOptionsFonteDeRecursos = (fonts) => {
    const filteredFonts = fonts.filter((item) => item.fonteId !== null);

    if (!filteredFonts.length) return [{ label: "", value: null }];

    const formattedFonts = filteredFonts.map((item) => {
      return {
        label: item.label,
        value: item.fonteId,
      };
    });
    return formattedFonts;
  };

  const formattedFonts = (data) => {
    const fonts = data.map((item) => {
      const fontInfo = item.fonteInfo.find((font) => font.id === item.fonteId);
      const date = new Date(item.dataCriacao);
      return {
        data: date.toISOString().split("T")[0],
        fonteId: item.fonteId,
        valor: item.valor,
        label: fontInfo ? `${fontInfo.conta} - ${fontInfo.titulo}` : "",
        percentage: "0,00",
      };
    });
    return fonts;
  };

  const getDataQdrde = useCallback(async () => {
    try {
      const resp = await api.get(
        `qdrde/${currentClient.clienteId}/${currentYear}`
      );
      const data = resp.data;

      const dataQdrde = data.map((item) => {
        const titulo = `${item.categoriaContaPcaspInfo[0].conta} - ${item.titulo}`;
        const fontes = formattedFonts(item.fontes);
        const qdrdeIsTranferencia = item.categoriaContaPcaspInfo[0].titulo
          .toLowerCase()
          .includes("transferências");
        return {
          id: item.id,
          titulo,
          funcao: item.categoriaContaPcaspInfo[0].funcao,
          fontes,
          qdrdeIsTranferencia,
        };
      });
      setOptionsQdrde(dataQdrde);
    } catch (error) {
      console.log(error);
    }
  }, [currentClient, currentYear]);

  const fetchOptionsContribuinte = useCallback(async () => {
    try {
      const resp = await api.get(`cpf/por-cliente/${currentClient.clienteId}`);
      const data = resp.data;
      const optionsCpf = data.map((item) => {
        return {
          id: item.pessoaId,
          name: item.nomeCompleto,
          cpf: FormatCpfPaste(item.cpf),
        };
      });

      setOptionsContribuintes(optionsCpf);
    } catch (error) {
      console.log(error);
    }
  }, [currentClient]);

  const fetchContas = useCallback(async () => {
    try {
      const resp = await api.get(
        `contaBancaria/cliente/${currentClient.clienteId}/${currentYear}`
      );
      const data = resp.data;
      const optionsContaBancaria = data.map((item) => {
        return {
          id: item.id,
          nameConta: item.nome,
          agencia: item.numeroConta,
        };
      });
      setOptionsContaBancariaCaixa(optionsContaBancaria);
    } catch (error) {
      console.log("Erro ao buscar contas bancárias", error);
    }
  }, [currentClient, currentYear]);

  const formattedUnidadesGestoras = useCallback(() => {
    const unidades = userAuthData.clientes
      .map((item) => {
        const hasCityUnidade =
          item.endereco.municipio === currentCity.municipio &&
          item.endereco.estado === currentCity.estado;
        const currentClientSelected =
          currentClient.clienteId === item.clienteId;

        if (!hasCityUnidade || currentClientSelected)
          return { label: "", value: null };
        return {
          label: item.nomeFantasia,
          value: item.clienteId,
          tipoNaturezaJuridica: item.tipoNaturezaJuridica,
        };
      })
      .filter((item) => item.value);

    setOptionsUnidadesGestoras(unidades);
  }, [currentCity, userAuthData, currentClient]);

  useEffect(() => {
    formattedUnidadesGestoras();
  }, [formattedUnidadesGestoras]);

  useEffect(() => {
    fetchOptionsContribuinte();
    fetchContas();
    getDataQdrde();
  }, [fetchContas, fetchOptionsContribuinte, getDataQdrde]);

  return (
    <Container
      id={"pags_responsividade_padding_geral"}
      fluid
      className={"container_conteudo conteudo_pagina"}
    >
      <HeaderCadastros
        NomePaginaTitulo={"GRE - Receita"}
        PaginaSubtitulo={"Cadastro"}
        RowTitle="Receita Extraorçamentária - GRE"
        ButtonSaveFunction={handleRegisterGre}
      />

      <RowForm className="mt-1">
        <CColNumero md={1}>
          <FloatingLabelInput disabled placeholder="Número" label="Número" />
        </CColNumero>
        <CCol md={2}>
          <MyAutoComplete
            options={[{ label: "teste", value: "teste" }]}
            labelFormat={(item) => `${item.label} - ${item.value}`}
            labelInput="EVB"
            style={{ height: "37px" }}
          />
        </CCol>
        <CCol>
          <MyAutoComplete
            style={{ height: "37px" }}
            labelInput="QDRDE"
            options={optionsQdrde}
            labelFormat={(item) => `${item.titulo} `}
            inputValue={inputQdrde}
            setInputValue={setInputQdrde}
            onOptionSelect={handleSelectQdrde}
          />
        </CCol>
        <CCol md={2}>
          <InputData
            onChange={(e) =>
              setInfosCadastro((prev) => ({
                ...prev,
                data: e.target.value,
              }))
            }
            externalValue={infosCadastro.data}
          />
        </CCol>
      </RowForm>

      {infosCadastro.funcao && (
        <RowInfosAcao>
          <CCol>
            <p>
              <span>{infosCadastro.funcao}</span>
            </p>
          </CCol>
        </RowInfosAcao>
      )}

      <RowForm style={{ marginTop: "-14px" }}>
        <CColNumero md={1}>
          <FloatingLabelInput disabled placeholder="Remessa" label="Remessa" />
        </CColNumero>
        <CCol>
          <MyAutoComplete
            style={{ height: "37px" }}
            labelInput="Contribuinte"
            options={optionsContribuinte}
            labelFormat={(item) => `${item.cpf} - ${item.name}`}
            inputValue={inputContribuinte}
            setInputValue={setInputContribuinte}
            onOptionSelect={handleSelectContribuinte}
          />
        </CCol>
      </RowForm>

      <RowForm style={{ marginTop: "-14px" }}>
        <CCol>
          <MyAutoComplete
            style={{ height: "37px" }}
            labelInput="Caixa e Equivalente Caixa"
            options={optionsContaBancariaCaixa}
            labelFormat={(item) => `${item.nameConta} - ${item.agencia}`}
            inputValue={inputContaBancaria}
            setInputValue={setInputContaBancaria}
            onOptionSelect={handleSelectContaBancaria}
          />
        </CCol>

        <CCol md={2}>
          <FloatingLabelInput
            placeholder="Doc Financeiro"
            label="Doc Financeiro"
          />
        </CCol>

        <CCol md={2}>
          <InputFormatRealBrasileiro
            disabled={infosCadastro.qdrdeId === null}
            onChange={handleChangeValorReceita}
          />
        </CCol>
      </RowForm>

      <RowSelecaoAbasInferior
        abas={[
          "Histórico",
          "Fonte de Recursos",
          `${
            infosCadastro.qdrdeIsTranferencia ? "Transferência Recebida" : ""
          }`,
          "Retenção",
        ]}
        abaSelecionada={abaSelecionada}
        setAbaSelecionada={setAbaSelecionada}
      />

      {abaSelecionada.nomeAba === "Histórico" && <HistoricoAba />}
      {abaSelecionada.nomeAba === "Fonte de Recursos" && (
        <FonteDeRecursosAba
          infosCadastro={infosCadastro}
          setInfosCadastro={setInfosCadastro}
          optionsFonteDeRecursos={optionsFonteDeRecursos}
        />
      )}
      {abaSelecionada.nomeAba === "Transferência Recebida" && (
        <TransferenciaRecebidaAba
          optionsUnidadesGestoras={optionsUnidadesGestoras}
          // handleSelectTipoTransferencia={handleSelectTipoTransferencia}
          // handleSelectUnidadeGestora={handleSelectUnidadeGestora}
          infosCadastro={infosCadastro}
          setInfosCadastro={setInfosCadastro}
          // handleOptionUnidadeGestora={handleOptionUnidadeGestora}
        />
      )}
      {abaSelecionada.nomeAba === "Retenção" && <RetencaoAba />}
    </Container>
  );
};

export default GreReceita;
