import api from "../../utils/api";

export const getCpfsCnpjsPorCliente = async (clienteId, type) => {
  try {
    const resp = await api.get(`/cpf/por-cliente/${clienteId}`);
    const data = resp.data;

    if (type === "cpf") {
      return data.cpf?.filter((item) => item.length === 11);
    } else if (type === "cnpj") {
      return data.cpf?.filter((item) => item.length === 14);
    }

    return data;
  } catch (error) {
    console.log(error);
    return;
  }
};
