import api from "../../utils/api";

export const getAllCnpjs = async () => {
  try {
    const resp = await api.get("cnpj");
    const data = resp.data;

    return data;
  } catch (error) {
    return [];
  }
};
