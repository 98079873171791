import React, { useState, useEffect, useContext } from "react";
import {
  CCol,
  CColButtonsAcoes2Icons,
  RowAninhada,
} from "../../../../components/Grid/CCol";
import { Row } from "react-bootstrap";
import {
  FormControlListaDeInputs,
  PTitulosInputs,
} from "../../../../styles/StyledComponents/ListaDeInputs";
import IconAdicionar from "../../../../components/ListaDeInputs/IconAdicionar";
import { CurrentClientContext } from "../../../../contexts/CurrentClientContext";
import api from "../../../../utils/api";
import { toast } from "react-toastify";
import MyAutoComplete from "../../../../components/Inputs/MyAutoComplete";
import { FormatCpfPaste } from "../../../../utils/FormatacaoDeDados/FormtCpfPaste";
import { formatToCurrency } from "../../../../utils/FormatacaoDeDados/FormatToCurrency";
import { modalidades } from "./menus";
import FloatingLabelInput from "../../../../components/Grid/FloatingLabelInput";
import InputFormatRealBrasileiro from "../../../../components/Grid/InputFormatRealBrasileiro";

const Participantes = ({
  addParticipante,
  values,
  acao,
  numeroLicitacao = " ",
}) => {
  const { currentClient } = useContext(CurrentClientContext);

  const [inputAutoCompleteValue, setInputAutoComplete] = useState("");
  const [participantesOptions, setParticipantesOptions] = useState([]);
  const [defValues, setDefValues] = useState({
    licitante: "",
    fornecedor: {
      nome: "",
    },
    proposta: {
      situacaoProposta: null,
      valor: "",
    },
    modalidadeLicitacao: "",
  });

  const addValues = () => {
    console.log(defValues);
    const hasEmptyValues = Object.values(defValues).some(
      (value) => value === "" || value === null || value === undefined
    );

    if (!hasEmptyValues) {
      setInputAutoComplete("");
      const sanitizedValues = {
        ...defValues,
        proposta: {
          ...defValues.proposta,
          valor: defValues.proposta.valor.replace(/\./g, "").replace(",", "."),
        },
      };
      addParticipante(sanitizedValues);
      setDefValues({
        licitante: "",
        fornecedor: {
          nome: "",
        },
        proposta: {
          situacaoProposta: null,
          valor: "",
        },
        modalidadeLicitacao: null,
      });
    }
  };

  useEffect(() => {
    console.log("Iniciada");
    api
      .get(`cpf/por-cliente/${currentClient.clienteId}`)
      .then((resp) => {
        const data = resp.data;
        console.log(data);
        const options = data.map((secretario) => ({
          cpf: secretario.cpf,
          pessoaId: secretario.pessoaId,
          nome: secretario.nomeCompleto,
        }));

        setParticipantesOptions(options);
      })
      .catch((error) => {
        console.log(error);
        toast.error("Algo deu errado ao carregar os dados.");
      });
    //eslint-disable-next-line
  }, [currentClient]);

  const handleOptionSelect = (option) => {
    if (option) {
      setDefValues((prevValues) => ({
        ...prevValues,
        licitante: option.cpf,
        fornecedor: {
          ...prevValues.fornecedor,
          nome: option.nome,
        },
      }));
    }
  };

  return (
    <>
      <Row className="row_form mt-1">
        <RowAninhada>
          <CCol md={1} style={{ width: "9em" }}>
            <PTitulosInputs>Nº Licitação</PTitulosInputs>
            <FormControlListaDeInputs
              disabled
              radiusborder={values.length > 0 ? "5px 5px 0 0" : "5px"}
              // placeholder="Nº Licitação"
              value={acao !== "visualizar" ? numeroLicitacao : ""}
            />
          </CCol>
          <CCol md={2} style={{ width: "18em" }}>
            <PTitulosInputs>Modalidade</PTitulosInputs>
            <FloatingLabelInput
              disabled={acao === "visualizar"}
              new={true}
              holdSelect
              style={{
                color: "#515c70",
              }}
              height="30px"
              tipo="input_select"
              value={defValues.modalidadeLicitacao}
              radiusborder={values.length > 0 ? "5px 5px 0 0" : "5px"}
              options={[{ label: " ", value: null }, ...modalidades]}
              onSelect={(option) => {
                setDefValues((prevValues) => ({
                  ...prevValues,
                  modalidadeLicitacao: option.value,
                }));
              }}
              noArrow={acao}
            />
          </CCol>
          <CCol>
            <PTitulosInputs>Participante</PTitulosInputs>
            <MyAutoComplete
              disabled={acao === "visualizar"}
              inputValue={inputAutoCompleteValue}
              setInputValue={setInputAutoComplete}
              options={participantesOptions}
              radiusborder={values.length > 0 ? "5px 5px 0 0" : "5px"}
              labelFormat={(option) =>
                `${option.nome} - ${FormatCpfPaste(option.cpf)}`
              }
              onOptionSelect={handleOptionSelect}
              placeholder="Participante"
            />
          </CCol>
          <CCol md={2}>
            <PTitulosInputs>Valor</PTitulosInputs>
            <InputFormatRealBrasileiro
              disabled={acao === "visualizar"}
              radiusborder={values.length > 0 ? "5px 5px 0 0" : "5px"}
              value={defValues.proposta.valor}
              onChange={(e, valor) => {
                setDefValues((prevValues) => ({
                  ...prevValues,
                  proposta: {
                    ...prevValues.proposta,
                    valor: valor,
                  },
                }));
              }}
              style={{ height: "30px" }}
            />
          </CCol>
          <CCol md={2}>
            <PTitulosInputs>Situação</PTitulosInputs>
            <FloatingLabelInput
              disabled={acao === "visualizar"}
              new={true}
              holdSelect
              style={{
                color: "#515c70",
              }}
              height="30px"
              tipo="input_select"
              value={defValues.proposta.situacaoProposta}
              radiusborder={values.length > 0 ? "5px 5px 0 0" : "5px"}
              options={
                !acao && [
                  { label: "Situação", value: null },
                  { label: "Vencedora", value: "1" },
                  { label: "Perdedora", value: "2" },
                ]
              }
              onSelect={(option) => {
                setDefValues((prevValues) => ({
                  ...prevValues,
                  proposta: {
                    ...prevValues.proposta,
                    situacaoProposta: option.value,
                  },
                }));
              }}
              noArrow={acao}
            />
          </CCol>
          <CColButtonsAcoes2Icons md={1}>
            <PTitulosInputs>Ação</PTitulosInputs>
            <IconAdicionar
              onClick={
                acao === "visualizar"
                  ? () => console.log("Desativado")
                  : addValues
              }
              color={acao === "visualizar" ? "#bcc1c4" : "#105200"}
              style={{ cursor: acao === "visualizar" ? "default" : "pointer" }}
            />
          </CColButtonsAcoes2Icons>
        </RowAninhada>

        {/* - Dados - */}
        {values.map((item, index) => {
          const border = index === values.length - 1 ? "0 0 5px 5px" : "0";
          return (
            <RowAninhada key={index}>
              <CCol md={1} style={{ width: "9em" }}>
                <FormControlListaDeInputs
                  disabled
                  radiusborder={border}
                  value={numeroLicitacao}
                />
              </CCol>
              <CCol md={2} style={{ width: "18em" }}>
                <FloatingLabelInput
                  disabled
                  new={true}
                  holdSelect
                  style={{
                    color: "#515c70",
                  }}
                  height="30px"
                  tipo="input_select"
                  placeholder="Modalidade"
                  value={String(item.modalidadeLicitacao)}
                  radiusborder={border}
                  options={[
                    { label: "Modalidade", value: null },
                    ...modalidades,
                  ]}
                />
              </CCol>
              <CCol>
                <FormControlListaDeInputs
                  disabled
                  radiusborder={border}
                  value={item.fornecedor.nome}
                />
              </CCol>
              <CCol md={2}>
                <FormControlListaDeInputs
                  disabled
                  radiusborder={border}
                  value={formatToCurrency(item.proposta.valor)}
                />
              </CCol>
              <CCol md={2}>
                <FormControlListaDeInputs
                  disabled
                  radiusborder={border}
                  value={
                    item.proposta.situacaoProposta === "1"
                      ? "Vencedora"
                      : "Perdedora"
                  }
                />
              </CCol>
              <CColButtonsAcoes2Icons md={1}>
                {/* Ícone para ações futuras, se necessário */}
              </CColButtonsAcoes2Icons>
            </RowAninhada>
          );
        })}
      </Row>
    </>
  );
};

export default Participantes;
