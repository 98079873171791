import React, { useContext, useEffect, useState } from "react";
import { Container, Row } from "react-bootstrap";
import HeaderCadastros from "../../../../components/HeaderCadastros/HeaderCadastros";
import { CCol, RowAninhada } from "../../../../components/Grid/CCol";
import FloatingLabelInput from "../../../../components/Grid/FloatingLabelInput";
import Localizacao from "./Localizacao";
import Valores from "./Valores";
import EvolucaoObra from "./EvolucaoObra";
// import localApi from "../../../../utils/localApi";
import api from "../../../../utils/api";
import { useParams } from "react-router-dom";
import { tiposExecucaoOptions, tiposObrasOptions } from "./Options";
import { CurrentClientContext } from "../../../../contexts/CurrentClientContext";
import { fetchRetry } from "../../../../utils/fetchRetry";
import { toast, ToastContainer } from "react-toastify";

const Obra = () => {
  const { currentClient } = useContext(CurrentClientContext);
  const { obraId } = useParams();
  const { acao } = useParams();

  const [values, setValues] = useState({
    clienteId: currentClient.clienteId,
    numeroObra: "",
    tipoObra: {
      apenasGeometria: "",
      id: "",
      nome: "",
      tipoGeorreferenciamento: "",
      unidade: "",
    },
    tipoExecucao: "",
    numeroProtocolo: "",
    titulo: "",
    descricao: "",
    cep: "",
    logradouro: "",
    numeroEndereco: "",
    complemento: "",
    bairro: "",
    municipio: "",
    estado: "",
    dimensao: "",
    valor: "",
    dataInicio: "",
    dataPrevisaoEntrega: "",
    situacao: "",
  });

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
  };

  const getTCEData = async () => {
    let response = [];
    try {
      if (acao === "visualizar") {
        response = await fetchRetry(
          `https://geopb.tce.pb.gov.br/api/v1/obras/${obraId}`
        );
        console.log("obra: ", response.data);
        setValues((prevValues) => ({
          ...prevValues,
          numeroObra: response.data.numeroObra || "",
          numeroProtocolo: response.data.numeroProtocolo || "",
          descricao: response.data.descricao || "",
          cep: response.data.cep?.replace(/-/g, "") || "",
          valor: response.data.valor || 0,
          dimensao: response.data.dimensao || 0,
          tipoObra: response.data.relacionamentoResource.tipoObra,
          tipoExecucao: response.data.tipoExecucao,
          dataInicio: formatDate(
            response.data.relacionamentoResource.evolucao.dataInicio
          ),
          dataPrevisaoEntrega: formatDate(
            response.data.relacionamentoResource.evolucao.dataPrevisaoEntrega
          ),
          situacao: response.data.situacao,
          titulo: `${response.data.nomeJurisdicionadoContrato} - ${
            response.data.relacionamentoResource.tipoObra.nome
              .charAt(0)
              .toUpperCase() +
            response.data.relacionamentoResource.tipoObra.nome
              .slice(1)
              .toLowerCase()
          }`,
        }));
      } else {
        response = await api.get(`/obras/por-id/${obraId}`);
        console.log("obra: ", response.data);
        setValues((prevValues) => ({
          ...prevValues,
          numeroObra: response.data.numeroObra || "",
          numeroProtocolo: response.data.numeroProtocolo || "",
          titulo: response.data.titulo,
          descricao: response.data.descricao || "",
          cep: response.data.cep?.replace(/-/g, "") || "",
          numeroEndereco: response.data.numeroEndereco,
          complemento: response.data.complemento,
          valor: response.data.valor || 0,
          dimensao: response.data.dimensao || 0,
          tipoObra: response.data.tipoObra,
          tipoExecucao: response.data.tipoExecucao,
          dataInicio: formatDate(response.data.dataInicio),
          dataPrevisaoEntrega: formatDate(response.data.dataPrevisaoEntrega),
          situacao: response.data.situacao,
        }));
      }
    } catch (error) {
      console.error("Erro ao buscar os dados da obra:", error);
    }
  };

  useEffect(() => {
    if (obraId) {
      getTCEData();
    }
    // eslint-disable-next-line
  }, [obraId]);

  const saveObra = async () => {
    let valuesToPost = values;
    valuesToPost.tipoObra = valuesToPost.tipoObra.id;
    try {
      if (!obraId) {
        const response = await api.post("/obras", valuesToPost);
        toast.success(response.data.message);
        setValues({
          clienteId: currentClient.clienteId,
          numeroObra: "",
          tipoObra: {
            apenasGeometria: "",
            id: "",
            nome: "",
            tipoGeorreferenciamento: "",
            unidade: "",
          },
          tipoExecucao: "",
          numeroProtocolo: "",
          titulo: "",
          descricao: "",
          cep: "",
          logradouro: "",
          numeroEndereco: "",
          complemento: "",
          bairro: "",
          municipio: "",
          estado: "",
          dimensao: "",
          valor: "",
          dataInicio: "",
          dataPrevisaoEntrega: "",
          situacao: "",
        });
      } else {
        const response = await api.put(`/obras/por-id/${obraId}`, valuesToPost);
        toast.success(response.data.message);
      }
    } catch (error) {
      console.log(error);
      toast.error(error.data.message || "Erro ao cadastrar obra");
    }
  };

  return (
    <Container
      id="pags_responsividade_padding_geral"
      fluid
      style={{ color: "#515C70" }}
      className="container_conteudo conteudo_pagina"
    >
      <HeaderCadastros
        NomePaginaTitulo={"Obra"}
        PaginaSubtitulo={"Cadastro"}
        PaginaConsulta={"/contabilidade/cadastros/obras/consulta"}
        ButtonSaveFunction={saveObra}
        BotaoNovoAtivo={false}
        BotaoSaveAtivo={acao === "visualizar" ? false : true}
      />
      <ToastContainer />
      <Row className={"row_form mt-1"}>
        <RowAninhada>
          <CCol md={2}>
            <FloatingLabelInput
              disabled={obraId}
              label={"Número da Obra"}
              placeholder={"Número da Obra"}
              value={
                values.numeroObra !== undefined && values.numeroObra !== null
                  ? values.numeroObra
                  : ""
              }
              onChange={(e) =>
                setValues((prevValues) => ({
                  ...prevValues,
                  numeroObra: e.target.value,
                }))
              }
            />
          </CCol>
          <CCol>
            <FloatingLabelInput
              disabled={acao === "visualizar"}
              new={true}
              holdSelect
              style={{
                color: "#515c70",
              }}
              tipo="input_select"
              label="Tipo de Obra"
              placeholder="Tipo de Obra"
              value={values.tipoObra.id}
              options={[
                { label: "Tipo de Obra", value: null },
                ...tiposObrasOptions,
              ]}
              onSelect={(option) => {
                console.log(option);
                setValues((prevValues) => ({
                  ...prevValues,
                  tipoObra: {
                    ...prevValues.tipoObra,
                    id: option.value,
                  },
                }));
              }}
            />
          </CCol>
          <CCol>
            <FloatingLabelInput
              disabled={acao === "visualizar"}
              new={true}
              holdSelect
              style={{
                color: "#515c70",
              }}
              tipo="input_select"
              label="Tipo de Execução"
              placeholder="Tipo de Execução"
              value={values.tipoExecucao}
              options={[
                { label: "Tipo de Execução", value: null },
                ...tiposExecucaoOptions,
              ]}
              onSelect={(option) =>
                setValues((prevValues) => ({
                  ...prevValues,
                  tipoExecucao: option.value,
                }))
              }
            />
          </CCol>
          <CCol md={2} style={{ width: "15em" }}>
            <FloatingLabelInput
              disabled={acao === "visualizar"}
              label={"Número do Protocolo"}
              placeholder={"Número do Protocolo"}
              value={values.numeroProtocolo || ""}
              onChange={(e) =>
                setValues((prevValues) => ({
                  ...prevValues,
                  numeroProtocolo: e.target.value,
                }))
              }
            />
          </CCol>
        </RowAninhada>
        <RowAninhada className={"mt-3"}>
          <CCol>
            <FloatingLabelInput
              disabled={acao === "visualizar"}
              label={"Título da Obra"}
              placeholder={"Título da Obra"}
              value={values.titulo || ""}
              onChange={(e) =>
                setValues((prevValues) => ({
                  ...prevValues,
                  titulo: e.target.value,
                }))
              }
            />
          </CCol>
        </RowAninhada>
        <RowAninhada className={"mt-3"}>
          <CCol>
            <FloatingLabelInput
              disabled={acao === "visualizar"}
              label={"Descrição da Obra"}
              placeholder={"Descrição da Obra"}
              as="textarea"
              tipo="text_area"
              style={{ minHeight: "63px" }}
              value={values.descricao || ""}
              onChange={(e) =>
                setValues((prevValues) => ({
                  ...prevValues,
                  descricao: e.target.value,
                }))
              }
            />
          </CCol>
        </RowAninhada>
      </Row>
      <Row className="mt-3">
        <CCol
          className="d-flex align-items-center p-2 header_body_conteudo"
          md={12}
        >
          <span
            style={{
              fontSize: "16.5px",
              fontWeight: "600",
              marginLeft: "10px",
            }}
          >
            Localização
          </span>
        </CCol>
      </Row>
      <Localizacao values={values} setValues={setValues} acao={acao} />
      <Row className="mt-3">
        <CCol
          className="d-flex align-items-center p-2 header_body_conteudo"
          md={12}
        >
          <span
            style={{
              fontSize: "16.5px",
              fontWeight: "600",
              marginLeft: "10px",
            }}
          >
            Valores
          </span>
        </CCol>
      </Row>
      <Valores values={values} setValues={setValues} acao={acao} />
      <Row className="mt-3">
        <CCol
          className="d-flex align-items-center p-2 header_body_conteudo"
          md={12}
        >
          <span
            style={{
              fontSize: "16.5px",
              fontWeight: "600",
              marginLeft: "10px",
            }}
          >
            Evolução da Obra
          </span>
        </CCol>
      </Row>
      <EvolucaoObra values={values} setValues={setValues} obraId={obraId} />
      <div style={{ minHeight: "200px" }}> </div>
    </Container>
  );
};

export default Obra;
