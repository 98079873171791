import React from "react";
import ComponentCadastroCpfCnpj from "../../../../components/ComponentCadastroCpfCnpj";

const CPFCNPJ = () => {
  return (
    <ComponentCadastroCpfCnpj pagConsulta="/contabilidade/cadastros/cpf-cnpj/consulta" />
  );
};

export default CPFCNPJ;
