export const listaDeTiposObras = [
  {
    id: 1,
    nome: "ABASTECIMENTO DE ÁGUA",
    unidade: "Unidade(s)",
    tipoGeorreferenciamento: "POLYGON",
    apenasGeometria: true,
  },
  {
    id: 2,
    nome: "AÇUDE BARRAGEM",
    unidade: "Volume Armazenado (m3)",
    tipoGeorreferenciamento: "POINT",
    apenasGeometria: false,
  },
  {
    id: 4,
    nome: "PRONTO SOCORRO",
    unidade: "Área Construída (m2)",
    tipoGeorreferenciamento: "POINT",
    apenasGeometria: false,
  },
  {
    id: 5,
    nome: "CRECHE",
    unidade: "Área Construída (m2)",
    tipoGeorreferenciamento: "POINT",
    apenasGeometria: false,
  },
  {
    id: 6,
    nome: "ELETRIFICAÇÃO",
    unidade: "Extensão (km)",
    tipoGeorreferenciamento: "POLYGON",
    apenasGeometria: true,
  },
  {
    id: 7,
    nome: "ESCOLA",
    unidade: "Área Construída (m2)",
    tipoGeorreferenciamento: "POINT",
    apenasGeometria: false,
  },
  {
    id: 8,
    nome: "ESTRADA DE TERRA",
    unidade: "Extensão (km)",
    tipoGeorreferenciamento: "POLYGON",
    apenasGeometria: true,
  },
  {
    id: 9,
    nome: "GALERIA PLUVIAL",
    unidade: "Comprimento (m)",
    tipoGeorreferenciamento: "POLYGON",
    apenasGeometria: true,
  },
  {
    id: 10,
    nome: "GINÁSIO POLIESPORTIVO / QUADRA DE ESPORTES",
    unidade: "Área Construída (m2)",
    tipoGeorreferenciamento: "POINT",
    apenasGeometria: false,
  },
  {
    id: 11,
    nome: "HOSPITAL",
    unidade: "Área Construída (m2)",
    tipoGeorreferenciamento: "POINT",
    apenasGeometria: false,
  },
  {
    id: 12,
    nome: "MERCADO PÚBLICO / ABATEDOURO",
    unidade: "Área Construída (m2)",
    tipoGeorreferenciamento: "POINT",
    apenasGeometria: false,
  },
  {
    id: 13,
    nome: "PASSAGEM MOLHADA",
    unidade: "Área Construída (m2)",
    tipoGeorreferenciamento: "BOTH",
    apenasGeometria: false,
  },
  {
    id: 14,
    nome: "PAVIMENTAÇÃO ASFALTO",
    unidade: "Área Construída (m2)",
    tipoGeorreferenciamento: "POLYGON",
    apenasGeometria: true,
  },
  {
    id: 15,
    nome: "PAVIMENTAÇÃO PARALEPÍPEDO",
    unidade: "Área Construída (m2)",
    tipoGeorreferenciamento: "POLYGON",
    apenasGeometria: true,
  },
  {
    id: 17,
    nome: "POÇO ARTESIANO",
    unidade: "Profundidade (m)",
    tipoGeorreferenciamento: "BOTH",
    apenasGeometria: false,
  },
  {
    id: 18,
    nome: "PONTE",
    unidade: "Área Construída (m2)",
    tipoGeorreferenciamento: "POINT",
    apenasGeometria: false,
  },
  {
    id: 19,
    nome: "POSTO DE SAÚDE",
    unidade: "Área Construída (m2)",
    tipoGeorreferenciamento: "POINT",
    apenasGeometria: false,
  },
  {
    id: 20,
    nome: "PRAÇA",
    unidade: "Área Construída (m2)",
    tipoGeorreferenciamento: "POINT",
    apenasGeometria: false,
  },
  {
    id: 21,
    nome: "PRESÍDIO",
    unidade: "Área Construída (m2)",
    tipoGeorreferenciamento: "POINT",
    apenasGeometria: false,
  },
  {
    id: 22,
    nome: "REDE COLETORA DE ESGOTO",
    unidade: "Quantidade de Ligações",
    tipoGeorreferenciamento: "POINT",
    apenasGeometria: true,
  },
  {
    id: 23,
    nome: "UNIDADE HABITACIONAL",
    unidade: "Unidade(s)",
    tipoGeorreferenciamento: "POINT",
    apenasGeometria: false,
  },
  {
    id: 24,
    nome: "OUTRAS",
    unidade: "-",
    tipoGeorreferenciamento: "POINT",
    apenasGeometria: false,
  },
  {
    id: 25,
    nome: "AEROPORTO",
    unidade: "Área do Projeto (m2)",
    tipoGeorreferenciamento: "POINT",
    apenasGeometria: false,
  },
  {
    id: 26,
    nome: "ADUTORA",
    unidade: "Extensão (km)",
    tipoGeorreferenciamento: "POINT",
    apenasGeometria: true,
  },
  {
    id: 27,
    nome: "ATERRO SANITÁRIO",
    unidade: "Área do Projeto (m2)",
    tipoGeorreferenciamento: "POINT",
    apenasGeometria: false,
  },
  {
    id: 28,
    nome: "CAIXA D'ÁGUA",
    unidade: "Volume Armazenado (m3)",
    tipoGeorreferenciamento: "POINT",
    apenasGeometria: false,
  },
  {
    id: 29,
    nome: "CISTERNA",
    unidade: "Unidade(s)",
    tipoGeorreferenciamento: "BOTH",
    apenasGeometria: false,
  },
  {
    id: 30,
    nome: "CANAL",
    unidade: "Extensão (km)",
    tipoGeorreferenciamento: "POLYGON",
    apenasGeometria: true,
  },
  {
    id: 31,
    nome: "MÓDULO SANITÁRIO",
    unidade: "Unidade(s)",
    tipoGeorreferenciamento: "BOTH",
    apenasGeometria: false,
  },
  {
    id: 32,
    nome: "MURO DE CONTENÇÃO DE ATERROS",
    unidade: "Volume da Parede (m3)",
    tipoGeorreferenciamento: "POINT",
    apenasGeometria: false,
  },
  {
    id: 33,
    nome: "PASSARELA",
    unidade: "Comprimento (m)",
    tipoGeorreferenciamento: "POINT",
    apenasGeometria: false,
  },
  {
    id: 34,
    nome: "POÇO TUBULAR",
    unidade: "Profundidade (m)",
    tipoGeorreferenciamento: "BOTH",
    apenasGeometria: false,
  },
  {
    id: 35,
    nome: "VIADUTO",
    unidade: "Área Construída (m2)",
    tipoGeorreferenciamento: "POINT",
    apenasGeometria: false,
  },
  {
    id: 36,
    nome: "PSF",
    unidade: "Área Construída (m2)",
    tipoGeorreferenciamento: "POINT",
    apenasGeometria: false,
  },
  {
    id: 37,
    nome: "SERVIÇO DE LIMPEZA E COLETA DE RESÍDUOS SÓLIDOS",
    unidade: "Extensão Percorrida (km)",
    tipoGeorreferenciamento: "POLYGON",
    apenasGeometria: false,
  },
  {
    id: 38,
    nome: "REDE DE ABASTECIMENTO",
    unidade: "Comprimento (m)",
    tipoGeorreferenciamento: "POLYGON",
    apenasGeometria: true,
  },
];

export const tiposObrasOptions = [
  { value: 1, label: "Abastecimento de Água" },
  { value: 2, label: "Açude Barragem" },
  { value: 4, label: "Pronto Socorro" },
  { value: 5, label: "Creche" },
  { value: 6, label: "Eletrificação" },
  { value: 7, label: "Escola" },
  { value: 8, label: "Estrada de Terra" },
  { value: 9, label: "Galeria Pluvial" },
  { value: 10, label: "Ginásio Poliesportivo / Quadra de Esportes" },
  { value: 11, label: "Hospital" },
  { value: 12, label: "Mercado Público / Abatedouro" },
  { value: 13, label: "Passagem Molhada" },
  { value: 14, label: "Pavimentação Asfalto" },
  { value: 15, label: "Pavimentação Paralelepípedo" },
  { value: 17, label: "Poço Artesiano" },
  { value: 18, label: "Ponte" },
  { value: 19, label: "Posto de Saúde" },
  { value: 20, label: "Praça" },
  { value: 21, label: "Presídio" },
  { value: 22, label: "Rede Coletora de Esgoto" },
  { value: 23, label: "Unidade Habitacional" },
  { value: 24, label: "Outras" },
  { value: 25, label: "Aeroporto" },
  { value: 26, label: "Adutora" },
  { value: 27, label: "Aterro Sanitário" },
  { value: 28, label: "Caixa D'Água" },
  { value: 29, label: "Cisterna" },
  { value: 30, label: "Canal" },
  { value: 31, label: "Módulo Sanitário" },
  { value: 32, label: "Muro de Contenção de Aterros" },
  { value: 33, label: "Passarela" },
  { value: 34, label: "Poço Tubular" },
  { value: 35, label: "Viaduto" },
  { value: 36, label: "Psf" },
  { value: 37, label: "Serviço de Limpeza e Coleta de Resíduos Sólidos" },
  { value: 38, label: "Rede de Abastecimento" },
];

export const tiposExecucaoOptions = [
  { value: "NOVA_CONSTRUCAO", label: "Nova Construção" },
  { value: "CONSTRUCAO", label: "Construção" },
  { value: "CONTINUACAO_CONSTRUCAO", label: "Continuação de Construção" },
  { value: "AMPLIACAO", label: "Ampliação" },
  { value: "REFORMA", label: "Reforma" },
  { value: "RECUPERACAO", label: "Recuperação" },
  { value: "SERVICO", label: "Serviço" },
  { value: "AMPLIACAO,REFORMA", label: "Ampliação e Reforma" },
  { value: "AMPLIACAO,RECUPERACAO", label: "Ampliação e Recuperação" },
  { value: "AMPLIACAO,SERVICO", label: "Ampliação e Serviço" },
  { value: "REFORMA,RECUPERACAO", label: "Reforma e Recuperação" },
  { value: "REFORMA,SERVICO", label: "Reforma e Serviço" },
  { value: "RECUPERACAO,SERVICO", label: "Recuperação e Serviço" },
  {
    value: "AMPLIACAO,REFORMA,RECUPERACAO",
    label: "Ampliação, Reforma e Recuperação",
  },
  { value: "AMPLIACAO,REFORMA,SERVICO", label: "Ampliação, Reforma e Serviço" },
  {
    value: "AMPLIACAO,RECUPERACAO,SERVICO",
    label: "Ampliação, Recuperação e Serviço",
  },
  {
    value: "REFORMA,RECUPERACAO,SERVICO",
    label: "Reforma, Recuperação e Serviço",
  },
  {
    value: "AMPLIACAO,REFORMA,RECUPERACAO,SERVICO",
    label: "Ampliação, Reforma, Recuperação e Serviço",
  },
];

export const situacaoOptions = [
  { value: "Em Execução Normal", label: "Em Execução Normal" },
  { value: "Paralisada", label: "Paralisada" },
  {
    value: "Finalizada por conclusão de construção",
    label: "Finalizada por conclusão de construção",
  },

  {
    value: "Finalizada Administrativa ou Judicialmente",
    label: "Finalizada Administrativa ou Judicialmente",
  },
  {
    value: "Situação recente ainda não informada",
    label: "Situação recente ainda não informada",
  },
  { value: "Atrasada", label: "Atrasada" },
];
