import { Icon } from "@iconify/react/dist/iconify.js";
import React from "react";

const IconAdicionar = (props) => {
  return (
    <Icon
      onClick={props.onClick}
      icon="ic:baseline-add-box"
      color={props.color ? props.color : "#105200"}
      height="26"
      {...props}
      style={{
        cursor: "pointer",
        ...props.style,
      }}
    />
  );
};

export default IconAdicionar;
