import api from "../../utils/api";
import { fetchRetry } from "../../utils/fetchRetry";

export const getObrasTcePorCliente = async (clienteId, codigoTceCliente) => {
  try {
    let clientObrasComTce = [];
    try {
      const jurisdicionados = await fetchRetry(
        "https://geopb.tce.pb.gov.br/api/v1/tramita/jurisdicionados/"
      );
      const obras = await fetchRetry(
        "https://geopb.tce.pb.gov.br/api/v1/obras/lista/"
      );

      const idTramita = jurisdicionados.data.find(
        (item) => parseInt(item.codigoSagres) === parseInt(codigoTceCliente)
      )?.id_tramita;

      if (idTramita) {
        const clientObras = obras.data.filter(
          (item) => parseInt(item.jurisdicionado) === parseInt(idTramita)
        );
        clientObrasComTce = clientObras.map((obra) => ({
          ...obra,
          tce: true,
        }));
      }
    } catch (error) {
      console.error("Erro ao buscar dados do TCE:", error);
      // Continua mesmo se houver erro ao buscar jurisdicionados ou obras/listas
    }

    try {
      const obrasLocais = await api.get(`/obras/por-cliente/${clienteId}`);
      const obrasLocaisComTce = obrasLocais.data.map((obra) => ({
        ...obra,
        tce: false,
      }));

      const obrasFinais = [...clientObrasComTce, ...obrasLocaisComTce];
      console.log(obrasFinais);
      return obrasFinais;
    } catch (error) {
      console.error("Erro ao buscar dados locais:", error);
      // Retorna apenas as obras do TCE caso o get local falhe
      return clientObrasComTce;
    }
  } catch (error) {
    console.error("Erro inesperado:", error);
    return [];
  }
};

export const getConveniosPbPorAno = async (ano) => {
  try {
    // const resp = await api.get(`/convenio/${ano}`);
    // const data = resp.data;
    const test = [];
    return test;
  } catch (error) {
    return [];
  }
};
