export const gerenciaOpcoesSubelemento = (elementoDeDespesa, allNaturezas) => {
  let subelementosFitlrados = allNaturezas
    .filter((item) =>
      item.naturezaDaDespesa.codigo.startsWith(elementoDeDespesa)
    )
    .map((item) => ({
      label: `${item.naturezaDaDespesa.codigo.split(".")[4]} - ${
        item.naturezaDaDespesa.nome
      }`,
      value: `${item.naturezaDaDespesa.codigo.split(".")[4]} - ${
        item.naturezaDaDespesa.nome
      }`,
    }));

  if (subelementosFitlrados.length > 1) {
    subelementosFitlrados = subelementosFitlrados.filter(
      (item) => item.value.replace(/[^0-9]/g, "") !== "00"
    );
  }

  return subelementosFitlrados;
};
