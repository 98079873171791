import React, { useContext, useState } from "react";
import { RowTituloAba } from "../../../PagesAcessoInterno/PagContaPerfil/StyledComponentsContaPerfil";
import { CCol, RowForm } from "../../../../components/Grid/CCol";
import FloatingLabelInput from "../../../../components/Grid/FloatingLabelInput";
import { optionsTranferencias } from "./optionsTranferenciasRecebidas";
import { CurrentClientContext } from "../../../../contexts/CurrentClientContext";

export const TransferenciaRecebidaAba = ({
  optionsUnidadesGestoras,
  infosCadastro,
  setInfosCadastro,
}) => {
  const { currentClient } = useContext(CurrentClientContext);

  const [itemUnidadeGestoraValue, setItemUnidadeGestoraValue] = useState(null);
  const [itemTipoTransferenciaValue, setItemTipoTransferenciaValue] =
    useState(null);
  const [optionsTipoTransferencia, setOptionsTipoTranferencias] =
    useState(optionsTranferencias);

  const handleSelectUnidadeGestora = (optionUnidade) => {
    console.log(optionUnidade);
    setInfosCadastro((prev) => ({
      ...prev,
      unidadeGestoraId: optionUnidade.value,
      tipoNaturezaJuridica: optionUnidade.tipoNaturezaJuridica,
    }));

    const tipoNaturezaJuridicaIsCamara =
      optionUnidade.tipoNaturezaJuridica === "1 - Câmara Municipal";

    const currentClientIsPrefeitura =
      currentClient.tipoNaturezaJuridica ===
      "2 - Prefeitura Municipal/Secretarias";

    if (tipoNaturezaJuridicaIsCamara && currentClientIsPrefeitura) {
      const filterOptionsTransferencia = optionsTranferencias.filter(
        (item) => item.label === "8 - Devolução de Recursos"
      );
      setOptionsTipoTranferencias(filterOptionsTransferencia);
      setItemTipoTransferenciaValue(filterOptionsTransferencia[0].value)
      return;
    }

    setOptionsTipoTranferencias(optionsTranferencias);
  };

  const handleSelectTipoTransferencia = (optionTipoTranferencia) => {
    console.log(optionTipoTranferencia);
    setInfosCadastro((prev) => ({
      ...prev,
      tipoTransferencia: optionTipoTranferencia.label,
    }));
  };

  console.log(currentClient);

  return (
    <>
      <RowTituloAba style={{ marginTop: "15px" }}>
        <div>
          <span>Transferência Recebida</span>
        </div>
      </RowTituloAba>

      <RowForm className="mt-1">
        <CCol md={8}>
          <FloatingLabelInput
            tipo="input_select"
            placeholder="Unidade Gestora Transferidora"
            label="Unidade Gestora Transferidora"
            new
            value={itemUnidadeGestoraValue}
            options={
              optionsUnidadesGestoras.length
                ? optionsUnidadesGestoras
                : [{ label: "Unidade Gestora Transferidora", value: null }]
            }
            onSelect={(option) => {
              handleSelectUnidadeGestora(option);
              setItemUnidadeGestoraValue(option.value);
            }}
          />
        </CCol>
        <CCol>
          <FloatingLabelInput
            disabled
            placeholder="Natureza Jurídica"
            label="Natureza Jurídica"
            value={infosCadastro.tipoNaturezaJuridica}
          />
        </CCol>
      </RowForm>
      <RowForm $borderRadiusType={2} style={{ marginTop: "-15px" }}>
        <CCol>
          <FloatingLabelInput
            tipo="input_select"
            new
            placeholder="Tipo de Transferência"
            label="Tipo de Transferência"
            value={itemTipoTransferenciaValue}
            options={[
              { label: "Tipo de Transferência", value: null },
              ...optionsTipoTransferencia,
            ]}
            onSelect={(option) => {
              handleSelectTipoTransferencia(option);
              setItemTipoTransferenciaValue(option.value);
            }}
          />
        </CCol>
      </RowForm>
    </>
  );
};
