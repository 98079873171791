import React, { useState, useContext, useEffect } from "react";
import { Icon } from "@iconify/react/dist/iconify.js";
import { CurrentYearContext } from "../../../../../../../contexts/YearContext";
import {
  CCol,
  RowAninhada,
  CColButtonsAcoes2Icons,
} from "../../../../../../../components/Grid/CCol";
import { FormControlListaDeInputs } from "../../../../../../../styles/StyledComponents/ListaDeInputs";
import styled from "styled-components";
import { Row } from "react-bootstrap";

const CustomInput = ({ title, md = 2, size, Btop, Bbottom, ...props }) => {
  return (
    <CCol md={md} style={size && { width: size }}>
      {title && <Title>{title}</Title>}
      <FormControlListaDeInputs
        radiusborder={Btop ? "5px 5px 0 0" : Bbottom ? "0 0 5px 5px" : "0"}
        {...props}
      />
    </CCol>
  );
};

const PrepararQDD = () => {
  const { currentYear } = useContext(CurrentYearContext);

  const [values, setValues] = useState([]);
  const [oldValues, setOldValues] = useState([]);
  const [editMode, setEditMode] = useState([]);
  const [titles, setTitles] = useState([]);
  const [saldosData, setSaldosData] = useState([]);
  // const [entidades, setEntidades] = useState([]);

  const Icons = ({ marginTop = "2px", index, onClick }) => {
    const open = editMode[index];
    return (
      <>
        <Icon
          icon="bx:edit"
          color="#5971C8"
          height="26"
          style={{
            cursor: "pointer",
            marginLeft: "5px",
            marginTop: marginTop,
          }}
          onClick={() => onClick(index, open)}
        />
        <Icon
          icon="ic:baseline-cancel"
          color={!editMode[index] ? "#F24E1E" : "transparent"}
          height="26"
          style={{
            cursor: !editMode[index] ? "pointer" : "auto",
            marginLeft: "2px",
            marginTop: marginTop,
          }}
          onClick={() => onClick(index, false, true)}
        />
      </>
    );
  };

  const updateTitles = () => {
    setTitles([
      "Elemento de Despesa",
      `${currentYear - 4} - Fixada`,
      `${currentYear - 4} - Realizada`,
      `${currentYear - 3} - Fixada`,
      `${currentYear - 3} - Realizada`,
      `${currentYear - 2} - Fixada`,
      `${currentYear - 2} - Realizada`,
      `${currentYear - 1} - Fixada`,
      `%`,
      `${currentYear} - Fixada`,
      `%`,
      `${currentYear + 1} - Fixada`,
      `%`,
      `${currentYear + 2} - Fixada`,
      `%`,
    ]);
  };

  const updateValues = () => {
    const newValues = [0, 0].map((_, index) => {
      return {
        elementoDeDespesa: `1${index}`,
        [`${currentYear - 4}Fixada`]: `4${index}`,
        [`${currentYear - 4}Realizada`]: `5${index}`,
        [`${currentYear - 3}Fixada`]: `6${index}`,
        [`${currentYear - 3}Realizada`]: `7${index}`,
        [`${currentYear - 2}Fixada`]: `8${index}`,
        [`${currentYear - 2}Realizada`]: `9${index}`,
        [`${currentYear - 1}Fixada`]: `10${index}`,
        [`${currentYear - 1}Percentual`]: `12${index}`,
        [`${currentYear}Fixada`]: `13${index}`,
        [`${currentYear}Percentual`]: `13${index}`,
        [`${currentYear + 1}Fixada`]: `14${index}`,
        [`${currentYear + 1}Percentual`]: `15${index}`,
        [`${currentYear + 2}Fixada`]: `16${index}`,
        [`${currentYear + 2}Percentual`]: `17${index}`,
      };
    });
    const editables = new Array(newValues.length).fill(true);
    setValues(newValues);
    setOldValues(newValues);
    setEditMode(editables);
  };

  const handleInputChange = (index, field, value) => {
    setValues((prevValues) =>
      prevValues.map((item, i) =>
        i === index ? { ...item, [field]: value } : item
      )
    );
  };

  const handleEditMode = (index, open, cancel) => {
    setEditMode((prevEditMode) => {
      const newEditMode = [...prevEditMode];
      newEditMode[index] = !newEditMode[index];
      return newEditMode;
    });

    if (open) {
      setOldValues(() => {
        const thisOldValues = oldValues;
        thisOldValues[index] = values[index];
        return thisOldValues;
      });
    }

    if (cancel) {
      setValues(() => {
        const thisValues = values;
        thisValues[index] = oldValues[index];
        return thisValues;
      });
    }
  };

  useEffect(() => {
    updateValues();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    updateTitles();
    // eslint-disable-next-line
  }, [currentYear]);

  useEffect(() => {
    const updateSaldosData = () => {
      const newSaldosData = [];
      for (let i = -4; i <= 2; i++) {
        const year = currentYear + i;
        const existingData = saldosData.find(
          (obj) => Object.keys(obj)[0] === `${year}`
        );
        newSaldosData.push({
          [year]: existingData
            ? existingData[year]
            : [{ input1: "0" }, { input2: "0" }, { input3: "0" }],
        });
      }
      setSaldosData(newSaldosData);
    };
    updateSaldosData();
    // eslint-disable-next-line
  }, [currentYear]);

  return (
    <>
      <Row className="row_form mt-1">
        <StyledCCol style={{ height: "100px" }}>
          {values?.map((_, index) => {
            const btop = index === 0;
            const bbottom = index === values.length - 1;
            return (
              <RowAninhada
                style={{
                  display: "flex",
                  flexWrap: "nowrap",
                }}
              >
                <CustomInput
                  disabled={editMode[index]}
                  title={index === 0 && titles[0]}
                  Btop={btop}
                  Bbottom={bbottom}
                  value={values[index]?.elementoDeDespesa || ""}
                  onChange={(e) =>
                    handleInputChange(
                      index,
                      "elementoDeDespesa",
                      e.target.value
                    )
                  }
                  md={3}
                />
                {/* <CustomInput
                  disabled={editMode[index]}
                  title={index === 0 && titles[1]}
                  Btop={btop}
                  Bbottom={bbottom}
                  value={values[index]?.[`${currentYear - 4}Fixada`] || ""}
                  onChange={(e) =>
                    handleInputChange(
                      index,
                      `${currentYear - 4}Fixada`,
                      e.target.value
                    )
                  }
                />
                <CustomInput
                  disabled={editMode[index]}
                  title={index === 0 && titles[2]}
                  Btop={btop}
                  Bbottom={bbottom}
                  value={values[index]?.[`${currentYear - 4}Realizada`] || ""}
                  onChange={(e) =>
                    handleInputChange(
                      index,
                      `${currentYear - 4}Realizada`,
                      e.target.value
                    )
                  }
                /> */}
                <CustomInput
                  disabled={editMode[index]}
                  title={index === 0 && titles[3]}
                  Btop={btop}
                  Bbottom={bbottom}
                  value={values[index]?.[`${currentYear - 3}Fixada`] || ""}
                  onChange={(e) =>
                    handleInputChange(
                      index,
                      `${currentYear - 3}Fixada`,
                      e.target.value
                    )
                  }
                />
                <CustomInput
                  disabled={editMode[index]}
                  title={index === 0 && titles[4]}
                  Btop={btop}
                  Bbottom={bbottom}
                  value={values[index]?.[`${currentYear - 3}Realizada`] || ""}
                  onChange={(e) =>
                    handleInputChange(
                      index,
                      `${currentYear - 3}Realizada`,
                      e.target.value
                    )
                  }
                />
                {/* <CustomInput
                  disabled={editMode[index]}
                  title={index === 0 && titles[5]}
                  Btop={btop}
                  Bbottom={bbottom}
                  value={values[index]?.[`${currentYear - 2}Fixada`] || ""}
                  onChange={(e) =>
                    handleInputChange(
                      index,
                      `${currentYear - 2}Fixada`,
                      e.target.value
                    )
                  }
                />
                <CustomInput
                  disabled={editMode[index]}
                  title={index === 0 && titles[6]}
                  Btop={btop}
                  Bbottom={bbottom}
                  value={values[index]?.[`${currentYear - 2}Realizada`] || ""}
                  onChange={(e) =>
                    handleInputChange(
                      index,
                      `${currentYear - 2}Realizada`,
                      e.target.value
                    )
                  }
                /> */}
                <CustomInput
                  disabled={editMode[index]}
                  title={index === 0 && titles[7]}
                  Btop={btop}
                  Bbottom={bbottom}
                  value={values[index]?.[`${currentYear - 1}Fixada`] || ""}
                  onChange={(e) =>
                    handleInputChange(
                      index,
                      `${currentYear - 1}Fixada`,
                      e.target.value
                    )
                  }
                />
                <CustomInput
                  disabled={editMode[index]}
                  title={index === 0 && titles[8]}
                  Btop={btop}
                  Bbottom={bbottom}
                  value={values[index]?.[`${currentYear - 1}Percentual`] || ""}
                  onChange={(e) =>
                    handleInputChange(
                      index,
                      `${currentYear - 1}Percentual`,
                      e.target.value
                    )
                  }
                  size={"80px"}
                />
                <CustomInput
                  disabled={editMode[index]}
                  title={index === 0 && titles[9]}
                  Btop={btop}
                  Bbottom={bbottom}
                  value={values[index]?.[`${currentYear}Fixada`] || ""}
                  onChange={(e) =>
                    handleInputChange(
                      index,
                      `${currentYear}Fixada`,
                      e.target.value
                    )
                  }
                />
                <CustomInput
                  disabled={editMode[index]}
                  title={index === 0 && titles[10]}
                  Btop={btop}
                  Bbottom={bbottom}
                  value={values[index]?.[`${currentYear}Percentual`] || ""}
                  onChange={(e) =>
                    handleInputChange(
                      index,
                      `${currentYear}Realizada`,
                      e.target.value
                    )
                  }
                  size={"80px"}
                />
                <CustomInput
                  disabled={editMode[index]}
                  title={index === 0 && titles[11]}
                  Btop={btop}
                  Bbottom={bbottom}
                  value={values[index]?.[`${currentYear + 1}Fixada`] || ""}
                  onChange={(e) =>
                    handleInputChange(
                      index,
                      `${currentYear + 1}Fixada`,
                      e.target.value
                    )
                  }
                />
                <CustomInput
                  disabled={editMode[index]}
                  title={index === 0 && titles[12]}
                  Btop={btop}
                  Bbottom={bbottom}
                  value={values[index]?.[`${currentYear + 1}Percentual`] || ""}
                  onChange={(e) =>
                    handleInputChange(
                      index,
                      `${currentYear + 1}Percentual`,
                      e.target.value
                    )
                  }
                  size={"80px"}
                />
                <CustomInput
                  disabled={editMode[index]}
                  title={index === 0 && titles[13]}
                  Btop={btop}
                  Bbottom={bbottom}
                  value={values[index]?.[`${currentYear + 2}Fixada`] || ""}
                  onChange={(e) =>
                    handleInputChange(
                      index,
                      `${currentYear + 2}Fixada`,
                      e.target.value
                    )
                  }
                />
                <CustomInput
                  disabled={editMode[index]}
                  title={index === 0 && titles[14]}
                  Btop={btop}
                  Bbottom={bbottom}
                  value={values[index]?.[`${currentYear + 2}Percentual`] || ""}
                  onChange={(e) =>
                    handleInputChange(
                      index,
                      `${currentYear + 2}Percentual`,
                      e.target.value
                    )
                  }
                  size={"80px"}
                />
              </RowAninhada>
            );
          })}
        </StyledCCol>
        <CColButtonsAcoes2Icons md={2}>
          <Title style={{ textAlign: "left" }}>Ação</Title>
          {values.map((_, index) => {
            const margintop = index === 0 ? "2px" : "4px";
            return (
              <Icons
                marginTop={margintop}
                index={index}
                onClick={handleEditMode}
              />
            );
          })}
        </CColButtonsAcoes2Icons>
      </Row>
    </>
  );
};

export default PrepararQDD;

const Title = styled.p`
  text-align: center;
  margin-top: 0;
  padding: 0px;
  margin-bottom: 0px;
  color: #888a8e;
  font-weight: 600;
  font-size: 14px;
`;

const StyledCCol = styled(CCol)`
  overflow: auto;

  &::-webkit-scrollbar {
    height: 4px;
    width: 2px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 2px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;
