import React, { useEffect, useState } from "react";
import { Form, InputGroup } from "react-bootstrap";
import styled from "styled-components";
import { InputMaskStyled } from "./InputSvg";
import { FormSelectStyled } from "../../styles/StyledComponents/FormsStyleds";
import CustomSelectComponent from "./CustomSelectComponent";
import { Icon } from "@iconify/react";

const FormGroup = styled(Form.Group)`
  position: relative;

  .form-control {
    padding-top: 6px;
    border-radius: 0;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    height: ${({ height }) => (height ? height : null)};
    border: 1px solid #ccc;
    border-radius: 5px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }

  .form-control:focus {
    box-shadow: none;
  }

  .form-control::-webkit-outer-spin-button,
  .form-control::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  label {
    position: absolute;
    top: 10px;
    background-color: #cccccc00;
    left: 0.5rem;
    color: #99999900;
    font-size: 0.8rem;
    transition: all 0.2s ease-out;
    pointer-events: none;
  }

  &.active label {
    color: #515c70;
    font-weight: 500;
    padding-right: 3px;
    padding-left: 3px;
    background: linear-gradient(
      to bottom,
      transparent 0,
      transparent calc(50% - 0.5px),
      white 50%,
      white calc(50% + 0.5px),
      transparent 100%
    );
    /* Gradiente que vai de transparente para branco e, em seguida, volta para transparente */
    background-size: 100% 5px; /* Altura da linha branca */
    background-position: 0 50%; /* Posiciona o gradiente no centro vertical do container */
    background-repeat: no-repeat;
    top: -9px;
    left: 13px;
    font-size: 0.75rem;
  }
`;

const FormControl = styled(Form.Control)`
  & {
    padding-left: ${(props) => (props.com_icon ? "27px" : "")};
    background: transparent;
    outline: none;
  }

  &:focus {
    outline: none;
    background: transparent;
  }
`;

const FormTextArea = styled(Form.Control)`
  font-weight: 500;
  padding-left: 12px;
  padding-right: ${({ voiceInput }) => (voiceInput ? "30px" : "12px")};
  padding-top: 6px;
  color: #515c70;
  background: transparent;
  border-radius: 5px;
  border: 1px solid #ccc;
  width: 100%;
  resize: vertical; /* Permite redimensionar verticalmente */
  line-height: 25px;
  overflow-y: ${(props) => (props.overflowY ? props.overflowY : "hidden")};

  &:focus {
    box-shadow: none;
    outline: none;
  }

  &:disabled {
    background-color: #e9ecef;
  }

  ::-webkit-scrollbar {
    width: 2px;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 2px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

const TextAreaContainer = styled.div`
  position: relative;
`;

const ContainerIconVoiceInput = styled.div`
  position: absolute;
  right: 10px;
  top: 5px;
  height: 25px;
  width: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const FloatingLabelInput = React.forwardRef((props, ref) => {
  const [isActive, setIsActive] = useState(false);

  function handleFocus(e) {
    setIsActive(true);

    if (props.onFocus) {
      props.onFocus(e);
    }
  }

  function handleBlur(event) {
    if (event.target.value.trim() === "") {
      setIsActive(false);
    }

    if (props.onBlur) {
      props.onBlur(event);
    }
  }

  useEffect(() => {
    if (["", null, undefined].includes(props.value)) {
      setIsActive(false);
    }
  }, [props.value]);

  return (
    <FormGroup
      className={`${isActive || props.value ? "active" : ""}`}
      height={props.height ? props.height : null}
      $controlId={props.$controlId}
    >
      {!props.tipo && (
        <FormControl
          ref={ref}
          readOnly={props.readOnly}
          as={props.as}
          title={props.title}
          style={props.style}
          id={props.id}
          com_icon={props.com_icon}
          type={props.type}
          name={props.name}
          rows={props.rows}
          required={props.required}
          disabled={props.disabled}
          maxLength={props.maxLength}
          placeholder={isActive ? "" : props.placeholder}
          onKeyDown={props.onKeyDown}
          onFocus={handleFocus}
          onBlur={handleBlur}
          value={props.value}
          onChange={props.onChange}
          onClick={props.onClick}
        />
      )}

      {props.tipo === "icon-x" && (
        <InputGroup style={{ zIndex: "0" }}>
          <FormControl
            style={{
              borderRight: "none",
            }}
            ref={ref}
            as={props.as}
            title={props.title}
            id={props.id}
            com_icon={props.com_icon}
            type={props.type}
            name={props.name}
            rows={props.rows}
            required={props.required}
            disabled={props.disabled}
            maxLength={props.maxLength}
            placeholder={isActive ? "" : props.placeholder}
            onKeyDown={props.onKeyDown}
            onFocus={handleFocus}
            onBlur={handleBlur}
            value={props.value}
            onChange={props.onChange}
            onClick={props.onClick}
          />
          <InputGroup.Text
            style={{
              backgroundColor: "transparent",
              borderLeft: "none",
              borderColor: "#ccc",
            }}
          >
            {props.cpfCnpj.cpfCnpj !== null && props.cpfCnpj.cpfCnpj !== "" && (
              <Icon
                icon={props.icon}
                width={props.width}
                height={props.height}
                color={props.colorIcon}
                onClick={props.onClickIcon}
              />
            )}
          </InputGroup.Text>
        </InputGroup>
      )}

      {props.tipo === "n_celular" && (
        <InputMaskStyled
          mask="(99) 99999-9999"
          style={props.style}
          paddingleft={props.paddingLeft}
          id={props.id}
          com_icon={props.com_icon}
          type={props.type}
          name={props.name}
          required={props.required}
          disabled={props.disabled}
          placeholder={isActive ? "" : props.placeholder}
          onKeyDown={props.onKeyDown}
          onFocus={handleFocus}
          value={props.value}
          onChange={props.onChange}
          onClick={props.onClick}
        />
      )}

      {props.tipo === "text_area" && (
        <TextAreaContainer>
          {props.voiceInput && (
            <ContainerIconVoiceInput>
              <Icon icon="bi:mic-fill" style={{ color: "#515c70" }} />
            </ContainerIconVoiceInput>
          )}
          <FormTextArea
            voiceInput={props.voiceInput}
            ref={ref}
            placeholder={isActive ? "" : props.placeholder}
            onFocus={handleFocus}
            onBlur={handleBlur}
            overflowY={props.overflowY}
            com_icon={props.com_icon}
            rows={props.rows}
            as={props.as}
            {...props}
          />
        </TextAreaContainer>
      )}

      {(props.hasOwnProperty("new") || props.new) &&
        props.tipo === "input_select" && (
          <>
            <CustomSelectComponent
              radiusborder={props.radiusborder}
              ref={ref}
              height={props.height}
              options={props.options}
              onSelect={props.onSelect}
              placeholder={props.placeholder}
              value={props.value}
              hasScroll={props.hasScroll}
              holdSelect={props.holdSelect}
              disabled={props.disabled}
              handleOptionButton={props.handleOptionButton}
              style={props.style}
              autoChange={props.autoChange}
              indexOptionsApplyBgPrevious={props.indexOptionsApplyBgPrevious}
              noArrow={props.noArrow}
              indexItemsApplyBgOptions={props.indexItemsApplyBgOptions}
            />
          </>
        )}
      {!props.hasOwnProperty("new") &&
        !props.new &&
        props.tipo === "input_select" && (
          <div className="input_icon_arrow_dir">
            <FormSelectStyled
              ref={ref}
              className="form_select_styled"
              as={props.as}
              title={props.title}
              style={props.style}
              id={props.id}
              com_icon={props.com_icon}
              type={props.type}
              name={props.name}
              rows={props.rows}
              required={props.required}
              disabled={props.disabled}
              maxLength={props.maxLength}
              placeholder={isActive ? "" : props.placeholder}
              defaultValue={props.defaultValue}
              onKeyDown={props.onKeyDown}
              onFocus={handleFocus}
              onBlur={handleBlur}
              value={props.value}
              onChange={props.onChange}
              onClick={props.onClick}
            >
              {props.options.map((option, index) => (
                <React.Fragment key={index}>
                  {index === 0 ? (
                    <option value={option.value} disabled selected>
                      {option.label || option.value}
                    </option>
                  ) : (
                    <option value={option.value}>
                      {option.label || option.value}
                    </option>
                  )}
                </React.Fragment>
              ))}
            </FormSelectStyled>
          </div>
        )}
      <Form.Label>{props.label}</Form.Label>
    </FormGroup>
  );
});

export default FloatingLabelInput;
