import React, { useContext, useEffect, useState } from "react";
import { Col, Container, Row, Table } from "react-bootstrap";
import { Icon } from "@iconify/react";
import outlineSearch from "@iconify/icons-ic/outline-search";
import {
  BodyTable,
  FilterContainer,
  FilterLabel,
  FilterSelect,
  PassadorPage,
  TituloTable,
  TituloTableAction,
} from "../../../../components/TableConsulta/TableConsulta";
import Pagination from "../../../../components/PaginationComponent/Pagination";
import HeaderOptions from "../../../../components/HeaderOptions/HeaderOptions";
import InputSvg from "../../../../components/Grid/InputSvg";
// import ModalDeleteData from "../../../../components/ModalDeleteData/ModalDeleteData";
import { Link } from "react-router-dom";
import api from "../../../../utils/api";
// import localApi from "../../../../utils/localApi";
import ButtonsAcoesListas from "../../../../styles/StyledComponents/ButtonsAcoesListas";
import { ToastContainer } from "react-toastify";
import { fetchRetry } from "../../../../utils/fetchRetry";
import { CurrentClientContext } from "../../../../contexts/CurrentClientContext";

function ObrasConsulta() {
  const { currentClient } = useContext(CurrentClientContext);

  const [obras, setObras] = useState([]);
  const [itensPorPagina, setItensPorPagina] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [obrasFiltradas, setObrasFiltradas] = useState([]);
  const [loading, setLoading] = useState(false);

  // const [modalOpened, setModalOpened] = useState(false);
  // const [dataDelete, setDataDelete] = useState({});

  // const openModal = (obra) => {
  //   setModalOpened(true);
  //   setDataDelete(obra);
  // };

  // const closeModal = () => {
  //   setModalOpened(false);
  // };

  // const deleteObra = () => {
  //   api
  //     .delete(`/obras/${dataDelete.id}`)
  //     .then(() => {
  //       toast.success("Obra deletada com sucesso!");
  //       fetchData();
  //     })
  //     .catch((error) => {
  //       console.error(error);
  //       toast.error("Erro ao deletar obra.");
  //     });
  // };

  const fetchData = async () => {
    setLoading(true);
    const obras = await getTCEData();
    setObras(obras);
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  const handleSearch = (event) => {
    const { value } = event.target;
    setSearchTerm(value);
    const filteredItems = obras.filter((item) => {
      return (
        item.numeroObra.toLowerCase().includes(value.toLowerCase()) ||
        item.descricao.toLowerCase().includes(value.toLowerCase()) ||
        item.tipoObra.nome.toLowerCase().includes(value.toLowerCase())
      );
    });
    setObrasFiltradas(filteredItems);
    setCurrentPage(0);
  };

  const getTCEData = async () => {
    try {
      const codigoTCE = currentClient.codigoTCE;

      let clientObrasComTce = [];
      try {
        const jurisdicionados = await fetchRetry(
          "https://geopb.tce.pb.gov.br/api/v1/tramita/jurisdicionados/"
        );
        const obras = await fetchRetry(
          "https://geopb.tce.pb.gov.br/api/v1/obras/lista/"
        );

        const idTramita = jurisdicionados.data.find(
          (item) => parseInt(item.codigoSagres) === parseInt(codigoTCE)
        )?.id_tramita;

        if (idTramita) {
          const clientObras = obras.data.filter(
            (item) => parseInt(item.jurisdicionado) === parseInt(idTramita)
          );
          clientObrasComTce = clientObras.map((obra) => ({
            ...obra,
            tce: true,
          }));
        }
      } catch (error) {
        console.error("Erro ao buscar dados do TCE:", error);
        // Continua mesmo se houver erro ao buscar jurisdicionados ou obras/listas
      }

      try {
        const obrasLocais = await api.get(
          `/obras/por-cliente/${currentClient.clienteId}`
        );
        const obrasLocaisComTce = obrasLocais.data.map((obra) => ({
          ...obra,
          tce: false,
        }));

        const obrasFinais = [...clientObrasComTce, ...obrasLocaisComTce];
        console.log(obrasFinais);
        return obrasFinais;
      } catch (error) {
        console.error("Erro ao buscar dados locais:", error);
        // Retorna apenas as obras do TCE caso o get local falhe
        return clientObrasComTce;
      }
    } catch (error) {
      console.error("Erro inesperado:", error);
      return [];
    }
  };

  const formatToCurrency = (value) => {
    if (!value) return "";

    const number = parseFloat(value).toFixed(2);

    return new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    })
      .format(number)
      .replace("R$", "")
      .trim();
  };

  const startIndex = currentPage * itensPorPagina;
  const endIndex = startIndex + itensPorPagina;
  const currentItens = obras.slice(startIndex, endIndex);
  const currentFilteredItens = obrasFiltradas.slice(startIndex, endIndex);

  const pages = Math.ceil(obras.length / itensPorPagina);
  const filteredPages = Math.ceil(obrasFiltradas.length / itensPorPagina);

  return (
    <Container
      id="pags_responsividade_padding_geral"
      fluid
      style={{ color: "#515C70" }}
      className="container_conteudo conteudo_pagina"
    >
      <HeaderOptions
        withRow={true}
        TituloPagina={"Obras"}
        SubTituloPagina={"Consulta"}
        ToPag={"/contabilidade/cadastros/obra"}
      />
      {/* <ModalDeleteData
        modalOpened={modalOpened}
        closeModal={closeModal}
        setDataDelete={setDataDelete}
        deleteDataFunction={deleteObra}
      /> */}
      <ToastContainer />
      <Row className="row_form mt-1">
        <Col md={8}>
          <FilterContainer>
            <FilterLabel>Itens por página:</FilterLabel>
            <FilterSelect
              value={itensPorPagina}
              onChange={(event) =>
                setItensPorPagina(Number(event.target.value))
              }
            >
              <option value={10}>10</option>
              <option value={15}>15</option>
              <option value={20}>20</option>
            </FilterSelect>
            {/* <div style={{ marginLeft: "15px" }}>
              Não encontrou a obra que buscava?{" "}
              <button
                onClick={() => getTCEData()}
                style={{
                  all: "unset",
                  fontWeight: "500",
                  cursor: "pointer",
                }}
              >
                clique aqui.
              </button>
            </div> */}
          </FilterContainer>
        </Col>
        <Col md={4}>
          <InputSvg
            icon={outlineSearch}
            height={"23px"}
            color={"#6C7585"}
            placeholder={"Pesquise"}
            onChange={handleSearch}
          />
        </Col>
      </Row>
      <Row className="row_form mt-1">
        {loading ? (
          <Col
            md={12}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Icon height={50} icon="line-md:loading-loop" color="#515c70" />
          </Col>
        ) : null}
        <Table
          responsive
          style={loading ? { display: "none" } : { marginTop: "-10px" }}
        >
          <thead>
            <tr>
              <TituloTable>Número da Obra</TituloTable>
              <TituloTable>Descrição</TituloTable>
              <TituloTable>Local</TituloTable>
              <TituloTable>Valor</TituloTable>
              <TituloTable>Tipo de Obra</TituloTable>
              <TituloTableAction>Ação</TituloTableAction>
            </tr>
          </thead>
          <BodyTable>
            {searchTerm
              ? currentFilteredItens.map((item, index) => (
                  <tr key={index}>
                    <td style={{ whiteSpace: "nowrap" }}>{item.numeroObra}</td>
                    <td style={{ whiteSpace: "nowrap" }} title={item.descricao}>
                      {item.descricao.length > 50
                        ? item.descricao.substring(0, 50) + "..."
                        : item.descricao}
                    </td>
                    <td style={{ whiteSpace: "nowrap" }}>
                      {item.tce ? "TCE" : "SAVE"}
                    </td>
                    <td style={{ whiteSpace: "nowrap" }}>
                      {formatToCurrency(item.valor) || "00,00"}
                    </td>
                    <td style={{ whiteSpace: "nowrap" }}>
                      {item.tipoObra.nome}
                    </td>
                    <td>
                      <Link
                        to={
                          item.tce
                            ? `/contabilidade/visualizar/obra/${item.id}`
                            : `/contabilidade/editar/obra/${item.id}`
                        }
                      >
                        <Icon
                          icon="bx:edit"
                          color="#5971C8"
                          height="22"
                          style={{
                            cursor: "pointer",
                            marginLeft: "5px",
                          }}
                        />
                      </Link>
                    </td>
                  </tr>
                ))
              : currentItens.map((item, index) => (
                  <tr key={index}>
                    <td style={{ whiteSpace: "nowrap" }}>{item.numeroObra}</td>
                    <td style={{ whiteSpace: "nowrap" }} title={item.descricao}>
                      {item.descricao.length > 50
                        ? item.descricao.substring(0, 50) + "..."
                        : item.descricao}
                    </td>
                    <td style={{ whiteSpace: "nowrap" }}>
                      {item.tce ? "TCE" : "SAVE"}
                    </td>
                    <td style={{ whiteSpace: "nowrap" }}>
                      {formatToCurrency(item.valor) || "00,00"}
                    </td>
                    <td style={{ whiteSpace: "nowrap" }}>
                      {item.tipoObra.nome}
                    </td>
                    <td>
                      <ButtonsAcoesListas
                        // FuncaoIconDelete={() => {
                        //   openModal();
                        //   setDataDelete(item);
                        // }}
                        CaminhoPagUpdate={
                          item.tce
                            ? `/contabilidade/visualizar/obra/${item.id}`
                            : `/contabilidade/editar/obra/${item.id}`
                        }
                      />
                    </td>
                  </tr>
                ))}
          </BodyTable>
        </Table>
      </Row>
      <Row
        className="row_form mt-1"
        style={{
          borderBottomLeftRadius: "5px",
          borderBottomRightRadius: "5px",
        }}
      >
        <Col md={4}>
          <span>
            Mostrando de {startIndex + 1} a {endIndex} de{" "}
            {searchTerm ? obrasFiltradas.length : obras.length} registros
          </span>
        </Col>
        <Col md={8}>
          <PassadorPage>
            <span>
              {currentPage + 1} de {searchTerm ? filteredPages : pages} páginas
            </span>
            <Pagination
              pages={searchTerm ? filteredPages : pages}
              itemsPerPage={itensPorPagina}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          </PassadorPage>
        </Col>
      </Row>
      <div style={{ padding: "11px" }}></div>
    </Container>
  );
}

export default ObrasConsulta;
