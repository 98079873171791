import React from "react";
import {
  CCol,
  CColButtonsAcoes2Icons,
  RowAninhada,
} from "../../../../../components/Grid/CCol";
import { Row } from "react-bootstrap";
import {
  FormControlListaDeInputs,
  PTitulosInputs,
} from "../../../../../styles/StyledComponents/ListaDeInputs";
// import IconAdicionar from "../../../../../components/ListaDeInputs/IconAdicionar";

const Aditivos = ({ values }) => {
  const formatToCurrency = (value) => {
    if (!value) return "";

    const number = parseFloat(value).toFixed(2);

    return new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    })
      .format(number)
      .replace("R$", "")
      .trim();
  };

  const formatToDate = (value) => {
    if (!value) return "";

    if (value.length === 8) {
      const day = value.slice(0, 2);
      const month = value.slice(2, 4);
      const year = value.slice(4, 8);

      return `${day}/${month}/${year}`;
    } else if (value.length === 6) {
      const month = value.slice(0, 2);
      const year = value.slice(2, 6);

      return `${month}/${year}`;
    }

    return "";
  };

  return (
    <>
      <Row className="row_form mt-1">
        {values.map((item, index) => {
          return (
            <RowAninhada key={index}>
              <CCol md={1} style={{ width: "10em" }}>
                {index === 0 && <PTitulosInputs>Nº Contrato</PTitulosInputs>}
                <FormControlListaDeInputs
                  disabled
                  value={item?.numeroContrato || null}
                />
              </CCol>
              <CCol md={1} style={{ width: "9em" }}>
                {index === 0 && <PTitulosInputs>Nº Aditivos</PTitulosInputs>}
                <FormControlListaDeInputs
                  disabled
                  value={item?.numeroAditivo || null}
                />
              </CCol>
              <CCol md={1} style={{ width: "9em" }}>
                {index === 0 && (
                  <PTitulosInputs>Data Assinatura</PTitulosInputs>
                )}
                <FormControlListaDeInputs
                  disabled
                  value={formatToDate(item?.dataAssinatura) || null}
                />
              </CCol>
              <CCol md={1} style={{ width: "9em" }}>
                {index === 0 && <PTitulosInputs>Vigência</PTitulosInputs>}
                <FormControlListaDeInputs
                  disabled
                  value={formatToDate(item?.mesAno) || null}
                />
              </CCol>
              <CCol>
                {index === 0 && <PTitulosInputs>Motivo</PTitulosInputs>}
                <FormControlListaDeInputs value={item?.descricao || null} />
              </CCol>
              <CCol md={2}>
                {index === 0 && <PTitulosInputs>Valor</PTitulosInputs>}
                <FormControlListaDeInputs
                  disabled
                  value={formatToCurrency(item?.valor) || null}
                />
              </CCol>
              <CColButtonsAcoes2Icons md={1}>
                {/* {index === 0 && <PTitulosInputs>Ação</PTitulosInputs>} */}
                {/* <IconAdicionar /> */}
              </CColButtonsAcoes2Icons>
            </RowAninhada>
          );
        })}
      </Row>
    </>
  );
};

export default Aditivos;
