import React, { useState, useContext, useEffect } from "react";
import {
  CCol,
  RowAninhada,
  CColButtonsAcoes2Icons,
} from "../../../../components/Grid/CCol";
import styled from "styled-components";
import { Row } from "react-bootstrap";
import {
  FormControlListaDeInputs,
  PTitulosInputs,
} from "../../../../styles/StyledComponents/ListaDeInputs";
import IconAdicionar from "../../../../components/ListaDeInputs/IconAdicionar";
import FloatingLabelInput from "../../../../components/Grid/FloatingLabelInput";
import MyAutoComplete from "../../../../components/Inputs/MyAutoComplete";
import { CurrentClientContext } from "../../../../contexts/CurrentClientContext";
import { toast } from "react-toastify";
// import { FormatCpfPaste } from "../../../../utils/FormatacaoDeDados/FormtCpfPaste";
import api from "../../../../utils/api";
import { formatRawDate } from "../../../../utils/FormatacaoDeDados/FormatRawDate";
import { formatToCurrency } from "../../../../utils/FormatacaoDeDados/FormatToCurrency";
import { modalidades } from "./menus";
import { FormatCPForCNPJ } from "../../../../utils/FormatacaoDeDados/FormatCPForCNPJ";
import InputFormatRealBrasileiro from "../../../../components/Grid/InputFormatRealBrasileiro";
import { transformInputData } from "./TransformInputData";

const CustomInput = ({
  title,
  md = 2,
  size,
  Btop,
  Bbottom,
  all,
  menu,
  ...props
}) => {
  const borderRadius = Btop
    ? "5px 5px 0 0"
    : Bbottom
    ? "0 0 5px 5px"
    : all
    ? "5px 5px 5px 5px"
    : "0";

  return (
    <CCol md={md} style={size && { width: size }}>
      {title && <Title>{title}</Title>}
      {!menu ? (
        <FormControlListaDeInputs radiusborder={borderRadius} {...props} />
      ) : menu === 1 ? (
        <FloatingLabelInput
          new
          tipo="input_select"
          height={"30px"}
          radiusborder={borderRadius}
          {...props}
        />
      ) : menu === 2 ? (
        <MyAutoComplete radiusborder={borderRadius} {...props} fix />
      ) : (
        menu === 3 && (
          <InputFormatRealBrasileiro
            radiusborder={borderRadius}
            height={"30px"}
            {...props}
          />
        )
      )}
    </CCol>
  );
};

const Contratos = ({
  addContratos,
  values,
  acao,
  fornecedores,
  participantes,
  numeroLicitacao,
}) => {
  const { currentClient } = useContext(CurrentClientContext);

  const [inputAutoCompleteValue, setInputAutoComplete] = useState("");
  const [participantesOptions, setParticipantesOptions] = useState([]);
  const [participantesDaLicitacao, setParticipantesDaLicitacao] = useState([]);
  const [height, setHeight] = useState(70);
  const [defValues, setDefValues] = useState({
    numeroContrato: "",
    fornecedor: "",
    dataInicial: "",
    dataFinal: "",
    valor: "",
  });

  const addValues = () => {
    console.log(defValues);

    const hasEmptyValues = Object.values(defValues).some(
      (value) => value === "" || value === null || value === undefined
    );

    if (!hasEmptyValues) {
      const sanitizedValues = {
        ...defValues,
        dataInicial: defValues.dataInicial.replace(/\//g, ""),
        dataFinal: defValues.dataFinal.replace(/\//g, ""),
        valor: defValues.valor.replace(/\./g, "").replace(",", "."),
      };

      setInputAutoComplete("");
      addContratos(sanitizedValues);
      setDefValues({
        numeroContrato: "",
        fornecedor: "",
        dataInicial: "",
        dataFinal: "",
        valor: "",
      });
    } else {
      console.log("Tem coisa faltando");
    }
  };

  useEffect(() => {
    setHeight(70 + 30 * values.length);
    setDefValues({
      modalidadeLicitacao: null,
      numeroContrato: "",
      fornecedor: "",
      dataInicial: "",
      dataFinal: "",
      valor: "",
    });
  }, [values]);

  useEffect(() => {
    console.log("Iniciada");
    api
      .get(`cpf/por-cliente/${currentClient.clienteId}`)
      .then((resp) => {
        const data = resp.data;
        console.log(data);

        const options = data.map((secretario) => ({
          cpf: secretario.cpf,
          pessoaId: secretario.pessoaId,
          nome: secretario.nomeCompleto,
        }));

        setParticipantesOptions(options);
      })
      .catch((error) => {
        console.log(error);
        toast.error("Algo deu errado ao carregar os dados.");
      });
    //eslint-disable-next-line
  }, [currentClient]);

  useEffect(() => {
    const filteredParticipantes = participantesOptions.filter((option) =>
      participantes.some(
        (participante) => participante.licitante === option.cpf
      )
    );
    setParticipantesDaLicitacao(filteredParticipantes);
  }, [participantesOptions, participantes]);

  const handleOptionSelect = (option) => {
    console.log(option);
    if (option) {
      setDefValues((prevValues) => ({
        ...prevValues,
        fornecedor: option.cpf,
      }));
    }
  };

  return (
    <>
      <Row className="row_form mt-1">
        <StyledCCol
          style={{
            height:
              inputAutoCompleteValue === ""
                ? `${height}px`
                : `${height + 278}px`,
          }}
        >
          <RowAninhada
            style={{
              display: "flex",
              flexWrap: "nowrap",
            }}
          >
            <CustomInput
              disabled
              title={"Nº Licitação"}
              all={values.length === 0}
              Btop={values.length > 0}
              value={numeroLicitacao}
              // placeholder="Nº Licitação"
              // onChange={(e) =>
              //   setDefValues((prevValues) => ({
              //     ...prevValues,
              //     numeroContrato: e.target.value,
              //   }))
              // }
            />
            <CustomInput
              disabled={acao === "visualizar"}
              title={"Modalidade"}
              all={values.length === 0}
              Btop={values.length > 0}
              menu={1}
              value={defValues.modalidadeLicitacao}
              onSelect={(option) => {
                setDefValues((prevValues) => ({
                  ...prevValues,
                  modalidadeLicitacao: option.value,
                }));
              }}
              options={[{ label: " ", value: null }, ...modalidades]}
              height="30px"
              style={{
                width: "18em",
                color: "#515c70",
              }}
              radiusborder={values.length > 0 ? "5px 5px 0 0" : "5px"}
              noArrow={acao}
            />
            <CustomInput
              disabled={acao === "visualizar"}
              title={"Nº Contrato"}
              all={values.length === 0}
              Btop={values.length > 0}
              value={acao === "visualizar" ? "" : defValues.numeroContrato}
              onChange={(e) => {
                const input = e.target.value.replace(/\D/g, "");
                if (input.length <= 12) {
                  setDefValues((prevValues) => ({
                    ...prevValues,
                    numeroContrato: input,
                  }));
                }
              }}
            />
            <CustomInput
              disabled={acao === "visualizar"}
              menu={2}
              title={"Participante"}
              all={values.length === 0}
              Btop={values.length > 0}
              inputValue={inputAutoCompleteValue}
              setInputValue={setInputAutoComplete}
              options={participantesDaLicitacao}
              labelFormat={(option) =>
                `${option.nome} - ${FormatCPForCNPJ(option.cpf)}`
              }
              onOptionSelect={handleOptionSelect}
              md={4}
            />
            <CustomInput
              disabled={acao === "visualizar"}
              title={"Data Assinatura"}
              all={values.length === 0}
              Btop={values.length > 0}
              value={
                defValues.dataInicial.length === 8
                  ? formatRawDate(defValues.dataInicial)
                  : defValues.dataInicial
              }
              onChange={(e) => {
                const input = transformInputData(e, 1);
                setDefValues((prevValues) => ({
                  ...prevValues,
                  dataInicial: input,
                }));
              }}
            />
            <CustomInput
              disabled={acao === "visualizar"}
              title={"Data Vigência"}
              all={values.length === 0}
              Btop={values.length > 0}
              value={
                defValues.dataFinal.length === 8
                  ? formatRawDate(defValues.dataFinal)
                  : defValues.dataFinal
              }
              onChange={(e) => {
                const input = transformInputData(e, 1);
                setDefValues((prevValues) => ({
                  ...prevValues,
                  dataFinal: input,
                }));
              }}
            />
            <CustomInput
              menu={3}
              disabled={acao === "visualizar"}
              title={"Valor"}
              all={values.length === 0}
              Btop={values.length > 0}
              value={defValues.valor}
              onChange={(e, valor) => {
                setDefValues((prevValues) => ({
                  ...prevValues,
                  valor: valor,
                }));
              }}
            />
          </RowAninhada>
          {values.map((item, index) => {
            return (
              <RowAninhada
                key={index}
                style={{
                  display: "flex",
                  flexWrap: "nowrap",
                }}
              >
                <CustomInput
                  disabled
                  Bbottom={index + 1 === values.length}
                  value={numeroLicitacao}
                />
                <CustomInput
                  menu={1}
                  disabled
                  Bbottom={index + 1 === values.length}
                  value={item.modalidadeLicitacao}
                  options={[{ label: " ", value: null }, ...modalidades]}
                  noArrow
                />
                <CustomInput
                  disabled
                  Bbottom={index + 1 === values.length}
                  value={item.numeroContrato}
                />
                <CustomInput
                  disabled
                  Bbottom={index + 1 === values.length}
                  value={
                    acao === "visualizar"
                      ? fornecedores.find(
                          (f) => f.fornecedor === item.fornecedor
                        )?.nome || "Fornecedor não encontrado"
                      : participantesOptions.find(
                          (participante) => participante.cpf === item.fornecedor
                        )?.nome || "Fornecedor não encontrado"
                  }
                  md={4}
                />
                <CustomInput
                  disabled
                  Bbottom={index + 1 === values.length}
                  value={formatRawDate(item.dataInicial)}
                />
                <CustomInput
                  disabled
                  Bbottom={index + 1 === values.length}
                  value={formatRawDate(item.dataFinal)}
                />
                <CustomInput
                  disabled
                  Bbottom={index + 1 === values.length}
                  value={formatToCurrency(item.valor)}
                />
              </RowAninhada>
            );
          })}
        </StyledCCol>
        <CColButtonsAcoes2Icons md={1} style={{ marginTop: "10px" }}>
          <PTitulosInputs>Ação</PTitulosInputs>
          <IconAdicionar
            onClick={
              acao === "visualizar"
                ? () => console.log("Desativado")
                : addValues
            }
            color={acao === "visualizar" ? "#bcc1c4" : "#105200"}
            style={{ cursor: acao === "visualizar" ? "default" : "pointer" }}
          />
        </CColButtonsAcoes2Icons>
      </Row>
    </>
  );
};

export default Contratos;

const Title = styled.p`
  text-align: left;
  margin-top: 0;
  padding: 0px;
  margin-bottom: 0px;
  color: #888a8e;
  font-weight: 600;
  font-size: 12px;
`;

const StyledCCol = styled(CCol)`
  overflow: auto;

  &::-webkit-scrollbar {
    height: 4px;
    width: 2px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 2px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;
