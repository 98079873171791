import React from "react";
import { Row } from "react-bootstrap";
import { CCol, RowAninhada } from "../../../../components/Grid/CCol";
import FloatingLabelInput from "../../../../components/Grid/FloatingLabelInput";
import { situacaoOptions } from "./Options";
import InputData from "../../../../components/Grid/InputData";

const EvolucaoObra = ({ values, setValues, obraId }) => {
  return (
    <Row className={"row_form mt-1"}>
      <RowAninhada>
        <CCol md={3}>
          <InputData
            disabled={obraId}
            label={"Data Início"}
            placeholder={"Data Início"}
            value={values.dataInicio}
            onChange={(e) =>
              setValues((prevValues) => ({
                ...prevValues,
                dataInicio: e.target.value,
              }))
            }
          />
        </CCol>
        <CCol md={4}>
          <InputData
            disabled={obraId}
            label={"Previsão de Conclusão"}
            placeholder={"Previsão de Conclusão"}
            value={values.dataPrevisaoEntrega}
            onChange={(e) =>
              setValues((prevValues) => ({
                ...prevValues,
                dataPrevisaoEntrega: e.target.value,
              }))
            }
          />
        </CCol>
        <CCol>
          <FloatingLabelInput
            disabled={obraId}
            new={true}
            holdSelect
            style={{
              color: "#515c70",
            }}
            tipo="input_select"
            label="Situação Atual"
            placeholder="Situação Atual"
            options={[
              { label: "Situação Atual", value: null },
              ...situacaoOptions,
            ]}
            value={values.situacao}
            onSelect={(option) =>
              setValues((prevValues) => ({
                ...prevValues,
                situacao: option.value,
              }))
            }
          />
        </CCol>
      </RowAninhada>
    </Row>
  );
};

export default EvolucaoObra;
