import React, { useState } from "react";
import { Container, Row } from "react-bootstrap";
import {
  ButtonOptionsFirst,
  ButtonOptionsPages,
  ColButtons,
  ButtonOptionsBetween,
} from "../../../styles/StyledComponents/ButtonsSelectPage";
import { Icon } from "@iconify/react/dist/iconify.js";
import IconModulosHeader from "../../../components/SvgComponents/IconModulosHeader";
import Notificacoes from "./Notificacoes";
import Savebot from "./Savebot";
import TarefasPrazos from "./TarefasPrazos";
import InicioDashboard from "./InicioDashboard";
import { TestButton } from "../../../utils/TestButton";

function InicioPlanejamento() {
  const [botaoAtivo, setBotaoAtivo] = useState("Tarefas e Prazos");

  return (
    <Container
      id="pags_responsividade_padding_geral"
      fluid
      style={{ color: "#515C70" }}
      className="container_conteudo conteudo_pagina"
    >
      <TestButton onClick={() => console.log("Test")}>Test</TestButton>
      <Row>
        <div className="d-flex titulo_conteudo">
          <span className="cor_letra_primaria fw-bold">
            {"Inicio"}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="15"
              height="15"
              viewBox="0 0 24 24"
            >
              <g transform="rotate(180 12 12)">
                <path
                  fill="currentColor"
                  d="M15.125 21.1L6.7 12.7q-.15-.15-.213-.325T6.425 12q0-.2.062-.375T6.7 11.3l8.425-8.425q.35-.35.875-.35t.9.375q.375.375.375.875t-.375.875L9.55 12l7.35 7.35q.35.35.35.863t-.375.887q-.375.375-.875.375t-.875-.375Z"
                />
              </g>
            </svg>
          </span>
          <span className="cor_letra_secundaria">{botaoAtivo}</span>
        </div>
      </Row>
      <Row>
        <ColButtons md={12}>
          <ButtonOptionsPages>
            <ButtonOptionsFirst
              ativo={botaoAtivo === "Tarefas e Prazos"}
              onClick={() => setBotaoAtivo("Tarefas e Prazos")}
            >
              <Icon
                style={{
                  marginRight: "8px",
                  transform: "scale(1.637,1.616) translate(0, -1px)",
                }}
                icon="mdi:calendar-range"
              />
              Tarefas e Prazos
            </ButtonOptionsFirst>
            <ButtonOptionsBetween
              ativo={botaoAtivo === "Notificações"}
              onClick={() => setBotaoAtivo("Notificações")}
              style={{
                padding: "0px 20px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              <Icon
                style={{ marginRight: "8px", transform: "scale(1.537,1.606)" }}
                icon="mdi:bell"
                color={botaoAtivo !== "Notificações" ? "#e79900" : null}
              />
              Notificações
            </ButtonOptionsBetween>
            <ButtonOptionsBetween
              ativo={botaoAtivo === "Dashboard"}
              onClick={() => setBotaoAtivo("Dashboard")}
            >
              <Icon
                style={{
                  marginRight: "8px",
                  transform: "scale(1.537,1.516) translate(0, -1px)",
                }}
                icon="ri:dashboard-fill"
                color={botaoAtivo !== "Dashboard" ? "#105200" : null}
              />
              Dashboard
            </ButtonOptionsBetween>
            <ButtonOptionsBetween
              ativo={botaoAtivo === "Savebot"}
              onClick={() => setBotaoAtivo("Savebot")}
              style={{
                marginBottom: "0px",
                display: "flex",
              }}
            >
              <IconModulosHeader
                ativo={botaoAtivo === "Savebot"}
                savebot
                width={20}
                height={20}
                style={{
                  transform: "translate(-10px, -4px)",
                  marginLeft: "8px",
                }}
              />
              <div
                style={{
                  transform: "translate(0px, 6px)",
                }}
              >
                Savebot
              </div>
            </ButtonOptionsBetween>
          </ButtonOptionsPages>
        </ColButtons>
      </Row>
      {botaoAtivo === "Notificações" && <Notificacoes />}
      {botaoAtivo === "Dashboard" && <InicioDashboard />}
      {botaoAtivo === "Tarefas e Prazos" && <TarefasPrazos />}
      {botaoAtivo === "Savebot" && <Savebot />}
    </Container>
  );
}

export default InicioPlanejamento;
