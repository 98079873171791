import React from "react";
import { Icon } from "@iconify/react";
import { DivAction } from "../../components/TableConsulta/TableConsulta";
import { NavLink } from "react-router-dom";

const ButtonsAcoesListas = ({
  FuncaoIconDelete,
  CaminhoPagUpdate,
  IconDelete,
  CaminhoPagReplica,
  onClick,
  deactivated,
}) => {
  return (
    <DivAction>
      <NavLink
        title={"editar"}
        to={!deactivated ? CaminhoPagUpdate : () => console.log("Desativado")}
        style={{ cursor: deactivated ? "default" : "pointer" }}
      >
        {CaminhoPagUpdate && (
          <Icon
            icon="bx:edit"
            color={!deactivated ? "#5971C8" : "#b6b6b6"}
            height="22"
            style={{
              marginBottom: "8px",
              marginTop: "-2px",
            }}
            onClick={!deactivated ? onClick : () => console.log("Desativado")}
          />
        )}
      </NavLink>

      {window.location.pathname.includes("exemplo") && (
        <Icon
          icon="gg:copy"
          color={!deactivated ? "#3E347B" : "#b6b6b6"}
          height="22"
          style={{
            marginBottom: "10px",
            marginTop: "-2px",
          }}
        />
      )}

      {CaminhoPagReplica && (
        <NavLink title={"replicar"} to={CaminhoPagReplica}>
          <Icon
            icon="gg:copy"
            color="#3E347B"
            height="22"
            style={{
              marginBottom: "10px",
              marginTop: "-2px",
              cursor: "pointer",
            }}
          />
        </NavLink>
      )}

      {(IconDelete === undefined || IconDelete) && FuncaoIconDelete && (
        <span title={"deletar"}>
          <Icon
            icon="ic:baseline-delete"
            color="#E79900"
            height="22"
            style={{
              marginBottom: "8px",
              marginTop: "-2px",
              cursor: "pointer",
            }}
            onClick={FuncaoIconDelete}
          />
        </span>
      )}
    </DivAction>
  );
};

export default ButtonsAcoesListas;
