import React, { useCallback, useContext, useEffect, useState } from "react";
import { Container, Table } from "react-bootstrap";

import styled from "styled-components";

import HeaderCadastros from "../../../../components/HeaderCadastros/HeaderCadastros";
import { CCol, RowForm } from "../../../../components/Grid/CCol";
import InputData from "../../../../components/Grid/InputData";
import FloatingLabelInput from "../../../../components/Grid/FloatingLabelInput";
import MyAutoComplete from "../../../../components/Inputs/MyAutoComplete";
import { RowInfosAcao } from "../../../../components/RowInfosAcao/RowInfosAcao";
import InputFormatRealBrasileiro from "../../../../components/Grid/InputFormatRealBrasileiro";
import RowSelecaoAbasInferior from "../../../../components/Grid/RowSelecaoAbasInferior";

import { HistoricoAba } from "./HistoricoAba";
import { ReceitaExtraAba } from "./ReceitaExtraAba";
import { TransfConcedidaAba } from "./TransfConcedidaAba";
import { FonteDeRecursosAba } from "./FonteDeRecursosAba";

import { CurrentClientContext } from "../../../../contexts/CurrentClientContext";

import { useCurrentYear } from "../../../../hooks/useCurrentYear";

import api from "../../../../utils/api";
import { ReturnDataAtual } from "../../../../utils/Gerais/ReturnDataAtual";
import { RowTituloAba } from "../../../PagesAcessoInterno/PagContaPerfil/StyledComponentsContaPerfil";
import {
  BodyTable,
  TituloTable,
} from "../../../Paginas_Sistemas_Planejamento/Cadastros/NaturezaDaReceita/EstruturaReceita";
import { AuthContext } from "../../../../contexts/AuthContext";
import { CurrentCityContext } from "../../../../contexts/CurrentCityContext";

const CColNumero = styled(CCol)`
  @media (min-width: 768px) {
    width: 10%;
  }
`;

const GdeDespesa = () => {
  const dataAtual = ReturnDataAtual()
    .replace(/\//g, "-")
    .split("-")
    .reverse()
    .join("-");

  const { currentClient } = useContext(CurrentClientContext);
  const { currentYear } = useCurrentYear();
  const { userAuthData } = useContext(AuthContext);
  const { currentCity } = useContext(CurrentCityContext);

  const [abaSelecionada, setAbaSelecionada] = useState({
    nomeAba: "Histórico",
    numeroAba: 0,
  });

  const [inputQdrde, setInputQdrde] = useState("");
  // const [inputFonteValue, setInputFonteValue] = useState("");

  // const [optionsFonteDeRecursos, setOptionsFonteDeRecurso] = useState([
  //   { label: "", value: null },
  // ]);
  const [optionsUnidadesGestoras, setOptionsUnidadesGestoras] = useState([
    { label: "", value: null, tipoNaturezaJuridica: "" },
  ]);

  const [optionsQdrde, setOptionsQdrde] = useState([
    {
      id: "",
      titulo: "",
      funcao: "",
      qdrdeIsTranferencia: false,
      fontes: [
        { fonteId: null, valor: "", data: "", label: "", percentage: "" },
      ],
    },
  ]);

  const [infosCadastro, setInfosCadastro] = useState({
    num: "",
    evb: "",
    qdrdeId: null,
    qdrdeIsTranferencia: false,
    funcao: "",
    data: dataAtual,
    beneficiario: "",
    valor: 0,
    fonteId: null,
    tipoTransferencia: "",
    unidadeGestoraId: null,
    tipoNaturezaJuridica: "",
    fontes: [{ fonteId: null, valor: "", data: "", label: "", percentage: "" }],
  });

  const handleSelectQdrde = (option) => {
    const { fontes, funcao, qdrdeIsTranferencia } = option;
    // const optionsFontes = formattedOptionsFonteDeRecursos(option.fontes);
    // setOptionsFonteDeRecurso(optionsFontes);

    setInfosCadastro((prev) => ({
      ...prev,
      qdrdeId: option.id,
      funcao,
      fontes,
      qdrdeIsTranferencia,
    }));

    if (
      !qdrdeIsTranferencia &&
      abaSelecionada.nomeAba === "Transferência Concedida"
    )
      setAbaSelecionada({ nomeAba: "Histórico", numeroAba: 0 });
  };

  const handleRegisterGre = () => {
    console.log(infosCadastro);
  };

  // const handleSelectFonte = (option) => {
  //   setInfosCadastro((prev) => ({
  //     ...prev,
  //     fonteId: option.value,
  //   }));
  // };

  // const formattedOptionsFonteDeRecursos = (fonts) => {
  //   const filteredFonts = fonts.filter((item) => item.fonteId !== null);

  //   if (!filteredFonts.length) return [{ label: "", value: null }];

  //   const formattedFonts = filteredFonts.map((item, index) => {
  //     return {
  //       label: item.label,
  //       value: item.fonteId,
  //     };
  //   });
  //   return formattedFonts;
  // };

  const formattedFonts = (data) => {
    const fonts = data.map((item) => {
      const fontInfo = item.fonteInfo.find((font) => font.id === item.fonteId);
      const date = new Date(item.dataCriacao);
      return {
        data: date.toISOString().split("T")[0],
        fonteId: item.fonteId,
        valor: item.valor,
        label: fontInfo ? `${fontInfo.conta} - ${fontInfo.titulo}` : "",
        percentage: "0,00",
      };
    });
    return fonts;
  };

  const getDataQdrde = useCallback(async () => {
    console.log("executou");
    try {
      const resp = await api.get(
        `qdrde/${currentClient.clienteId}/${currentYear}`
      );
      const data = resp.data;
      console.log(data);

      const dataQdrde = data.map((item) => {
        const titulo = `${item.categoriaContaPcaspInfo[0].conta} - ${item.titulo}`;
        const fontes = formattedFonts(item.fontes);
        const qdrdeIsTranferencia = item.categoriaContaPcaspInfo[0].titulo
          .toLowerCase()
          .includes("transferências");
        return {
          id: item.id,
          titulo,
          funcao: item.categoriaContaPcaspInfo[0].funcao,
          fontes,
          qdrdeIsTranferencia,
        };
      });
      setOptionsQdrde(dataQdrde);
    } catch (error) {
      console.log(error);
    }
  }, [currentClient, currentYear]);
  const formattedUnidadesGestoras = useCallback(() => {
    console.log("executou");

    const unidades = userAuthData.clientes
      .map((item) => {
        const hasCityUnidade =
          item.endereco.municipio === currentCity.municipio &&
          item.endereco.estado === currentCity.estado;
        const currentClientSelected =
          currentClient.clienteId === item.clienteId;

        if (!hasCityUnidade || currentClientSelected)
          return { label: "", value: null };
        return {
          label: item.nomeFantasia,
          value: item.clienteId,
          tipoNaturezaJuridica: item.tipoNaturezaJuridica,
        };
      })
      .filter((item) => item.value);
    console.log(unidades);

    setOptionsUnidadesGestoras(unidades);
  }, [currentCity, userAuthData, currentClient]);

  useEffect(() => {
    console.log("executou");
    formattedUnidadesGestoras();
  }, [formattedUnidadesGestoras]);

  useEffect(() => {
    getDataQdrde();
  }, [getDataQdrde]);

  return (
    <Container
      id={"pags_responsividade_padding_geral"}
      fluid
      className={"container_conteudo conteudo_pagina"}
    >
      <HeaderCadastros
        NomePaginaTitulo={"GDE - Despesa"}
        PaginaSubtitulo={"Cadastro"}
        ButtonSaveFunction={handleRegisterGre}
        RowTitle={"Despesa Extraorçamentária - GDE"}
      />

      <RowForm className="mt-1">
        <CColNumero md={1}>
          <FloatingLabelInput label="Número" placeholder="Número" />
        </CColNumero>
        <CCol md={2}>
          <MyAutoComplete
            options={[{ label: "teste", value: "teste" }]}
            labelFormat={(item) => `${item.label} - ${item.value}`}
            labelInput="EVB"
            style={{ height: "37px" }}
          />
        </CCol>
        <CCol>
          <MyAutoComplete
            style={{ height: "37px" }}
            labelInput="QDRDE"
            options={optionsQdrde}
            labelFormat={(item) => `${item.titulo} `}
            inputValue={inputQdrde}
            setInputValue={setInputQdrde}
            onOptionSelect={handleSelectQdrde}
          />
        </CCol>
        <CCol md={2}>
          <InputData
            onChange={(e) =>
              setInfosCadastro((prev) => ({
                ...prev,
                data: e.target.value,
              }))
            }
            externalValue={infosCadastro.data}
          />
        </CCol>
      </RowForm>

      {infosCadastro.funcao && (
        <RowInfosAcao>
          <CCol>
            <p>
              <span>{infosCadastro.funcao}</span>
            </p>
          </CCol>
        </RowInfosAcao>
      )}

      <RowForm style={{ marginTop: "-14px" }}>
        <CCol>
          <MyAutoComplete
            options={[{ label: "teste", value: "teste" }]}
            labelFormat={(item) => `${item.label} - ${item.value}`}
            labelInput="Beneficiário"
            style={{ height: "37px" }}
          />
        </CCol>
        <CCol md={3}>
          <InputFormatRealBrasileiro iconReal />
        </CCol>
      </RowForm>

      {/* <RowForm style={{ marginTop: "-14px" }}>
        <CCol>
          <MyAutoComplete
            labelInput="Fonte de Recursos"
            style={{ height: "37px" }}
            options={[...optionsFonteDeRecursos, { label: "", value: null }]}
            labelFormat={(item) => `${item.label} - ${item.value}`}
            inputValue={inputFonteValue}
            setInputValue={setInputFonteValue}
            onOptionSelect={(option) => {
              handleSelectFonte(option);
            }}
          />
        </CCol>
      </RowForm> */}

      <RowSelecaoAbasInferior
        abas={[
          "Histórico",
          "Fonte de Recursos",
          "Receita Extra",
          `${
            infosCadastro.qdrdeIsTranferencia ? "Transferência Concedida" : ""
          }`,
        ]}
        abaSelecionada={abaSelecionada}
        setAbaSelecionada={setAbaSelecionada}
      />

      {abaSelecionada.nomeAba === "Histórico" && <HistoricoAba />}
      {abaSelecionada.nomeAba === "Fonte de Recursos" && <FonteDeRecursosAba />}
      {abaSelecionada.nomeAba === "Receita Extra" && <ReceitaExtraAba />}
      {abaSelecionada.nomeAba === "Transferência Concedida" && (
        <TransfConcedidaAba
          optionsUnidadesGestoras={optionsUnidadesGestoras}
          infosCadastro={infosCadastro}
          setInfosCadastro={setInfosCadastro}
        />
      )}

      <RowTituloAba style={{ marginTop: "15px" }}>
        <div>
          <span>Procedimentos Contábeis</span>
        </div>
      </RowTituloAba>
      <RowForm style={{ marginTop: "4px" }}>
        <Table responsive>
          <thead>
            <tr>
              <TituloTable style={{ borderStyle: "none" }}>Título</TituloTable>
              <TituloTable style={{ borderStyle: "none" }}>
                Categoria
              </TituloTable>
              <TituloTable style={{ borderStyle: "none" }}>
                Natureza Saldo
              </TituloTable>
            </tr>
          </thead>
          <BodyTable
            style={{
              fontSize: "14px",
              fontWeight: "bold",
              borderStyle: "none",
              borderColor: "transparent",
              borderBottomWidth: 0,
            }}
          >
            <tr style={{ backgroundColor: "#EBEFFF" }}>
              <td
                style={{
                  whiteSpace: "nowrap",
                  textTransform: "capitalize",
                  paddingTop: "2px",
                  paddingBottom: "2px",
                  borderBottomLeftRadius: "8px",
                  borderTopLeftRadius: "8px",
                }}
              >
                02051082442001203844901500
              </td>
              <td
                style={{
                  whiteSpace: "nowrap",
                  textTransform: "capitalize",
                  paddingTop: "2px",
                  paddingBottom: "2px",
                }}
              >
                D 1.1.1.1.1.00.00 Caixa e Equivalentes de Caixa em Moeda
                Nacional
              </td>
              <td
                style={{
                  whiteSpace: "nowrap",
                  paddingTop: "2px",
                  paddingBottom: "2px",
                  borderBottomRightRadius: "8px",
                  borderTopRightRadius: "8px",
                }}
              >
                R$ 260.000,00
              </td>
            </tr>
          </BodyTable>
        </Table>
      </RowForm>
    </Container>
  );
};

export default GdeDespesa;
