import React, { useState, useContext, useEffect } from "react";
import HeaderCadastros from "../../../components/HeaderCadastros/HeaderCadastros";
import {
  CCol,
  ContainerToModal,
  RowToModal,
} from "../../../components/Grid/CCol";
import FloatingLabelInput from "../../../components/Grid/FloatingLabelInput";
import { ToastContainer, toast } from "react-toastify";
import api from "../../../utils/api";
import { CurrentClientContext } from "../../../contexts/CurrentClientContext";
import { CurrentYearContext } from "../../../contexts/YearContext";
import { useParams } from "react-router-dom";
import ButtonSaveModalAutoComplete from "../../../components/Modais/ButtonSaveModalAutoComplete";
import { DefinirRequisicaoDoModalComoConluida } from "../../../utils/Gerais/DefinirRequisicaoDoModalComoConcluida";
import { OpenedModalsContext } from "../../../contexts/OpenedModalsContext";

const Indicadores = ({ isAModalPage }) => {
  const [atualizarCodigo, setAtualizarCodigo] = useState(false);
  const [editable, setEditable] = useState(true);
  const { currentClient } = useContext(CurrentClientContext);
  const { currentYear } = useContext(CurrentYearContext);
  const { openedModals, setOpenedModals } = useContext(OpenedModalsContext);
  const { indicadorId: paramsIndicadorId } = useParams();
  const [valuesIndicador, setValuesIndicador] = useState({
    codigo: "",
    descricao: "",
    unidadeDeMedida: "",
    clienteId: currentClient.clienteId,
  });

  const selectTiposOptions = [
    { value: "Un - Unidade", label: "Un - Unidade" },
    { value: "% - Percentual", label: "% - Percentual" },
    { value: "KG - Quilograma", label: "KG - Quilograma" },
    { value: "R$ - Reais", label: "R$ - Reais" },
    { value: "L - Litros", label: "L - Litros" },
    { value: "m - Metros Linear", label: "m - Metros Linear" },
    { value: "KM - Quilômetros", label: "KM - Quilômetros" },
    { value: "T - Toneladas", label: "T - Toneladas" },
    { value: "m² - Metros Quadrados", label: "m² - Metros Quadrados" },
    { value: "h - Horas", label: "h - Horas" },
  ];

  const fetchData = () => {
    console.log("Maaaano", paramsIndicadorId, isAModalPage);
    if (paramsIndicadorId == null || isAModalPage) {
      api
        .get(
          `programaPlanejamento/indicador/next-codigo/${currentClient.clienteId}/${currentYear}`
        )
        .then((response) => {
          setValuesIndicador((prevValues) => ({
            ...prevValues,
            codigo: response.data,
          }));
        })
        .catch((error) =>
          toast.error(
            "Algo deu errado ao obter o próximo código. Por favor, tente mais tarde"
          )
        );
      setAtualizarCodigo(false);
    } else {
      api
        .get(
          `programaPlanejamento/indicadores/unico/porId/${paramsIndicadorId}`
        )
        .then((response) => {
          const indexOfUDM = selectTiposOptions.findIndex(
            (option) => option.value === response.data[0].unidadeDeMedida.trim()
          );
          setValuesIndicador((prevValues) => ({
            ...prevValues,
            codigo: response.data[0].codigo,
            descricao: response.data[0].descricao,
          }));
          if (indexOfUDM !== undefined && indexOfUDM !== -1) {
            setValuesIndicador((prevValues) => ({
              ...prevValues,
              unidadeDeMedida: selectTiposOptions[indexOfUDM].value,
            }));
          }
          if (response.data[0].clienteId !== currentClient.clienteId) {
            if (currentClient.tipoNaturezaJuridica[0] !== "2") {
              setEditable(false);
            }
          }
        });
    }
    console.log("IndicadorId: ", paramsIndicadorId);
  };

  useEffect(() => {
    fetchData();
    //eslint-disable-next-line
  }, [currentClient, atualizarCodigo]);

  const limpaDados = () => {
    setValuesIndicador((prevValues) => ({
      ...prevValues,
      descricao: "",
      unidadeDeMedida: "",
    }));
  };

  const cadastraIndicador = () => {
    if (valuesIndicador.codigo !== "" && valuesIndicador.descricao) {
      api
        .post(`programaPlanejamento/indicador`, valuesIndicador)
        .then((resp) => {
          console.log(resp);
          toast.success("Indicador cadastrado com sucesso!");
          setAtualizarCodigo(true);
          limpaDados();

          if (isAModalPage) {
            DefinirRequisicaoDoModalComoConluida(
              openedModals,
              setOpenedModals,
              "Indicadores"
            );
          }
        });
    } else {
      toast.warning(
        "Preencha todos os campos necessarios para cadastrar um novo indicador."
      );
    }
  };

  const atualizaIndicador = () => {
    api
      .put(
        `programaPlanejamento/${currentClient.clienteId}/indicador/${paramsIndicadorId}`,
        valuesIndicador
      )
      .then((resp) => {
        console.log(resp);
        toast.success("Indicador atualizado com sucesso!");
      });
  };

  return (
    <ContainerToModal
      isAModalPage={isAModalPage}
      id={isAModalPage ? undefined : "pags_responsividade_padding_geral"}
      fluid
      style={{ color: "#515C70" }}
      className={
        isAModalPage ? undefined : "container_conteudo conteudo_pagina"
      }
    >
      {!isAModalPage && (
        <>
          <HeaderCadastros
            NomePaginaTitulo={"Indicadores"}
            PaginaSubtitulo={"Cadastro"}
            PaginaConsulta={"/planejamento/cadastro/indicadores/consulta"}
            BotaoSaveAtivo={editable ? true : false}
            ButtonSaveFunction={
              paramsIndicadorId === undefined
                ? cadastraIndicador
                : atualizaIndicador
            }
            DashboardPage={"/planejamento/cadastro/indicadores/dashboard"}
            PaginaRelatorio={"/planejamento/cadastro/indicadores/relatorio"}
          />
          <ToastContainer />
        </>
      )}

      {isAModalPage && (
        <ButtonSaveModalAutoComplete
          SaveFunction={cadastraIndicador}
          pageTitle={"Indicadores"}
        />
      )}

      <RowToModal isAModalPage={isAModalPage} className="row_form_2 mt-1">
        <CCol md={2}>
          <FloatingLabelInput
            disabled={paramsIndicadorId && !isAModalPage ? true : false}
            maxLength="2"
            placeholder="Código"
            label="Código"
            value={valuesIndicador.codigo}
            onChange={(e) => {
              e.target.value = e.target.value.replace(/[^0-9]/g, "");
              setValuesIndicador((prevValues) => ({
                ...prevValues,
                codigo: e.target.value,
              }));
            }}
            //disabled={paramsIndicadorId !== undefined}
          />
        </CCol>
        <CCol>
          <FloatingLabelInput
            placeholder="Descrição"
            label="Descrição"
            value={valuesIndicador.descricao}
            onChange={(e) => {
              setValuesIndicador((prevValues) => ({
                ...prevValues,
                descricao: e.target.value,
              }));
            }}
          />
        </CCol>
        <CCol md={3}>
          <FloatingLabelInput
            new={true}
            tipo="input_select"
            placeholder="Unidade de medida"
            label="Unidade de medida"
            value={valuesIndicador.unidadeDeMedida}
            options={[...selectTiposOptions]}
            onChange={(e) => {
              setValuesIndicador((prevValues) => ({
                ...prevValues,
                unidadeDeMedida: e.target.value,
              }));
            }}
            onSelect={(option) =>
              setValuesIndicador((prevValues) => ({
                ...prevValues,
                unidadeDeMedida: option.value,
              }))
            }
          />
        </CCol>
      </RowToModal>
    </ContainerToModal>
  );
};

export default Indicadores;
