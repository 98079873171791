import React, { useState } from "react";
import { Collapse, Container } from "react-bootstrap";
import HeaderCadastros from "../../../../components/HeaderCadastros/HeaderCadastros";
import {
  CCol,
  CColButtonsAcoes2Icons,
  RowAninhada,
  RowForm,
} from "../../../../components/Grid/CCol";
import {
  FormControlListaDeInputs,
  PTitulosInputs,
} from "../../../../styles/StyledComponents/ListaDeInputs";
import AutoCompleteFichas from "../../../../components/Inputs/AutoCompleteFichas";
import IconAdicionar from "../../../../components/ListaDeInputs/IconAdicionar";
import IconDelete from "../../../../components/ListaDeInputs/IconDelete";
import IconEdit from "../../../../components/ListaDeInputs/IconEdit";
import RowTitulo from "../../../../components/Grid/RowTitulo";
import { Icon } from "@iconify/react/dist/iconify.js";
import FloatingLabelInput from "../../../../components/Grid/FloatingLabelInput";
import MyAutoComplete from "../../../../components/Inputs/MyAutoComplete";
import InputData from "../../../../components/Grid/InputData";

const Pagamento = () => {
  const [documentos] = useState([
    {
      nome: "teste",
      value: "teste",
    },
  ]);
  const [openedRetencao, setOpenedRetencao] = useState(false);

  return (
    <Container
      id={"pags_responsividade_padding_geral"}
      fluid
      className={"container_conteudo conteudo_pagina"}
    >
      <HeaderCadastros
        NomePaginaTitulo={"Pagamento"}
        PaginaSubtitulo={"Cadastro"}
      />

      <RowForm $borderRadiusType={2} className="mt-1">
        <CCol md={2}>
          <PTitulosInputs>Tipo de Documento</PTitulosInputs>
          <FormControlListaDeInputs
            radiusborder={documentos.length > 0 ? "5px 5px 0px 0px" : "5px"}
          />
        </CCol>
        <CCol>
          <PTitulosInputs>Documento</PTitulosInputs>
          <AutoCompleteFichas
            options={[{ id: 0, valor: "teste" }]}
            labelFormat={(option) => `${option.id} - ${option.valor}`}
            radiusborder={documentos.length > 0 ? "5px 5px 0px 0px" : "5px"}
          />
        </CCol>
        <CCol md={1}>
          <PTitulosInputs>Parcela</PTitulosInputs>
          <FormControlListaDeInputs
            radiusborder={documentos.length > 0 ? "5px 5px 0px 0px" : "5px"}
          />
        </CCol>
        <CCol md={3}>
          <PTitulosInputs>Valor</PTitulosInputs>
          <FormControlListaDeInputs
            radiusborder={documentos.length > 0 ? "5px 5px 0px 0px" : "5px"}
          />
        </CCol>
        <CColButtonsAcoes2Icons md={1}>
          <PTitulosInputs>Ação</PTitulosInputs>
          <IconAdicionar />
        </CColButtonsAcoes2Icons>

        {documentos.map((item, index, array) => (
          <RowAninhada style={{ marginTop: "-1px " }} key={index}>
            <CCol md={2}>
              <FormControlListaDeInputs
                disabled
                radiusborder={
                  array.length - 1 === index ? "0px 0px 5px 5px " : "0px"
                }
              />
            </CCol>
            <CCol>
              <FormControlListaDeInputs
                disabled
                radiusborder={
                  array.length - 1 === index ? "0px 0px 5px 5px " : "0px"
                }
              />
            </CCol>
            <CCol md={1}>
              <FormControlListaDeInputs
                disabled
                radiusborder={
                  array.length - 1 === index ? "0px 0px 5px 5px " : "0px"
                }
              />
            </CCol>
            <CCol md={3}>
              <FormControlListaDeInputs
                disabled
                radiusborder={
                  array.length - 1 === index ? "0px 0px 5px 5px " : "0px"
                }
              />
            </CCol>
            <CColButtonsAcoes2Icons md={1}>
              <IconEdit />
              <IconDelete />
            </CColButtonsAcoes2Icons>
          </RowAninhada>
        ))}

        <RowAninhada style={{ marginTop: "15px" }}>
          <CCol md={2}></CCol>
          <CCol></CCol>
          <CCol md={1}></CCol>
          <CCol md={3}>
            <FormControlListaDeInputs
              style={{
                borderRadius: "10px",
                padding: "15px",
                border: "none",
                textAlign: "end",
              }}
              value={`R$ 0,00`}
              disabled
            />
          </CCol>
          <CColButtonsAcoes2Icons md={1}></CColButtonsAcoes2Icons>
        </RowAninhada>
      </RowForm>

      <RowTitulo
        style={{
          borderRadius: `${openedRetencao ? "5px 5px 0px 0px" : " 5px"}`,
        }}
      >
        <CCol style={{ display: "flex", gap: "5px" }}>
          <Icon
            icon={openedRetencao ? "ph:minus-square" : "si:add-square-line"}
            width="24"
            height="24"
            style={{ color: "#515c70", cursor: "pointer" }}
            onClick={() => setOpenedRetencao((prev) => !prev)}
          />
          {/* <Icon
            icon="ph:minus-square"
            width="24"
            height="24"
            style={{ color: "#515c70" }}
          /> */}
          <p style={{ margin: "0px" }}>Retenção</p>
        </CCol>
      </RowTitulo>
      <Collapse in={openedRetencao}>
        <RowForm $borderRadiusType={2} className="mt-1">
          <CCol md={2}>
            <PTitulosInputs>Tipo de Documento</PTitulosInputs>
            <FormControlListaDeInputs
              radiusborder={documentos.length > 0 ? "5px 5px 0px 0px" : "5px"}
            />
          </CCol>
          <CCol>
            <PTitulosInputs>Documento</PTitulosInputs>
            <AutoCompleteFichas
              options={[{ id: 0, valor: "teste" }]}
              labelFormat={(option) => `${option.id} - ${option.valor}`}
              radiusborder={documentos.length > 0 ? "5px 5px 0px 0px" : "5px"}
            />
          </CCol>
          <CCol md={3}>
            <PTitulosInputs>Valor</PTitulosInputs>
            <FormControlListaDeInputs
              radiusborder={documentos.length > 0 ? "5px 5px 0px 0px" : "5px"}
            />
          </CCol>
          <CColButtonsAcoes2Icons md={1}>
            <PTitulosInputs>Ação</PTitulosInputs>
            <IconAdicionar />
          </CColButtonsAcoes2Icons>

          {documentos.map((item, index, array) => (
            <RowAninhada style={{ marginTop: "-1px " }} key={index}>
              <CCol md={2}>
                <FormControlListaDeInputs
                  disabled
                  radiusborder={
                    array.length - 1 === index ? "0px 0px 5px 5px " : "0px"
                  }
                />
              </CCol>
              <CCol>
                <FormControlListaDeInputs
                  disabled
                  radiusborder={
                    array.length - 1 === index ? "0px 0px 5px 5px " : "0px"
                  }
                />
              </CCol>
              <CCol md={3}>
                <FormControlListaDeInputs
                  disabled
                  radiusborder={
                    array.length - 1 === index ? "0px 0px 5px 5px " : "0px"
                  }
                />
              </CCol>
              <CColButtonsAcoes2Icons md={1}>
                <IconEdit />
                <IconDelete />
              </CColButtonsAcoes2Icons>
            </RowAninhada>
          ))}

          <RowAninhada style={{ marginTop: "15px" }}>
            <CCol md={2}></CCol>
            <CCol></CCol>

            <CCol md={3}>
              <FormControlListaDeInputs
                style={{
                  borderRadius: "10px",
                  padding: "15px",
                  border: "none",
                  textAlign: "end",
                }}
                value={`R$ 0,00`}
                disabled
              />
            </CCol>
            <CColButtonsAcoes2Icons md={1}></CColButtonsAcoes2Icons>
          </RowAninhada>
        </RowForm>
      </Collapse>

      <RowForm $borderRadiusType={3} className="mt-3">
        <CCol></CCol>
        <CCol md={3} style={{ display: "flex", justifyContent: "end" }}>
          <FormControlListaDeInputs
            style={{
              borderRadius: "10px",
              padding: "15px",
              border: "none",
              textAlign: "end",
            }}
            value={`R$ 0,00`}
            disabled
          />
        </CCol>
        <CColButtonsAcoes2Icons md={1}></CColButtonsAcoes2Icons>
      </RowForm>

      <RowTitulo>Origem Financeira</RowTitulo>

      <RowForm $borderRadiusType={2} className="mt-1">
        <CCol>
          <FloatingLabelInput disabled />
        </CCol>
        <CCol>
          <MyAutoComplete
            options={[{ id: 0, valor: "teste" }]}
            labelFormat={(option) => `${option.id} - ${option.valor}`}
            style={{ height: "38px" }}
          />
        </CCol>
        <CCol>
          <FloatingLabelInput label="Remessa" />
        </CCol>
        <CCol>
          <InputData />
        </CCol>

        <RowAninhada style={{ marginTop: "15px" }}>
          <CCol>
            <MyAutoComplete
              options={[{ id: 0, valor: "teste" }]}
              labelFormat={(option) => `${option.id} - ${option.valor}`}
              style={{ height: "38px" }}
            />
          </CCol>
          <CCol>
            <FloatingLabelInput label="Documento" />
          </CCol>
        </RowAninhada>
      </RowForm>

      <div className="p-3"></div>
    </Container>
  );
};

export default Pagamento;
