import React, { useMemo, useState } from "react";
import {
  CCol,
  CColButtonsAcoes2Icons,
  RowAninhada,
  RowForm,
  RowToModal,
} from "../../../../components/Grid/CCol";
import MyAutoComplete from "../../../../components/Inputs/MyAutoComplete";
import {
  FormControlListaDeInputs,
  PTitulosInputs,
} from "../../../../styles/StyledComponents/ListaDeInputs";
import IconAdicionar from "../../../../components/ListaDeInputs/IconAdicionar";
import InputFormatRealBrasileiro from "../../../../components/Grid/InputFormatRealBrasileiro";
import FloatingLabelInput from "../../../../components/Grid/FloatingLabelInput";
import { ReturnDataAtual } from "../../../../utils/Gerais/ReturnDataAtual";
import { toast } from "react-toastify";

import { Icon } from "@iconify/react/dist/iconify.js";
import { FormatValueToLocaleString } from "../../../../utils/FormatacaoDeDados/FormatValueToLocaleString";
export const FonteDeRecursosAba = ({
  infosCadastro,
  setInfosCadastro,
  optionsFonteDeRecursos,
}) => {

  const dataAtual = ReturnDataAtual()
    .replace(/\//g, "-")
    .split("-")
    .reverse()
    .join("-");

  const [inputFonteValue, setInputFonteValue] = useState("");

  const [fonteParaAdicionar, setFonteParaAdicionar] = useState({
    fonteId: null,
    valor: "",
    data: dataAtual,
    label: "",
    percentage: "0,00",
  });

  const [indexEditFonteDeRecurso, setIndexEditFonteDeRecurso] = useState(null);
  const [valuesFonteAntesDaEdicao, setValuesFonteAntesDaEdicao] = useState({
    percentage: "",
    valor: "",
  });

  const handleSelectFonte = (option) => {
    setFonteParaAdicionar((prev) => ({
      ...prev,
      fonteId: option.value,
      label: option.label,
    }));
  };

  const handleAddFonteRecurso = () => {
    const hasDataFonteRecurso = Object.values(fonteParaAdicionar).every(
      (item) => item !== null
    );

    if (!hasDataFonteRecurso) {
      toast.error(
        "Preencha todos os campos corretamente para adicionar uma fonte de recursos."
      );
      return;
    }
    const filterFonts = infosCadastro.fontes.filter(
      (item) => item.fonteId !== null
    );

    const fontesAdicionadas = [...filterFonts, fonteParaAdicionar];
    setInfosCadastro({ ...infosCadastro, fontes: fontesAdicionadas });
    setFonteParaAdicionar({
      ...fonteParaAdicionar,
      valor: "0,00",
      percentage: "0,00",
    });
  };

  const handleEditFonteDeRecurso = (indexFonteRecurso) => {
    const { percentage, valor } = infosCadastro.fontes[indexFonteRecurso];
    setValuesFonteAntesDaEdicao({ percentage, valor });
    setIndexEditFonteDeRecurso(indexFonteRecurso);
  };

  const handleSaveEditFonte = () => {
    setIndexEditFonteDeRecurso(null);
  };
  const handleCancelEditFonte = (indexFonteRecurso) => {
    const fontes = infosCadastro.fontes.map((item, index) => {
      if (index === indexFonteRecurso) {
        return {
          ...item,
          percentage: valuesFonteAntesDaEdicao.percentage,
          valor: valuesFonteAntesDaEdicao.valor,
        };
      }
      return item;
    });

    setInfosCadastro({ ...infosCadastro, fontes });
    setIndexEditFonteDeRecurso(null);
  };

  const handleValorFonteEdit = (indexFonteRecurso, valor) => {
    const fontes = infosCadastro.fontes.map((item, index) => {
      if (index === indexFonteRecurso) {
        return {
          ...item,
          valor,
        };
      }
      return item;
    });

    setInfosCadastro({ ...infosCadastro, fontes });
  };
  
  const handlePercentageFonteEdit = (indexFonteRecurso, percentage) => {
    console.log(percentage);
    const percentageFormatted = maskInputPercentage(percentage);
    const fontes = infosCadastro.fontes.map((item, index) => {
      if (index === indexFonteRecurso) {
        return {
          ...item,
          percentage: percentageFormatted,
        };
      }
      return item;
    });

    setInfosCadastro({ ...infosCadastro, fontes });
  };

  const handleDeleteFonteRecurso = (indexFonteRecurso) => {
    const fontes = infosCadastro.fontes.filter(
      (_, index) => index !== indexFonteRecurso
    );

    setInfosCadastro({ ...infosCadastro, fontes });
  };

  const maskInputPercentage = (value) => {
    const rawValue = value.replace(/[^\d]/g, "");
    const numericValue = Number(rawValue) / 100;
    const formattedValue = numericValue.toLocaleString("pt-BR", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    return formattedValue;
  };

  const totalFonteRecurso = useMemo(() => {
    const total = infosCadastro.fontes.reduce(
      (acc, item) => {
        acc.totalFonte += parseFloat(item.valor.replace(",", ".")) || 0;
        acc.percentageFonte +=
          parseFloat(item.percentage.replace(",", ".")) || 0;
        return acc;
      },
      { totalFonte: 0, percentageFonte: 0 }
    );

    return total;
  }, [infosCadastro.fontes]);
  console.log("loop ??");

  return (
    <>
      <RowToModal
        className="row_form"
        style={{
          marginTop: "12px",
          borderRadius: "5px 5px 0px 0px",
          padding: "8px 10px",
        }}
      >
        <CCol style={{ fontSize: "16px", color: "#515c70", fontWeight: "700" }}>
          Fonte de Recursos / CO
        </CCol>
      </RowToModal>

      <RowForm $borderRadiusType={2} className="mt-1">
        <CCol>
          <PTitulosInputs>
            Orientações para utilização Fontes ou Destinações de Recursos -
            Exclusivas da Conta Bancária
          </PTitulosInputs>
        </CCol>

        <CCol md={1}>
          <PTitulosInputs>%</PTitulosInputs>
        </CCol>
        <CCol md={2}>
          <PTitulosInputs>Valor</PTitulosInputs>
        </CCol>
        <CColButtonsAcoes2Icons md={1}>
          <PTitulosInputs>Ação</PTitulosInputs>
        </CColButtonsAcoes2Icons>

        <RowAninhada>
          <CCol>
            <MyAutoComplete
              options={[...optionsFonteDeRecursos, { label: "", value: null }]}
              labelFormat={(item) => `${item.label} - ${item.value}`}
              inputValue={inputFonteValue}
              setInputValue={setInputFonteValue}
              onOptionSelect={(option) => {
                handleSelectFonte(option);
              }}
            />
          </CCol>
          <CCol md={1}>
            <FormControlListaDeInputs
              style={{ textAlign: "end" }}
              value={fonteParaAdicionar.percentage}
              onChange={(e) => {
                setFonteParaAdicionar((prev) => ({
                  ...prev,
                  percentage: maskInputPercentage(e.target.value),
                }));
              }}
            />
          </CCol>
          <CCol md={2}>
            <InputFormatRealBrasileiro
              listaDeInputs={true}
              externalValue={fonteParaAdicionar.valor}
        
              onChange={(_, valor) =>
                setFonteParaAdicionar((prev) => ({
                  ...prev,
                  valor,
                }))
              }
            />
          </CCol>
          <CColButtonsAcoes2Icons md={1}>
            <IconAdicionar
              onClick={handleAddFonteRecurso}
              height="28"
              style={{
                cursor: "pointer",
                marginLeft: "5px",
                marginTop: "1px",
              }}
            />
          </CColButtonsAcoes2Icons>
        </RowAninhada>
        {infosCadastro.fontes.map((item, index, array) => {
          if (item.fonteId === null) return null;
          return (
            <RowAninhada key={index} style={{ marginTop: "-1px" }}>
              <CCol>
                <FormControlListaDeInputs
                  radiusborder={
                    array.length - 1 === index ? "0px 0px 5px 5px" : "0px"
                  }
                  value={item.label}
                  disabled
                />
              </CCol>

              <CCol md={1}>
                <FormControlListaDeInputs
                  style={{ textAlign: "end" }}
                  radiusborder={
                    array.length - 1 === index ? "0px 0px 5px 5px" : "0px"
                  }
                  value={item.percentage}
                  disabled={indexEditFonteDeRecurso !== index}
                  onChange={(e) =>
                    handlePercentageFonteEdit(index, e.target.value)
                  }
                />
              </CCol>

              <CCol md={2}>
                <InputFormatRealBrasileiro
                  listaDeInputs
                  externalValue={item.valor}
                  disabled={indexEditFonteDeRecurso !== index}
                  radiusborder={
                    array.length - 1 === index ? "0px 0px 5px 5px" : "0px"
                  }
                  onChange={(_, valor) => handleValorFonteEdit(index, valor)}
                />
              </CCol>
              <CColButtonsAcoes2Icons md={1}>
                {indexEditFonteDeRecurso !== index && (
                  <>
                    <Icon
                      onClick={() => handleEditFonteDeRecurso(index)}
                      icon="bx:edit"
                      color="#5971C8"
                      height="26"
                      style={{
                        cursor: "pointer",
                        marginLeft: "5px",
                        marginTop: "3px",
                      }}
                    />
                    <Icon
                      onClick={() => handleDeleteFonteRecurso(index)}
                      icon="ic:baseline-delete"
                      color="#E79900"
                      height="26"
                      style={{
                        cursor: "pointer",
                        marginLeft: "5px",
                        marginTop: "1px",
                      }}
                    />
                  </>
                )}
                {indexEditFonteDeRecurso !== null &&
                  indexEditFonteDeRecurso === index && (
                    <>
                      <Icon
                        onClick={() => handleSaveEditFonte()}
                        icon="fluent:save-24-filled"
                        color="#008CFF"
                        height="26"
                        style={{
                          cursor: "pointer",
                          marginLeft: "5px",
                          marginTop: "3px",
                        }}
                      />
                      <Icon
                        icon="ic:baseline-cancel"
                        onClick={() => handleCancelEditFonte(index)}
                        height="26"
                        color="#F24E1E"
                        style={{
                          display:
                            indexEditFonteDeRecurso !== index
                              ? "none"
                              : "inline-block",
                          pointerEvents:
                            indexEditFonteDeRecurso !== index ? "none" : "auto",
                          cursor:
                            indexEditFonteDeRecurso !== index
                              ? "not-allowed"
                              : "pointer",
                          marginLeft: "2px",
                          marginRight: "-5px",
                          marginTop: "3px",
                        }}
                      />
                    </>
                  )}
              </CColButtonsAcoes2Icons>
            </RowAninhada>
          );
        })}
      </RowForm>

      <RowForm $borderRadiusType={2} style={{ marginTop: "-14px" }}>
        <CCol
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "end",
          }}
        >
          <p style={{ margin: 0, color: "#515c70", fontWeight: 600 }}>
            Valor Total
          </p>
        </CCol>
        <CCol md={1}>
          <FloatingLabelInput
            disabled
            value={FormatValueToLocaleString(totalFonteRecurso.percentageFonte)}
            style={{ border: "none", borderRadius: 10, textAlign: "center" }}
          />
        </CCol>
        <CCol md={2}>
          <FloatingLabelInput
            disabled
            value={FormatValueToLocaleString(totalFonteRecurso.totalFonte)}
            style={{ border: "none", borderRadius: 10, textAlign: "end" }}
          />
        </CCol>
        <CColButtonsAcoes2Icons md={1}></CColButtonsAcoes2Icons>
      </RowForm>
    </>
  );
};