import React, { useMemo, useState } from "react";
import {
  CCol,
  CColButtonsAcoes2Icons,
  RowAninhada,
  RowForm,
  RowToModal,
} from "../../../../components/Grid/CCol";
import MyAutoComplete from "../../../../components/Inputs/MyAutoComplete";
import {
  FormControlListaDeInputs,
  PTitulosInputs,
} from "../../../../styles/StyledComponents/ListaDeInputs";
import IconAdicionar from "../../../../components/ListaDeInputs/IconAdicionar";
import InputFormatRealBrasileiro from "../../../../components/Grid/InputFormatRealBrasileiro";
import FloatingLabelInput from "../../../../components/Grid/FloatingLabelInput";
import InputData from "../../../../components/Grid/InputData";
import { ReturnDataAtual } from "../../../../utils/Gerais/ReturnDataAtual";
import { toast } from "react-toastify";

import { Icon } from "@iconify/react/dist/iconify.js";
import { FormatValueToLocaleString } from "../../../../utils/FormatacaoDeDados/FormatValueToLocaleString";


export const FonteDeRecursosAba = ({
  fontesSubOptions = [],
  valuesQdrde,
  setValuesQdrde,
  dataFontesDeRecursos
}) => {

  const dataAtual = ReturnDataAtual()
    .replace(/\//g, "-")
    .split("-")
    .reverse()
    .join("-");

  const [inputFonteValue, setInputFonteValue] = useState("");

  const [fonteParaAdicionar, setFonteParaAdicionar] = useState({
    fonteId: null,
    valor: "",
    data: dataAtual,
    label: "",
  });
 
  const [indexEditFonteDeRecurso, setIndexEditFonteDeRecurso] = useState(null);
  const [valuesFonteAntesDaEdicao, setValuesFonteAntesDaEdicao] = useState({
    data: "",
    valor: "",
  });

  const getIdFonte = (contaFonte) => {
    
    const fonte = dataFontesDeRecursos.find(
      (item) => item.conta === contaFonte
    );

    return fonte?.id || null;
  };

  const formatFonts = useMemo(() => {
    if (!fontesSubOptions.length) return [];

    const fontes = fontesSubOptions.map((item, index) => {
      const idFonte = getIdFonte(`${item.exercicio}.${item.codigo}`);
      return {
        label: `${item.exercicio}.${item.codigo} - ${item.label}`,
        value: idFonte || String(index + 1),
        code: `${item.exercicio}.${item.codigo}`,
      };
    });
    // setInputFonteValue(null);
    return fontes;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fontesSubOptions]);

  const handleSelectFonte = (option) => {

    setFonteParaAdicionar((prev) => ({
      ...prev,
      fonteId: option.value,
      label: option.label,
    }));
  };

  const handleAddFonteRecurso = () => {

    const hasDataFonteRecurso = Object.values(fonteParaAdicionar).every(
      (item) => item !== null
    );

    if (!hasDataFonteRecurso) {
      toast.error(
        "Preencha todos os campos corretamente para adicionar uma fonte de recursos."
      );
      return;
    }
    const filterFonts = valuesQdrde.fontes.filter(
      (item) => item.fonteId !== null
    );

    const fontesAdicionadas = [...filterFonts, fonteParaAdicionar];
    setValuesQdrde({ ...valuesQdrde, fontes: fontesAdicionadas });
    setFonteParaAdicionar({ ...fonteParaAdicionar, valor: "0,00" });
  };

  const handleEditFonteDeRecurso = (indexFonteRecurso) => {
    const { data, valor } = valuesQdrde.fontes[indexFonteRecurso];
    setValuesFonteAntesDaEdicao({ data, valor });
    setIndexEditFonteDeRecurso(indexFonteRecurso);
  };

  const handleSaveEditFonte = () => {
    setIndexEditFonteDeRecurso(null);
  };
  const handleCancelEditFonte = (indexFonteRecurso) => {
    const fontes = valuesQdrde.fontes.map((item, index) => {
      if (index === indexFonteRecurso) {
        return {
          ...item,
          data: valuesFonteAntesDaEdicao.data,
          valor: valuesFonteAntesDaEdicao.valor,
        };
      }
      return item;
    });

    setValuesQdrde({ ...valuesQdrde, fontes });
    setIndexEditFonteDeRecurso(null);
  };

  const handleChangeDataFonte = (data, indexFonteRecurso) => {
    const fontes = valuesQdrde.fontes.map((item, index) => {
      if (index === indexFonteRecurso) {
        return {
          ...item,
          data,
        };
      }
      return item;
    });

    setValuesQdrde({ ...valuesQdrde, fontes });
  };

  const handleValorFonteEdit = (indexFonteRecurso, valor) => {
    const fontes = valuesQdrde.fontes.map((item, index) => {
      if (index === indexFonteRecurso) {
        return {
          ...item,
          valor,
        };
      }
      return item;
    });

    setValuesQdrde({ ...valuesQdrde, fontes });
  };

  const handleDeleteFonteRecurso = (indexFonteRecurso) => {
    const fontes = valuesQdrde.fontes.filter(
      (_, index) => index !== indexFonteRecurso
    );

    setValuesQdrde({ ...valuesQdrde, fontes });
  };

  const disabledInputValueFonte = (indexFonte) => {
    const dateFonte = valuesQdrde.fontes[indexFonte].data.split("-");
    const dia = dateFonte[2];
    const mes = dateFonte[1];

    return !(
      dia === "01" &&
      mes === "01" &&
      indexEditFonteDeRecurso === indexFonte
    );
  };

  const disabledValue = () => {
    const dataArray = fonteParaAdicionar.data.split("-");
    const dia = dataArray[2];
    const mes = dataArray[1];

    return !(dia === "01" && mes === "01");
  };


  const totalFonteRecurso = useMemo(() => {

    const total = valuesQdrde.fontes.reduce((acc, item) => {
      acc += parseFloat(item.valor.replace(",", ".")) || 0;
      return acc;
    }, 0);

    return total;
  }, [valuesQdrde.fontes]);

  return (
    <>
      <RowToModal
        className="row_form"
        style={{
          marginTop: "12px",
          borderRadius: "5px 5px 0px 0px",
          padding: "8px 10px",
        }}
      >
        <CCol style={{ fontSize: "16px", color: "#515c70", fontWeight: "700" }}>
          Fonte de Recursos / CO
        </CCol>
      </RowToModal>

      <RowForm $borderRadiusType={2} className="mt-1">
        <CCol>
          <PTitulosInputs>
            Orientações para utilização Fontes ou Destinações de Recursos -
            Exclusivas da Conta Bancária
          </PTitulosInputs>
        </CCol>

        <CCol md={2}>
          <PTitulosInputs>Data</PTitulosInputs>
        </CCol>
        <CCol md={2}>
          <PTitulosInputs>Valor</PTitulosInputs>
        </CCol>
        <CColButtonsAcoes2Icons md={1}>
          <PTitulosInputs>Ação</PTitulosInputs>
        </CColButtonsAcoes2Icons>

        <RowAninhada>
          <CCol>
            <MyAutoComplete
              options={
                fontesSubOptions.length
                  ? formatFonts
                  : [{ label: "", value: null }]
              }
              labelFormat={(item) => `${item.label} - ${item.value}`}
              inputValue={inputFonteValue}
              setInputValue={setInputFonteValue}
              onOptionSelect={handleSelectFonte}
            />
          </CCol>
          <CCol md={2}>
            <InputData
              style={{ height: "30px" }}
              onChange={(e) =>
                setFonteParaAdicionar((prev) => ({
                  ...prev,
                  data: e.target.value,
                }))
              }
              externalValue={fonteParaAdicionar.data}
            />
          </CCol>
          <CCol md={2}>
            <InputFormatRealBrasileiro
              listaDeInputs={true}
              externalValue={fonteParaAdicionar.valor}
              disabled={disabledValue()}
              onChange={(_, valor) =>
                setFonteParaAdicionar((prev) => ({
                  ...prev,
                  valor,
                }))
              }
            />
          </CCol>
          <CColButtonsAcoes2Icons md={1}>
            <IconAdicionar
              onClick={handleAddFonteRecurso}
              height="28"
              style={{
                cursor: "pointer",
                marginLeft: "5px",
                marginTop: "1px",
              }}
            />
          </CColButtonsAcoes2Icons>
        </RowAninhada>
        {valuesQdrde.fontes.map((item, index, array) => {
          if (item.fonteId === null) return null;
          return (
            <RowAninhada key={index} style={{ marginTop: "-1px" }}>
              <CCol>
                <FormControlListaDeInputs
                  radiusborder={
                    array.length - 1 === index ? "0px 0px 5px 5px" : "0px"
                  }
                  value={item.label}
                  disabled
                />
              </CCol>

              <CCol md={2}>
                <InputData
                  style={{ height: "30px" }}
                  externalValue={item.data}
                  disabled={index !== indexEditFonteDeRecurso}
                  radiusborder={
                    array.length - 1 === index ? "0px 0px 5px 5px" : "0px"
                  }
                  onChange={(e) => handleChangeDataFonte(e.target.value, index)}
                />
              </CCol>

              <CCol md={2}>
                <InputFormatRealBrasileiro
                  listaDeInputs
                  externalValue={item.valor}
                  disabled={disabledInputValueFonte(index)}
                  radiusborder={
                    array.length - 1 === index ? "0px 0px 5px 5px" : "0px"
                  }
                  onChange={(_, valor) => handleValorFonteEdit(index, valor)}
                />
              </CCol>
              <CColButtonsAcoes2Icons md={1}>
                {indexEditFonteDeRecurso !== index && (
                  <>
                    <Icon
                      onClick={() => handleEditFonteDeRecurso(index)}
                      icon="bx:edit"
                      color="#5971C8"
                      height="26"
                      style={{
                        cursor: "pointer",
                        marginLeft: "5px",
                        marginTop: "3px",
                      }}
                    />
                    <Icon
                      onClick={() => handleDeleteFonteRecurso(index)}
                      icon="ic:baseline-delete"
                      color="#E79900"
                      height="26"
                      style={{
                        cursor: "pointer",
                        marginLeft: "5px",
                        marginTop: "1px",
                      }}
                    />
                  </>
                )}
                {indexEditFonteDeRecurso !== null &&
                  indexEditFonteDeRecurso === index && (
                    <>
                      <Icon
                        onClick={() => handleSaveEditFonte()}
                        icon="fluent:save-24-filled"
                        color="#008CFF"
                        height="26"
                        style={{
                          cursor: "pointer",
                          marginLeft: "5px",
                          marginTop: "3px",
                        }}
                      />
                      <Icon
                        icon="ic:baseline-cancel"
                        onClick={() => handleCancelEditFonte(index)}
                        height="26"
                        color="#F24E1E"
                        style={{
                          display:
                            indexEditFonteDeRecurso !== index
                              ? "none"
                              : "inline-block",
                          pointerEvents:
                            indexEditFonteDeRecurso !== index ? "none" : "auto",
                          cursor:
                            indexEditFonteDeRecurso !== index
                              ? "not-allowed"
                              : "pointer",
                          marginLeft: "2px",
                          marginRight: "-5px",
                          marginTop: "3px",
                        }}
                      />
                    </>
                  )}
              </CColButtonsAcoes2Icons>
            </RowAninhada>
          );
        })}
      </RowForm>

      <RowForm $borderRadiusType={2} style={{ marginTop: "-14px" }}>
        <CCol
          style={{
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
          }}
        >
          <span style={{ color: "#515c70", fontWeight: 600 }}>
            Primeiro Saldo da Conta
          </span>
        </CCol>
        <CCol md={2}>
          <FloatingLabelInput
            disabled
            style={{ border: "none", textAlign: "end", borderRadius: 10 }}
            value={FormatValueToLocaleString(totalFonteRecurso)}
          />
        </CCol>
        <CColButtonsAcoes2Icons md={1} />
      </RowForm>
    </>
  );
};
