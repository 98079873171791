import api from "../../utils/api";

export const getAllCpfsCliente = async (clienteId) => {
  try {
    const resp = await api.get(`/cpf/por-cliente/${clienteId}`);
    const data = resp.data;

    return data;
  } catch (error) {
    return [];
  }
};

export const getCpfPorCpfCliente = async (cpf, clienteId) => {
  try {
    const resp = await api.get(`/cpf/busca/${clienteId}/${cpf}`);
    const data = resp.data;

    return data;
  } catch (error) {
    return;
  }
};
