export const optionsTranferencias = [
  {
    label: "1 - Duodécimo",
    value: 1,
  },
  {
    label: "2 - Aporte para Despesas Administrativas / Custeio",
    value: 2,
  },
  {
    label: "3 - Aporte para Cobertura de Insuficiência Financeira",
    value: 3,
  },
  {
    label:
      "4 - Aporte para Pagamento de Benefícios Previdenciários de Responsabilidade do Ente",
    value: 4,
  },
  {
    label: "5 -Outros Aportes",
    value: 5,
  },
  {
    label: "8 - Devolução de Recursos",
    value: 8,
  },
  {
    label: "9 - Transferências Indireta ( Geral )",
    value: 9,
  },
];
