import { useContext } from "react";
import { OpenedModalsContext } from "../contexts/OpenedModalsContext";

export const useOpenedModals = () => {
  const context = useContext(OpenedModalsContext);

  if (!context) {
    throw new Error(
      "OpenedModalsContext deve ser usado dentro de um OpenedModalsProvider"
    );
  }

  return context;
};
