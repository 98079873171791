import React, { useEffect } from "react";
import { Row } from "react-bootstrap";
import { CCol, RowAninhada } from "../../../../components/Grid/CCol";
import FloatingLabelInput from "../../../../components/Grid/FloatingLabelInput";
import axios from "axios";

const Localizacao = ({ values, setValues, acao }) => {
  useEffect(() => {
    if (values?.cep?.length === 8) {
      console.log("CEP");
      axios
        .get(`https://brasilapi.com.br/api/cep/v1/${values.cep}`)
        .then((response) => {
          const { street, neighborhood, city, state } = response.data;
          console.log(response.data);
          setValues((prevValues) => ({
            ...prevValues,
            logradouro: street || prevValues.logradouro || "",
            bairro: neighborhood || prevValues.bairro || "",
            municipio: city || prevValues.municipio || "",
            estado: state || prevValues.estado || "",
          }));
        })
        .catch((error) => {
          console.error("Erro ao consultar o CEP:", error);
        });
    }
    // eslint-disable-next-line
  }, [values.cep]);

  return (
    <Row className={"row_form mt-1"}>
      <RowAninhada>
        <CCol md={2} style={{ width: "10em" }}>
          <FloatingLabelInput
            disabled={acao === "visualizar"}
            label={"CEP"}
            placeholder={"CEP"}
            maxLength={8}
            value={values.cep || ""}
            onChange={(e) =>
              setValues((prevValues) => ({
                ...prevValues,
                cep: e.target.value,
              }))
            }
          />
        </CCol>
        <CCol>
          <FloatingLabelInput
            disabled
            label={"Logradouro"}
            placeholder={"Logradouro"}
            value={values.logradouro || ""}
          />
        </CCol>
        <CCol md={1}>
          <FloatingLabelInput
            disabled={acao === "visualizar"}
            label={"Nº"}
            placeholder={"Nº"}
            value={values.numeroEndereco || ""}
            onChange={(e) =>
              setValues((prevValues) => ({
                ...prevValues,
                numeroEndereco: e.target.value,
              }))
            }
          />
        </CCol>
        <CCol md={1} style={{ width: "25em" }}>
          <FloatingLabelInput
            disabled={acao === "visualizar"}
            label={"Complemento"}
            placeholder={"Complemento"}
            value={values.complemento || ""}
            onChange={(e) =>
              setValues((prevValues) => ({
                ...prevValues,
                complemento: e.target.value,
              }))
            }
          />
        </CCol>
      </RowAninhada>
      <RowAninhada className={"mt-3"}>
        <CCol>
          <FloatingLabelInput
            disabled
            label={"Bairro"}
            placeholder={"Bairro"}
            value={values.bairro || ""}
          />
        </CCol>
        <CCol>
          <FloatingLabelInput
            disabled
            label={"Município"}
            placeholder={"Município"}
            value={values.municipio || ""}
          />
        </CCol>
        <CCol md={2}>
          <FloatingLabelInput
            disabled
            label={"Estado"}
            placeholder={"Estado"}
            value={values.estado || ""}
          />
        </CCol>
      </RowAninhada>
    </Row>
  );
};

export default Localizacao;
