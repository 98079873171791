import React, { useState, useEffect } from "react";
import HeaderCadastros from "../../../components/HeaderCadastros/HeaderCadastros";
import { ToastContainer } from "react-toastify";
import { Container, Row } from "react-bootstrap";
import FloatingLabelInput from "../../../components/Grid/FloatingLabelInput";
import { CCol, RowAninhada } from "../../../components/Grid/CCol";
import Preparar from "./Preparar/Preparar";
import Importar from "./Importar/Importar";
import Configurar from "./Configurar/Configurar";

const UtilitariosContabilidade = () => {
  const [option, setOption] = useState(0);
  const [menuSelectOption, setMenuSelectOption] = useState(null);

  const [mainSelectOptions, setMainSelectOptions] = useState(["", []]);

  useEffect(() => {
    setMenuSelectOption(null);
    if (option === 0) {
      setMainSelectOptions([
        "Selecione",
        [
          {
            label: "Selecione",
            value: null,
          },
        ],
      ]);
    }
    if (option === 1) {
      setMainSelectOptions([
        "Selecione",
        [
          {
            label: "Selecione",
            value: null,
          },
          {
            label: "Dados de Licitação do TCE-PB",
            value: "2.1",
          },
        ],
      ]);
    }
    if (option === 2) {
      setMainSelectOptions([
        "Selecione",
        [
          {
            label: "Selecione",
            value: null,
          },
        ],
      ]);
    }
  }, [option]);

  return (
    <Container
      id={"pags_responsividade_padding_geral"}
      fluid
      style={{ color: "#515C70" }}
      className={"container_conteudo conteudo_pagina"}
    >
      <HeaderCadastros
        NomePaginaTitulo={"Utilitários"}
        PaginaSubtitulo={"Cadastro"}
        BotaoSaveAtivo={true}
        saveOnly
      />

      <ToastContainer />
      <Row className="row_form mt-1">
        <RowAninhada>
          <CCol md={1} style={{ width: "250px" }}>
            <FloatingLabelInput
              new={true}
              tipo="input_select"
              placeholder={"Preparar"}
              options={[
                { label: "Preparar", value: 0 },
                { label: "Importar", value: 1 },
                { label: "Configurar", value: 2 },
              ]}
              onSelect={(option) => setOption(option.value)}
            />
          </CCol>
          <CCol>
            <FloatingLabelInput
              new={true}
              tipo="input_select"
              placeholder={mainSelectOptions[0]}
              options={mainSelectOptions[1]}
              onSelect={(option) => setMenuSelectOption(option.value)}
            />
          </CCol>
        </RowAninhada>
      </Row>
      {option === 0 && <Preparar option={menuSelectOption} />}
      {option === 1 && <Importar option={menuSelectOption} />}
      {option === 2 && <Configurar option={menuSelectOption} />}
      <div style={{ height: "40px" }} />
    </Container>
  );
};

export default UtilitariosContabilidade;
