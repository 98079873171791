import React, { useContext, useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import HeaderCadastros from "../../../../components/HeaderCadastros/HeaderCadastros";
import { CCol, RowForm } from "../../../../components/Grid/CCol";
import MyAutoComplete from "../../../../components/Inputs/MyAutoComplete";
import FloatingLabelInput from "../../../../components/Grid/FloatingLabelInput";
import InputData from "../../../../components/Grid/InputData";
import InputFormatRealBrasileiro from "../../../../components/Grid/InputFormatRealBrasileiro";
import { RowInfosAcao } from "../../../../components/RowInfosAcao/RowInfosAcao";
import RowSelecaoAbasInferior from "../../../../components/Grid/RowSelecaoAbasInferior";
import { NotaFiscal } from "./NotaFiscal";
import { SaldosAba } from "./SaldosAba";
import { OrdemCronologicaAba } from "./OrdemCronologicaAba";
import { EfdReinfAba } from "./EfdReinfAba";
import { mockedDataEmpenho } from "./mockedDataEmpenho";
import { TestButton } from "../../../../utils/TestButton";
import { FormatCpfPaste } from "../../../../utils/FormatacaoDeDados/FormtCpfPaste";
import { getCpfsCnpjsPorCliente } from "../../../../services/gets/getCpfsCnpjsPorCliente";
import { CurrentClientContext } from "../../../../contexts/CurrentClientContext";
import { useOpenedModals } from "../../../../hooks/useOpenedModals";
import { getCurrentDate } from "../../../../utils/getCurrentDate";
import { Agrupamento } from "./Agrupamento";

const LiquidacaoDeEmpenho = () => {
  const { currentClient } = useContext(CurrentClientContext);
  const { openedModals, setOpenedModals } = useOpenedModals();
  const [abaSelecionada, setAbaSelecionada] = useState({});
  const [values, setValues] = useState({
    empenho: mockedDataEmpenho,
    data: getCurrentDate(),
    responsavelPeloAtesto: {
      responsavel: "",
      data: getCurrentDate(),
    },
    unidadeOrcamentaria: {
      codigo: "02.050",
      titulo: "Secretaria Municipal de Educação",
    },
    elementoDespesa: {
      codigo: "3.3.90.39",
      descricao: "OUTROS SERVIÇOS DE TERCEIROS",
      tipoPessoa: "PESSOA JURÍDICA",
    },
    subelemento: {
      codigo: "17",
      descricao: "MANUTENÇÃO E CONSERVAÇÃO DE MÁQUINAS E EQUIPAMENTOS",
    },
    fonteRecursos: {
      codigo: "1.500.1001",
      tipoDeRecurso: "Recursos não Vinculados de Impostos",
      descricao:
        "Identificação das despesas com manutenção e desenvolvimento do ensino",
    },
    modalidade: "Ordinário",
    notaFiscal: {
      numero: "",
      serie: "",
      tipo: "",
      data: "",
      valor: "",
      numeroChave: "",
    },
  });
  const [inputFornecedor, setInputFornecedor] = useState("");
  const [optionsCpf, setOptionsCpf] = useState([]);
  const [abas, setAbas] = useState([
    "Nota Fiscal",
    "Produtos e Aquisição",
    "Ordem Cronológica",
    "EFD-Reinf",
    "Agrupamentos",
    "Saldos",
  ]);
  const [valuesToTest, setValuesToTest] = useState({
    elemento: "",
    subElemento: "",
  });

  const elementsList = ["30", "32", "35", "36", "39", "51", "52"];

  const fetchCpfs = async (reqDeModal) => {
    const data = await getCpfsCnpjsPorCliente(currentClient.clienteId, "cpf");
    console.log(data);

    if (data) {
      setOptionsCpf(
        data.map((item) => ({
          id: item.pessoaId,
          nome: item.nomeCompleto,
          cpf: FormatCpfPaste(item.cpf),
        }))
      );

      if (reqDeModal) {
        const dado = data.reverse()[0];
        console.log(dado);

        setInputFornecedor(
          `${dado.nomeCompleto} - ${FormatCpfPaste(dado.cpf)}`
        );

        setOpenedModals((prev) =>
          prev.filter((item) => item.tituloModal !== "CPF/CNPJ")
        );
      }
    }
  };

  const handleAddNewCpf = () => {
    setOpenedModals((prev) => [
      ...prev,
      { tituloModal: "CPF/CNPJ", requisicaoDoModalConcluida: false },
    ]);
  };

  const handleOptionSelect = (item) => {
    console.log(item);

    setValues((prevValues) => ({
      ...prevValues,
      responsavelPeloAtesto: {
        ...prevValues.responsavelPeloAtesto,
        responsavel: item.id,
      },
    }));
  };

  useEffect(() => {
    const modalCpfCnpj = openedModals.find(
      (item) => item.tituloModal === "CPF/CNPJ"
    );

    if (modalCpfCnpj && modalCpfCnpj.requisicaoDoModalConcluida) {
      fetchCpfs(true);
    }

    //eslint-disable-next-line
  }, [openedModals]);

  useEffect(() => {
    if (!elementsList.includes(valuesToTest.elemento.slice(-2))) {
      setAbas((prevAbas) =>
        prevAbas.filter(
          (aba) => aba !== "Nota Fiscal" && aba !== "Agrupamentos"
        )
      );
    } else {
      setAbas((prevAbas) => {
        const newAbas = [...prevAbas];

        if (!newAbas.includes("Nota Fiscal")) {
          newAbas.unshift("Nota Fiscal");
        }

        if (!newAbas.includes("Agrupamentos")) {
          newAbas.splice(-1, 0, "Agrupamentos");
        }

        return newAbas;
      });
    }
    // eslint-disable-next-line
  }, [valuesToTest]);

  useEffect(() => {
    setValuesToTest({
      elemento: "3.3.90.39",
      subElemento: "17",
    });
  }, []);

  useEffect(() => {
    fetchCpfs();
    //eslint-disable-next-line
  }, []);

  //FUNCOES TEMPORÁRIAS
  const transformToZeroZero = () => {
    setValues((prevValues) => ({
      ...prevValues,
      elementoDespesa: {
        ...prevValues.elementoDespesa,
        codigo: "3.3.90.00",
      },
    }));
    setValuesToTest((prevValues) => ({
      ...prevValues,
      elemento: "3.3.90.00",
    }));
  };

  const transformToThreeNine = () => {
    setValues((prevValues) => ({
      ...prevValues,
      elementoDespesa: {
        ...prevValues.elementoDespesa,
        codigo: "3.3.90.39",
      },
    }));
    setValuesToTest((prevValues) => ({
      ...prevValues,
      elemento: "3.3.90.39",
    }));
  };

  return (
    <Container
      id={"pags_responsividade_padding_geral"}
      fluid
      className={"container_conteudo conteudo_pagina"}
    >
      <TestButton onClick={() => console.log(values)}>MOCKEDDATA</TestButton>
      <HeaderCadastros
        PaginaSubtitulo={"Cadastro"}
        NomePaginaTitulo={"Liquidação de Empenho - LE"}
      />

      <RowForm>
        <CCol>
          <MyAutoComplete
            options={[{ label: "teste", value: "teste" }]}
            labelFormat={(item) => `${item.label} - ${item.value}`}
            labelInput="Empenho"
            style={{ height: "37px" }}
          />
        </CCol>
        <CCol md={2}>
          <FloatingLabelInput
            disabled
            label="Liquidação"
            placeholder="Liquidação"
            value={values?.empenho?.liquidacao}
          />
        </CCol>
        <CCol md={2}>
          <InputData
            value={values.data}
            onInput={(e) => {
              console.log(e.target.value);
              setValues({
                ...values,
                data: e.target.value,
              });
            }}
          />
        </CCol>
      </RowForm>

      <RowForm style={{ marginTop: "-14px" }}>
        <CCol>
          <FloatingLabelInput
            disabled
            placeholder="Ação"
            label="Ação"
            value={`${values?.empenho?.acaoGovernamental?.codigo} - ${values?.empenho?.acaoGovernamental?.titulo}`}
          />
        </CCol>
        <CCol md={4}>
          <InputFormatRealBrasileiro
            disabled
            iconReal
            value={values?.empenho?.valor}
          />
        </CCol>
      </RowForm>

      <RowInfosAcao>
        <CCol>
          <p>
            Unidade Orçamentária:{" "}
            <span>{`${values?.unidadeOrcamentaria?.codigo} - ${values?.unidadeOrcamentaria?.titulo}`}</span>
          </p>
          <p>
            Elemento de Despesa:{" "}
            <span>
              {`${values?.elementoDespesa?.codigo} - ${values?.elementoDespesa?.descricao} - ${values?.elementoDespesa?.tipoPessoa}`}
            </span>
          </p>
          <p>
            Subelemento:{" "}
            <span>
              {`${values?.subelemento?.codigo} - ${values?.subelemento?.descricao}`}
            </span>
          </p>
          <p>
            Fonte de Recursos - CO:{" "}
            <span>
              {`${values?.fonteRecursos?.codigo} - ${values?.fonteRecursos?.tipoDeRecurso} - ${values?.fonteRecursos?.descricao}`}
            </span>
          </p>
          <p>
            Modalidade: <span>{values?.modalidade}</span>
          </p>
        </CCol>
      </RowInfosAcao>
      <button onClick={() => transformToZeroZero()}>
        Transformar elemento em 00
      </button>
      <button onClick={() => transformToThreeNine()}>
        Transformar elemento em 39
      </button>

      <RowForm className="mt-1">
        <CCol>
          <MyAutoComplete
            labelInput="Responsável pelo Atesto"
            options={optionsCpf}
            inputValue={inputFornecedor}
            setInputValue={setInputFornecedor}
            addNewDataFunction={handleAddNewCpf}
            labelFormat={(item) => `${item.nome} - ${item.cpf}`}
            style={{ height: "37px" }}
            onOptionSelect={handleOptionSelect}
          />
        </CCol>
        <CCol md={2}>
          <InputData
            value={values.responsavelPeloAtesto.data}
            onInput={(e) => {
              console.log(e.target.value);
              setValues({
                ...values,
                responsavelPeloAtesto: {
                  ...values.responsavelPeloAtesto,
                  data: e.target.value,
                },
              });
            }}
          />
        </CCol>
      </RowForm>

      <RowSelecaoAbasInferior
        abas={abas}
        abaSelecionada={abaSelecionada}
        setAbaSelecionada={setAbaSelecionada}
        alwaysSelectFirst
      />

      {abaSelecionada.nomeAba === "Nota Fiscal" && (
        <NotaFiscal values={values} setValues={setValues} />
      )}
      {abaSelecionada.nomeAba === "Ordem Cronológica" && (
        <OrdemCronologicaAba />
      )}
      {abaSelecionada.nomeAba === "EFD-Reinf" && <EfdReinfAba />}
      {abaSelecionada.nomeAba === "Saldos" && <SaldosAba />}
      {abaSelecionada.nomeAba === "Agrupamentos" && <Agrupamento />}

      <div className="p-3"></div>
    </Container>
  );
};

export default LiquidacaoDeEmpenho;
