import api from "../../utils/api";

export const getAllNaturezasDaDespesa = async (clienteId, ano) => {
  try {
    const resp = await api.get(
      `naturezaDaDespesa/por-cliente/${clienteId}/${ano}/filtro`
    );
    const data = resp.data;

    return data;
  } catch (error) {
    console.log(error);
    return [];
  }
};
