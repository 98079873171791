import React from "react";
import { RowTituloAba } from "../../../PagesAcessoInterno/PagContaPerfil/StyledComponentsContaPerfil";
import { CCol, RowForm } from "../../../../components/Grid/CCol";

import FloatingLabelInput from "../../../../components/Grid/FloatingLabelInput";
import styled from "styled-components";

const PTitulosSaldos = styled.p`
  margin: 0;
  margin-bottom: 5px;
  font-weight: 400;
  font-size: 14px;
  color: #515c70;
`;

const SaldosAba = () => {
  return (
    <>
      <RowTituloAba style={{ marginTop: "15px" }}>
        <div>
          <span>Saldos</span>
        </div>
      </RowTituloAba>

      <RowForm className="mt-1">
        <CCol>
          <PTitulosSaldos>Saldo Anterior</PTitulosSaldos>
          <FloatingLabelInput
            disabled
            value="R$ 20.000,00"
            style={{ border: "none", borderRadius: "10px", textAlign: "end" }}
          />
        </CCol>
        <CCol>
          <PTitulosSaldos>Entradas</PTitulosSaldos>
          <FloatingLabelInput
            disabled
            value="R$ 20.000,00"
            style={{ border: "none", borderRadius: "10px", textAlign: "end" }}
          />
        </CCol>
        <CCol>
          <PTitulosSaldos>Saídas</PTitulosSaldos>
          <FloatingLabelInput
            disabled
            value="R$ 20.000,00"
            style={{ border: "none", borderRadius: "10px", textAlign: "end" }}
          />
        </CCol>
        <CCol>
          <PTitulosSaldos>Saldo Atualizado</PTitulosSaldos>
          <FloatingLabelInput
            disabled
            value="R$ 20.000,00"
            style={{ border: "none", borderRadius: "10px", textAlign: "end" }}
          />
        </CCol>
      </RowForm>
    </>
  );
};

export default SaldosAba;
