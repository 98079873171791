export const transformInputData = (value, version) => {
  let input = value.target.value.replace(/\D/g, "");
  const isBackspace = value.nativeEvent.inputType === "deleteContentBackward";

  if (version === 1) {
    if (input.length > 8) {
      input = input.slice(0, 8);
    }

    if (!isBackspace) {
      if (input.length >= 1 && input.length <= 2) {
        if (input[0] > "3") {
          input = `0${input[0]}`;
        } else if (parseInt(input, 10) > 31) {
          input = "31";
        }
      }

      if (input.length >= 3 && input.length <= 4) {
        const mes = input.slice(2, 4);
        if (mes[0] > "1") {
          input = input.slice(0, 2) + `0${mes[0]}`;
        } else if (parseInt(mes, 10) > 12) {
          input = input.slice(0, 2) + "12";
        }
      }

      if (input.length === 3 || input.length === 4) {
        input = `${input.slice(0, 2)}/${input.slice(2)}`;
      }
      if (input.length === 5 || input.length === 6) {
        input = `${input.slice(0, 5)}/${input.slice(5)}`;
      }
    }

    return input;
  } else if (version === 2) {
    if (input.length > 6) {
      input = input.slice(0, 6);
    }

    if (!isBackspace) {
      if (input.length === 1) {
        if (input > "1") {
          input = `0${input}`;
        }
      } else if (input.length === 2) {
        if (parseInt(input, 10) > 12) {
          input = "12";
        }
      }

      if (input.length === 3 || input.length === 4) {
        input = `${input.slice(0, 2)}/${input.slice(2)}`;
      }
    }

    return input;
  }
};
