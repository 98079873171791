import api from "../../utils/api";

export const getAllCpfsApi = async () => {
  try {
    const resp = await api.get("cpf/api/all");
    const data = resp.data;

    return data;
  } catch (error) {
    return [];
  }
};

export const getCpfApiPorCpf = async (cpf) => {
  try {
    const resp = await api.get(`/cpf/api/busca/${cpf}`);
    const data = resp.data;

    return data;
  } catch (error) {
    return;
  }
};
