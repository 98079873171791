import React from "react";
import { Row } from "react-bootstrap";
import { CCol, RowAninhada } from "../../../../components/Grid/CCol";
// import FloatingLabelInput from "../../../../components/Grid/FloatingLabelInput";
import InputFormatRealBrasileiro from "../../../../components/Grid/InputFormatRealBrasileiro";

const Valores = ({ values, setValues, acao }) => {
  return (
    <Row className={"row_form mt-1"}>
      <RowAninhada>
        <CCol md={2}>
          <InputFormatRealBrasileiro
            disabled={acao === "visualizar"}
            label={"Dimensões"}
            placeholder={"Dimensões"}
            value={values.dimensao}
            onChange={(e, valor) =>
              setValues((prevValues) => ({
                ...prevValues,
                dimensao: valor,
              }))
            }
          />
        </CCol>
        <CCol>
          <InputFormatRealBrasileiro
            disabled
            label={"Valor Inicial da Obra"}
            placeholder={"Valor Inicial da Obra"}
            value={values?.valorInicial || ""}
            onChange={(e, valor) =>
              setValues((prevValues) => ({
                ...prevValues,
                valorInicia: valor,
              }))
            }
          />
        </CCol>
        <CCol>
          <InputFormatRealBrasileiro
            disabled={acao === "visualizar"}
            label={"Valor do Contrato"}
            placeholder={"Valor do Contrato"}
            value={values.valor}
            onChange={(e, valor) =>
              setValues((prevValues) => ({
                ...prevValues,
                valor: valor,
              }))
            }
          />
        </CCol>
        <CCol>
          <InputFormatRealBrasileiro
            disabled
            label={"Valor Aditivado"}
            placeholder={"Valor Aditivado"}
            values={values?.valores?.valorAditivado || ""}
            onChange={(e, valor) =>
              setValues((prevValues) => ({
                ...prevValues,
                valores: {
                  ...prevValues.valores,
                  valorAditivado: valor,
                },
              }))
            }
          />
        </CCol>
        <CCol>
          <InputFormatRealBrasileiro
            disabled
            label={"Total de Recursos Próprios"}
            placeholder={"Total de Recursos Próprios"}
            values={values?.valores?.totalRecursosProprios || ""}
            onChange={(e, valor) =>
              setValues((prevValues) => ({
                ...prevValues,
                valores: {
                  ...prevValues.valores,
                  totalRecursosProprios: valor,
                },
              }))
            }
          />
        </CCol>
      </RowAninhada>
    </Row>
  );
};

export default Valores;
