import { CCol, RowForm } from "../../../../components/Grid/CCol";
import FloatingLabelInput from "../../../../components/Grid/FloatingLabelInput";
import InputData from "../../../../components/Grid/InputData";
import InputFormatRealBrasileiro from "../../../../components/Grid/InputFormatRealBrasileiro";
import { RowTituloAba } from "../../../PagesAcessoInterno/PagContaPerfil/StyledComponentsContaPerfil";

export const NotaFiscal = ({ values, setValues }) => {
  return (
    <>
      <RowTituloAba style={{ marginTop: "15px" }}>
        <div>
          <span>Nota Fiscal</span>
        </div>
      </RowTituloAba>

      <RowForm className="mt-1">
        <CCol>
          <FloatingLabelInput
            label="Número Nota Fiscal"
            placeholder="Número Nota Fiscal"
            onChange={(e) => {
              setValues((prevValues) => ({
                ...prevValues,
                notaFiscal: {
                  ...prevValues.notaFiscal,
                  numero: e.target.value,
                },
              }));
            }}
          />
        </CCol>
        <CCol>
          <FloatingLabelInput
            label="Série NF"
            placeholder="Série NF"
            value={values.notaFiscal.serie}
            onChange={(e) => {
              setValues((prevValues) => ({
                ...prevValues,
                notaFiscal: {
                  ...prevValues.notaFiscal,
                  serie: e.target.value,
                },
              }));
            }}
          />
        </CCol>
        <CCol md={8}>
          <FloatingLabelInput
            tipo="input_select"
            new
            options={[{ label: "Teste", value: "1" }]}
            placeholder="Tipo Nota Fiscal"
          />
        </CCol>
      </RowForm>
      <RowForm style={{ marginTop: "-14px" }} $borderRadiusType={2}>
        <CCol>
          <InputData
            value={values.notaFiscal.data}
            onInput={(e) => {
              console.log(e.target.value);
              setValues((prevValues) => ({
                ...prevValues,
                notaFiscal: {
                  ...prevValues.notaFiscal,
                  data: e.target.value,
                },
              }));
            }}
          />
        </CCol>
        <CCol>
          <InputFormatRealBrasileiro
            iconReal
            value={values.notaFiscal.valor}
            onChange={(e) => {
              setValues((prevValues) => ({
                ...prevValues,
                notaFiscal: {
                  ...prevValues.notaFiscal,
                  valor: e.target.value,
                },
              }));
            }}
          />
        </CCol>
        <CCol>
          <FloatingLabelInput
            placeholder="Número da chave de acesso da NF-e"
            label="Número da chave de acesso da NF-e"
          />
        </CCol>
      </RowForm>
    </>
  );
};
