import React, { useContext, useEffect, useState } from "react";
import { Col, Container, Row, Table } from "react-bootstrap";
import { Icon } from "@iconify/react";
import outlineSearch from "@iconify/icons-ic/outline-search";
import {
  BodyTable,
  FilterContainer,
  FilterLabel,
  FilterSelect,
  PassadorPage,
  TituloTable,
  TituloTableAction,
} from "../../../../components/TableConsulta/TableConsulta";
import Pagination from "../../../../components/PaginationComponent/Pagination";
import HeaderOptions from "../../../../components/HeaderOptions/HeaderOptions";
import InputSvg from "../../../../components/Grid/InputSvg";
// import ModalDeleteData from "../../../../components/ModalDeleteData/ModalDeleteData";
import { Link } from "react-router-dom";
import api from "../../../../utils/api";
import { useCurrentYear } from "../../../../hooks/useCurrentYear";
import ButtonsAcoesListas from "../../../../styles/StyledComponents/ButtonsAcoesListas";
import { ToastContainer } from "react-toastify";
import { CurrentClientContext } from "../../../../contexts/CurrentClientContext";
// import localApi from "../../../../utils/localApi";
import { tiposDeObjeto, modalidades } from "../Licitacao/menus";

function ConvenioConsulta() {
  const { currentClient } = useContext(CurrentClientContext);
  const { currentYear } = useCurrentYear();
  const [convenios, setConvenios] = useState([]);
  const [itensPorPagina, setItensPorPagina] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [conveniosFiltrados, setConveniosFiltrados] = useState([]);
  const [loading, setLoading] = useState(false);
  const [month, setMonth] = useState("01");

  // const [modalOpened, setModalOpened] = useState(false);
  // const [dataDelete, setDataDelete] = useState({});

  // const openModal = (licitacao) => {
  //   setModalOpened(true);
  //   setDataDelete(licitacao);
  // };

  // const closeModal = () => {
  //   setModalOpened(false);
  // };

  // const deleteLicitacao = () => {
  //   api
  //     .delete(`/licitacaoContabilidade/${dataDelete}`)
  //     .then(() => {
  //       toast.success("Licitação deletada com sucesso!");
  //       fetchData();
  //     })
  //     .catch((error) => {
  //       console.error(error);
  //       toast.error("Erro ao deletar licitação.");
  //     });
  // };

  const fetchData = async () => {
    setLoading(true);
    const maxRetries = 3;
    let attempts = 0;

    while (attempts < maxRetries) {
      try {
        const response = await api.get(
          `/convenio/${currentYear}/${month}/${currentClient.endereco.estado}`
        );

        console.log(response.data);
        const updatedData = response.data.map((item) => ({
          ...item,
          tce: true,
        }));
        setConvenios(updatedData);
        setLoading(false);
        return;
      } catch (error) {
        attempts++;
        console.log(`Tentativa ${attempts} falhou:`, error);

        if (attempts === maxRetries) {
          console.log("Número máximo de tentativas atingido.");
          setLoading(false);
          return;
        }
      }
    }
  };

  useEffect(() => {
    fetchData();
    setCurrentPage(0);
    // eslint-disable-next-line
  }, [month]);

  const handleSearch = (event) => {
    const { value } = event.target;
    setSearchTerm(value);
    const filteredItems = convenios.filter((item) => {
      return (
        item.objetivo.toLowerCase().includes(value.toLowerCase()) ||
        item.situacao.toLowerCase().includes(value.toLowerCase()) ||
        item.numConvenio.toString().includes(value)
      );
    });
    setConveniosFiltrados(filteredItems);
    setCurrentPage(0);
  };

  const startIndex = currentPage * itensPorPagina;
  const endIndex = startIndex + itensPorPagina;
  const currentItens = convenios.slice(startIndex, endIndex);
  const currentFilteredItens = conveniosFiltrados.slice(startIndex, endIndex);

  const pages = Math.ceil(convenios.length / itensPorPagina);
  const filteredPages = Math.ceil(conveniosFiltrados.length / itensPorPagina);

  return (
    <Container
      id="pags_responsividade_padding_geral"
      fluid
      style={{ color: "#515C70" }}
      className="container_conteudo conteudo_pagina"
    >
      <HeaderOptions
        withRow={true}
        TituloPagina={"Convênios"}
        SubTituloPagina={"Consulta"}
        // botaoNovo={false}
        ToPag={"/contabilidade/cadastros/convenio"}
      />
      {/* <ModalDeleteData
        modalOpened={modalOpened}
        closeModal={closeModal}
        setDataDelete={setDataDelete}
        deleteDataFunction={deleteLicitacao}
      /> */}
      <ToastContainer />
      <Row className="row_form mt-1">
        <Col md={8} style={{ display: "flex" }}>
          <FilterContainer>
            <FilterLabel>Itens por página:</FilterLabel>
            <FilterSelect
              value={itensPorPagina}
              onChange={(event) =>
                setItensPorPagina(Number(event.target.value))
              }
            >
              <option value={10}>10</option>
              <option value={15}>15</option>
              <option value={20}>20</option>
            </FilterSelect>
          </FilterContainer>
          <FilterContainer style={{ marginLeft: "20px" }}>
            <FilterLabel>Selecione o mês de início</FilterLabel>
            <FilterSelect
              value={month}
              onChange={(event) => {
                const month = Number(event.target.value);
                setMonth(
                  month < 10
                    ? month.toString().padStart(2, "0")
                    : month.toString()
                );
              }}
            >
              {Array.from(
                { length: new Date().getMonth() + 1 },
                (_, i) => i + 1
              ).map((mes) => (
                <option
                  key={mes}
                  value={
                    mes < 10 ? mes.toString().padStart(2, "0") : mes.toString()
                  }
                >
                  {mes}
                </option>
              ))}
            </FilterSelect>
          </FilterContainer>
        </Col>
        <Col md={4}>
          <InputSvg
            icon={outlineSearch}
            height={"23px"}
            color={"#6C7585"}
            placeholder={"pesquise"}
            onChange={handleSearch}
          />
        </Col>
      </Row>
      <Row className="row_form mt-1">
        {loading ? (
          <Col
            md={12}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Icon height={50} icon="line-md:loading-loop" color="#515c70" />
          </Col>
        ) : null}
        <Table
          responsive
          style={loading ? { display: "none" } : { marginTop: "-10px" }}
        >
          <thead>
            <tr>
              <TituloTable>Número</TituloTable>
              <TituloTable>Objetivo</TituloTable>
              <TituloTable>Situação</TituloTable>
              <TituloTable>Local</TituloTable>
              <TituloTableAction>Ação</TituloTableAction>
            </tr>
          </thead>
          <BodyTable>
            {searchTerm
              ? currentFilteredItens.map((item, index) => (
                  <tr key={index}>
                    <td
                      style={{ whiteSpace: "nowrap" }}
                      title={item.numeroLicitacao}
                    >
                      {item.numeroLicitacao}
                    </td>
                    <td
                      style={{ whiteSpace: "nowrap" }}
                      title={item.modalidadeLicitacao}
                    >
                      {modalidades.find(
                        (modalidade) =>
                          modalidade.value === item.modalidadeLicitacao
                      )?.label || "Modalidade desconhecida"}
                    </td>
                    <td
                      style={{ whiteSpace: "nowrap" }}
                      title={
                        tiposDeObjeto.find(
                          (tipo) => tipo.value === item.tipoObjeto
                        )?.label || "Tipo desconhecido"
                      }
                    >
                      {tiposDeObjeto.find(
                        (tipo) => tipo.value === item.tipoObjeto
                      )?.label || "Tipo desconhecido"}
                    </td>
                    <td style={{ whiteSpace: "nowrap" }}>
                      {item.tce ? "TCE" : "SAVE"}
                    </td>
                    <td>
                      <Link
                        onClick={() => {
                          localStorage.setItem(
                            "licitacaoItem",
                            JSON.stringify(item)
                          );
                        }}
                        to={`/contabilidade/visualizar/licitacao/TCE`}
                      >
                        <Icon
                          icon="bx:edit"
                          color="#5971C8"
                          height="22"
                          style={{
                            cursor: "pointer",
                            marginLeft: "5px",
                          }}
                        />
                      </Link>
                    </td>
                  </tr>
                ))
              : currentItens.map((item, index) => (
                  <tr key={index}>
                    <td
                      style={{ whiteSpace: "nowrap" }}
                      title={item.numConvenio}
                    >
                      {item.numConvenio}
                    </td>
                    <td style={{ whiteSpace: "nowrap" }} title={item.objetivo}>
                      {item.objetivo.length > 60
                        ? item.objetivo.substring(0, 60) + "..."
                        : item.objetivo}
                    </td>
                    <td style={{ whiteSpace: "nowrap" }} title={item.situacao}>
                      {item.situacao}
                    </td>
                    <td style={{ whiteSpace: "nowrap" }}>
                      {item.tce ? "TCE" : "SAVE"}
                    </td>
                    <td>
                      <ButtonsAcoesListas
                        // FuncaoIconDelete={() => {
                        //   openModal();
                        //   setDataDelete(item.numero);
                        // }}
                        onClick={() => {
                          localStorage.setItem(
                            "licitacaoItem",
                            JSON.stringify(item)
                          );
                        }}
                        CaminhoPagUpdate={`/contabilidade/visualizar/licitacao/TCE`}
                      />
                    </td>
                  </tr>
                ))}
          </BodyTable>
        </Table>
      </Row>
      <Row
        className="row_form mt-1"
        style={{
          borderBottomLeftRadius: "5px",
          borderBottomRightRadius: "5px",
        }}
      >
        <Col md={4}>
          <span>
            Mostrando de {startIndex + 1} a {endIndex} de{" "}
            {searchTerm ? conveniosFiltrados.length : convenios.length}{" "}
            registros
          </span>
        </Col>
        <Col md={8}>
          <PassadorPage>
            <span>
              {currentPage + 1} de {searchTerm ? filteredPages : pages} páginas
            </span>
            <Pagination
              pages={searchTerm ? filteredPages : pages}
              itemsPerPage={itensPorPagina}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          </PassadorPage>
        </Col>
      </Row>
      <div style={{ padding: "11px" }}></div>
    </Container>
  );
}

export default ConvenioConsulta;
