import React, { useContext, useEffect, useState } from "react";
import { Row } from "react-bootstrap";
import { TituloAbaSolo } from "../../../../styles/StyledComponents/LayoutGerais";
import {
  CCol,
  CColButtonsAcoes2Icons,
  RowAninhada,
} from "../../../../components/Grid/CCol";
import { CurrentYearContext } from "../../../../contexts/YearContext";
import { AuthContext } from "../../../../contexts/AuthContext";
import { TestButton } from "../../../../utils/TestButton";
import Saldos from "./Saldos";
import styled from "styled-components";
import { FormControlListaDeInputs } from "../../../../styles/StyledComponents/ListaDeInputs";
import { CurrentCityContext } from "../../../../contexts/CurrentCityContext";
import { Icon } from "@iconify/react/dist/iconify.js";

const CustomInput = ({
  title,
  md = 2,
  size = "170px",
  Btop,
  Bbottom,
  ...props
}) => {
  return (
    <CCol md={md} style={size && { width: size }}>
      {title && <Title>{title}</Title>}
      <FormControlListaDeInputs
        radiusborder={Btop ? "5px 5px 0 0" : Bbottom ? "0 0 5px 5px" : "0"}
        {...props}
      />
    </CCol>
  );
};

const Receita = () => {
  const { currentCity } = useContext(CurrentCityContext);
  const { currentYear } = useContext(CurrentYearContext);
  const { userAuthData } = useContext(AuthContext);
  const [values, setValues] = useState([]);
  const [oldValues, setOldValues] = useState([]);
  const [saldosData, setSaldosData] = useState([]);
  const [entidades, setEntidades] = useState([]);
  const [titles, setTitles] = useState([]);
  const [editMode, setEditMode] = useState([]);

  const Icons = ({ marginTop = "2px", index, onClick }) => {
    const open = editMode[index];
    return (
      <>
        <Icon
          icon="bx:edit"
          color="#5971C8"
          height="26"
          style={{
            cursor: "pointer",
            marginLeft: "5px",
            marginTop: marginTop,
          }}
          onClick={() => onClick(index, open)}
        />
        <Icon
          icon="ic:baseline-cancel"
          color={!editMode[index] ? "#F24E1E" : "transparent"}
          height="26"
          style={{
            cursor: !editMode[index] ? "pointer" : "auto",
            marginLeft: "2px",
            marginTop: marginTop,
          }}
          onClick={() => onClick(index, false, true)}
        />
      </>
    );
  };

  const updateTitles = () => {
    setTitles([
      "UG",
      "Natureza da Receita",
      "Fonte de Recursos",
      "Esfera",
      `${currentYear - 4} - Previsão`,
      `${currentYear - 4} - Arrecadada`,
      `${currentYear - 3} - Previsão`,
      `${currentYear - 3} - Arrecadada`,
      `${currentYear - 2} - Previsão`,
      `${currentYear - 2} - Arrecadada`,
      `${currentYear - 1} - Previsão`,
      `${currentYear - 1} - Arrecadada`,
      `${currentYear} - Previsão`,
      `${currentYear + 1} - Previsão`,
      `${currentYear + 1} - Percentual`,
      `${currentYear + 2} - Previsão`,
      `${currentYear + 2} - Percentual`,
    ]);
  };

  const updateValues = () => {
    const newValues = entidades.map((_, index) => {
      return {
        ug: `0${index}`,
        naturezaDaReceita: `1${index}`,
        fonteDeRecursos: `2${index}`,
        esfera: `3${index}`,
        [`${currentYear - 4}Previsao`]: `4${index}`,
        [`${currentYear - 4}Arrecadada`]: `5${index}`,
        [`${currentYear - 3}Previsao`]: `6${index}`,
        [`${currentYear - 3}Arrecadada`]: `7${index}`,
        [`${currentYear - 2}Previsao`]: `8${index}`,
        [`${currentYear - 2}Arrecadada`]: `9${index}`,
        [`${currentYear - 1}Previsao`]: `10${index}`,
        [`${currentYear - 1}Arrecadada`]: `12${index}`,
        [`${currentYear}Previsao`]: `13${index}`,
        [`${currentYear + 1}Previsao`]: `14${index}`,
        [`${currentYear + 1}Percentual`]: `15${index}`,
        [`${currentYear + 2}Previsao`]: `16${index}`,
        [`${currentYear + 2}Percentual`]: `17${index}`,
      };
    });
    const editables = new Array(newValues.length).fill(true);
    setValues(newValues);
    setOldValues(newValues);
    setEditMode(editables);
  };

  const handleInputChange = (index, field, value) => {
    setValues((prevValues) =>
      prevValues.map((item, i) =>
        i === index ? { ...item, [field]: value } : item
      )
    );
  };

  const handleEditMode = (index, open, cancel) => {
    setEditMode((prevEditMode) => {
      const newEditMode = [...prevEditMode];
      newEditMode[index] = !newEditMode[index];
      return newEditMode;
    });

    if (open) {
      setOldValues(() => {
        const thisOldValues = oldValues;
        thisOldValues[index] = values[index];
        return thisOldValues;
      });
    }

    if (cancel) {
      setValues(() => {
        const thisValues = values;
        thisValues[index] = oldValues[index];
        return thisValues;
      });
    }
  };

  useEffect(() => {
    const updateSaldosData = () => {
      const newSaldosData = [];
      for (let i = -4; i <= 2; i++) {
        const year = currentYear + i;
        const existingData = saldosData.find(
          (obj) => Object.keys(obj)[0] === `${year}`
        );
        newSaldosData.push({
          [year]: existingData
            ? existingData[year]
            : [{ input1: "0" }, { input2: "0" }, { input3: "0" }],
        });
      }
      setSaldosData(newSaldosData);
    };
    updateSaldosData();
    // eslint-disable-next-line
  }, [currentYear]);

  useEffect(() => {
    const newEntidades = userAuthData.clientes.filter(
      (item) =>
        item.endereco.municipio === currentCity.municipio &&
        item.endereco.estado === currentCity.estado
    );

    setEntidades(newEntidades);
  }, [currentCity, userAuthData]);

  useEffect(() => {
    updateValues();
    updateTitles();
    // eslint-disable-next-line
  }, [currentYear, userAuthData, entidades]);

  return (
    <>
      <Row className="row_form mt-1">
        <TituloAbaSolo>Receita Total</TituloAbaSolo>
      </Row>
      <Row className="row_form mt-1">
        <StyledCCol style={{ height: "300px" }}>
          {values?.map((_, index) => {
            const btop = index === 0;
            const bbottom = index === values.length - 1;
            return (
              <RowAninhada
                style={{
                  display: "flex",
                  flexWrap: "nowrap",
                }}
              >
                <CustomInput
                  disabled={editMode[index]}
                  title={index === 0 && titles[0]}
                  Btop={btop}
                  Bbottom={bbottom}
                  value={values[index]?.ug || ""}
                  onChange={(e) =>
                    handleInputChange(index, "ug", e.target.value)
                  }
                />
                <CustomInput
                  disabled={editMode[index]}
                  title={index === 0 && titles[1]}
                  Btop={btop}
                  Bbottom={bbottom}
                  value={values[index]?.naturezaDaReceita || ""}
                  onChange={(e) =>
                    handleInputChange(
                      index,
                      "naturezaDaReceita",
                      e.target.value
                    )
                  }
                />
                <CustomInput
                  disabled={editMode[index]}
                  title={index === 0 && titles[2]}
                  Btop={btop}
                  Bbottom={bbottom}
                  value={values[index]?.fonteDeRecursos || ""}
                  onChange={(e) =>
                    handleInputChange(index, "fonteDeRecursos", e.target.value)
                  }
                />
                <CustomInput
                  disabled={editMode[index]}
                  title={index === 0 && titles[3]}
                  Btop={btop}
                  Bbottom={bbottom}
                  value={values[index]?.esfera || ""}
                  onChange={(e) =>
                    handleInputChange(index, "esfera", e.target.value)
                  }
                />
                <CustomInput
                  disabled={editMode[index]}
                  title={index === 0 && titles[4]}
                  Btop={btop}
                  Bbottom={bbottom}
                  value={values[index]?.[`${currentYear - 4}Previsao`] || ""}
                  onChange={(e) =>
                    handleInputChange(
                      index,
                      `${currentYear - 4}Previsao`,
                      e.target.value
                    )
                  }
                />
                <CustomInput
                  disabled={editMode[index]}
                  title={index === 0 && titles[5]}
                  Btop={btop}
                  Bbottom={bbottom}
                  value={values[index]?.[`${currentYear - 4}Arrecadada`] || ""}
                  onChange={(e) =>
                    handleInputChange(
                      index,
                      `${currentYear - 4}Arrecadada`,
                      e.target.value
                    )
                  }
                />
                <CustomInput
                  disabled={editMode[index]}
                  title={index === 0 && titles[6]}
                  Btop={btop}
                  Bbottom={bbottom}
                  value={values[index]?.[`${currentYear - 3}Previsao`] || ""}
                  onChange={(e) =>
                    handleInputChange(
                      index,
                      `${currentYear - 3}Previsao`,
                      e.target.value
                    )
                  }
                />
                <CustomInput
                  disabled={editMode[index]}
                  title={index === 0 && titles[7]}
                  Btop={btop}
                  Bbottom={bbottom}
                  value={values[index]?.[`${currentYear - 3}Arrecadada`] || ""}
                  onChange={(e) =>
                    handleInputChange(
                      index,
                      `${currentYear - 3}Arrecadada`,
                      e.target.value
                    )
                  }
                />
                <CustomInput
                  disabled={editMode[index]}
                  title={index === 0 && titles[8]}
                  Btop={btop}
                  Bbottom={bbottom}
                  value={values[index]?.[`${currentYear - 2}Previsao`] || ""}
                  onChange={(e) =>
                    handleInputChange(
                      index,
                      `${currentYear - 2}Previsao`,
                      e.target.value
                    )
                  }
                />
                <CustomInput
                  disabled={editMode[index]}
                  title={index === 0 && titles[9]}
                  Btop={btop}
                  Bbottom={bbottom}
                  value={values[index]?.[`${currentYear - 2}Arrecadada`] || ""}
                  onChange={(e) =>
                    handleInputChange(
                      index,
                      `${currentYear - 2}Arrecadada`,
                      e.target.value
                    )
                  }
                />
                <CustomInput
                  disabled={editMode[index]}
                  title={index === 0 && titles[10]}
                  Btop={btop}
                  Bbottom={bbottom}
                  value={values[index]?.[`${currentYear - 1}Previsao`] || ""}
                  onChange={(e) =>
                    handleInputChange(
                      index,
                      `${currentYear - 1}Previsao`,
                      e.target.value
                    )
                  }
                />
                <CustomInput
                  disabled={editMode[index]}
                  title={index === 0 && titles[11]}
                  Btop={btop}
                  Bbottom={bbottom}
                  value={values[index]?.[`${currentYear - 1}Arrecadada`] || ""}
                  onChange={(e) =>
                    handleInputChange(
                      index,
                      `${currentYear - 1}Arrecadada`,
                      e.target.value
                    )
                  }
                />
                <CustomInput
                  disabled={editMode[index]}
                  title={index === 0 && titles[12]}
                  Btop={btop}
                  Bbottom={bbottom}
                  value={values[index]?.[`${currentYear}Previsao`] || ""}
                  onChange={(e) =>
                    handleInputChange(
                      index,
                      `${currentYear}Previsao`,
                      e.target.value
                    )
                  }
                />
                <CustomInput
                  disabled={editMode[index]}
                  title={index === 0 && titles[13]}
                  Btop={btop}
                  Bbottom={bbottom}
                  value={values[index]?.[`${currentYear + 1}Previsao`] || ""}
                  onChange={(e) =>
                    handleInputChange(
                      index,
                      `${currentYear + 1}Previsao`,
                      e.target.value
                    )
                  }
                />
                <CustomInput
                  disabled={editMode[index]}
                  title={index === 0 && titles[14]}
                  Btop={btop}
                  Bbottom={bbottom}
                  value={values[index]?.[`${currentYear + 1}Percentual`] || ""}
                  onChange={(e) =>
                    handleInputChange(
                      index,
                      `${currentYear + 1}Percentual`,
                      e.target.value
                    )
                  }
                />
                <CustomInput
                  disabled={editMode[index]}
                  title={index === 0 && titles[15]}
                  Btop={btop}
                  Bbottom={bbottom}
                  value={values[index]?.[`${currentYear + 2}Previsao`] || ""}
                  onChange={(e) =>
                    handleInputChange(
                      index,
                      `${currentYear + 2}Previsao`,
                      e.target.value
                    )
                  }
                />
                <CustomInput
                  disabled={editMode[index]}
                  title={index === 0 && titles[16]}
                  Btop={btop}
                  Bbottom={bbottom}
                  value={values[index]?.[`${currentYear + 2}Percentual`] || ""}
                  onChange={(e) =>
                    handleInputChange(
                      index,
                      `${currentYear + 2}Percentual`,
                      e.target.value
                    )
                  }
                />
              </RowAninhada>
            );
          })}
        </StyledCCol>
        <CColButtonsAcoes2Icons md={2}>
          <Title style={{ textAlign: "left" }}>Ação</Title>
          {values.map((_, index) => {
            const margintop = index === 0 ? "2px" : "4px";
            return (
              <Icons
                marginTop={margintop}
                index={index}
                onClick={handleEditMode}
              />
            );
          })}
        </CColButtonsAcoes2Icons>
      </Row>
      <Row className="mt-3">
        <CCol
          className="d-flex align-items-center p-2 header_body_conteudo"
          md={12}
        >
          <span
            style={{
              fontSize: "16.5px",
              fontWeight: "600",
              marginLeft: "10px",
            }}
          >
            Saldos
          </span>
        </CCol>
      </Row>
      <Saldos
        titles={["Receita Prevista", "Despesa Fixada", "Despesa Fixada"]}
        data={saldosData}
        nameBefore={"Exercício "}
      />
      <TestButton onClick={() => console.log(editMode)}>EditMote</TestButton>
      <TestButton onClick={() => console.log(values)}>Values</TestButton>
      <TestButton onClick={() => console.log(oldValues)}>OldValues</TestButton>
      <TestButton onClick={() => console.log(saldosData)}>Saldos</TestButton>
    </>
  );
};

export default Receita;

const StyledCCol = styled(CCol)`
  overflow: auto;

  &::-webkit-scrollbar {
    height: 4px;
    width: 2px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 2px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

const Title = styled.p`
  text-align: center;
  margin-top: 0;
  padding: 0px;
  margin-bottom: 0px;
  color: #888a8e;
  font-weight: 600;
  font-size: 14px;
`;
