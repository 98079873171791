const elementosExclusoes = {
  30: {
    opcoesExcluidas: [0, 2, 3, 4],
    opcaoPadrao: 1,
  },
  31: {
    opcoesExcluidas: [0, 2, 3, 4],
    opcaoPadrao: 1,
  },
  32: {
    opcoesExcluidas: [0, 2, 3, 4],
    opcaoPadrao: 1,
  },
  33: {
    opcoesExcluidas: [0, 2, 3, 4],
    opcaoPadrao: 1,
  },
  34: {
    opcoesExcluidas: [0, 1, 2, 4],
    opcaoPadrao: 3,
  },
  35: {
    opcoesExcluidas: [0, 1, 2, 4],
    opcaoPadrao: 3,
  },
  36: {
    opcoesExcluidas: [0, 1, 4],
    opcaoPadrao: 2,
  },
  37: {
    opcoesExcluidas: [0, 1, 2, 4],
    opcaoPadrao: 3,
  },
  38: {
    opcoesExcluidas: [0, 2, 3, 4],
    opcaoPadrao: 1,
  },
  39: {
    opcoesExcluidas: [0, 1, 4],
    opcaoPadrao: 2,
  },
  51: {
    opcoesExcluidas: [0, 1, 2, 3],
    opcaoPadrao: 4,
  },
  52: {
    opcoesExcluidas: [0, 2, 3, 4],
    opcaoPadrao: 1,
  },
  92: {
    opcoesExcluidas: [0],
    opcaoPadrao: 1,
  },
  93: {
    opcoesExcluidas: [0],
    opcaoPadrao: 1,
  },
};

export const gerenciaOpcoesOrdemCronologica = (codigoDespesa, options) => {
  const splitCodigo = codigoDespesa.split(".");
  const elementoDespesa = splitCodigo[3];
  const grupoDespesa = splitCodigo.slice(0, 2).join(".");
  const gruposDespesaEspeciais = ["3.3", "3.4", "4.3", "4.4"];
  const elementosDespesasEspeciais = ["92", "93"];

  let newOptions = [...options];
  let opcaoPadrao = undefined;

  if (
    elementosDespesasEspeciais.includes(elementoDespesa) ||
    !elementosExclusoes[elementoDespesa]
  ) {
    //aqui é o fluxo quando o elemento for especial mas o grupo da despesa dele nao for especial
    //ou quando o elemento da despesa nn esteja listado no objeto de exclusoes
    //nesse fluxo, a unica opcao ativa será 0
    const index = newOptions.findIndex((item) => item.value === 0);

    if (index !== -1) {
      newOptions[index].disabled = false;
      opcaoPadrao = 0;
    }
  } else if (
    (!elementosDespesasEspeciais.includes(elementoDespesa) &&
      elementosExclusoes[elementoDespesa]) ||
    (gruposDespesaEspeciais.includes(grupoDespesa) &&
      elementosDespesasEspeciais.includes(elementoDespesa))
  ) {
    //fluxo para quando o elemento de despesa for especial ou apenas esteja no objeto de exclusoes

    const opcoesExcluidas = elementosExclusoes[elementoDespesa].opcoesExcluidas;

    newOptions = newOptions.map((item) => ({
      ...item,
      disabled: opcoesExcluidas.includes(item.value),
    }));

    opcaoPadrao = elementosExclusoes[elementoDespesa].opcaoPadrao;

    console.log(newOptions);
    console.log(opcaoPadrao);
  }

  console.log(elementoDespesa);
  console.log(grupoDespesa);

  return {
    newOptions,
    opcaoPadrao,
  };
};
