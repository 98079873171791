import React, { useState } from "react";
import { CCol } from "../../../../../components/Grid/CCol";
import { Row } from "react-bootstrap";
import Participantes from "./Participantes";
import Contratos from "./Contratos";
import Aditivos from "./Aditivos";
import Principal from "./Principal";
import AbaLicitacao from "./AbaLicitacao";

const ImportarLicitacao = () => {
  const [values, setValues] = useState({
    licitacao: [],
    propostas: [],
    participantes: [],
    fornecedores: [],
    contratos: [],
    aditivos: [],
    controle: "",
  });

  return (
    <>
      <Row className="mt-3">
        <CCol
          className="d-flex align-items-center p-2 header_body_conteudo"
          md={12}
          style={{ justifyContent: "left" }}
        >
          <span
            style={{
              fontSize: "16.5px",
              fontWeight: 600,
              marginLeft: "10px",
            }}
          >
            Principal
          </span>
        </CCol>
      </Row>
      <Principal values={values} setValues={setValues} />
      {values.licitacao.length > 0 && (
        <>
          <Row className="mt-3">
            <CCol
              className="d-flex align-items-center p-2 header_body_conteudo"
              md={12}
              style={{ justifyContent: "left" }}
            >
              <span
                style={{
                  fontSize: "16.5px",
                  fontWeight: 600,
                  marginLeft: "10px",
                }}
              >
                Licitação
              </span>
            </CCol>
          </Row>
          <AbaLicitacao values={values.licitacao} />
        </>
      )}

      {values.participantes.length > 0 && (
        <>
          <Row className="mt-3">
            <CCol
              className="d-flex align-items-center p-2 header_body_conteudo"
              md={12}
            >
              <span
                style={{
                  fontSize: "16.5px",
                  fontWeight: "600",
                  marginLeft: "10px",
                }}
              >
                Participantes
              </span>
            </CCol>
          </Row>
          <Participantes values={values.participantes} />
        </>
      )}
      {values.contratos.length > 0 && (
        <>
          <Row className="mt-3">
            <CCol
              className="d-flex align-items-center p-2 header_body_conteudo"
              md={12}
            >
              <span
                style={{
                  fontSize: "16.5px",
                  fontWeight: "600",
                  marginLeft: "10px",
                }}
              >
                Contratos
              </span>
            </CCol>
          </Row>
          <Contratos values={values.contratos} />
        </>
      )}
      {values.aditivos.length > 0 && (
        <>
          <Row className="mt-3">
            <CCol
              className="d-flex align-items-center p-2 header_body_conteudo"
              md={12}
            >
              <span
                style={{
                  fontSize: "16.5px",
                  fontWeight: "600",
                  marginLeft: "10px",
                }}
              >
                Aditivos
              </span>
            </CCol>
          </Row>
          <Aditivos values={values.aditivos} />
        </>
      )}
      <div style={{ minHeight: "40px" }}> </div>
    </>
  );
};

export default ImportarLicitacao;
