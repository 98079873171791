import { RowTituloAba } from "../../../PagesAcessoInterno/PagContaPerfil/StyledComponentsContaPerfil";
import { CCol, CColButtonsAcoes } from "../../../../components/Grid/CCol";

import FloatingLabelInput from "../../../../components/Grid/FloatingLabelInput";

import { Row } from "react-bootstrap";
import { Icon } from "@iconify/react/dist/iconify.js";
import {
  FormControlListaDeInputs,
  PTitulosInputs,
  RowItemListaDeInputs,
} from "../../../../styles/StyledComponents/ListaDeInputs";
import MyAutoComplete from "../../../../components/Inputs/MyAutoComplete";

export const ProcedimentoContabelAba = () => {
  return (
    <>
      <RowTituloAba style={{ marginTop: "15px" }}>
        <div>
          <span>PCASP</span>
        </div>
      </RowTituloAba>

      <Row className="row_form mt-1">
        <CCol>
          <FloatingLabelInput
            style={{ border: "none" }}
            disabled
            value="1 - No momento da abertura do Orçamento"
          />
        </CCol>
        <CColButtonsAcoes md={1}>
          <Icon
            icon="ic:baseline-add-box"
            color="#105200"
            height="28"
            style={{
              cursor: "pointer",
              marginLeft: "5px",
              marginTop: "4px",
            }}
          />
        </CColButtonsAcoes>
      </Row>

      <Row
        className="row_form"
        style={{ marginTop: "-10px", paddingLeft: "10px" }}
      >
        <CCol md={8}>
          <PTitulosInputs>Conta Corrente</PTitulosInputs>
        </CCol>
        <CCol md={1}>
          <PTitulosInputs>Origem</PTitulosInputs>
        </CCol>
        <CCol>
          <PTitulosInputs>Natureza da Informação</PTitulosInputs>
        </CCol>
        <CColButtonsAcoes md={1}>
          <PTitulosInputs>Ação</PTitulosInputs>
        </CColButtonsAcoes>
      </Row>

      <Row
        className="row_form"
        style={{
          marginTop: "-10px",
          paddingTop: "0px",
          paddingBottom: "10px",
        }}
      >
        <RowItemListaDeInputs>
          <CCol md={8}>
            <MyAutoComplete
              placeholder={"Conta Corrente"}
              iconClearFunction={() => console.log("cleared")}
              options={[{ label: "teste", value: 1 }]}
              labelFormat={(option) => `${option.label} - ${option.value}`}
            />
          </CCol>
          <CCol md={1}>
            <FormControlListaDeInputs value={"D"} disabled />
          </CCol>
          <CCol>
            <FormControlListaDeInputs
              // TODO: logica para aplicar borda
              // radiusborder={
              //
              //     ? "0px 0px 5px 5px"
              //     : "5px 5px 0px 0px"
              // }
              disabledbg={"#C6D9FC"}
              value={"Patrimonial"}
              disabled
            />
          </CCol>
          <CColButtonsAcoes md={1}>
            <Icon
              onClick={() => console.log("edit")}
              icon="bx:edit"
              color="#5971C8"
              height="26"
              style={{
                cursor: "pointer",
                marginLeft: "5px",
                marginTop: "3px",
              }}
            />
          </CColButtonsAcoes>
        </RowItemListaDeInputs>
      </Row>

      <Row className="row_form mt-1">
        <CCol>
          <FloatingLabelInput
            style={{ border: "none" }}
            disabled
            value="2º - No momento da despesa orçamentária"
          />
        </CCol>
        <CColButtonsAcoes md={1}>
          <Icon
            icon="ic:baseline-add-box"
            color="#105200"
            height="28"
            style={{
              cursor: "pointer",
              marginLeft: "5px",
              marginTop: "4px",
            }}
          />
        </CColButtonsAcoes>
      </Row>

      <Row
        className="row_form"
        style={{ marginTop: "-10px", paddingLeft: "10px" }}
      >
        <CCol md={8}>
          <PTitulosInputs>Conta Corrente</PTitulosInputs>
        </CCol>
        <CCol md={1}>
          <PTitulosInputs>Origem</PTitulosInputs>
        </CCol>
        <CCol>
          <PTitulosInputs>Natureza da Informação</PTitulosInputs>
        </CCol>
        <CColButtonsAcoes md={1}>
          <PTitulosInputs>Ação</PTitulosInputs>
        </CColButtonsAcoes>
      </Row>

      <Row
        className="row_form"
        style={{
          marginTop: "-10px",
          paddingTop: "0px",
          paddingBottom: "10px",
        }}
      >
        <RowItemListaDeInputs>
          <CCol md={8}>
            <MyAutoComplete
              placeholder={"Conta Corrente"}
              iconClearFunction={() => console.log("cleared")}
              options={[{ label: "teste", value: 1 }]}
              labelFormat={(option) => `${option.label} - ${option.value}`}
            />
          </CCol>
          <CCol md={1}>
            <FormControlListaDeInputs disabled />
          </CCol>
          <CCol>
            <FormControlListaDeInputs
              // TODO: logica para aplicar borda
              // radiusborder={
              //
              //     ? "0px 0px 5px 5px"
              //     : "5px 5px 0px 0px"
              // }
              disabledbg={"#F5D0F5"}
              disabled
            />
          </CCol>
          <CColButtonsAcoes md={1}>
            <Icon
              onClick={() => console.log("edit")}
              icon="bx:edit"
              color="#5971C8"
              height="26"
              style={{
                cursor: "pointer",
                marginLeft: "5px",
                marginTop: "3px",
              }}
            />
          </CColButtonsAcoes>
        </RowItemListaDeInputs>
      </Row>
    </>
  );
};
