import React, { useState, useContext, useEffect } from "react";
import { CurrentYearContext } from "../../../../../contexts/YearContext";
import {
  CCol,
  RowAninhada,
  CColButtonsAcoes2Icons,
} from "../../../../../components/Grid/CCol";
import styled from "styled-components";
import { Row } from "react-bootstrap";
import { FormControlListaDeInputs } from "../../../../../styles/StyledComponents/ListaDeInputs";
// import IconAdicionar from "../../../../../components/ListaDeInputs/IconAdicionar";
import { modalidades } from "../../../Cadastros/Licitacao/menus";
import MyAutoComplete from "../../../../../components/Inputs/MyAutoComplete";
import FloatingLabelInput from "../../../../../components/Grid/FloatingLabelInput";

const CustomInput = ({
  title,
  md = 2,
  size,
  Btop,
  Bbottom,
  all,
  menu,
  ...props
}) => {
  const borderRadius = Btop
    ? "5px 5px 0 0"
    : Bbottom
    ? "0 0 5px 5px"
    : all
    ? "5px 5px 5px 5px"
    : "0";

  return (
    <CCol md={md} style={size && { width: size }}>
      {title && <Title>{title}</Title>}
      {!menu ? (
        <FormControlListaDeInputs radiusborder={borderRadius} {...props} />
      ) : menu === 1 ? (
        <FloatingLabelInput
          new
          tipo="input_select"
          height={"30px"}
          radiusborder={borderRadius}
          {...props}
        />
      ) : (
        menu === 2 && <MyAutoComplete radiusborder={borderRadius} {...props} />
      )}
    </CCol>
  );
};

const Contratos = ({ values }) => {
  const { currentYear } = useContext(CurrentYearContext);

  const [titles, setTitles] = useState([]);

  const updateTitles = () => {
    setTitles([
      "Nº Licitação",
      "Modalidade",
      "Nº Contrato",
      "Participante",
      "Data Assinatura",
      "Data Vigência",
      "Valor",
    ]);
  };

  const formatToCurrency = (value) => {
    if (!value) return "";

    const number = parseFloat(value).toFixed(2);

    return new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    })
      .format(number)
      .replace("R$", "")
      .trim();
  };

  const formatToDate = (value) => {
    if (!value || value.length !== 8) return "";

    const day = value.slice(0, 2);
    const month = value.slice(2, 4);
    const year = value.slice(4, 8);

    return `${day}/${month}/${year}`;
  };

  useEffect(() => {
    updateTitles();
    // eslint-disable-next-line
  }, [currentYear]);

  useEffect(() => {
    updateTitles();
    // eslint-disable-next-line
  }, [currentYear]);

  return (
    <>
      <Row className="row_form mt-1">
        <StyledCCol
          style={{
            height: `${
              60 + (values.length > 1 ? 30 * (values.length - 1) : 0)
            }px`,
          }}
        >
          {values.map((item, index) => {
            return (
              <RowAninhada
                key={index}
                style={{
                  display: "flex",
                  flexWrap: "nowrap",
                }}
              >
                <CustomInput
                  title={index === 0 && titles[0]}
                  all
                  disabled
                  size={"8em"}
                  value={item?.numeroLicitacao || null}
                />
                <CustomInput
                  disabled
                  title={index === 0 && titles[1]}
                  all
                  md={4}
                  menu={1}
                  value={item?.modalidadeLicitacao || null}
                  options={[
                    { label: "Modalidade", value: null },
                    ...modalidades,
                  ]}
                />
                <CustomInput
                  title={index === 0 && titles[2]}
                  all
                  disabled
                  size={"8em"}
                  value={item?.numeroContrato || null}
                />
                <CustomInput
                  disabled
                  title={index === 0 && titles[3]}
                  all
                  md={4}
                  value={item?.fornecedor || null}
                />
                <CustomInput
                  disabled
                  title={index === 0 && titles[4]}
                  all
                  value={formatToDate(item?.dataInicial) || null}
                />
                <CustomInput
                  disabled
                  title={index === 0 && titles[5]}
                  all
                  value={formatToDate(item?.dataFinal) || null}
                />
                <CustomInput
                  disabled
                  title={index === 0 && titles[6]}
                  all
                  value={formatToCurrency(item?.valor) || null}
                />
              </RowAninhada>
            );
          })}
        </StyledCCol>
        <CColButtonsAcoes2Icons md={2} style={{ marginLeft: "5px" }}>
          {/* <Title>Ação</Title> */}
          {/* <IconAdicionar /> */}
        </CColButtonsAcoes2Icons>
      </Row>
    </>
  );
};

export default Contratos;

const Title = styled.p`
  text-align: left;
  margin-top: 0;
  padding: 0px;
  margin-bottom: 0px;
  color: #888a8e;
  font-weight: 600;
  font-size: 12px;
`;

const StyledCCol = styled(CCol)`
  overflow: auto;

  &::-webkit-scrollbar {
    height: 4px;
    width: 2px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 2px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;
