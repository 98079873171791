export const formatToCurrency = (value) => {
  if (!value) return "";

  const number = parseFloat(value).toFixed(2);

  return new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  })
    .format(number)
    .replace("R$", "")
    .trim();
};
