import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import { Container } from "react-bootstrap";

import styled from "styled-components";

import { toast, ToastContainer } from "react-toastify";

import HeaderCadastros from "../../../../components/HeaderCadastros/HeaderCadastros";
import { CCol, RowForm, RowToModal } from "../../../../components/Grid/CCol";
import FloatingLabelInput from "../../../../components/Grid/FloatingLabelInput";
import { RowInfosAcao } from "../../../../components/RowInfosAcao/RowInfosAcao";

import { FonteDeRecursosAba } from "./FonteDeRecursosAba";
import {
  accountCategoriesPcasp,
  indexItemsApplyBgPreviousOptions,
} from "./accountsPCASP";

import api from "../../../../utils/api";
import { ReturnDataAtual } from "../../../../utils/Gerais/ReturnDataAtual";

import { useCurrentYear } from "../../../../hooks/useCurrentYear";

import { OptionsStyle } from "../../../Paginas_Sistemas_Planejamento/Cadastros/NaturezaDaReceita/NaturezaDaReceita";

import { CurrentClientContext } from "../../../../contexts/CurrentClientContext";
import { useParams } from "react-router-dom";

const CColNumero = styled(CCol)`
  @media (min-width: 768px) {
    width: 10%;
  }
`;

export const QDRDE = () => {
  const { currentYear } = useCurrentYear();
  const { currentClient } = useContext(CurrentClientContext);

  const { id: idContaPcasp } = useParams();

  const dataAtual = ReturnDataAtual()
    .replace(/\//g, "-")
    .split("-")
    .reverse()
    .join("-");

  const [itemContaPcaspValue, setItemContaPcaspValue] = useState(null);
  const [dataFontesDeRecursos, setDataFontesDeRecursos] = useState([
    {
      id: "",
      conta: "",
      titulo: "",
    },
  ]);

  const [optionContaPcaspSelected, setOptionContaPcaspSelected] = useState({
    label: "",
    value: "",
    code: "",
    highlightOptionPrevious: false,
    subOptions: [
      {
        label: "",
        value: "",
        code: "",
        fontesDeRecursos: [
          {
            exercicio: "",
            codigo: "",
            value: "",
            label: "",
          },
        ],
      },
    ],
  });

  const [valuesQdrde, setValuesQdrde] = useState({
    idContaPcasp: "",
    idItemContaPcasp: "",
    funcaoItemContaPcasp: "",
    numero: "",
    titulo: "",
    codigoCategoriaContaPcasp: "",
    naturezaDeSaldo: "",
    codigoItemContaPcasp: "",
    fontes: [{ fonteId: null, valor: "", data: "", label: "" }],
  });

  const getInfosContaPcasp = async (codigoContaPcasp) => {
    if (!codigoContaPcasp) return;

    try {
      const resp = await api.get(
        `pcasp/1/ano/${currentYear}/filtro?filter=${codigoContaPcasp}`
      );
      const data = resp.data;
      const infosContaPcasp = data.pcasp.find(
        (item) => item.conta === codigoContaPcasp
      );

      /* //TODO: Por enquanto a natureza saldo vem somente uma string no back mas em algum momento iŕa vir em array */
      if (infosContaPcasp) {
        return infosContaPcasp;
      }
      return null;
    } catch (error) {
      console.log(error);
      return null;
    }
  };

  const getIdFonte = (contaFonte) => {
    const fonte = dataFontesDeRecursos.find(
      (item) => item.conta === contaFonte
    );

    return fonte.id || null;
  };

  const handleSelectCategoryPcasp = (option) => {
    setItemContaPcaspValue(null);
    console.log(option);

    if (!option.subOptions[0].label) {
      const codigoFonte = `${option.subOptions[0].fontesDeRecursos[0].exercicio}.${option.subOptions[0].fontesDeRecursos[0].codigo}`;
      const fonteId = getIdFonte(codigoFonte);
      const label = `${option.subOptions[0].fontesDeRecursos[0].exercicio}.${option.subOptions[0].fontesDeRecursos[0].codigo} - ${option.subOptions[0].fontesDeRecursos[0].label}`;

      setValuesQdrde({
        ...valuesQdrde,
        naturezaDeSaldo: null,
        funcaoItemContaPcasp: "",
        fontes: [
          {
            data: dataAtual,
            fonteId,
            valor: "",
            label,
          },
        ],
      });
      return;
    }

    setValuesQdrde({
      ...valuesQdrde,
      idItemContaPcasp: "",
      naturezaDeSaldo: null,
      codigoItemContaPcasp: "",
      codigoCategoriaContaPcasp: option.code,
      funcaoItemContaPcasp: "",
      fontes: [
        {
          data: dataAtual,
          fonteId: null,
          valor: "",
          label: "",
        },
      ],
    });
  };

  const handleSelectItemCategoryPcasp = async (option) => {
    console.log(option);
    console.log(valuesQdrde);

    const infosItemContaPcasp = await getInfosContaPcasp(option.code);

    if (!infosItemContaPcasp) return;

    console.log(infosItemContaPcasp);

    const codigoFonte = `${option.fontesDeRecursos[0].exercicio}.${option.fontesDeRecursos[0].codigo}`;
    const fonteId = getIdFonte(codigoFonte);

    setValuesQdrde({
      ...valuesQdrde,
      idItemContaPcasp: infosItemContaPcasp.id,
      naturezaDeSaldo: infosItemContaPcasp.naturezaDeSaldo,
      codigoItemContaPcasp: infosItemContaPcasp.conta,
      funcaoItemContaPcasp: infosItemContaPcasp.funcao,
      fontes: [
        {
          data: dataAtual,
          fonteId,
          valor: "",
          label: `${option.fontesDeRecursos[0].exercicio}.${option.fontesDeRecursos[0].codigo} - ${option.fontesDeRecursos[0].label}`,
        },
      ],
    });
  };

  const handleCadastroQdrde = async () => {
    console.log(valuesQdrde);

    if (!valuesQdrde.fontes.length) {
      toast.info("Adicione uma fonte de recursos.");
      return;
    }

    if (!valuesQdrde.titulo || !valuesQdrde.codigoCategoriaContaPcasp) {
      toast.error("Preencha todos os campos corretamente.");
      return;
    }

    const infosContaPcasp = await getInfosContaPcasp(
      valuesQdrde.codigoCategoriaContaPcasp
    );

    if (infosContaPcasp === null) {
      toast.error("Erro ao criar o cadastro. Tente novamente mais tarde.");
      return;
    }

    const fontes = valuesQdrde.fontes.map((item) => {
      return {
        fonteId: item.fonteId,
        valor: parseFloat(item.valor.replace(",", ".")) || 0,
        dataCriacao: item.data,
      };
    });
    const infosCadastro = {
      clienteId: currentClient.clienteId,
      titulo: valuesQdrde.titulo,
      categoriaContaPcasp: infosContaPcasp.id,
      naturezaSaldo: valuesQdrde.naturezaDeSaldo,
      itemContaPcasp: valuesQdrde.idItemContaPcasp,
      dataCriacao: dataAtual,
      fontes,
    };
    await postInfosQdrde(infosCadastro);
  };

  const handleUpdateInfosQdrde = async () => {
    if (!valuesQdrde.fontes.length) {
      toast.info("Adicione uma fonte de recursos.");
      return;
    }

    if (!valuesQdrde.titulo || !valuesQdrde.codigoCategoriaContaPcasp) {
      toast.error("Preencha todos os campos corretamente.");
      return;
    }

    const fontes = valuesQdrde.fontes.map((item) => {
      return {
        fonteId: item.fonteId,
        valor: parseFloat(item.valor.replace(",", ".")) || 0,
      };
    });
    const infosUpdate = {
      titulo: valuesQdrde.titulo,
      categoriaContaPcasp: valuesQdrde.idContaPcasp,
      naturezaSaldo: valuesQdrde.naturezaDeSaldo,
      itemContaPcasp: valuesQdrde.idItemContaPcasp,
      fontes,
    };
    await updateInfosQdrde(infosUpdate);
  };

  const updateInfosQdrde = async (infos) => {
    try {
      await api.put(`qdrde/update/${idContaPcasp}`, infos);
      toast.success("Cadastro atualizado com sucesso.");
    } catch (error) {
      console.log(error);
      toast.error("Erro ao atualizar o cadastro. Tente novamente mais tarde.");
    }
  };

  const postInfosQdrde = async (infos) => {
    try {
      await api.post("qdrde", infos);
      toast.success("Cadastro realizado com sucesso.");
    } catch (error) {
      console.log(error);
      toast.error("Erro ao criar o cadastro. Tente novamente mais tarde.");
    }
  };

  const fontesItemContaPcasp = useMemo(() => {
    if (!optionContaPcaspSelected.label) return [];

    const contaPcaspSemItem = optionContaPcaspSelected.subOptions.find(
      (item) => item.label === ""
    );

    if (contaPcaspSemItem) return contaPcaspSemItem.fontesDeRecursos;

    if (!itemContaPcaspValue) return [];
    const fontes = optionContaPcaspSelected.subOptions.find(
      (item) => item.value === itemContaPcaspValue
    );

    return fontes.fontesDeRecursos || [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemContaPcaspValue, optionContaPcaspSelected.subOptions]);

  const fetchFontesDeRecursos = useCallback(async () => {
    try {
      const resp = await api.get(`fonteDeRecurso/por-ano/${currentYear}`);
      const data = resp.data;
      const fonts = data.map((item) => {
        return {
          id: item.id,
          conta: item.conta,
          titulo: item.titulo,
        };
      });
      setDataFontesDeRecursos(fonts);
    } catch (error) {
      console.log(error);
    }
  }, [currentYear]);

  const getInfosPcaspByCode = (codePcasp, data) => {
    const infosItemPcasp = data.find((item) => item.code === codePcasp);

    return infosItemPcasp || null;
  };

  const formattedFonts = (data) => {
    const fonts = data.map((item) => {
      const fontInfo = item.fonteInfo.find((font) => font.id === item.fonteId);
      const date = new Date(item.dataCriacao);
      return {
        data: date.toISOString().split("T")[0],
        fonteId: item.fonteId,
        valor: item.valor,
        label: fontInfo ? `${fontInfo.conta} - ${fontInfo.titulo}` : "",
      };
    });
    return fonts;
  };

  const fetchDataQdrdeById = useCallback(async () => {
    try {
      const resp = await api.get(`/qdrde/por-id/id/${idContaPcasp}`);
      const data = resp.data;

      const infosCategoryPcasp = getInfosPcaspByCode(
        data.categoriaContaPcaspInfo[0].conta,
        accountCategoriesPcasp
      );
      const infosItemCategoryPcasp = getInfosPcaspByCode(
        data.itemContaPcaspInfo[0].conta,
        infosCategoryPcasp.subOptions
      );

      const fontes = formattedFonts(data.fontes);

      
      setItemContaPcaspValue(infosItemCategoryPcasp.value || "");
      setOptionContaPcaspSelected(infosCategoryPcasp || {});
      setValuesQdrde({
        ...valuesQdrde,
        numero: data.id,
        titulo: data.titulo,
        naturezaDeSaldo: data.naturezaSaldo,
        codigoCategoriaContaPcasp: data.categoriaContaPcaspInfo[0].conta || "",
        idContaPcasp: data.categoriaContaPcasp,
        codigoItemContaPcasp: data.itemContaPcaspInfo[0].conta,
        idItemContaPcasp: data.itemContaPcasp,
        funcaoItemContaPcasp: data.itemContaPcaspInfo[0].funcao,
        fontes,
      });
    } catch (error) {
      console.log(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idContaPcasp]);

  useEffect(() => {
    if (idContaPcasp) {
      fetchDataQdrdeById();
    }
  }, [fetchDataQdrdeById, idContaPcasp]);

  useEffect(() => {
    fetchFontesDeRecursos();
  }, [fetchFontesDeRecursos]);
  
  return (
    <Container
      id="pags_responsividade_padding_geral"
      fluid
      style={{ color: "#515C70" }}
      className="container_conteudo conteudo_pagina"
    >
      <HeaderCadastros
        NomePaginaTitulo={"QDRDE"}
        PaginaSubtitulo={"Cadastro"}
        RowTitle={"Quadro Detalhado da Receita e Despesa Extra"}
        ButtonSaveFunction={
          !idContaPcasp ? handleCadastroQdrde : handleUpdateInfosQdrde
        }
        PaginaConsulta={"/contabilidade/extra/qdrde/consulta"}
      />
      <ToastContainer />
      <RowForm className="mt-1">
        <CColNumero md={1}>
          <FloatingLabelInput
            disabled
            placeholder="Número"
            label="Número"
            value={valuesQdrde.numero}
          />
        </CColNumero>
        <CCol>
          <FloatingLabelInput
            type="text"
            placeholder="Título"
            label="Título"
            value={valuesQdrde.titulo || ""}
            onChange={(e) => {
              setValuesQdrde((prevState) => ({
                ...prevState,
                titulo: e.target.value,
              }));
            }}
          />
        </CCol>
      </RowForm>
      <RowForm style={{ marginTop: "-14px" }}>
        <CCol>
          <FloatingLabelInput
            placeholder="Categoria da Conta - PCASP"
            new
            tipo="input_select"
            label="Categoria da Conta - PCASP"
            value={optionContaPcaspSelected.value}
            onSelect={(option) => {
              // setItemContaPcaspValue(1);
              setOptionContaPcaspSelected(option);
              handleSelectCategoryPcasp(option);
              console.log(option);
            }}
            options={accountCategoriesPcasp}
            indexOptionsApplyBgPrevious={indexItemsApplyBgPreviousOptions}
          />
        </CCol>

        <CCol md={3}>
          <FloatingLabelInput
            placeholder="Natureza do Saldo"
            new
            disabled
            value={valuesQdrde.naturezaDeSaldo}
            tipo="input_select"
            label="Natureza do Saldo"
            options={[
              {
                label: "Natureza do Saldo",
                value: null,
              },
              {
                label: valuesQdrde.naturezaDeSaldo,
                value: valuesQdrde.naturezaDeSaldo || null,
              },
            ]}
          />
        </CCol>
      </RowForm>
      <RowForm style={{ marginTop: "-14px" }}>
        <FloatingLabelInput
          placeholder="Item"
          new
          tipo="input_select"
          label="Item"
          disabled={
            optionContaPcaspSelected.subOptions.every(
              (item) => item.value === 0
            ) ||
            !optionContaPcaspSelected.subOptions.length ||
            !optionContaPcaspSelected.code
          }
          value={itemContaPcaspValue}
          onSelect={(option) => {
            setItemContaPcaspValue(option.value);
            handleSelectItemCategoryPcasp(option);
          }}
          options={[
            { label: "Item", value: null },
            ...optionContaPcaspSelected.subOptions,
          ]}
        />
      </RowForm>
      {!!valuesQdrde.funcaoItemContaPcasp && (
        <RowInfosAcao>
          <CCol>
            <p>
              <span>{valuesQdrde.funcaoItemContaPcasp}</span>
            </p>
          </CCol>
        </RowInfosAcao>
      )}

      <RowToModal
        style={{ backgroundColor: "#fff", borderRadius: "0px 0px 5px 5px" }}
        className={"mt-1"}
      >
        <CCol style={{ display: "flex", alignItems: "center", padding: "0px" }}>
          <OptionsStyle selecionado>Fonte de Recursos / CO</OptionsStyle>
        </CCol>
      </RowToModal>
      <FonteDeRecursosAba
        fontesSubOptions={fontesItemContaPcasp}
        valuesQdrde={valuesQdrde}
        setValuesQdrde={setValuesQdrde}
        dataFontesDeRecursos={dataFontesDeRecursos}
      />
    </Container>
  );
};
