import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Container } from "react-bootstrap";
import styled from "styled-components";
import { toast, ToastContainer } from "react-toastify";
import HeaderCadastros from "../../../../components/HeaderCadastros/HeaderCadastros";
import { CCol, RowForm, RowToModal } from "../../../../components/Grid/CCol";
import FloatingLabelInput from "../../../../components/Grid/FloatingLabelInput";
import { RowInfosAcao } from "../../../../components/RowInfosAcao/RowInfosAcao";
import { OptionsStyle } from "../../../Paginas_Sistemas_Planejamento/Cadastros/NaturezaDaReceita/NaturezaDaReceita";
import SaldosAba from "./SaldosAba";
import { ProcedimentoContabelAba } from "./ProcedimentoContabelAba";
import {
  accountCategoriesPcasp,
  indexItemsApplyBgOptions,
} from "./accountsPCASP";
import api from "../../../../utils/api";
import { useCurrentYear } from "../../../../hooks/useCurrentYear";
import { CurrentClientContext } from "../../../../contexts/CurrentClientContext";
import { useParams } from "react-router-dom";
import { TestButton } from "../../../../utils/TestButton";

const CColNumero = styled(CCol)`
  @media (min-width: 768px) {
    width: 10%;
  }
`;

const infosAbaOptions = [
  {
    title: "Saldos",
    id: 1,
  },
  {
    title: "Procedimentos Contábeis",
    id: 2,
  },
];

const optionsUnidadeMedida = [
  { value: "Un - Unidade", label: "Un - Unidade" },
  { value: "% - Percentual", label: "% - Percentual" },
  { value: "KG - Quilograma", label: "KG - Quilograma" },
  { value: "R$ - Reais", label: "R$ - Reais" },
  { value: "L - Litros", label: "L - Litros" },
  { value: "m - Metros Linear", label: "m - Metros Linear" },
  { value: "KM - Quilômetros", label: "KM - Quilômetros" },
  { value: "T - Toneladas", label: "T - Toneladas" },
  { value: "m² - Metros Quadrados", label: "m² - Metros Quadrados" },
  { value: "h - Horas", label: "h - Horas" },
];

export const ProdutosAquisicao = () => {
  const { numeroPR } = useParams();
  const navigate = useNavigate();
  const { currentClient } = useContext(CurrentClientContext);
  const { currentYear } = useCurrentYear();
  const [abaSelected, setAbaSelected] = useState(infosAbaOptions[0].id);

  const [itemContaPcaspValue, setItemContaPcaspValue] = useState(null);

  const [optionContaPcaspSelected, setOptionContaPcaspSelected] = useState({
    label: "",
    value: "",
    code: "",
    highlightOptionPrevious: false,
    subOptions: [
      {
        label: "",
        value: "",
        code: "",
        fontesDeRecursos: [
          {
            exercicio: "",
            codigo: "",
            value: "",
            label: "",
          },
        ],
      },
    ],
  });

  const [valuesProdutosAquisicao, setValuesProdutosAquisicao] = useState({
    numero: null,
    produtoAquisicao: null,
    idCategoriaContaPcasp: null,
    unidadeDeMedida: null,
    idItemContaPcasp: null,
    codigoCategoriaContaPcasp: null,
    codigoItemContaPcasp: null,
    funcaoItemContaPcasp: null,
  });

  const handleSelectCategoryPcasp = async (option) => {
    console.log("Estou setando codigoCategoriaContaPcasp como: ", option.code);
    setItemContaPcaspValue(null);
    setOptionContaPcaspSelected(option);
    console.log("UAAAAAAAAAAAA");
    setValuesProdutosAquisicao((prevValues) => ({
      ...prevValues,
      codigoCategoriaContaPcasp: option.code,
      teste: "UAAAAAAA",
    }));
  };

  const handleSelectItemCategoryPcasp = async (option) => {
    console.log("Estou setando codigoItemContaPcasp como: ", option.code);
    setItemContaPcaspValue(option.value);

    const infosItemContaPcasp = await getInfosContaPcasp(option.code);

    if (!infosItemContaPcasp) return;

    setValuesProdutosAquisicao((prevValues) => ({
      ...prevValues,
      funcaoItemContaPcasp: infosItemContaPcasp.funcao,
      idItemContaPcasp: infosItemContaPcasp.id,
      codigoItemContaPcasp: option.code,
    }));
  };

  const handleSelectUnidadeMedida = (option) => {
    console.log(option);
    setValuesProdutosAquisicao((prevValues) => ({
      ...prevValues,
      unidadeDeMedida: option.value,
    }));
  };

  const handleRegister = async () => {
    if (!numeroPR) {
      console.log(valuesProdutosAquisicao);
      try {
        const response = await api.post(
          `/produtoAquisicao/${currentClient.clienteId}`,
          valuesProdutosAquisicao
        );
        console.log(response.data);
        fetchData();
        toast.success(
          response.data.message || "Produto ou Aquisição Cadastrada Com Sucesso"
        );
        navigate("/contabilidade/cadastros/produtos-aquisicao/consulta");
      } catch (error) {
        console.log(error);
        toast.error(
          error.data.message ||
            "Erro Desconhecido ao Cadastrar um Produto ou Aquisição!"
        );
      }
    } else {
      try {
        const response = await api.put(
          `/produtoAquisicao/${numeroPR}`,
          valuesProdutosAquisicao
        );
        console.log(response.data);
        toast.success(
          response.data.message || "Produto ou Aquisição Atualizada Com Sucesso"
        );
      } catch (error) {
        console.log(error);
        toast.error(
          error.data.message ||
            "Erro Desconhecido ao Cadastrar um Produto e Aquisição!"
        );
      }
    }
  };

  const getInfosContaPcasp = async (codigoContaPcasp) => {
    if (!codigoContaPcasp) return;

    try {
      const resp = await api.get(
        `pcasp/1/ano/${currentYear}/filtro?filter=${codigoContaPcasp}`
      );
      const data = resp.data;
      const infosContaPcasp = data.pcasp.find(
        (item) => item.conta === codigoContaPcasp
      );

      if (infosContaPcasp) {
        return infosContaPcasp;
      }
      return null;
    } catch (error) {
      console.log(error);
      return null;
    }
  };

  // const postInfosProdutoAquisicao = async (infos) => {
  //   console.log("cadastro");
  // };

  const fetchData = async () => {
    if (!numeroPR) {
      try {
        const response = await api.get("/produtoAquisicao/get/nextNum");
        if (response?.data?.numero) {
          const numero = response.data.numero;
          setValuesProdutosAquisicao((prevValues) => ({
            ...prevValues,
            numero: numero,
            produtoAquisicao: null,
            idCategoriaContaPcasp: null,
            unidadeDeMedida: null,
            idItemContaPcasp: null,
            codigoCategoriaContaPcasp: null,
            codigoItemContaPcasp: null,
            funcaoItemContaPcasp: null,
          }));
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        const response = await api.get(
          `/produtoAquisicao/porNumero/${numeroPR}`
        );
        const item = await accountCategoriesPcasp.find(
          (item) => item.code === response.data.codigoCategoriaContaPcasp
        );
        const subItem = await item.subOptions.find(
          (item) => item.code === response.data.codigoItemContaPcasp
        );

        await handleSelectCategoryPcasp(item);
        await handleSelectItemCategoryPcasp(subItem);

        const data = response.data;

        await setValuesProdutosAquisicao((prevValues) => ({
          ...prevValues,
          numero: data.numero,
          produtoAquisicao: data.produtoAquisicao,
          unidadeDeMedida: data.unidadeDeMedida,
        }));
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, [numeroPR]);

  return (
    <Container
      id="pags_responsividade_padding_geral"
      fluid
      style={{ color: "#515C70" }}
      className="container_conteudo conteudo_pagina"
    >
      <TestButton onClick={() => console.log(valuesProdutosAquisicao)}>
        Test
      </TestButton>
      <HeaderCadastros
        NomePaginaTitulo={"Produto ou Aquisição"}
        PaginaSubtitulo={"Cadastro"}
        RowTitle={"Produto ou Aquisição"}
        ButtonSaveFunction={handleRegister}
        PaginaConsulta={"/contabilidade/cadastros/produtos-aquisicao/consulta"}
      />
      <ToastContainer />
      <RowForm className="mt-1">
        <CColNumero md={1}>
          <FloatingLabelInput
            disabled
            placeholder="Número"
            label="Número"
            value={valuesProdutosAquisicao.numero}
          />
        </CColNumero>
        <CCol>
          <FloatingLabelInput
            type="text"
            placeholder="Produto ou Aquisição"
            label="Produto ou Aquisição"
            value={valuesProdutosAquisicao.produtoAquisicao}
            onChange={(e) => {
              setValuesProdutosAquisicao((prevState) => ({
                ...prevState,
                produtoAquisicao: e.target.value,
              }));
            }}
          />
        </CCol>
      </RowForm>
      <RowForm style={{ marginTop: "-14px" }}>
        <CCol>
          <FloatingLabelInput
            placeholder="Categoria da Conta - PCASP"
            new
            tipo="input_select"
            label="Categoria da Conta - PCASP"
            value={optionContaPcaspSelected.value}
            onSelect={(option) => handleSelectCategoryPcasp(option)}
            options={accountCategoriesPcasp}
            indexItemsApplyBgOptions={indexItemsApplyBgOptions}
          />
        </CCol>

        <CCol md={3}>
          <FloatingLabelInput
            new
            tipo="input_select"
            placeholder="Unidade de medida"
            label="Unidade de medida"
            value={valuesProdutosAquisicao.unidadeDeMedida}
            options={[
              { label: "Unidade de medida", value: null },
              ...optionsUnidadeMedida,
            ]}
            onSelect={(option) => handleSelectUnidadeMedida(option)}
          />
        </CCol>
      </RowForm>
      <RowForm style={{ marginTop: "-14px" }}>
        <FloatingLabelInput
          placeholder="Item"
          new
          tipo="input_select"
          label="Item"
          disabled={
            optionContaPcaspSelected.subOptions.every(
              (item) => item.value === null
            ) ||
            !optionContaPcaspSelected.subOptions.length ||
            !optionContaPcaspSelected.code
          }
          value={itemContaPcaspValue}
          onSelect={(option) => {
            handleSelectItemCategoryPcasp(option);
          }}
          options={[
            { label: "Item", value: null },
            ...optionContaPcaspSelected.subOptions,
          ]}
        />
      </RowForm>
      {!!valuesProdutosAquisicao.funcaoItemContaPcasp && (
        <RowInfosAcao>
          <CCol>
            <p>
              <span>{valuesProdutosAquisicao.funcaoItemContaPcasp}</span>
            </p>
          </CCol>
        </RowInfosAcao>
      )}

      {/* <RowSelecaoAbasInferior
        abas={["Fonte de Recursos / CO"]}
        abaSelecionada={abaSelecionada}
        setAbaSelecionada={setAbaSelecionada}
      /> */}
      <RowToModal
        style={{ backgroundColor: "#fff", borderRadius: "0px 0px 5px 5px" }}
        className={"mt-1"}
      >
        <CCol style={{ display: "flex", alignItems: "center", padding: "0px" }}>
          {infosAbaOptions.map((item) => (
            <OptionsStyle
              key={item.id}
              selecionado={item.id === abaSelected}
              onClick={() => setAbaSelected(item.id)}
            >
              {item.title}
            </OptionsStyle>
          ))}
        </CCol>
      </RowToModal>

      {abaSelected === 1 && <SaldosAba />}

      {abaSelected === 2 && <ProcedimentoContabelAba />}
    </Container>
  );
};
