import React, { useRef, useState } from "react";
import {
  CCol,
  CColButtonsAcoes2Icons,
  RowAninhada,
} from "../../../../../components/Grid/CCol";
import { Row } from "react-bootstrap";
import { PTitulosInputs } from "../../../../../styles/StyledComponents/ListaDeInputs";
import IconAdicionar from "../../../../../components/ListaDeInputs/IconAdicionar";
import { InputDateRelatorio } from "../../../../../components/Inputs/InputDateRelatorio";
import { ProgressBarComponent } from "../../../../../components/Grid/ProgressBarComponent";
import handleFile from "./getFile";

const Principal = ({ values, setValues }) => {
  const [percentage, setPercentage] = useState(0);

  const fileInputRef = useRef(null);

  const handleClick = () => {
    fileInputRef.current.click();
  };

  const handleChange = async (event) => {
    setPercentage(0);
    const updatePercentage = () => {
      setPercentage((prev) => {
        const next = Math.min(prev + 10, 100);
        return next;
      });
    };

    const intervalId = setInterval(() => {
      updatePercentage();
    }, 100);

    const data = await handleFile(event);

    clearInterval(intervalId);
    setPercentage(100);

    console.log(data);
    setValues(data);
  };

  return (
    <>
      <Row className="row_form mt-1">
        <RowAninhada>
          <CCol md={1} style={{ width: "9em" }}>
            <PTitulosInputs>Mês Início</PTitulosInputs>
            <InputDateRelatorio
              label={"Mês Início"}
              placeholder={"Mês Início"}
              little
            />
          </CCol>
          <CCol md={1} style={{ width: "9em" }}>
            <PTitulosInputs>Mês Final</PTitulosInputs>
            <InputDateRelatorio
              label={"Mês Final"}
              placeholder={"Mês Final"}
              little
            />
          </CCol>
          <CCol>
            <PTitulosInputs> &nbsp; </PTitulosInputs>
            <ProgressBarComponent
              height={30}
              percentage={percentage}
              label={"Processando dados Licitação"}
            />
          </CCol>
          <CColButtonsAcoes2Icons md={1}>
            <PTitulosInputs>Importar</PTitulosInputs>
            <IconAdicionar
              onClick={handleClick}
              style={{ marginLeft: "13px", marginTop: "1.5px" }}
            />
            {/* Input de arquivo oculto */}
            <input
              type="file"
              ref={fileInputRef}
              style={{ display: "none" }}
              onChange={handleChange}
            />
          </CColButtonsAcoes2Icons>
        </RowAninhada>
      </Row>
    </>
  );
};

export default Principal;
