import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import Login from "../../pages/login/login";
import UsuariosPage from "../../pages/PagesAcessoInterno/Cadastros/UsuariosPage/UsuariosPage";
import Estrutura from "../Estrutura/Estrutura";
import GerenciadorFinanceiro from "../../pages/PagesAcessoInterno/GerenciadorFinanceiro/GerenciadorFinanceiro";
import TarefasPrazosHoje from "../../pages/Tarefas_e_Prazos/TAREFAS_E_PRAZOS_HOJE";
import RiscosFiscais from "../../pages/Paginas_Sistemas_Planejamento/LDO/Riscos_Fiscais/RiscosFiscais";
import NaturezaDaReceita from "../../pages/Paginas_Sistemas_Planejamento/Cadastros/NaturezaDaReceita/NaturezaDaReceita";
import TarefasPrazosMensal from "../../pages/Tarefas_e_Prazos/TAREFAS_E_PRAZOS_MENSAL";
import NaturezaDaDespesa from "../../pages/Paginas_Sistemas_Planejamento/Cadastros/NaturezaDaDespesa/NaturezaDaDespesa";
import ConsultaDespesa from "../../pages/Paginas_Sistemas_Planejamento/Cadastros/NaturezaDaDespesa/ConsultaDespesa";
import MetasFiscais from "../../pages/Paginas_Sistemas_Planejamento/LDO/Metas_Fiscais/MetasFiscais";
import HomeTest from "../../pages/home/HomeTest";
import ConsultaQDD from "../../pages/Paginas_Sistemas_Planejamento/LOA_QDD/ConsultaQDD";
import ConsultaQDR from "../../pages/Paginas_Sistemas_Planejamento/LOA_QDR/ConsultaQDR";
import FonteDeRecursos from "../../pages/Paginas_Sistemas_Planejamento/Cadastros/FonteDeRecursos/FonteDeRecursos";
import Dashboard from "../../pages/PagesAcessoInterno/Dashboard/Dashboard";
import UnidadeOrcamentaria from "../../pages/Paginas_Sistemas_Planejamento/Cadastros/UnidadeOrcamentaria/UnidadeOrcamentaria";
import AcaoGovernamental from "../../pages/Paginas_Sistemas_Planejamento/Cadastros/AcaoGovernamental/AcaoGovernamental";
import ConsultaAcaoGovernamental from "../../pages/Paginas_Sistemas_Planejamento/Cadastros/AcaoGovernamental/ConsultaAcaoGovernamental";
import Programa from "../../pages/Paginas_Sistemas_Planejamento/Cadastros/Programa/Programa";
//import CadastroAcessoInterno from "../../pages/PagesAcessoInterno/Cadastros/Cadastro_Acesso_Interno/CadastroAcessoInterno";
import CadastrosCpfCnpj from "../../pages/PagesAcessoInterno/Cadastros/Gerenciador_Cadastros_CPF_CNPJ/Cadastros_cpf_cnpj";
import EixoEstrategico from "../../pages/Paginas_Sistemas_Planejamento/Cadastros/EixoEstrategico/EixoEstrategico";
import ConsultaCliente from "../../pages/PagesAcessoInterno/Cadastros/Gerenciador_Clientes/ConsultaCliente";
import ConsultaCpfCnpj from "../../pages/PagesAcessoInterno/Cadastros/Gerenciador_Cadastros_CPF_CNPJ/ConsultaCpfCnpj";
import ConsultaUsuarios from "../../pages/PagesAcessoInterno/Cadastros/UsuariosPage/ConsultaUsuarios";
import FuncaoSubfuncao from "../../pages/PagesAcessoInterno/Cadastros/Funcao_Subfuncao/FuncaoSubfuncao";
import ConsultaFuncaoSubfuncao from "../../pages/PagesAcessoInterno/Cadastros/Funcao_Subfuncao/ConsultaFuncaoSubfuncao";
import ObjetivosDoMilenio from "../../pages/Paginas_Sistemas_Planejamento/Cadastros/ObjetivosDoMilenio/ObjetivosDoMilenio";
import ConsultaObjetivosDoMilenio from "../../pages/Paginas_Sistemas_Planejamento/Cadastros/ObjetivosDoMilenio/ConsultaObjetivosDoMilenio";
import TipoDePrograma from "../../pages/Paginas_Sistemas_Planejamento/Cadastros/TipoDePrograma/TipoDePrograma";
import ConsultaTipoDePrograma from "../../pages/Paginas_Sistemas_Planejamento/Cadastros/TipoDePrograma/ConsultaTiposDeProgramas";
import ConsultaCpfCnpjPlanejamento from "../../pages/Paginas_Sistemas_Planejamento/Cadastros/CpfCnpjPlanejamento/ConsultaCpfCnpjPlanejamento";
import CadastroCpfCnpjPlanejamento from "../../pages/Paginas_Sistemas_Planejamento/Cadastros/CpfCnpjPlanejamento/CadastroCpfCnpjPlanejamento";
import ConsultaFuncaoSubfuncaoPlanejamento from "../../pages/Paginas_Sistemas_Planejamento/Cadastros/FuncaoSubfuncaoPlanejamento/ConsultaFuncaoSubfuncaoPlanejamento";
import ParametroIndices from "../../pages/Paginas_Sistemas_Planejamento/Cadastros/ParametroIndices/ParametroIndices";
import ConsultaParametroIndices from "../../pages/Paginas_Sistemas_Planejamento/Cadastros/ParametroIndices/ConsultaParametroIndices";
import { useContext } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import VizualizarFuncaoSubfuncao from "../../pages/Paginas_Sistemas_Planejamento/Cadastros/FuncaoSubfuncaoPlanejamento/VizualizarFuncaoSubfuncao";
import ConsultaDiretriz from "../../pages/Paginas_Sistemas_Planejamento/Cadastros/Diretriz/ConsultaDiretriz";
import CadastroDiretriz from "../../pages/Paginas_Sistemas_Planejamento/Cadastros/Diretriz/CadastroDiretriz";
import ConsultaEixoEstrategico from "../../pages/Paginas_Sistemas_Planejamento/Cadastros/EixoEstrategico/ConsultaEixoEstrategico";
import ConsultaUnidadeOrcamentaria from "../../pages/Paginas_Sistemas_Planejamento/Cadastros/UnidadeOrcamentaria/ConsultaUnidadeOrcametario";
import ConsultaIndicadores from "../../pages/Paginas_Sistemas_Planejamento/Indicadores/ConsultaIndicadores";
import Indicadores from "../../pages/Paginas_Sistemas_Planejamento/Indicadores/Indicadores";
import ConsultaPrograma from "../../pages/Paginas_Sistemas_Planejamento/Cadastros/Programa/ConsultaPrograma";
import PCASP from "../../pages/Paginas_Sistemas_Planejamento/PCASP/PCASP";
import ConsultaPCASP from "../../pages/Paginas_Sistemas_Planejamento/PCASP/ConsultaPCASP";
import ConsultaReceita from "../../pages/Paginas_Sistemas_Planejamento/Cadastros/NaturezaDaReceita/ConsultaReceita";
import ConsultaFonteRecursos from "../../pages/Paginas_Sistemas_Planejamento/Cadastros/FonteDeRecursos/ConsultaFonteRecursos";
import QDD from "../../pages/Paginas_Sistemas_Planejamento/LOA_QDD/QDD";
//import PagUpdateQDD from "../../pages/Paginas_Sistemas_Planejamento/LOA_QDD/PagUpdateQDD";
import RelatorioObjetiosMilenio from "../../pages/Paginas_Sistemas_Planejamento/Cadastros/ObjetivosDoMilenio/Relatorio/RelatorioObjetiosMilenio";
import QDRCadastro from "../../pages/Paginas_Sistemas_Planejamento/LOA_QDR/QDRCadastro";
import ReceitaEstimadaPPA from "../../pages/Paginas_Sistemas_Planejamento/PPA/Receita_Estimada_PPA/ReceitaEstimadaPPA";
import ConsultaReceitaEstimadaPPA from "../../pages/Paginas_Sistemas_Planejamento/PPA/Receita_Estimada_PPA/ConsultaReceitaEstimadaPPA";
import ClientesCadastro from "../../pages/PagesAcessoInterno/Cadastros/Gerenciador_Clientes/ClientesCadastro";
import { CurrentClientContext } from "../../contexts/CurrentClientContext";
import { useEffect } from "react";
import { SidebarExpandidaContext } from "../../contexts/SidebarExpandidaContext";
import styled from "styled-components";
import DashboardObjetivosDoMilenio from "../../pages/Paginas_Sistemas_Planejamento/Cadastros/ObjetivosDoMilenio/DashboardObjetivosDoMilenio";
import { DashboardIndicadores } from "../../pages/Paginas_Sistemas_Planejamento/Indicadores/DashboardIndicadores";
import { DashboardTipoPrograma } from "../../pages/Paginas_Sistemas_Planejamento/Cadastros/TipoDePrograma/DashboardTipoPrograma";
import { DashboardFuncaoSubfuncao } from "../../pages/Paginas_Sistemas_Planejamento/Cadastros/FuncaoSubfuncaoPlanejamento/DashboardFuncaoSubfuncao";
import VizualizacaoRelatorioMilenio from "../../pages/Paginas_Sistemas_Planejamento/Cadastros/ObjetivosDoMilenio/Relatorio/VizualizacaoRelatorio";
import RelatorioIndicadores from "../../pages/Paginas_Sistemas_Planejamento/Indicadores/Relatorio/RelatorioIndicadores";
import RelatorioTipoDePrograma from "../../pages/Paginas_Sistemas_Planejamento/Cadastros/TipoDePrograma/Relatorio/RelatorioTipoDePrograma";
import RelatorioFuncaoSubFuncao from "../../pages/Paginas_Sistemas_Planejamento/Cadastros/FuncaoSubfuncaoPlanejamento/Relatorio/RelatorioFuncaoSubFuncao";
import RelatorioEixoEstrategico from "../../pages/Paginas_Sistemas_Planejamento/Cadastros/EixoEstrategico/RelatorioEixoEstrategico";
import RelatorioPrograma from "../../pages/Paginas_Sistemas_Planejamento/Cadastros/Programa/Relatorio/RelatorioPrograma";
import RelatorioQDR from "../../pages/Paginas_Sistemas_Planejamento/LOA_QDR/RELATORIO_QDR/RelatorioQDR";
import RelatorioDiretriz from "../../pages/Paginas_Sistemas_Planejamento/Cadastros/Diretriz/Relatorio/RelatorioDiretriz";
import RelatorioParametroIndice from "../../pages/Paginas_Sistemas_Planejamento/Cadastros/ParametroIndices/Relatorio/RelatorioParametroIndice";
import RelatorioAcaoGovernamental from "../../pages/Paginas_Sistemas_Planejamento/Cadastros/AcaoGovernamental/Relatorio/RelatorioAcaoGovernamental";
import RelatorioFonteDeRecurso from "../../pages/Paginas_Sistemas_Planejamento/Cadastros/FonteDeRecursos/Relatorio/RelatorioFonteDeRecurso";
import RelatorioNatDesp from "../../pages/Paginas_Sistemas_Planejamento/Cadastros/NaturezaDaDespesa/Relatorio/RelatorioNatDesp";
import RelatorioNatRec from "../../pages/Paginas_Sistemas_Planejamento/Cadastros/NaturezaDaReceita/Relatorio/RelatorioNatRec";
import RelatorioCpfCnpj from "../../pages/Paginas_Sistemas_Planejamento/Cadastros/CpfCnpjPlanejamento/Relatorio/RelatorioCpfCnpj";
import InicioPlanejamento from "../../pages/Paginas_Sistemas_Planejamento/Inicio/InicioPlanejamento";
import ContaBancariaPag from "../../pages/Pages_Contabilidade/Cadastros/ContaBancaria/ContaBancariaPag";
import EVACadastro from "../../pages/Pages_Contabilidade/EVA/EVACadastro";
import EVBCadastro from "../../pages/Pages_Contabilidade/EVB/EVBCadastro";
import ReceitaOrcamentaria from "../../pages/Pages_Contabilidade/Orcamentario/GuiaDeReceita/ReceitaOrcamentaria";
import DashboardNatRec from "../../pages/Paginas_Sistemas_Planejamento/Cadastros/NaturezaDaReceita/DashboardNatRec";
import DashboardNatDesp from "../../pages/Paginas_Sistemas_Planejamento/Cadastros/NaturezaDaDespesa/DashboardNatDesp";
import DashboardEixoEstrategico from "../../pages/Paginas_Sistemas_Planejamento/Cadastros/EixoEstrategico/DashboardEixoEstrategico";
import DashboardUnidadeOrcamentaria from "../../pages/Paginas_Sistemas_Planejamento/Cadastros/UnidadeOrcamentaria/DashboardUnidadeOrcamentaria";
import { Relatorios } from "../../pages/Paginas_Sistemas_Planejamento/Impressos/Relatorios/relatorios";
import RelatorioQDD from "../../pages/Paginas_Sistemas_Planejamento/LOA_QDD/Relatorio/RelatorioQDD";
import RelatorioUnidadeOrcamentaria from "../../pages/Paginas_Sistemas_Planejamento/Cadastros/UnidadeOrcamentaria/Relatorio/RelatorioUnidadeOrcamentaria";
import DashboardFontRec from "../../pages/Paginas_Sistemas_Planejamento/Cadastros/FonteDeRecursos/DashboardFontRec";
import UtilitariosPlanejamento from "../../pages/Paginas_Sistemas_Planejamento/Cadastros/Utilitarios/UtilitariosPlanejamento";
import UtilitariosContabilidade from "../../pages/Pages_Contabilidade/Utilitarios/UtilitariosContabilidade";
import Exportar from "../../pages/Paginas_Sistemas_Planejamento/Cadastros/Exportar/Exportar";
import LeisDocumentos from "../../pages/Paginas_Sistemas_Planejamento/Cadastros/LeisDocumentos/LeisDocumentos";
import PagCadastroDeUsuario from "../../pages/PagesAcessoInterno/PagContaPerfil/PagCadastroDeUsuario";
import PerfilDoUsuario from "../../pages/PagesAcessoInterno/PagContaPerfil/PerfilDoUsuario";
import PagRecuperacaoDeSenha from "../../pages/PagRecuperacaoDeSenha/PagRecuperacaoDeSenha";
import CadastroMaster from "../../pages/PagesAcessoInterno/Cadastros/CadastroMaster/CadastroMaster";
import AnulacaoDeReceita from "../../pages/Pages_Contabilidade/Receita/AnulacaoDeReceita/AnulacaoDeReceita";
import NotaDeEmpenho from "../../pages/Pages_Contabilidade/Despesa/NotaDeEmpenho/NotaDeEmpenho";
import LiquidacaoDeEmpenho from "../../pages/Pages_Contabilidade/Despesa/LiquidacaoDeEmpenho/LiquidacaoDeEmpenho";
import AnulacaoDaDespesa from "../../pages/Pages_Contabilidade/Despesa/AnulacaoDaDespesa/AnulacaoDaDespesa";
import AtualizacaoOrcamentaria from "../../pages/Pages_Contabilidade/Despesa/AtualizacaoOrcamentaria/AtualizacaoOrcamentaria";
import GreReceita from "../../pages/Pages_Contabilidade/Extra/GreReceita/GreReceita";
import GdeDespesa from "../../pages/Pages_Contabilidade/Extra/GdeDespesa/GdeDespesa";
import { QDRDE } from "../../pages/Pages_Contabilidade/Extra/QDRDE/QDRDE";
import DescontoNaFonte from "../../pages/Pages_Contabilidade/Cadastros/DescontoNaFonte/DescontoNaFonte";
import Obra from "../../pages/Pages_Contabilidade/Cadastros/Obra/Obra";
import AnulacaoExtra from "../../pages/Pages_Contabilidade/Extra/AnulacaoExtra/AnulacaoExtra";
import GrpDespesa from "../../pages/Pages_Contabilidade/RestosAPagas/GrpDespesa/GrpDespesa";
import LiquidacaoRp from "../../pages/Pages_Contabilidade/RestosAPagas/LiquidacaoRp/LiquidacaoRp";
import InscricaoRp from "../../pages/Pages_Contabilidade/RestosAPagas/InscricaoRp/InscricaoRp";
import ConsultaCadastroMaster from "../../pages/PagesAcessoInterno/Cadastros/CadastroMaster/ConsultaCadastroMaster";
import TestPcasp from "../../pages/Paginas_Sistemas_Planejamento/PCASP/TestPcasp";
import QDDContabilidade from "../../pages/Pages_Contabilidade/Cadastros/QDDComponents/QDD";
import QDDConsultaContabilidade from "../../pages/Pages_Contabilidade/Cadastros/QDDComponents/QDDConsulta";
import QDRContabilidade from "../../pages/Pages_Contabilidade/Cadastros/QDRComponents/QDR";
import CPFCNPJContabilidade from "../../pages/Pages_Contabilidade/Cadastros/CPFCNPJ/CPFCNPJ";
import FonteDeRecursosContabilidade from "../../pages/Pages_Contabilidade/Cadastros/FonteDeRecursos/FonteDeRecursos";
import NaturezaRendimentoContabilidade from "../../pages/Pages_Contabilidade/Cadastros/NaturezaRendimentos/NaturezaRendimentos";
import ConvenioContabilidade from "../../pages/Pages_Contabilidade/Cadastros/Convenio/Convenio";
import DividaPublicaContabilidade from "../../pages/Pages_Contabilidade/Cadastros/DividaPublica/DividaPublica";
import ConsultaContaAgencia from "../../pages/Pages_Contabilidade/Cadastros/ContaBancaria/ConsultaContaAgencia";
import ListaFontesDeRecursos from "../../pages/Pages_Contabilidade/Cadastros/FonteDeRecursos/ListaFontesDeRecursos";
import ConsultaQDRContabilidade from "../../pages/Pages_Contabilidade/Cadastros/QDRComponents/ConsultaQDR";
import ConsultaReceitaOrcamentaria from "../../pages/Pages_Contabilidade/Orcamentario/GuiaDeReceita/ConsultaReceitaOrcamentaria";
import Licitacao from "../../pages/Pages_Contabilidade/Cadastros/Licitacao/Licitacao";
import ListaLicitacaoConsulta from "../../pages/Pages_Contabilidade/Cadastros/Licitacao/LicitacaoConsulta";
import ConsultaCpfCnpjContabilidade from "../../pages/Pages_Contabilidade/Cadastros/CPFCNPJ/ConsultaCpfCnpjContabilidade";
import { ProdutosAquisicao } from "../../pages/Pages_Contabilidade/Cadastros/ProdutosAquisicao/ProdutosAquisicao";
import ObrasConsulta from "../../pages/Pages_Contabilidade/Cadastros/Obra/ObraConsulta";
import { ConsultaQdrde } from "../../pages/Pages_Contabilidade/Extra/QDRDE/ConsultaQdrde";
import Pagamento from "../../pages/Pages_Contabilidade/Financeiro/Pagamento";
import ConvenioConsulta from "../../pages/Pages_Contabilidade/Cadastros/Convenio/ConvenioConsulta";
import ProdutosAquisicaoConsulta from "../../pages/Pages_Contabilidade/Cadastros/ProdutosAquisicao/ProdutosAquisicaoConsulta";
import ConsultaAnulacaoReceita from "../../pages/Pages_Contabilidade/Receita/AnulacaoDeReceita/ConsultaAnulacaoReceita";

const ContainerPaddingGeral = styled.div`
  padding-left: ${({ sidebarExpandida }) =>
    sidebarExpandida ? "210px" : null};

  transition: padding-left 1s;
`;

function Rotas() {
  const { userAuthData: user } = useContext(AuthContext);
  const { currentClient } = useContext(CurrentClientContext);
  const { sidebarExpandida } = useContext(SidebarExpandidaContext);

  useEffect(() => {
    if (currentClient.clienteId) {
      const userHistory = JSON.parse(localStorage.getItem("userHistory"));
      console.log(userHistory);
      userHistory.clienteId = currentClient.clienteId;
      localStorage.setItem("userHistory", JSON.stringify(userHistory));
    }
  }, [currentClient]);

  const handleGetLocalZoom = () => {
    if (localStorage.getItem("zoomDocument")) {
      const zoomPercent = `${localStorage.getItem("zoomDocument")}%`;
      document.body.style.zoom = zoomPercent;
    }
  };

  const hasPageVisualizarRelatorio = (path) => {
    console.log(path);
    const regex = /^\/planejamento\/visualizar\/[^/]+\/relatorio$/;
    return regex.test(path);
  };

  return (
    <Router>
      <div id="corpo" onLoad={handleGetLocalZoom()}>
        <ContainerPaddingGeral sidebarExpandida={!sidebarExpandida}>
          <Routes>
            <Route
              path="/planejamento/cadastro/leis-e-documentos/"
              element={
                <PrivateRoute>
                  <LeisDocumentos />
                </PrivateRoute>
              }
            />
            <Route
              path="/planejamento/cadastro/utilitarios/"
              element={
                <PrivateRoute>
                  <UtilitariosPlanejamento />
                </PrivateRoute>
              }
            />
            <Route
              path="/planejamento/cadastro/exportar/"
              element={
                <PrivateRoute>
                  <Exportar />
                </PrivateRoute>
              }
            />
            <Route
              path="/planejamento/inicio/"
              element={
                <PrivateRoute>
                  <InicioPlanejamento />
                </PrivateRoute>
              }
            />
            <Route
              path="/acesso-interno/cadastro/cadastros-cpf-cnpj/consulta"
              element={
                <PrivateRoute apenasAcessoMaster={true}>
                  <ConsultaCpfCnpj />
                </PrivateRoute>
              }
            />
            <Route
              path="/acesso-interno/master/consulta"
              element={
                <PrivateRoute apenasAcessoMaster={true}>
                  <ConsultaCadastroMaster />
                </PrivateRoute>
              }
            />
            <Route
              path="/acesso-interno/master/cadastro"
              element={
                <PrivateRoute apenasAcessoMaster={true}>
                  <CadastroMaster />
                </PrivateRoute>
              }
            />
            <Route
              path="/planejamento/pcasp/cadastro"
              element={
                <PrivateRoute>
                  <PCASP />
                </PrivateRoute>
              }
            />
            <Route
              path="/planejamento/pcasp/consulta"
              element={
                <PrivateRoute>
                  <ConsultaPCASP />
                </PrivateRoute>
              }
            />
            <Route
              path="/planejamento/impressos/relatorios"
              element={
                <PrivateRoute>
                  <Relatorios />
                </PrivateRoute>
              }
            />

            <Route
              path="/planejamento/cadastro/eixo-estrategico"
              element={
                <PrivateRoute>
                  <EixoEstrategico />
                </PrivateRoute>
              }
            />
            <Route
              path="/planejamento/cadastro/eixo-estrategico/consulta"
              element={
                <PrivateRoute>
                  <ConsultaEixoEstrategico />
                </PrivateRoute>
              }
            />
            <Route
              path="/planejamento/cadastro/eixo-estrategico/relatorio"
              element={
                <PrivateRoute>
                  <RelatorioEixoEstrategico />
                </PrivateRoute>
              }
            />
            <Route
              path="/planejamento/cadastro/eixo-estrategico/visualizar/:codigo"
              element={
                <PrivateRoute>
                  <EixoEstrategico />
                </PrivateRoute>
              }
            />
            <Route
              path="/planejamento/cadastro/eixo-estrategico/dashboard"
              element={
                <PrivateRoute>
                  <DashboardEixoEstrategico />
                </PrivateRoute>
              }
            />
            <Route
              path="/planejamento/cadastro/programa"
              element={
                <PrivateRoute>
                  <Programa />
                </PrivateRoute>
              }
            />
            <Route
              path="/planejamento/cadastro/programa/visualizar/:id"
              element={
                <PrivateRoute>
                  <Programa />
                </PrivateRoute>
              }
            />
            <Route
              path="/planejamento/cadastro/programa/consulta"
              element={
                <PrivateRoute>
                  <ConsultaPrograma />
                </PrivateRoute>
              }
            />
            <Route
              path="/planejamento/cadastro/programa/relatorio"
              element={
                <PrivateRoute>
                  <RelatorioPrograma />
                </PrivateRoute>
              }
            />
            <Route
              path="/planejamento/cadastro/acao-governamental/consulta"
              element={
                <PrivateRoute>
                  <ConsultaAcaoGovernamental />
                </PrivateRoute>
              }
            />
            <Route
              path="/planejamento/cadastro/acao-governamental/visualizar/:numeroAcao"
              element={
                <PrivateRoute>
                  <AcaoGovernamental />
                </PrivateRoute>
              }
            />
            <Route
              path="/planejamento/cadastro/acao-governamental/replicar/:numeroAcao"
              element={
                <PrivateRoute>
                  <AcaoGovernamental />
                </PrivateRoute>
              }
            />
            <Route
              path="/planejamento/cadastro/acao-governamental"
              element={
                <PrivateRoute>
                  <AcaoGovernamental />
                </PrivateRoute>
              }
            />
            <Route
              path="/planejamento/cadastro/acao-governamental/relatorio"
              element={
                <PrivateRoute>
                  <RelatorioAcaoGovernamental />
                </PrivateRoute>
              }
            />
            <Route
              path="/planejamento/cadastro/objetivos-da-onu"
              element={
                <PrivateRoute>
                  <ObjetivosDoMilenio />
                </PrivateRoute>
              }
            />
            <Route
              path="/planejamento/cadastro/objetivos-da-onu/relatorio"
              element={
                <PrivateRoute>
                  <RelatorioObjetiosMilenio />
                </PrivateRoute>
              }
            />
            <Route
              path="/planejamento/visualizar/objetivos-da-onu/relatorio"
              element={
                <PrivateRoute>
                  <VizualizacaoRelatorioMilenio />
                </PrivateRoute>
              }
            />
            <Route
              path="/planejamento/cadastro/objetivos-da-onu/dashboard"
              element={
                <PrivateRoute>
                  <DashboardObjetivosDoMilenio />
                </PrivateRoute>
              }
            />
            <Route
              path="/planejamento/cadastro/objetivos-da-onu/visualizar/:codigo"
              element={
                <PrivateRoute>
                  <ObjetivosDoMilenio />
                </PrivateRoute>
              }
            />
            <Route
              path="/planejamento/cadastro/diretriz"
              element={
                <PrivateRoute>
                  <CadastroDiretriz />
                </PrivateRoute>
              }
            />
            <Route
              path="/planejamento/cadastro/diretriz/visualizar/:id"
              element={
                <PrivateRoute>
                  <CadastroDiretriz />
                </PrivateRoute>
              }
            />
            <Route
              path="/planejamento/cadastro/diretriz/consulta"
              element={
                <PrivateRoute>
                  <ConsultaDiretriz />
                </PrivateRoute>
              }
            />
            <Route
              path="/planejamento/cadastro/diretriz/relatorio"
              element={
                <PrivateRoute>
                  <RelatorioDiretriz />
                </PrivateRoute>
              }
            />
            <Route
              path="/planejamento/cadastro/objetivos-da-onu/consulta"
              element={
                <PrivateRoute>
                  <ConsultaObjetivosDoMilenio />
                </PrivateRoute>
              }
            />
            <Route
              path="/planejamento/cadastro/unidade-orcamentaria"
              element={
                <PrivateRoute>
                  <UnidadeOrcamentaria />
                </PrivateRoute>
              }
            />

            <Route
              path="/planejamento/cadastro/unidade-orcamentaria/consulta"
              element={
                <PrivateRoute>
                  <ConsultaUnidadeOrcamentaria />
                </PrivateRoute>
              }
            />

            <Route
              path="/planejamento/cadastro/unidade-orcamentaria/visualizar/:codigo"
              element={
                <PrivateRoute>
                  <UnidadeOrcamentaria />
                </PrivateRoute>
              }
            />

            <Route
              path="/planejamento/unidade-orcamentaria/relatorio"
              element={
                <PrivateRoute>
                  <RelatorioUnidadeOrcamentaria />
                </PrivateRoute>
              }
            />
            <Route
              path="/planejamento/cadastro/unidade-orcamentaria/dashboard"
              element={
                <PrivateRoute>
                  <DashboardUnidadeOrcamentaria />
                </PrivateRoute>
              }
            />
            <Route
              path="/planejamento/cadastro/indicadores/consulta"
              element={
                <PrivateRoute>
                  <ConsultaIndicadores />
                </PrivateRoute>
              }
            />
            <Route
              path="/planejamento/cadastro/indicadores/:indicadorId"
              element={
                <PrivateRoute>
                  <Indicadores />
                </PrivateRoute>
              }
            />
            <Route
              path="/planejamento/cadastro/indicadores"
              element={
                <PrivateRoute>
                  <Indicadores />
                </PrivateRoute>
              }
            />
            <Route
              path="/planejamento/cadastro/indicadores/dashboard"
              element={
                <PrivateRoute>
                  <DashboardIndicadores />
                </PrivateRoute>
              }
            />
            <Route
              path="/planejamento/cadastro/indicadores/relatorio"
              element={
                <PrivateRoute>
                  <RelatorioIndicadores />
                </PrivateRoute>
              }
            />
            <Route
              path="/planejamento/cadastro/fonte-de-recursos"
              element={
                <PrivateRoute>
                  <FonteDeRecursos />
                </PrivateRoute>
              }
            />
            <Route
              path="/planejamento/cadastro/fonte-de-recursos/consulta"
              element={
                <PrivateRoute>
                  <ConsultaFonteRecursos />
                </PrivateRoute>
              }
            />
            <Route
              path="/planejamento/cadastro/fonte-de-recursos/visualizar/:codigo"
              element={
                <PrivateRoute>
                  <FonteDeRecursos />
                </PrivateRoute>
              }
            />
            <Route
              path="/planejamento/cadastro/fonte-de-recursos/dashboard"
              element={
                <PrivateRoute>
                  <DashboardFontRec />
                </PrivateRoute>
              }
            />
            <Route
              path="/planejamento/loa/qdd/cadastro"
              element={
                <PrivateRoute>
                  <QDD />
                </PrivateRoute>
              }
            />
            <Route
              path="/planejamento/loa/qdd/relatorio"
              element={
                <PrivateRoute>
                  <RelatorioQDD />
                </PrivateRoute>
              }
            />

            <Route
              path="/planejamento/loa/qdr/cadastro"
              element={
                <PrivateRoute>
                  <QDRCadastro />
                </PrivateRoute>
              }
            />
            <Route
              path="/planejamento/loa/qdr/replicar/:idReplica"
              element={
                <PrivateRoute>
                  <QDRCadastro />
                </PrivateRoute>
              }
            />

            <Route
              path="/planejamento/loa/qdr/visualizar/:id"
              element={
                <PrivateRoute>
                  <QDRCadastro />
                </PrivateRoute>
              }
            />

            <Route
              path="/planejamento/loa/qdd/visualizar/:numeroFicha"
              element={
                <PrivateRoute>
                  <QDD />
                </PrivateRoute>
              }
            />
            <Route
              path="/planejamento/loa/qdd/replicar/:numeroFicha"
              element={
                <PrivateRoute>
                  <QDD />
                </PrivateRoute>
              }
            />
            <Route
              path="/planejamento/loa/qdd/consulta"
              element={
                <PrivateRoute
                  permissaoNecessaria={{ sistema: "Planejamento", menu: "LOA" }}
                >
                  <ConsultaQDD />
                </PrivateRoute>
              }
            />
            <Route
              path="/planejamento/ppa/receita-estimada/consulta"
              element={
                <PrivateRoute
                  permissaoNecessaria={{ sistema: "Planejamento", menu: "PPA" }}
                >
                  <ConsultaReceitaEstimadaPPA />
                </PrivateRoute>
              }
            />

            <Route
              path={":prefixPath/usuario/meu-perfil"}
              element={
                <PrivateRoute>
                  <PerfilDoUsuario />
                </PrivateRoute>
              }
            />

            {/* //------------------------------------------------ */}
            {/* Pag Usuario No Sistema Gerenciador */}
            <Route
              path="/acesso-interno/usuario/cadastro"
              element={
                <PrivateRoute>
                  <PagCadastroDeUsuario moduloDoSistema={"Gerenciador"} />
                </PrivateRoute>
              }
            />

            <Route
              path="/acesso-interno/usuario/visualizar-usuario/:id"
              element={
                <PrivateRoute>
                  <PagCadastroDeUsuario moduloDoSistema={"Gerenciador"} />
                </PrivateRoute>
              }
            />

            {/* Pag Usuario no Sistema Planejamento */}

            <Route
              path="/planejamento/usuario/cadastro"
              element={
                <PrivateRoute>
                  <PagCadastroDeUsuario moduloDoSistema={"Planejamento"} />
                </PrivateRoute>
              }
            />

            <Route
              path="/planejamento/usuario/visualizar-usuario/:id"
              element={
                <PrivateRoute>
                  <PagCadastroDeUsuario moduloDoSistema={"Planejamento"} />
                </PrivateRoute>
              }
            />

            {/* //------------------------------------------------ */}
            <Route
              path="/acesso-interno/clientes/cadastro"
              element={
                <PrivateRoute>
                  <ClientesCadastro sistema={"Gerenciador"} />
                </PrivateRoute>
              }
            />
            <Route
              path="/planejamento/clientes/cadastro"
              element={
                <PrivateRoute>
                  <ClientesCadastro sistema={"Planejamento"} />
                </PrivateRoute>
              }
            />
            <Route
              path="/acesso-interno/clientes/visualizar/:id"
              element={
                <PrivateRoute>
                  <ClientesCadastro sistema={"Gerenciador"} />
                </PrivateRoute>
              }
            />
            <Route
              path="/planejamento/ppa/receita-estimada/cadastro"
              element={
                <PrivateRoute>
                  <ReceitaEstimadaPPA />
                </PrivateRoute>
              }
            />

            <Route
              path="/planejamento/cadastro/tipo-de-programa"
              element={
                <PrivateRoute>
                  <TipoDePrograma />
                </PrivateRoute>
              }
            />
            <Route
              path="/planejamento/cadastro/tipo-de-programa/visualizar/:codigo"
              element={
                <PrivateRoute>
                  <TipoDePrograma />
                </PrivateRoute>
              }
            />
            <Route
              path="/planejamento/cadastro/tipo-de-programa/consulta"
              element={
                <PrivateRoute>
                  <ConsultaTipoDePrograma />
                </PrivateRoute>
              }
            />
            <Route
              path="/planejamento/cadastro/tipo-de-programa/dashboard"
              element={
                <PrivateRoute>
                  <DashboardTipoPrograma />
                </PrivateRoute>
              }
            />
            <Route
              path="/planejamento/cadastro/tipo-de-programa/relatorio"
              element={
                <PrivateRoute>
                  <RelatorioTipoDePrograma />
                </PrivateRoute>
              }
            />
            <Route
              path="/planejamento/cadastro/cadastros-cpf-cnpj/consulta"
              element={
                <PrivateRoute>
                  <ConsultaCpfCnpjPlanejamento />
                </PrivateRoute>
              }
            />
            <Route
              path="/planejamento/cadastro/cadastros-cpf-cnpj/relatorio"
              element={
                <PrivateRoute>
                  <RelatorioCpfCnpj />
                </PrivateRoute>
              }
            />
            <Route
              path="/planejamento/cadastro/funcao-subfuncao/consulta"
              element={
                <PrivateRoute>
                  <ConsultaFuncaoSubfuncaoPlanejamento />
                </PrivateRoute>
              }
            />
            <Route
              path="/planejamento/cadastro/funcao-subfuncao/visualizar/:valor"
              element={
                <PrivateRoute>
                  <VizualizarFuncaoSubfuncao />
                </PrivateRoute>
              }
            />
            <Route
              path="/planejamento/cadastro/funcao-subfuncao/dashboard"
              element={
                <PrivateRoute>
                  <DashboardFuncaoSubfuncao />
                </PrivateRoute>
              }
            />
            <Route
              path="/planejamento/cadastro/funcao-subfuncao/relatorio"
              element={
                <PrivateRoute>
                  <RelatorioFuncaoSubFuncao />
                </PrivateRoute>
              }
            />
            <Route
              path="/planejamento/cadastro/parametro-indices"
              element={
                <PrivateRoute>
                  <ParametroIndices />
                </PrivateRoute>
              }
            />
            <Route
              path="/planejamento/cadastro/parametro-indices/:pag"
              element={
                <PrivateRoute>
                  <ParametroIndices />
                </PrivateRoute>
              }
            />
            <Route
              path="/planejamento/cadastro/parametro-indices/consulta"
              element={
                <PrivateRoute>
                  <ConsultaParametroIndices />
                </PrivateRoute>
              }
            />
            <Route
              path="/planejamento/cadastro/parametro-indices/relatorio"
              element={
                <PrivateRoute>
                  <RelatorioParametroIndice />
                </PrivateRoute>
              }
            />
            <Route
              path="/planejamento/cadastro/cadastros-cpf-cnpj"
              element={
                <PrivateRoute>
                  <CadastroCpfCnpjPlanejamento />
                </PrivateRoute>
              }
            />
            <Route
              path="/planejamento/cadastro/cadastros-cpf-cnpj/visualizar/:valor"
              element={
                <PrivateRoute>
                  <CadastroCpfCnpjPlanejamento />
                </PrivateRoute>
              }
            />
            <Route
              path="/planejamento/loa/qdr/consulta"
              element={
                <PrivateRoute
                  permissaoNecessaria={{ sistema: "Planejamento", menu: "LOA" }}
                >
                  <ConsultaQDR />
                </PrivateRoute>
              }
            />
            <Route
              path="/planejamento/cadastro/natureza-da-despesa"
              element={
                <PrivateRoute>
                  <NaturezaDaDespesa />
                </PrivateRoute>
              }
            />
            <Route
              path="/planejamento/cadastro/natureza-da-despesa/visualizar/:codigo"
              element={
                <PrivateRoute>
                  <NaturezaDaDespesa />
                </PrivateRoute>
              }
            />
            <Route
              path="/planejamento/cadastro/natureza-da-despesa/consulta"
              element={
                <PrivateRoute>
                  <ConsultaDespesa />
                </PrivateRoute>
              }
            />
            <Route
              path="/planejamento/cadastro/natureza-da-despesa/dashboard"
              element={
                <PrivateRoute>
                  <DashboardNatDesp />
                </PrivateRoute>
              }
            />
            <Route
              path="/planejamento/cadastro/natureza-da-receita"
              element={
                <PrivateRoute>
                  <NaturezaDaReceita />
                </PrivateRoute>
              }
            />
            <Route
              path="/planejamento/cadastro/natureza-da-receita/consulta"
              element={
                <PrivateRoute>
                  <ConsultaReceita />
                </PrivateRoute>
              }
            />
            <Route
              path="/planejamento/cadastro/natureza-da-receita/visualizar/:codigo"
              element={
                <PrivateRoute>
                  <NaturezaDaReceita />
                </PrivateRoute>
              }
            />
            <Route
              path="/planejamento/cadastro/natureza-da-receita/dashboard"
              element={
                <PrivateRoute>
                  <DashboardNatRec />
                </PrivateRoute>
              }
            />
            <Route
              path="/planejamento/ldo/riscos-fiscais"
              element={
                <PrivateRoute
                  permissaoNecessaria={{ sistema: "Planejamento", menu: "LDO" }}
                >
                  <RiscosFiscais />
                </PrivateRoute>
              }
            />

            <Route
              path="/planejamento/loa-qdr-relatorio"
              element={
                <PrivateRoute>
                  <RelatorioQDR />
                </PrivateRoute>
              }
            />

            <Route
              path="/planejamento/cadastro/fonte-de-recurso/relatorio"
              element={
                <PrivateRoute>
                  <RelatorioFonteDeRecurso />
                </PrivateRoute>
              }
            />
            <Route
              path="/planejamento/cadastro/natureza-da-despesa/relatorio"
              element={
                <PrivateRoute>
                  <RelatorioNatDesp />
                </PrivateRoute>
              }
            />
            <Route
              path="/planejamento/cadastro/natureza-da-receita/relatorio"
              element={
                <PrivateRoute>
                  <RelatorioNatRec />
                </PrivateRoute>
              }
            />
            <Route
              path="/planejamento/tarefas-prazos-hoje"
              element={
                <PrivateRoute>
                  <TarefasPrazosHoje />
                </PrivateRoute>
              }
            />
            <Route
              path="/planejamento/tarefas-prazos-mensal"
              element={
                <PrivateRoute>
                  <TarefasPrazosMensal />
                </PrivateRoute>
              }
            />

            <Route
              path="/planejamento/ldo/metas-fiscais"
              element={
                <PrivateRoute>
                  <MetasFiscais />
                </PrivateRoute>
              }
            />

            <Route
              path="/acesso-interno/cadastro/cadastros-cpf-cnpj"
              element={
                <PrivateRoute>
                  <CadastrosCpfCnpj />
                </PrivateRoute>
              }
            />

            <Route
              path="/acesso-interno/cadastro/cadastros-cpf-cnpj/:valor"
              element={
                <PrivateRoute>
                  <CadastrosCpfCnpj />
                </PrivateRoute>
              }
            />

            <Route
              path="/acesso-interno/cadastro/funcao-subfuncao"
              element={
                <PrivateRoute>
                  <FuncaoSubfuncao />
                </PrivateRoute>
              }
            />

            {/* Rota para a consulta da funcao e subfuncao */}
            <Route
              path="/acesso-interno/cadastro/funcao-subfuncao/consulta"
              element={
                <PrivateRoute>
                  <ConsultaFuncaoSubfuncao />
                </PrivateRoute>
              }
            />
            {/* Rota para atualizacao de funcao subfuncao ja existente */}
            <Route
              path="/acesso-interno/cadastro/funcao-subfuncao/visualizar/:valor/:id"
              element={
                <PrivateRoute>
                  <FuncaoSubfuncao />
                </PrivateRoute>
              }
            />

            <Route
              path="/acesso-interno/usuarios/consulta"
              element={
                <PrivateRoute>
                  <ConsultaUsuarios />
                </PrivateRoute>
              }
            />

            <Route
              path="/acesso-interno/gerenciador-clientes/consulta"
              element={
                <PrivateRoute>
                  <ConsultaCliente />
                </PrivateRoute>
              }
            />

            <Route
              path="/acesso-interno/gerenciador-financeiro"
              element={
                <PrivateRoute>
                  <GerenciadorFinanceiro />
                </PrivateRoute>
              }
            />

            <Route
              path="/acesso-interno/usuarios"
              element={
                <PrivateRoute>
                  <UsuariosPage />
                </PrivateRoute>
              }
            />
            <Route
              path="/acesso-interno/dashboard"
              element={
                <PrivateRoute>
                  <Dashboard />
                </PrivateRoute>
              }
            />
            <Route
              path="/home-test"
              element={
                <PrivateRoute>
                  <HomeTest />
                </PrivateRoute>
              }
            />
            <Route path="/" exact element={<Login />} />

            {/* rotas de contabilidade */}
            {/* <Route
              path="/contabilidade/receita/guia-de-receita"
              element={
                <PrivateRoute>
                  <GuiaDeReceita />
                </PrivateRoute>
              }
            /> */}

            <Route
              path="/contabilidade/financeiro/conta-bancaria/consulta"
              element={
                <PrivateRoute>
                  <ConsultaContaAgencia />
                </PrivateRoute>
              }
            />
            <Route
              path="/contabilidade/financeiro/agencias/consulta"
              element={
                <PrivateRoute>
                  <ConsultaContaAgencia />
                </PrivateRoute>
              }
            />
            <Route
              path="/contabilidade/financeiro/conta-bancaria/cadastro"
              element={
                <PrivateRoute>
                  <ContaBancariaPag path="conta-bancaria" />
                </PrivateRoute>
              }
            />
            <Route
              path="/contabilidade/financeiro/agencia/cadastro"
              element={
                <PrivateRoute>
                  <ContaBancariaPag path="agencia" />
                </PrivateRoute>
              }
            />
            <Route
              path="/contabilidade/financeiro/conta-bancaria/visualizar/:contaBancariaId"
              element={
                <PrivateRoute>
                  <ContaBancariaPag path="conta-bancaria" />
                </PrivateRoute>
              }
            />
            <Route
              path="/contabilidade/financeiro/agencia/visualizar/:agenciaId"
              element={
                <PrivateRoute>
                  <ContaBancariaPag path="agencia" />
                </PrivateRoute>
              }
            />
            <Route
              path="/contabilidade/restos-a-pagar/inscricao-rp"
              element={
                <PrivateRoute>
                  <InscricaoRp />
                </PrivateRoute>
              }
            />

            <Route
              path="/contabilidade/cadastros/eva"
              element={
                <PrivateRoute>
                  <EVACadastro />
                </PrivateRoute>
              }
            />
            <Route
              path="/contabilidade/cadastros/evb"
              element={
                <PrivateRoute>
                  <EVBCadastro />
                </PrivateRoute>
              }
            />
            <Route
              path="/contabilidade/receita/guia-de-receita"
              element={
                <PrivateRoute>
                  <ReceitaOrcamentaria />
                </PrivateRoute>
              }
            />
            <Route
              path="/contabilidade/receita/guia-de-receita/consulta"
              element={
                <PrivateRoute>
                  <ConsultaReceitaOrcamentaria />
                </PrivateRoute>
              }
            />
            <Route
              path="/contabilidade/receita/guia-de-receita/visualizar/:id"
              element={
                <PrivateRoute>
                  <ReceitaOrcamentaria />
                </PrivateRoute>
              }
            />
            <Route
              path="contabilidade/financeiro/pagamento"
              element={
                <PrivateRoute>
                  <Pagamento />
                </PrivateRoute>
              }
            />
            <Route
              path="/contabilidade/consulta/qdd-despesa"
              element={
                <PrivateRoute>
                  <QDDConsultaContabilidade />
                </PrivateRoute>
              }
            />
            <Route
              path="/contabilidade/cadastros/qdd-despesa"
              element={
                <PrivateRoute>
                  <QDDContabilidade />
                </PrivateRoute>
              }
            />
            <Route
              path="/contabilidade/cadastros/qdd-despesa/visualizar/:id"
              element={
                <PrivateRoute>
                  <QDDContabilidade />
                </PrivateRoute>
              }
            />
            <Route
              path="/contabilidade/cadastros/qdr-receita"
              element={
                <PrivateRoute>
                  <QDRContabilidade />
                </PrivateRoute>
              }
            />
            <Route
              path="/contabilidade/cadastros/qdr-receita/visualizar/:id"
              element={
                <PrivateRoute>
                  <QDRContabilidade />
                </PrivateRoute>
              }
            />
            <Route
              path="/contabilidade/cadastros/qdr-receita/consulta"
              element={
                <PrivateRoute>
                  <ConsultaQDRContabilidade />
                </PrivateRoute>
              }
            />
            <Route
              path="/contabilidade/cadastros/cpf-cnpj"
              element={
                <PrivateRoute>
                  <CPFCNPJContabilidade />
                </PrivateRoute>
              }
            />
            <Route
              path="/contabilidade/cadastros/cpf-cnpj/visualizar/:valor"
              element={
                <PrivateRoute>
                  <CPFCNPJContabilidade />
                </PrivateRoute>
              }
            />
            <Route
              path="/contabilidade/cadastros/cpf-cnpj/consulta"
              element={
                <PrivateRoute>
                  <ConsultaCpfCnpjContabilidade />
                </PrivateRoute>
              }
            />
            <Route
              path="/contabilidade/cadastros/fonte-de-recursos/consulta"
              element={
                <PrivateRoute>
                  <ListaFontesDeRecursos />
                </PrivateRoute>
              }
            />
            <Route
              path="/contabilidade/cadastros/licitacao"
              element={
                <PrivateRoute>
                  <Licitacao />
                </PrivateRoute>
              }
            />
            <Route
              path="/contabilidade/cadastros/licitacao/consulta"
              element={
                <PrivateRoute>
                  <ListaLicitacaoConsulta />
                </PrivateRoute>
              }
            />
            <Route
              path="/contabilidade/:acao/licitacao/:numero"
              element={
                <PrivateRoute>
                  <Licitacao />
                </PrivateRoute>
              }
            />
            <Route
              path="/contabilidade/cadastros/obras/consulta"
              element={
                <PrivateRoute>
                  <ObrasConsulta />
                </PrivateRoute>
              }
            />
            <Route
              path="/contabilidade/cadastros/fonte-de-recursos"
              element={
                <PrivateRoute>
                  <FonteDeRecursosContabilidade />
                </PrivateRoute>
              }
            />
            <Route
              path="/contabilidade/cadastros/natureza-de-rendimentos"
              element={
                <PrivateRoute>
                  <NaturezaRendimentoContabilidade />
                </PrivateRoute>
              }
            />
            <Route
              path="/contabilidade/cadastros/convenio"
              element={
                <PrivateRoute>
                  <ConvenioContabilidade />
                </PrivateRoute>
              }
            />
            <Route
              path="/contabilidade/cadastros/convenio/consulta"
              element={
                <PrivateRoute>
                  <ConvenioConsulta />
                </PrivateRoute>
              }
            />
            <Route
              path="/contabilidade/cadastros/divida-publica"
              element={
                <PrivateRoute>
                  <DividaPublicaContabilidade />
                </PrivateRoute>
              }
            />
            <Route
              path="/contabilidade/despesa/nota-de-empenho"
              element={
                <PrivateRoute>
                  <NotaDeEmpenho />
                </PrivateRoute>
              }
            />
            <Route
              path="/contabilidade/despesa/liquidacao-de-empenho"
              element={
                <PrivateRoute>
                  <LiquidacaoDeEmpenho />
                </PrivateRoute>
              }
            />
            <Route
              path="/contabilidade/despesa/anulacao-da-despesa"
              element={
                <PrivateRoute>
                  <AnulacaoDaDespesa />
                </PrivateRoute>
              }
            />
            <Route
              path="/contabilidade/despesa/atualizacao-orcamentaria"
              element={
                <PrivateRoute>
                  <AtualizacaoOrcamentaria />
                </PrivateRoute>
              }
            />
            <Route
              path="/contabilidade/extra/gre-receita"
              element={
                <PrivateRoute>
                  <GreReceita />
                </PrivateRoute>
              }
            />
            <Route
              path="/contabilidade/extra/gde-despesa"
              element={
                <PrivateRoute>
                  <GdeDespesa />
                </PrivateRoute>
              }
            />
            <Route
              path="/contabilidade/extra/qdrde/cadastro"
              element={
                <PrivateRoute>
                  <QDRDE />
                </PrivateRoute>
              }
            />
            <Route
              path="/contabilidade/extra/qdrde/consulta"
              element={
                <PrivateRoute>
                  <ConsultaQdrde />
                </PrivateRoute>
              }
            />
            <Route
              path="/contabilidade/extra/qdrde/visualizar/:id"
              element={
                <PrivateRoute>
                  <QDRDE />
                </PrivateRoute>
              }
            />
            <Route
              path="/contabilidade/cadastros/desconto-na-fonte"
              element={
                <PrivateRoute>
                  <DescontoNaFonte />
                </PrivateRoute>
              }
            />
            <Route
              path="/contabilidade/cadastros/obra"
              element={
                <PrivateRoute>
                  <Obra />
                </PrivateRoute>
              }
            />
            <Route
              path="/contabilidade/:acao/obra/:obraId"
              element={
                <PrivateRoute>
                  <Obra />
                </PrivateRoute>
              }
            />
            <Route
              path="/contabilidade/cadastros/produtos-aquisicao"
              element={
                <PrivateRoute>
                  <ProdutosAquisicao />
                </PrivateRoute>
              }
            />
            <Route
              path="/contabilidade/cadastros/produtos-aquisicao/consulta"
              element={
                <PrivateRoute>
                  <ProdutosAquisicaoConsulta />
                </PrivateRoute>
              }
            />
            <Route
              path="/contabilidade/editar/produtos-aquisicao/:numeroPR"
              element={
                <PrivateRoute>
                  <ProdutosAquisicao />
                </PrivateRoute>
              }
            />
            <Route
              path="/contabilidade/extra/anulacao-extra"
              element={
                <PrivateRoute>
                  <AnulacaoExtra />
                </PrivateRoute>
              }
            />
            <Route
              path="/contabilidade/restos-a-pagar/grp-despesa"
              element={
                <PrivateRoute>
                  <GrpDespesa />
                </PrivateRoute>
              }
            />
            <Route
              path="/contabilidade/restos-a-pagar/liquidacao-rp"
              element={
                <PrivateRoute>
                  <LiquidacaoRp />
                </PrivateRoute>
              }
            />
            <Route
              path="/contabilidade/receita/anulacao-de-receita/cadastro"
              element={
                <PrivateRoute>
                  <AnulacaoDeReceita />
                </PrivateRoute>
              }
            />
            <Route
              path="/contabilidade/receita/anulacao-de-receita/consulta"
              element={
                <PrivateRoute>
                  <ConsultaAnulacaoReceita />
                </PrivateRoute>
              }
            />
             <Route
              path="/contabilidade/receita/anulacao-de-receita/visualizar/:id"
              element={
                <PrivateRoute>
                  <AnulacaoDeReceita />
                </PrivateRoute>
              }
            />
            <Route
              path="/contabilidade/cadastro/utilitarios/"
              element={
                <PrivateRoute>
                  <UtilitariosContabilidade />
                </PrivateRoute>
              }
            />

            {/* Geracao de senha */}

            <Route
              path="/recuperacao-de-senha"
              element={<PagRecuperacaoDeSenha />}
            />

            {/* Rotas para testes de componentes */}
            <Route
              path="/planejamento/teste-de-componente"
              element={
                <PrivateRoute>
                  <TestPcasp />
                </PrivateRoute>
              }
            />
          </Routes>
        </ContainerPaddingGeral>
      </div>
      {window.location.pathname !== "" &&
        !hasPageVisualizarRelatorio(window.location.pathname) &&
        Object.keys(user).length !== 0 && <Estrutura />}
    </Router>
  );
}

export default Rotas;
