import React, { useState, useEffect, useContext } from "react";
import { Container, Row, Col, Table } from "react-bootstrap";
import { Icon } from "@iconify/react";
import outlineSearch from "@iconify/icons-ic/outline-search";
import Modal from "react-modal";
import {
  BodyTable,
  FilterContainer,
  FilterLabel,
  FilterSelect,
  PassadorPage,
  TituloTable,
  TituloTableAction,
} from "../../../../components/TableConsulta/TableConsulta";
import {
  ModalStyled,
  ButtonsDiv,
  DivIconModal,
  IconAlertModal,
} from "../../../../styles/StyledComponents/ModalStyled";
import Pagination from "../../../../components/PaginationComponent/Pagination";
import InputSvg from "../../../../components/Grid/InputSvg";
import HeaderOptions from "../../../../components/HeaderOptions/HeaderOptions";
import { ToastContainer, toast } from "react-toastify";
import ButtonsAcoesListas from "../../../../styles/StyledComponents/ButtonsAcoesListas";
import { useFetchData } from "../../../../hooks/useFetchData";
import { CurrentClientContext } from "../../../../contexts/CurrentClientContext";
import api from "../../../../utils/api";

function ConsultaCpfCnpjContabilidade() {
  Modal.setAppElement("#root");

  const { currentClient } = useContext(CurrentClientContext);
  const [dadoDeletado, setDadoDeletado] = useState({ boolean: true });
  const [modalOpened, setModalOpened] = useState(false);
  const [itens, setItens] = useState([]);
  const [itensPorPagina, setItensPorPagina] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [itensFiltrados, setItensFiltrados] = useState([]);
  const [loadingLista, setLoadingLista] = useState(true);
  const [dataDelete, setDataDelete] = useState({});
  const { data: dataListaCpf } = useFetchData(
    `/cpf/por-cliente/${currentClient.clienteId}`,
    dadoDeletado.boolean
  );

  useEffect(() => {
    if (dataListaCpf) {
      setItens(dataListaCpf);
      console.log(dataListaCpf);
      setLoadingLista(false);
    }
  }, [dataListaCpf]);

  const handleSearch = (event) => {
    const { value } = event.target;
    setSearchTerm(value);
    const filteredItems = itens.filter((item) => {
      return (
        item.nomeCompleto?.toLowerCase().includes(value.toLowerCase()) ||
        item.nomeFantasia?.toLowerCase().includes(value.toLowerCase()) ||
        item.cpf?.includes(value)
      );
    });
    setItensFiltrados(filteredItems);
    setCurrentPage(0);
  };

  const deleteCpfCnpj = () => {
    const { value, id } = dataDelete;

    api
      .delete(value.length <= 11 ? `cpf/${id}` : `cnpj/${id}`)
      .then(() => {
        toast.success("Cadastro deletado com sucesso");
        setDadoDeletado({ boolean: true });
      })
      .catch((error) => {
        setDadoDeletado({ boolean: false });
        if (error.response.status === 400) {
          toast.error(error.response.data.message);
        } else {
          toast.error("Algo deu errado. Por favor, tente mais tarde.");
        }

        console.log(error);
      });
  };

  const startIndex = currentPage * itensPorPagina;
  const endIndex = startIndex + itensPorPagina;
  const currentItens = itens.slice(startIndex, endIndex);
  const currentFilteredItens = itensFiltrados.slice(startIndex, endIndex);

  const pages = Math.ceil(itens.length / itensPorPagina);
  const filteredPages = Math.ceil(itensFiltrados.length / itensPorPagina);

  function formatCpf(cpf) {
    const cpfFormatado =
      cpf.slice(0, 3) +
      "." +
      cpf.slice(3, 6) +
      "." +
      cpf.slice(6, 9) +
      "-" +
      cpf.slice(9);

    return cpfFormatado;
  }

  function openModal() {
    setModalOpened(true);
  }

  function closeModal() {
    setModalOpened(false);
  }

  const confirmDeleteData = (value, id) => {
    setDataDelete({ value: value, id: id });
    openModal();
  };

  return (
    <Container
      id="pags_responsividade_padding_geral"
      fluid
      style={{ color: "#515C70" }}
      className="container_conteudo conteudo_pagina"
    >
      <div>
        <ModalStyled
          isOpen={modalOpened}
          onRequestClose={closeModal}
          overlayClassName="modal-overlay"
          contentLabel="Example Modal"
        >
          <DivIconModal>
            <IconAlertModal
              width={100}
              icon="fluent:error-circle-20-regular"
              color="red"
              hexcolor="#ff0000"
            />
            <Icon />
          </DivIconModal>

          <h6>Tem certeza que deseja deletar permanentemente esses dados?</h6>
          <p>Continuar e deletar os dados</p>
          <ButtonsDiv>
            <button
              className="sim_button"
              onClick={() => {
                deleteCpfCnpj();
                closeModal();
              }}
            >
              Sim
            </button>
            <button
              className="nao_button"
              onClick={() => {
                closeModal();
                setDataDelete({});
              }}
            >
              Não
            </button>
          </ButtonsDiv>
        </ModalStyled>
      </div>
      <ToastContainer />
      <HeaderOptions
        withRow={true}
        TituloPagina={"CPF / CNPJ"}
        SubTituloPagina={"Consulta"}
        ToPag={"/contabilidade/cadastros/cpf-cnpj"}
        PaginaRelatorio={"/planejamento/cadastro/cadastros-cpf-cnpj/relatorio"}
      />
      <Row className="row_form mt-1">
        <Col md={8}>
          <FilterContainer>
            <FilterLabel>Itens por página:</FilterLabel>
            <FilterSelect
              value={itensPorPagina}
              onChange={(event) =>
                setItensPorPagina(Number(event.target.value))
              }
            >
              <option value={10}>10</option>
              <option value={15}>15</option>
              <option value={20}>20</option>
            </FilterSelect>
          </FilterContainer>
        </Col>
        <Col md={4}>
          <InputSvg
            icon={outlineSearch}
            height={"23px"}
            color={"#6C7585"}
            placeholder={"pesquise"}
            onChange={handleSearch}
          />
        </Col>
      </Row>
      <Row className="row_form mt-1">
        {loadingLista ? (
          <Col
            md={12}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Icon height={50} icon="line-md:loading-loop" color="#515c70" />
          </Col>
        ) : null}
        <Table
          responsive
          style={loadingLista ? { display: "none" } : { marginTop: "-10px" }}
        >
          <thead>
            <tr>
              <TituloTable>Código</TituloTable>
              <TituloTable>Nome</TituloTable>
              <TituloTable>CPF/CNPJ</TituloTable>
              <TituloTable>Endereço</TituloTable>
              <TituloTableAction>Ação</TituloTableAction>
            </tr>
          </thead>
          <BodyTable>
            {searchTerm
              ? currentFilteredItens.map((item, index) => (
                  <tr key={index}>
                    <td style={{ whiteSpace: "nowrap" }}>{item.pessoaId}</td>
                    <td style={{ whiteSpace: "nowrap" }}>
                      {item.nomeCompleto}
                    </td>
                    <td style={{ whiteSpace: "nowrap" }}>
                      {formatCpf(item.cpf)}
                    </td>
                    <td
                      title={`${item.Endereco.municipio} - ${item.Endereco.estado} - ${item.Endereco.logradouro}`}
                      style={{ whiteSpace: "nowrap" }}
                    >
                      {`${item.Endereco.municipio} - ${item.Endereco.estado} - ${item.Endereco.logradouro}`
                        .length > 35
                        ? `${item.Endereco.municipio} - ${item.Endereco.estado} - ${item.Endereco.logradouro}`.substring(
                            0,
                            35
                          ) + "..."
                        : `${item.Endereco.municipio} - ${item.Endereco.estado} - ${item.Endereco.logradouro}`}
                    </td>
                    <td>
                      <ButtonsAcoesListas
                        //Verificacao para saber se ira ser enviado para a funcao de delete um cpf ou um cnpj
                        FuncaoIconDelete={() =>
                          confirmDeleteData(item.cpf, item.pessoaId)
                        }
                        CaminhoPagUpdate={`/contabilidade/cadastros/cpf-cnpj/visualizar/${
                          item.cpf || item.cnpj
                        }`}
                        CadastroValor={item.cpf}
                      />
                    </td>
                  </tr>
                ))
              : currentItens.map((item, index) => (
                  <tr key={index}>
                    <td style={{ whiteSpace: "nowrap" }}>{item.pessoaId}</td>
                    <td style={{ whiteSpace: "nowrap" }}>
                      {item.nomeCompleto}
                    </td>
                    <td style={{ whiteSpace: "nowrap" }}>
                      {formatCpf(item.cpf)}
                    </td>
                    <td
                      title={`${item.Endereco.municipio} - ${item.Endereco.estado} - ${item.Endereco.logradouro}`}
                      style={{ whiteSpace: "nowrap" }}
                    >
                      {`${item.Endereco.municipio} - ${item.Endereco.estado} - ${item.Endereco.logradouro}`
                        .length > 35
                        ? `${item.Endereco.municipio} - ${item.Endereco.estado} - ${item.Endereco.logradouro}`.substring(
                            0,
                            35
                          ) + "..."
                        : `${item.Endereco.municipio} - ${item.Endereco.estado} - ${item.Endereco.logradouro}`}
                    </td>
                    <td>
                      <ButtonsAcoesListas
                        //Verificacao para saber se ira ser enviado para a funcao de delete um cpf ou um cnpj
                        FuncaoIconDelete={() =>
                          confirmDeleteData(item.cpf, item.pessoaId)
                        }
                        CaminhoPagUpdate={`/contabilidade/cadastros/cpf-cnpj/visualizar/${
                          item.cpf || item.cnpj
                        }`}
                        CadastroValor={item.cpf}
                      />
                    </td>
                  </tr>
                ))}
          </BodyTable>
        </Table>
      </Row>
      <Row
        className="row_form mt-1"
        style={{
          borderBottomLeftRadius: "5px",
          borderBottomRightRadius: "5px",
        }}
      >
        <Col md={4}>
          <span>
            Mostrando de {startIndex + 1} a {endIndex} de{" "}
            {searchTerm ? itensFiltrados.length : itens.length} registros
          </span>
        </Col>
        <Col md={8}>
          <PassadorPage>
            <span>
              {currentPage + 1} de {searchTerm ? filteredPages : pages} páginas
            </span>
            <Pagination
              pages={searchTerm ? filteredPages : pages}
              itemsPerPage={itensPorPagina}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          </PassadorPage>
        </Col>
      </Row>
      <div style={{ padding: "11px" }}></div>
    </Container>
  );
}

export default ConsultaCpfCnpjContabilidade;
