import { Container } from "react-bootstrap";
import HeaderCadastros from "../../../../components/HeaderCadastros/HeaderCadastros";
import { CCol, RowForm } from "../../../../components/Grid/CCol";
import MyAutoComplete from "../../../../components/Inputs/MyAutoComplete";
import FloatingLabelInput from "../../../../components/Grid/FloatingLabelInput";
import InputData from "../../../../components/Grid/InputData";
import InputFormatRealBrasileiro from "../../../../components/Grid/InputFormatRealBrasileiro";
import { RowInfosAcao } from "../../../../components/RowInfosAcao/RowInfosAcao";
import RowSelecaoAbasInferior from "../../../../components/Grid/RowSelecaoAbasInferior";
import { useState } from "react";
import { MotivosAba } from "./MotivosAba";
import SaldosAba from "./SaldosAba";
import { TestButton } from "../../../../utils/TestButton";

const AnulacaoDaDespesa = () => {
  const [abaSelecionada, setAbaSelecionada] = useState({
    nomeAba: "Motivo",
    numeroAba: 0,
  });

  const [values, setValues] = useState({
    tipoAnulacao: null,
    data: "",
    liquidacao: {},
    anulacao: "",
    valor: "",
  });

  return (
    <Container
      id={"pags_responsividade_padding_geral"}
      fluid
      className={"container_conteudo conteudo_pagina"}
    >
      <TestButton onClick={() => console.log(values)}>Test</TestButton>
      <HeaderCadastros
        NomePaginaTitulo={"Anulação da Despesa"}
        PaginaSubtitulo={"Cadastro"}
      />

      <RowForm className="mt-1">
        <CCol>
          <FloatingLabelInput
            tipo="input_select"
            new
            options={[
              { label: "Tipo de Anulação", value: null },
              { label: "Anulação", value: "1" },
              { label: "Restituição", value: "2" },
            ]}
            placeholder="Tipo de Anulação - Liquidação - Anulação"
            value={values.tipoAnulacao}
            onSelect={(option) => {
              setValues((prevValues) => ({
                ...prevValues,
                tipoAnulacao: option.value,
              }));
            }}
          />
        </CCol>
        <CCol md={2}>
          <InputData
            value={values.data}
            onInput={(e) => {
              console.log(e.target.value);
              setValues({
                ...values,
                data: e.target.value,
              });
            }}
          />
        </CCol>
      </RowForm>
      <RowForm style={{ marginTop: "-14px" }}>
        <CCol>
          <MyAutoComplete
            options={[{ label: "teste", value: "teste" }]}
            labelFormat={(item) => `${item.label} - ${item.value}`}
            labelInput="Liquidação"
            style={{ height: "37px" }}
          />
        </CCol>

        <CCol md={2}>
          <FloatingLabelInput
            disabled
            label="Anulação"
            placeholder="Anulação"
            value={values.anulacao}
          />
        </CCol>

        <CCol md={2}>
          <InputFormatRealBrasileiro
            value={values.valor}
            onChange={(e) => {
              setValues((prevValues) => ({
                ...prevValues,
                valor: e.target.value,
              }));
            }}
          />
        </CCol>
      </RowForm>

      <RowInfosAcao>
        <CCol md={7}>
          <p>
            Unidade Orçamentária:{" "}
            <span>02.050 - Secretaria Municipal de Educação</span>
          </p>
          <p>
            Programa:{" "}
            <span>
              2025 - Manutenção dos Serviços da Secretaria Municipal de Educação
            </span>
          </p>
          <p>
            Elemento de Despesa:{" "}
            <span>
              3.3.90.39 - OUTROS SERVIÇOS DE TERCEIROS - PESSOA JURÍDICA
            </span>
          </p>
          <p>
            Fonte de Recursos:{" "}
            <span>500 - Recursos não Vinculados de Impostos</span>
          </p>
        </CCol>
        <CCol>
          <p>
            Função: <span>08 - Assistência Social</span>
          </p>
          <p>
            Subfunção: <span>244 - Assistência Comunitária</span>
          </p>
          <p>
            Exercício - CNP: <span>1 - Recursos do Exercício Corrente</span>
          </p>
        </CCol>
      </RowInfosAcao>

      <RowSelecaoAbasInferior
        abas={["Motivo", "Produto e Aquisição", "Saldos"]}
        abaSelecionada={abaSelecionada}
        setAbaSelecionada={setAbaSelecionada}
      />

      {abaSelecionada.nomeAba === "Motivo" && <MotivosAba />}
      {abaSelecionada.nomeAba === "Saldos" && <SaldosAba />}
    </Container>
  );
};

export default AnulacaoDaDespesa;
