import React, { useEffect, useState } from "react";
import { QueryTable } from "../../../../components/QueryTable";
import { Container } from "react-bootstrap";
import { CCol, RowForm } from "../../../../components/Grid/CCol";
import api from "../../../../utils/api";
import { useCurrentYear } from "../../../../hooks/useCurrentYear";
import { useCurrentClient } from "../../../../hooks/useCurrentClient";
import { FormatValueToLocaleString } from "../../../../utils/FormatacaoDeDados/FormatValueToLocaleString";
import IconDelete from "../../../../components/ListaDeInputs/IconDelete";
import IconEdit from "../../../../components/ListaDeInputs/IconEdit";
import HeaderCadastros from "../../../../components/HeaderCadastros/HeaderCadastros";
import { useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react/dist/iconify.js";
import { FormatDateToBR } from "../../../../utils/FormatacaoDeDados/FormatDateToBR";
import { FormatCpfPaste } from "../../../../utils/FormatacaoDeDados/FormtCpfPaste";

const ConsultaReceitaOrcamentaria = () => {
  const [colunas, setColunas] = useState([]);
  const [data, setData] = useState([]);
  const [loadingData, setLoadingData] = useState(true);
  const navigate = useNavigate();
  const { currentYear } = useCurrentYear();
  const { currentClient } = useCurrentClient();

  const fetchData = async () => {
    try {
      setLoadingData(true);
      const resp = await api.get(
        `/guiaDeReceita/${currentClient.clienteId}/${currentYear}`
      );
      const data = resp.data;
      console.log(data);

      const cols = [
        {
          title: "Natureza da Receita",
          key: "naturezaDaReceita",
          values: data.map(
            (item) =>
              `${item.naturezaInfo.nr} - ${item.naturezaInfo.especificacao}`
          ),
        },
        {
          title: "Tipo de Lançamento",
          key: "tipoDeLancamento",
          values: data.map((item) => `${item.tipoDeLancamento}`),
        },
        {
          title: "Data",
          key: "dataCriacao",
          values: data.map((item) =>
            FormatDateToBR(item.dataCriacao.split("T")[0])
          ),
        },
        {
          title: "Devedor",
          key: "devedor",
          values: data.map(
            (item) =>
              `${FormatCpfPaste(item.devedorInfo.cpf)} - ${
                item.devedorInfo.nomeCompleto
              }`
          ),
        },
        {
          title: "Conta Bancária",
          key: "contaBancaria",
          values: data.map(
            (item) =>
              `${item.contaBancariaInfo.numeroConta}-${item.contaBancariaInfo.digito} - ${item.contaBancariaInfo.nome}`
          ),
        },
        {
          title: "Valor",
          key: "valor",
          values: data.map((item) => FormatValueToLocaleString(item.valor)),
        },
        {
          title: "Ações",
          key: "dataActions",
          values: data.map((item) => ({
            dataId: item.id,
            actions: (
              <div
                style={{
                  display: "flex",
                  justifyContent: "start",
                  flexShrink: "0",
                  gap: "2px",
                }}
              >
                <IconEdit
                  height={22}
                  onClick={() =>
                    navigate(
                      `/contabilidade/receita/guia-de-receita/visualizar/${item.id}`
                    )
                  }
                />
                <Icon
                  icon="gg:copy"
                  color="#3E347B"
                  height="22"
                  style={{
                    cursor: "pointer",
                  }}
                />
                <Icon
                  icon="fluent:document-dismiss-16-regular"
                  height="22"
                  style={{ color: "#f00" }}
                />
                <IconDelete height={22} />
              </div>
            ),
          })),
        },

        // {
        //   title: "Fonte de Recursos",
        //   values: data.map(item =>FormatValueToLocaleString(item.valor))
        // }
      ];

      setLoadingData(false);
      setColunas(cols);
      setData(
        data.map((item) => ({
          id: item.id,
          naturezaDaReceita: `${item.naturezaInfo.nr} - ${item.naturezaInfo.especificacao}`,
          tipoDeLancamento: item.tipoDeLancamento,
          dataCriacao: item.dataCriacao,
          devedor: `${item.devedorInfo.cpf} - ${item.devedorInfo.nomeCompleto}`,
          contaBancaria: `${item.contaBancariaInfo.numeroConta}-${item.contaBancariaInfo.digito} - ${item.contaBancariaInfo.nome}`,
          valor: FormatValueToLocaleString(item.valor),
        }))
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();

    //eslint-disable-next-line
  }, []);

  return (
    <div>
      <Container
        id={"pags_responsividade_padding_geral"}
        fluid
        className={"container_conteudo conteudo_pagina"}
      >
        <HeaderCadastros
          NomePaginaTitulo={"Guia de Receita"}
          PaginaSubtitulo={"Consulta"}
          OpcoesDeArquivo
          BotaoSaveAtivo={false}
          RowTitle={"Guia de Receita Orçamentária - GRO"}
          BotaoNovoFunction={() =>
            navigate("/contabilidade/receita/guia-de-receita")
          }
        />
        <RowForm $borderRadiusType={3} className="mt-1" style={{ padding: 0 }}>
          <CCol md={12} style={{ padding: 0 }}>
            <QueryTable
              colunas={colunas}
              data={data}
              loadingData={loadingData}
            />
          </CCol>
        </RowForm>
        <div className="p-3"></div>
      </Container>
    </div>
  );
};

export default ConsultaReceitaOrcamentaria;
