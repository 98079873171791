import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

const ProgressBarContainer = styled.div`
  position: relative;
  background-color: #cad9f4;
  width: 100%;
  height: ${(props) => `${props.height}px` || "35px"};
  display: flex;
  align-items: center;
  border-radius: 4px;
`;

const ProgressLabel = styled.span`
  position: absolute;
  left: 10px;
  font-weight: 600;
  margin-right: 10px;
  z-index: 1;
  color: #f7f7f7;
  text-shadow: -0.5px -0.5px 2px #515c70, 0.5px -0.5px 2px #515c70,
    -0.5px 0.5px 2px #515c70, 0.5px 0.5px 2px #515c70;
`;

const ProgressBar = styled.div`
  background-color: #515c70;
  width: ${(props) => (props.percentage ? `${props.percentage}%` : "0")};
  height: 100%;
  border-radius: 4px;
`;

const PercentageValue = styled.span`
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  font-weight: 600;
  display: flex;
  color: transparent;
  background: linear-gradient(
    to right,
    ${(props) => props.barColor} ${(props) => props.coveredPercentage}%,
    ${(props) => props.textColor} ${(props) => props.coveredPercentage}% 100%
  );
  -webkit-background-clip: text;
  background-clip: text;
`;

export const ProgressBarComponent = ({
  percentage = 0,
  label = "teste",
  height = 35,
  barColor = "#f7f7f7",
  textColor = "#515c70",
}) => {
  const [coveredPercentage, setCoveredPercentage] = useState(0);
  const textRef = useRef(null);
  const barRef = useRef(null);

  useEffect(() => {
    if (textRef.current && barRef.current) {
      const textRect = textRef.current.getBoundingClientRect();
      const barRect = barRef.current.getBoundingClientRect();

      const overlapWidth = Math.max(
        0,
        Math.min(barRect.right, textRect.right) -
          Math.max(barRect.left, textRect.left)
      );

      const covered = (overlapWidth / textRect.width) * 100;
      setCoveredPercentage(covered);
    }
  }, [percentage]);

  return (
    <ProgressBarContainer height={height}>
      <ProgressLabel>{label}</ProgressLabel>
      <ProgressBar ref={barRef} percentage={percentage} />
      <PercentageValue
        ref={textRef}
        coveredPercentage={coveredPercentage}
        barColor={barColor}
        textColor={textColor}
      >
        {percentage}%
      </PercentageValue>
    </ProgressBarContainer>
  );
};
