export const mockedDataEmpenho = {
  label: "2001 - Manutenção das Atividades da Câmara Municipal",
  pill: "Ficha 2",
  acaoGovernamental: {
    id: 11,
    codigo: "2001",
    titulo: "Manutenção das Atividades da Câmara Municipal",
  },
  unidadeOrcamentaria: "01.010 - Câmara Municipal",
  funcao: "01 - Legislativa",
  subfuncao: "031 - Ação Legislativa",
  elementoDaDespesa: {
    codigo: "3.1.90.04.00",
    titulo: "Contratação por Tempo Determinado",
    funcao:
      "Despesas orçamentárias com a contratação de pessoal por tempo determinado para atender à necessidade temporária de excepcional interesse público, de acordo com legislação específica de cada ente da Federação, inclusive obrigações patronais e outras despesas variáveis, quando for o caso.",
  },
  fonteDeRecursos: "500 - Recursos não Vinculados de Impostos",
  fonteDeRecursosInfos: {
    conta: "1.500",
  },
  programa: "2001 - Gestão com igualdade, equidade e justiça social para todos",
  exercicio: "1 - Recursos do Exercício Corrente",
  valor: "1500.00",
  liquidacao: "ExLiq",
};
