export const formatRawDate = (data) => {
  if (!data || typeof data !== "string" || data.length !== 8) {
    return "Data inválida";
  }
  if (data === "00000000") {
    return "Sem Data";
  }
  const dia = data.slice(0, 2);
  const mes = data.slice(2, 4);
  const ano = data.slice(4);
  return `${dia}/${mes}/${ano}`;
};
