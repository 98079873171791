const accountCategories = [
  {
    label: "1.1.5.6.1.00.00 - ALMOXARIFADO - CONSOLIDAÇÃO",
    value: 1,
    code: "1.1.5.6.1.00.00",
    applyBgOption: false,
    subOptions: [
      {
        label: "1.1.5.6.1.01.00 - MATERIAL DE CONSUMO",
        value: 1,
        code: "1.1.5.6.1.01.00",
      },
      {
        label: "1.1.5.6.1.02.00 - GÊNEROS ALIMENTÍCIOS",
        value: 2,
        code: "1.1.5.6.1.02.00",
      },
      {
        label: "1.1.5.6.1.03.00 - MATERIAIS DE CONSTRUÇÃO",
        value: 3,
        code: "1.1.5.6.1.03.00",
      },
      {
        label: "1.1.5.6.1.04.00 - AUTOPEÇAS",
        value: 4,
        code: "1.1.5.6.1.04.00",
      },
      {
        label: "1.1.5.6.1.05.00 - MEDICAMENTOS E MATERIAIS HOSPITALARES",
        value: 5,
        code: "1.1.5.6.1.05.00",
      },
      {
        label: "1.1.5.6.1.06.00 - MATERIAIS GRÁFICOS",
        value: 6,
        code: "1.1.5.6.1.06.00",
      },
      {
        label: "1.1.5.6.1.07.00 - MATERIAL DE EXPEDIENTE",
        value: 7,
        code: "1.1.5.6.1.07.00",
      },
      {
        label: "1.1.5.6.1.08.00 - MATERIAIS A CLASSIFICAR",
        value: 8,
        code: "1.1.5.6.1.08.00",
      },
      {
        label: "1.1.5.6.1.09.00 - ESTOQUE SOBRESSALENTES A ALIENAR",
        value: 9,
        code: "1.1.5.6.1.09.00",
      },
      {
        label: "1.1.5.6.1.99.00 - OUTROS - ALMOXARIFADO",
        value: 10,
        code: "1.1.5.6.1.99.00",
      },
    ],
  },
  {
    label: "3.3.1.1.1.00.00 - CONSUMO DE MATERIAL - CONSOLIDAÇÃO",
    value: 2,
    code: "3.3.1.1.1.00.00",
    applyBgOption: false,
    subOptions: [
      {
        label: "3.3.1.1.1.01.00 - COMBUSTÍVEIS E LUBRIFICANTES AUTOMOTIVOS",
        value: 1,
        code: "3.3.1.1.1.01.00",
      },
      {
        label: "3.3.1.1.1.03.00 - GÁS E OUTROS MATERIAIS ENGARRAFADOS",
        value: 2,
        code: "3.3.1.1.1.03.00",
      },
      {
        label: "3.3.1.1.1.04.00 - EXPLOSIVOS E MUNIÇÕES",
        value: 3,
        code: "3.3.1.1.1.04.00",
      },
      {
        label: "3.3.1.1.1.05.00 - ALIMENTOS PARA ANIMAIS",
        value: 4,
        code: "3.3.1.1.1.05.00",
      },
      {
        label: "3.3.1.1.1.06.00 - GÊNEROS ALIMENTAÇÃO",
        value: 5,
        code: "3.3.1.1.1.06.00",
      },
      {
        label: "3.3.1.1.1.07.00 - ANIMAIS PARA PESQUISA E ABATE",
        value: 6,
        code: "3.3.1.1.1.07.00",
      },
      {
        label: "3.3.1.1.1.08.00 - MATERIAL FARMACOLÓGICO",
        value: 7,
        code: "3.3.1.1.1.08.00",
      },
      {
        label: "3.3.1.1.1.09.00 - MATERIAL ODONTOLÓGICO",
        value: 8,
        code: "3.3.1.1.1.09.00",
      },
      {
        label: "3.3.1.1.1.10.00 - MATERIAL QUÍMICO",
        value: 9,
        code: "3.3.1.1.1.10.00",
      },
      {
        label: "3.3.1.1.1.11.00 - MATERIAL DE COUDELARIA OU DE USO ZOOTÉCNICO",
        value: 10,
        code: "3.3.1.1.1.11.00",
      },
      {
        label: "3.3.1.1.1.13.00 - MATERIAL DE CAÇA E PESCA",
        value: 11,
        code: "3.3.1.1.1.13.00",
      },
      {
        label: "3.3.1.1.1.14.00 - MATERIAL EDUCATIVO E ESPORTIVO",
        value: 12,
        code: "3.3.1.1.1.14.00",
      },
      {
        label: "3.3.1.1.1.15.00 - MATERIAL PARA FESTIVIDADES E HOMENAGENS",
        value: 13,
        code: "3.3.1.1.1.15.00",
      },
      {
        label: "3.3.1.1.1.16.00 - MATERIAL DE EXPEDIENTE",
        value: 14,
        code: "3.3.1.1.1.16.00",
      },
      {
        label: "3.3.1.1.1.17.00 - MATERIAL DE PROCESSAMENTO DE DADOS",
        value: 15,
        code: "3.3.1.1.1.17.00",
      },
      {
        label: "3.3.1.1.1.18.00 - MATERIAIS E MEDICAMENTOS P/USO VETERINÁRIO",
        value: 16,
        code: "3.3.1.1.1.18.00",
      },
      {
        label: "3.3.1.1.1.19.00 - MATERIAL DE ACONDICIONAMENTO E EMBALAGEM",
        value: 17,
        code: "3.3.1.1.1.19.00",
      },
      {
        label: "3.3.1.1.1.20.00 - MATERIAL DE CAMA, MESA E BANHO",
        value: 18,
        code: "3.3.1.1.1.20.00",
      },
      {
        label: "3.3.1.1.1.21.00 - MATERIAL DE COPA E COZINHA",
        value: 19,
        code: "3.3.1.1.1.21.00",
      },
      {
        label:
          "3.3.1.1.1.22.00 - MATERIAL DE LIMPEZA E PRODUTOS DE HIGIENIZAÇÃO",
        value: 20,
        code: "3.3.1.1.1.22.00",
      },
      {
        label: "3.3.1.1.1.23.00 - UNIFORMES, TECIDOS E AVIAMENTOS",
        value: 21,
        code: "3.3.1.1.1.23.00",
      },
      {
        label:
          "3.3.1.1.1.24.00 - MATERIAL PARA MANUTENÇÃO DE BENS IMÓVEIS E INSTALAÇÕES",
        value: 22,
        code: "3.3.1.1.1.24.00",
      },
      {
        label: "3.3.1.1.1.25.00 - MATERIAL PARA MANUTENÇÃO DE BENS",
        value: 23,
        code: "3.3.1.1.1.25.00",
      },
      {
        label: "3.3.1.1.1.26.00 - MATERIAL ELÉTRICO E ELETRÔNICO",
        value: 24,
        code: "3.3.1.1.1.26.00",
      },
      {
        label: "3.3.1.1.1.27.00 - MATERIAL DE MANOBRA E PATRULHAMENTO",
        value: 25,
        code: "3.3.1.1.1.27.00",
      },
      {
        label: "3.3.1.1.1.28.00 - MATERIAL DE PROTEÇÃO E SEGURANÇA",
        value: 26,
        code: "3.3.1.1.1.28.00",
      },
      {
        label: "3.3.1.1.1.29.00 - MATERIAL PARA ÁUDIO, VÍDEO E FOTO",
        value: 27,
        code: "3.3.1.1.1.29.00",
      },
      {
        label: "3.3.1.1.1.30.00 - MATERIAL PARA COMUNICAÇÕES",
        value: 28,
        code: "3.3.1.1.1.30.00",
      },
      {
        label: "3.3.1.1.1.31.00 - SEMENTES, MUDAS DE PLANTAS E INSUMOS",
        value: 29,
        code: "3.3.1.1.1.31.00",
      },
      {
        label: "3.3.1.1.1.32.00 - SUPRIMENTO DE AVIAÇÃO",
        value: 30,
        code: "3.3.1.1.1.32.00",
      },
      {
        label: "3.3.1.1.1.33.00 - MATERIAL PARA PRODUÇÃO INDUSTRIAL",
        value: 31,
        code: "3.3.1.1.1.33.00",
      },
      {
        label:
          "3.3.1.1.1.34.00 - SUPRIMENTOS PARA MAQ. E MOTORES NAVIOS E EMBARCAÇÕES",
        value: 32,
        code: "3.3.1.1.1.34.00",
      },
      {
        label: "3.3.1.1.1.35.00 - MATERIAL LABORATORIAL",
        value: 33,
        code: "3.3.1.1.1.35.00",
      },
      {
        label: "3.3.1.1.1.36.00 - MATERIAL HOSPITALAR",
        value: 34,
        code: "3.3.1.1.1.36.00",
      },
      {
        label: "3.3.1.1.1.37.00 - SUPRIMENTOS DE ARMAMENTO",
        value: 35,
        code: "3.3.1.1.1.37.00",
      },
      {
        label: "3.3.1.1.1.38.00 - SUPRIMENTO DE PROTEÇÃO AO VOO",
        value: 36,
        code: "3.3.1.1.1.38.00",
      },
      {
        label: "3.3.1.1.1.39.00 - MATERIAL PARA MANUTENÇÃO DE VEÍCULOS",
        value: 37,
        code: "3.3.1.1.1.39.00",
      },
      {
        label: "3.3.1.1.1.40.00 - MATERIAL BIOLÓGICO",
        value: 38,
        code: "3.3.1.1.1.40.00",
      },
      {
        label: "3.3.1.1.1.41.00 - MATERIAL PARA UTILIZAÇÃO EM GRÁFICA",
        value: 39,
        code: "3.3.1.1.1.41.00",
      },
      {
        label: "3.3.1.1.1.42.00 - FERRAMENTAS",
        value: 40,
        code: "3.3.1.1.1.42.00",
      },
      {
        label: "3.3.1.1.1.43.00 - MATERIAL PARA REABILITAÇÃO PROFISSIONAL",
        value: 41,
        code: "3.3.1.1.1.43.00",
      },
      {
        label: "3.3.1.1.1.44.00 - MATERIAL DE SINALIZAÇÃO VISUAL E OUTROS",
        value: 42,
        code: "3.3.1.1.1.44.00",
      },
      {
        label: "3.3.1.1.1.45.00 - MATERIAL TÉCNICO PARA SELEÇÃO E TREINAMENTO",
        value: 43,
        code: "3.3.1.1.1.45.00",
      },
      {
        label: "3.3.1.1.1.46.00 - MATERIAL BIBLIOGRÁFICO",
        value: 44,
        code: "3.3.1.1.1.46.00",
      },
      {
        label: "3.3.1.1.1.48.00 - BENS MÓVEIS NÃO ATIVÁVEIS",
        value: 45,
        code: "3.3.1.1.1.48.00",
      },
      {
        label: "3.3.1.1.1.49.00 - BILHETES DE PASSAGEM",
        value: 46,
        code: "3.3.1.1.1.49.00",
      },
      {
        label: "3.3.1.1.1.50.00 - BANDEIRAS, FLÂMULAS E INSÍGNIAS",
        value: 47,
        code: "3.3.1.1.1.50.00",
      },
      {
        label: "3.3.1.1.1.51.00 - DISCOTECAS E FILMOTECAS NÃO IMOBILIZÁVEL",
        value: 48,
        code: "3.3.1.1.1.51.00",
      },
      {
        label: "3.3.1.1.1.52.00 - MATERIAL DE CARÁTER SECRETO OU RESERVADO",
        value: 49,
        code: "3.3.1.1.1.52.00",
      },
      {
        label: "3.3.1.1.1.53.00 - MATERIAL METEOROLÓGICO",
        value: 50,
        code: "3.3.1.1.1.53.00",
      },
      {
        label: "3.3.1.1.1.54.00 - MATERIAL P/MANUT.CON SERV.DE ESTRADAS E VIAS",
        value: 51,
        code: "3.3.1.1.1.54.00",
      },
      {
        label: "3.3.1.1.1.55.00 - SELOS PARA CONTROLE FISCAL",
        value: 52,
        code: "3.3.1.1.1.55.00",
      },
      {
        label: "3.3.1.1.1.56.00 - MATERIAL DE MARCAÇÃO DA FAUNA SILVESTRE",
        value: 53,
        code: "3.3.1.1.1.56.00",
      },
      {
        label:
          "3.3.1.1.1.57.00 - MATERIAL DE CONSUMO - REPARTIÇÕES NO EXTERIOR",
        value: 54,
        code: "3.3.1.1.1.57.00",
      },
      {
        label:
          "3.3.1.1.1.58.00 - SOBRESSALENTES PARA MÁQUINAS E EQUIP. PARA PRODUÇÃO INDUSTRIAL",
        value: 55,
        code: "3.3.1.1.1.58.00",
      },
      {
        label:
          "3.3.1.1.1.71.00 - MATERIAIS PARA CONSERVAÇÃO E MANUTENÇÃO DE BENS DE USO COMUM DO POVO",
        value: 56,
        code: "3.3.1.1.1.71.00",
      },
      {
        label:
          "3.3.1.1.1.98.00 - SENTENÇAS JUDICIAIS DE FORNECEDORES DE MATERIAIS",
        value: 57,
        code: "3.3.1.1.1.98.00",
      },
      {
        label: "3.3.1.1.1.99.00 - OUTROS MATERIAIS DE CONSUMO",
        value: 58,
        code: "3.3.1.1.1.99.00",
      },
    ],
  },

  {
    label: "1.2.3.1.1.00.00 - BENS MOVEIS - CONSOLIDAÇÃO",
    value: 3,
    code: "1.2.3.1.1.00.00",
    applyBgOption: true,
    subOptions: [
      {
        label: "1.2.3.1.1.01.01 - APARELHOS DE MEDIÇÃO E ORIENTAÇÃO",
        value: 1,
        code: "1.2.3.1.1.01.01",
      },
      {
        label: "1.2.3.1.1.01.02 - APARELHOS E EQUIPAMENTOS DE COMUNICAÇÃO",
        value: 2,
        code: "1.2.3.1.1.01.02",
      },
      {
        label:
          "1.2.3.1.1.01.03 - APARELHOS, EQUIPAMENTOS E UTENSÍLIOS MÉDICOS, ODONTOLÓGICOS, LABORATORIAIS E HOSPITALARES",
        value: 3,
        code: "1.2.3.1.1.01.03",
      },
      {
        label:
          "1.2.3.1.1.01.04 - APARELHOS E EQUIPAMENTOS PARA ESPORTES E DIVERSÕES",
        value: 4,
        code: "1.2.3.1.1.01.04",
      },
      {
        label: "1.2.3.1.1.01.05 - EQUIPAMENTO DE PROTEÇÃO, SEGURANÇA E SOCORRO",
        value: 5,
        code: "1.2.3.1.1.01.05",
      },
      {
        label: "1.2.3.1.1.01.06 - MÁQUINAS E EQUIPAMENTOS INDUSTRIAIS",
        value: 6,
        code: "1.2.3.1.1.01.06",
      },
      {
        label: "1.2.3.1.1.01.07 - MÁQUINAS E EQUIPAMENTOS ENERGÉTICOS",
        value: 7,
        code: "1.2.3.1.1.01.07",
      },
      {
        label: "1.2.3.1.1.01.08 - MÁQUINAS E EQUIPAMENTOS GRÁFICOS",
        value: 8,
        code: "1.2.3.1.1.01.08",
      },
      {
        label:
          "1.2.3.1.1.01.09 - MÁQUINAS, FERRAMENTAS E UTENSÍLIOS DE OFICINA",
        value: 9,
        code: "1.2.3.1.1.01.09",
      },
      {
        label: "1.2.3.1.1.01.10 - EQUIPAMENTOS DE MONTARIA",
        value: 10,
        code: "1.2.3.1.1.01.10",
      },
      {
        label: "1.2.3.1.1.01.11 - EQUIPAMENTOS E MATERIAL SIGILOSO E RESERVADO",
        value: 11,
        code: "1.2.3.1.1.01.11",
      },
      {
        label:
          "1.2.3.1.1.01.12 - EQUIPAMENTOS, PEÇAS E ACESSÓRIOS PARA AUTOMÓVEIS",
        value: 12,
        code: "1.2.3.1.1.01.12",
      },
      {
        label: "1.2.3.1.1.01.13 - EQUIPAMENTOS, PEÇAS E ACESSÓRIOS MARÍTIMOS",
        value: 13,
        code: "1.2.3.1.1.01.13",
      },
      {
        label:
          "1.2.3.1.1.01.14 - EQUIPAMENTOS, PEÇAS E ACESSÓRIOS AERONÁUTICOS",
        value: 14,
        code: "1.2.3.1.1.01.14",
      },
      {
        label:
          "1.2.3.1.1.01.15 - EQUIPAMENTOS, PEÇAS E ACESSÓRIOS DE PROTEÇÃO AO VOO",
        value: 15,
        code: "1.2.3.1.1.01.15",
      },
      {
        label: "1.2.3.1.1.01.16 - EQUIPAMENTOS DE MERGULHO E SALVAMENTO",
        value: 16,
        code: "1.2.3.1.1.01.16",
      },
      {
        label: "1.2.3.1.1.01.17 - EQUIPAMENTOS DE MANOBRAS E PATRULHAMENTO",
        value: 17,
        code: "1.2.3.1.1.01.17",
      },
      {
        label:
          "1.2.3.1.1.01.18 - EQUIPAMENTOS DE PROTEÇÃO E VIGILÂNCIA AMBIENTAL",
        value: 18,
        code: "1.2.3.1.1.01.18",
      },
      {
        label:
          "1.2.3.1.1.01.19 - MÁQUINAS, EQUIPAMENTOS E UTENSÍLIOS AGROPECUÁRIOS",
        value: 19,
        code: "1.2.3.1.1.01.19",
      },
      {
        label:
          "1.2.3.1.1.01.20 - MÁQUINAS, EQUIPAMENTOS E UTENSÍLIOS RODOVIÁRIOS",
        value: 20,
        code: "1.2.3.1.1.01.20",
      },
      {
        label: "1.2.3.1.1.01.21 - EQUIPAMENTOS HIDRÁULICOS E ELÉTRICOS",
        value: 21,
        code: "1.2.3.1.1.01.21",
      },
      {
        label:
          "1.2.3.1.1.01.99 - OUTRAS MÁQUINAS, APARELHOS, EQUIPAMENTOS E FERRAMENTAS",
        value: 21,
        code: "1.2.3.1.1.01.99",
      },
    ],
  },
  {
    label: "1.2.3.1.1.01.00 - MÁQUINAS, APARELHOS, EQUIPAMENTOS E FERRAMENTAS",
    value: 4,
    code: "1.2.3.1.1.01.00",
    applyBgOption: false,
    subOptions: [
      {
        label: "1.2.3.1.1.01.01 - APARELHOS DE MEDIÇÃO E ORIENTAÇÃO",
        value: 1,
        code: "1.2.3.1.1.01.01",
      },
      {
        label: "1.2.3.1.1.01.02 - APARELHOS E EQUIPAMENTOS DE COMUNICAÇÃO",
        value: 2,
        code: "1.2.3.1.1.01.02",
      },
      {
        label:
          "1.2.3.1.1.01.03 - APARELHOS, EQUIPAMENTOS E UTENSÍLIOS MÉDICOS, ODONTOLÓGICOS, LABORATORIAIS E HOSPITALARES",
        value: 3,
        code: "1.2.3.1.1.01.03",
      },
      {
        label:
          "1.2.3.1.1.01.04 - APARELHOS E EQUIPAMENTOS PARA ESPORTES E DIVERSÕES",
        value: 4,
        code: "1.2.3.1.1.01.04",
      },
      {
        label: "1.2.3.1.1.01.05 - EQUIPAMENTO DE PROTEÇÃO, SEGURANÇA E SOCORRO",
        value: 5,
        code: "1.2.3.1.1.01.05",
      },
      {
        label: "1.2.3.1.1.01.06 - MÁQUINAS E EQUIPAMENTOS INDUSTRIAIS",
        value: 6,
        code: "1.2.3.1.1.01.06",
      },
      {
        label: "1.2.3.1.1.01.07 - MÁQUINAS E EQUIPAMENTOS ENERGÉTICOS",
        value: 7,
        code: "1.2.3.1.1.01.07",
      },
      {
        label: "1.2.3.1.1.01.08 - MÁQUINAS E EQUIPAMENTOS GRÁFICOS",
        value: 8,
        code: "1.2.3.1.1.01.08",
      },
      {
        label:
          "1.2.3.1.1.01.09 - MÁQUINAS, FERRAMENTAS E UTENSÍLIOS DE OFICINA",
        value: 9,
        code: "1.2.3.1.1.01.09",
      },
      {
        label: "1.2.3.1.1.01.10 - EQUIPAMENTOS DE MONTARIA",
        value: 10,
        code: "1.2.3.1.1.01.10",
      },
      {
        label: "1.2.3.1.1.01.11 - EQUIPAMENTOS E MATERIAL SIGILOSO E RESERVADO",
        value: 11,
        code: "1.2.3.1.1.01.11",
      },
      {
        label:
          "1.2.3.1.1.01.12 - EQUIPAMENTOS, PEÇAS E ACESSÓRIOS PARA AUTOMÓVEIS",
        value: 12,
        code: "1.2.3.1.1.01.12",
      },
      {
        label: "1.2.3.1.1.01.13 - EQUIPAMENTOS, PEÇAS E ACESSÓRIOS MARÍTIMOS",
        value: 13,
        code: "1.2.3.1.1.01.13",
      },
      {
        label:
          "1.2.3.1.1.01.14 - EQUIPAMENTOS, PEÇAS E ACESSÓRIOS AERONÁUTICOS",
        value: 14,
        code: "1.2.3.1.1.01.14",
      },
      {
        label:
          "1.2.3.1.1.01.15 - EQUIPAMENTOS, PEÇAS E ACESSÓRIOS DE PROTEÇÃO AO VOO",
        value: 15,
        code: "1.2.3.1.1.01.15",
      },
      {
        label: "1.2.3.1.1.01.16 - EQUIPAMENTOS DE MERGULHO E SALVAMENTO",
        value: 16,
        code: "1.2.3.1.1.01.16",
      },
      {
        label: "1.2.3.1.1.01.17 - EQUIPAMENTOS DE MANOBRAS E PATRULHAMENTO",
        value: 17,
        code: "1.2.3.1.1.01.17",
      },
      {
        label:
          "1.2.3.1.1.01.18 - EQUIPAMENTOS DE PROTEÇÃO E VIGILÂNCIA AMBIENTAL",
        value: 18,
        code: "1.2.3.1.1.01.18",
      },
      {
        label:
          "1.2.3.1.1.01.19 - MÁQUINAS, EQUIPAMENTOS E UTENSÍLIOS AGROPECUÁRIOS",
        value: 19,
        code: "1.2.3.1.1.01.19",
      },
      {
        label:
          "1.2.3.1.1.01.20 - MÁQUINAS, EQUIPAMENTOS E UTENSÍLIOS RODOVIÁRIOS",
        value: 20,
        code: "1.2.3.1.1.01.20",
      },
      {
        label: "1.2.3.1.1.01.21 - EQUIPAMENTOS HIDRÁULICOS E ELÉTRICOS",
        value: 21,
        code: "1.2.3.1.1.01.21",
      },
      {
        label:
          "1.2.3.1.1.01.99 - OUTRAS MÁQUINAS, APARELHOS, EQUIPAMENTOS E FERRAMENTAS",
        value: 21,
        code: "1.2.3.1.1.01.99",
      },
    ],
  },
  {
    label: "1.2.3.1.1.02.00 - BENS DE INFORMÁTICA",
    value: 5,
    code: "1.2.3.1.1.02.00",
    applyBgOption: false,
    subOptions: [
      {
        label: "1.2.3.1.1.02.01 - EQUIPAMENTOS DE PROCESSAMENTO DE DADOS",
        value: 1,
        code: "1.2.3.1.1.02.01",
      },
      {
        label: "1.2.3.1.1.02.02 - EQUIPAMENTOS DE TECNOLOGIA DA INFORMAÇÃO",
        value: 2,
        code: "1.2.3.1.1.02.02",
      },
    ],
  },
  {
    label: "1.2.3.1.1.03.00 - MÓVEIS E UTENSÍLIOS",
    value: 6,
    code: "1.2.3.1.1.03.00",
    applyBgOption: false,
    subOptions: [
      {
        label: "1.2.3.1.1.03.01 - APARELHOS E UTENSÍLIOS DOMÉSTICOS",
        value: 1,
        code: "1.2.3.1.1.03.01",
      },
      {
        label: "1.2.3.1.1.03.02 - MÁQUINAS E UTENSÍLIOS DE ESCRITÓRIO",
        value: 2,
        code: "1.2.3.1.1.03.02",
      },
      {
        label: "1.2.3.1.1.03.03 - MOBILIÁRIO EM GERAL",
        value: 3,
        code: "1.2.3.1.1.03.03",
      },
      {
        label: "1.2.3.1.1.03.04 - UTENSÍLIOS EM GERAL",
        value: 4,
        code: "1.2.3.1.1.03.04",
      },
    ],
  },
  {
    label:
      "1.2.3.1.1.04.00 - MATERIAIS CULTURAIS, EDUCACIONAIS E DE COMUNICAÇÃO",
    value: 6,
    code: "1.2.3.1.1.04.00",
    applyBgOption: false,
    subOptions: [
      {
        label: "1.2.3.1.1.04.01 - BANDEIRAS, FLÂMULAS E INSÍGNIAS",
        value: 1,
        code: "1.2.3.1.1.04.01",
      },
      {
        label: "1.2.3.1.1.04.02 - COLEÇÕES E MATERIAIS BIBLIOGRÁFICOS",
        value: 2,
        code: "1.2.3.1.1.04.02",
      },
      {
        label: "1.2.3.1.1.04.03 - DISCOTECAS E FILMOTECAS",
        value: 3,
        code: "1.2.3.1.1.04.03",
      },
      {
        label: "1.2.3.1.1.04.04 - INSTRUMENTOS MUSICAIS E ARTÍSTICOS",
        value: 4,
        code: "1.2.3.1.1.04.04",
      },
      {
        label: "1.2.3.1.1.04.05 - EQUIPAMENTOS PARA ÁUDIO, VÍDEO E FOTO",
        value: 5,
        code: "1.2.3.1.1.04.05",
      },
      {
        label: "1.2.3.1.1.04.06 - OBRAS DE ARTE E PEÇAS PARA EXPOSIÇÃO",
        value: 6,
        code: "1.2.3.1.1.04.06",
      },
      {
        label:
          "1.2.3.1.1.04.99 - OUTROS MATERIAIS CULTURAIS, EDUCACIONAIS E DE COMUNICAÇÃO",
        value: 7,
        code: "1.2.3.1.1.04.99",
      },
    ],
  },
  {
    label: "1.2.3.1.1.05.00 - VEÍCULOS",
    value: 7,
    code: "1.2.3.1.1.05.00",
    applyBgOption: false,
    subOptions: [
      {
        label: "1.2.3.1.1.05.01 - VEÍCULOS EM GERAL",
        value: 1,
        code: "1.2.3.1.1.05.01",
      },
      {
        label: "1.2.3.1.1.05.02 - VEÍCULOS FERROVIÁRIOS",
        value: 2,
        code: "1.2.3.1.1.05.02",
      },
      {
        label: "1.2.3.1.1.05.03 - VEÍCULOS DE TRAÇÃO MECÂNICA",
        value: 3,
        code: "1.2.3.1.1.05.03",
      },
      {
        label: "1.2.3.1.1.05.04 - CARROS DE COMBATE",
        value: 4,
        code: "1.2.3.1.1.05.04",
      },
      {
        label: "1.2.3.1.1.05.05 - AERONAVES",
        value: 5,
        code: "1.2.3.1.1.05.05",
      },
      {
        label: "1.2.3.1.1.05.06 - EMBARCAÇÕES",
        value: 6,
        code: "1.2.3.1.1.05.06",
      },
    ],
  },
  {
    label: "1.2.3.1.1.06.00 - PEÇAS E CONJUNTOS DE REPOSIÇÃO",
    value: 8,
    code: "1.2.3.1.1.06.00",
    applyBgOption: false,
    subOptions: [
      {
        label: "",
        value: null,
        code: "",
      },
    ],
  },
  {
    label: "1.2.3.1.1.07.00 -BENS MÓVEIS EM ANDAMENTO",
    value: 9,
    code: "1.2.3.1.1.07.00",
    applyBgOption: false,
    subOptions: [
      {
        label: "1.2.3.1.1.07.01 - BENS MÓVEIS EM ELABORAÇÃO",
        value: 1,
        code: "1.2.3.1.1.07.01",
      },
      {
        label: "1.2.3.1.1.07.02 - IMPORTAÇÕES EM ANDAMENTO",
        value: 2,
        code: "1.2.3.1.1.07.02",
      },
      {
        label:
          "1.2.3.1.1.07.04 - ALMOXARIFADO DE MATERIAIS A SEREM APLICADOS EM BENS EM ANDAMENTO",
        value: 3,
        code: "1.2.3.1.1.07.04",
      },
    ],
  },
  {
    label: "1.2.3.1.1.08.00 - BENS MÓVEIS EM ALMOXARIFADO",
    value: 10,
    code: "1.2.3.1.1.08.00",
    applyBgOption: false,
    subOptions: [
      {
        label: "1.2.3.1.1.08.01 - ESTOQUE INTERNO",
        value: 1,
        code: "1.2.3.1.1.08.01",
      },
      {
        label: "1.2.3.1.1.08.03 - BENS MÓVEIS A REPARAR",
        value: 2,
        code: "1.2.3.1.1.08.03",
      },
      {
        label: "1.2.3.1.1.08.05 - BENS MÓVEIS INSERVÍVEIS",
        value: 3,
        code: "1.2.3.1.1.08.05",
      },
    ],
  },
  {
    label: "1.2.3.1.1.09.00 - ARMAMENTOS",
    value: 11,
    code: "1.2.3.1.1.09.00",
    applyBgOption: false,
    subOptions: [
      {
        label: "",
        value: null,
        code: "",
      },
    ],
  },
  {
    label: "1.2.3.1.1.10.00 - SEMOVENTES",
    value: 12,
    code: "1.2.3.1.1.10.00",
    applyBgOption: false,
    subOptions: [
      {
        label: "",
        value: null,
        code: "",
      },
    ],
  },
  {
    label: "1.2.3.1.1.11.00 - BENS MÓVEIS - ATIVOS DE CONCESSÃO",
    value: 13,
    code: "1.2.3.1.1.11.00",
    applyBgOption: false,
    subOptions: [
      {
        label: "",
        value: null,
        code: "",
      },
    ],
  },
  {
    label: "1.2.3.1.1.99.00 - DEMAIS BENS MÓVEIS",
    value: 14,
    code: "1.2.3.1.1.99.00",
    applyBgOption: false,
    subOptions: [
      {
        label: "1.2.3.1.1.99.01 - BENS MÓVEIS A ALIENAR",
        value: 1,
        code: "1.2.3.1.1.99.01",
      },
      {
        label: "1.2.3.1.1.99.02 - BENS EM PODER DE OUTRA UNIDADE OU TERCEIROS ",
        value: 2,
        code: "1.2.3.1.1.99.02",
      },
      {
        label: "1.2.3.1.1.99.08 - BENS MÓVEIS A CLASSIFICAR",
        value: 3,
        code: "1.2.3.1.1.99.08",
      },
      {
        label: "1.2.3.1.1.99.99 - OUTROS BENS MÓVEIS",
        value: 4,
        code: "1.2.3.1.1.99.99",
      },
    ],
  },
  {
    label: "1.2.3.2.1.00.00 - BENS IMOVEIS - CONSOLIDAÇÃO",
    value: 15,
    code: "1.2.3.2.1.00.00",
    applyBgOption: true,
    subOptions: [
      {
        label: "1.2.3.2.1.01.01 - IMÓVEIS RESIDENCIAIS",
        value: 1,
        code: "1.2.3.2.1.01.01",
      },
      {
        label: "1.2.3.2.1.01.02 - IMÓVEIS COMERCIAIS",
        value: 2,
        code: "1.2.3.2.1.01.02",
      },
      {
        label: "1.2.3.2.1.01.03 - EDIFÍCIOS",
        value: 3,
        code: "1.2.3.2.1.01.03",
      },
      {
        label: "1.2.3.2.1.01.04 - TERRENOS/GLEBAS",
        value: 4,
        code: "1.2.3.2.1.01.04",
      },
      {
        label: "1.2.3.2.1.01.05 - ARMAZÉNS/GALPÕES",
        value: 5,
        code: "1.2.3.2.1.01.05",
      },
      {
        label: "1.2.3.2.1.01.06 - AQUARTELAMENTOS",
        value: 6,
        code: "1.2.3.2.1.01.06",
      },
      {
        label: "1.2.3.2.1.01.07 - AEROPORTOS/ESTAÇÕES/AERÓDROMOS",
        value: 7,
        code: "1.2.3.2.1.01.07",
      },
      {
        label: "1.2.3.2.1.01.08 - IMÓVEIS DE USO EDUCACIONAL",
        value: 8,
        code: "1.2.3.2.1.01.08",
      },
      {
        label: "1.2.3.2.1.01.09 - REPRESAS/AÇUDES",
        value: 9,
        code: "1.2.3.2.1.01.09",
      },
      {
        label: "1.2.3.2.1.01.10 - FAZENDAS, PARQUES E RESERVAS",
        value: 10,
        code: "1.2.3.2.1.01.10",
      },
      {
        label: "1.2.3.2.1.01.11 - IMÓVEIS DE USO RECREATIVO",
        value: 11,
        code: "1.2.3.2.1.01.11",
      },
      { label: "1.2.3.2.1.01.12 - FARÓIS", value: 12, code: "1.2.3.2.1.01.12" },
      {
        label: "1.2.3.2.1.01.13 - MUSEUS/PALÁCIOS",
        value: 13,
        code: "1.2.3.2.1.01.13",
      },
      {
        label: "1.2.3.2.1.01.14 - LABORATÓRIOS/OBSERVATÓRIOS",
        value: 14,
        code: "1.2.3.2.1.01.14",
      },
      {
        label: "1.2.3.2.1.01.15 - HOSPITAIS E UNIDADES DE SAÚDE",
        value: 15,
        code: "1.2.3.2.1.01.15",
      },
      {
        label: "1.2.3.2.1.01.16 - HOTÉIS",
        value: 16,
        code: "1.2.3.2.1.01.16",
      },
      {
        label: "1.2.3.2.1.01.17 - PRESÍDIOS/DELEGACIAS",
        value: 17,
        code: "1.2.3.2.1.01.17",
      },
      {
        label: "1.2.3.2.1.01.18 - PORTOS/ESTALEIROS",
        value: 18,
        code: "1.2.3.2.1.01.18",
      },
      {
        label: "1.2.3.2.1.01.19 - COMPLEXOS/FÁBRICAS/USINAS",
        value: 19,
        code: "1.2.3.2.1.01.19",
      },
      {
        label: "1.2.3.2.1.01.20 - CEMITÉRIOS",
        value: 20,
        code: "1.2.3.2.1.01.20",
      },
      {
        label: "1.2.3.2.1.01.21 - ESTACIONAMENTOS E GARAGENS",
        value: 21,
        code: "1.2.3.2.1.01.21",
      },
      {
        label: "1.2.3.2.1.01.22 - POSTOS DE FISCALIZAÇÃO",
        value: 22,
        code: "1.2.3.2.1.01.22",
      },
      {
        label: "1.2.3.2.1.01.98 - OUTROS BENS IMÓVEIS DE USO ESPECIAL",
        value: 23,
        code: "1.2.3.2.1.01.98",
      },
    ],
  },
  {
    label: "1.2.3.2.1.01.00 - BENS DE USO ESPECIAL",
    value: 16,
    code: "1.2.3.2.1.01.00",
    applyBgOption: false,
    subOptions: [
      {
        label: "1.2.3.2.1.01.01 - IMÓVEIS RESIDENCIAIS",
        value: 1,
        code: "1.2.3.2.1.01.01",
      },
      {
        label: "1.2.3.2.1.01.02 - IMÓVEIS COMERCIAIS",
        value: 2,
        code: "1.2.3.2.1.01.02",
      },
      {
        label: "1.2.3.2.1.01.03 - EDIFÍCIOS",
        value: 3,
        code: "1.2.3.2.1.01.03",
      },
      {
        label: "1.2.3.2.1.01.04 - TERRENOS/GLEBAS",
        value: 4,
        code: "1.2.3.2.1.01.04",
      },
      {
        label: "1.2.3.2.1.01.05 - ARMAZÉNS/GALPÕES",
        value: 5,
        code: "1.2.3.2.1.01.05",
      },
      {
        label: "1.2.3.2.1.01.06 - AQUARTELAMENTOS",
        value: 6,
        code: "1.2.3.2.1.01.06",
      },
      {
        label: "1.2.3.2.1.01.07 - AEROPORTOS/ESTAÇÕES/AERÓDROMOS",
        value: 7,
        code: "1.2.3.2.1.01.07",
      },
      {
        label: "1.2.3.2.1.01.08 - IMÓVEIS DE USO EDUCACIONAL",
        value: 8,
        code: "1.2.3.2.1.01.08",
      },
      {
        label: "1.2.3.2.1.01.09 - REPRESAS/AÇUDES",
        value: 9,
        code: "1.2.3.2.1.01.09",
      },
      {
        label: "1.2.3.2.1.01.10 - FAZENDAS, PARQUES E RESERVAS",
        value: 10,
        code: "1.2.3.2.1.01.10",
      },
      {
        label: "1.2.3.2.1.01.11 - IMÓVEIS DE USO RECREATIVO",
        value: 11,
        code: "1.2.3.2.1.01.11",
      },
      { label: "1.2.3.2.1.01.12 - FARÓIS", value: 12, code: "1.2.3.2.1.01.12" },
      {
        label: "1.2.3.2.1.01.13 - MUSEUS/PALÁCIOS",
        value: 13,
        code: "1.2.3.2.1.01.13",
      },
      {
        label: "1.2.3.2.1.01.14 - LABORATÓRIOS/OBSERVATÓRIOS",
        value: 14,
        code: "1.2.3.2.1.01.14",
      },
      {
        label: "1.2.3.2.1.01.15 - HOSPITAIS E UNIDADES DE SAÚDE",
        value: 15,
        code: "1.2.3.2.1.01.15",
      },
      {
        label: "1.2.3.2.1.01.16 - HOTÉIS",
        value: 16,
        code: "1.2.3.2.1.01.16",
      },
      {
        label: "1.2.3.2.1.01.17 - PRESÍDIOS/DELEGACIAS",
        value: 17,
        code: "1.2.3.2.1.01.17",
      },
      {
        label: "1.2.3.2.1.01.18 - PORTOS/ESTALEIROS",
        value: 18,
        code: "1.2.3.2.1.01.18",
      },
      {
        label: "1.2.3.2.1.01.19 - COMPLEXOS/FÁBRICAS/USINAS",
        value: 19,
        code: "1.2.3.2.1.01.19",
      },
      {
        label: "1.2.3.2.1.01.20 - CEMITÉRIOS",
        value: 20,
        code: "1.2.3.2.1.01.20",
      },
      {
        label: "1.2.3.2.1.01.21 - ESTACIONAMENTOS E GARAGENS",
        value: 21,
        code: "1.2.3.2.1.01.21",
      },
      {
        label: "1.2.3.2.1.01.22 - POSTOS DE FISCALIZAÇÃO",
        value: 22,
        code: "1.2.3.2.1.01.22",
      },
      {
        label: "1.2.3.2.1.01.98 - OUTROS BENS IMÓVEIS DE USO ESPECIAL",
        value: 23,
        code: "1.2.3.2.1.01.98",
      },
    ],
  },
  {
    label: "1.2.3.2.1.04.00 - BENS DOMINICAIS",
    value: 17,
    code: "1.2.3.2.1.04.00",
    applyBgOption: false,
    subOptions: [
      {
        label: "1.2.3.2.1.04.01 - EDIFÍCIOS",
        value: 2,
        code: "1.2.3.2.1.04.01",
      },
      {
        label: "1.2.3.2.1.04.02 - APARTAMENTOS",
        value: 3,
        code: "1.2.3.2.1.04.02",
      },
      {
        label: "1.2.3.2.1.04.03 - ARMAZÉNS",
        value: 4,
        code: "1.2.3.2.1.04.03",
      },
      {
        label: "1.2.3.2.1.04.04 - CASAS",
        value: 5,
        code: "1.2.3.2.1.04.04",
      },
      {
        label: "1.2.3.2.1.04.05 - CEMITÉRIOS",
        value: 6,
        code: "1.2.3.2.1.04.05",
      },
      {
        label: "1.2.3.2.1.04.07 - GARAGENS E ESTACIONAMENTOS",
        value: 7,
        code: "1.2.3.2.1.04.07",
      },
      {
        label: "1.2.3.2.1.04.08 - FAZENDAS",
        value: 8,
        code: "1.2.3.2.1.04.08",
      },
      {
        label: "1.2.3.2.1.04.09 - GALPÕES",
        value: 9,
        code: "1.2.3.2.1.04.09",
      },
      {
        label: "1.2.3.2.1.04.10 - GLEBAS",
        value: 10,
        code: "1.2.3.2.1.04.10",
      },
      {
        label: "1.2.3.2.1.04.11 - LOJAS",
        value: 11,
        code: "1.2.3.2.1.04.11",
      },
      {
        label: "1.2.3.2.1.04.12 - SALAS",
        value: 12,
        code: "1.2.3.2.1.04.12",
      },
      {
        label: "1.2.3.2.1.04.13 - TERRENOS",
        value: 13,
        code: "1.2.3.2.1.04.13",
      },
      {
        label: "1.2.3.2.1.04.14 - LOTES",
        value: 14,
        code: "1.2.3.2.1.04.14",
      },
      {
        label: "1.2.3.2.1.04.15 - LOTES INDUSTRIAIS",
        value: 15,
        code: "1.2.3.2.1.04.15",
      },
      {
        label: "1.2.3.2.1.04.16 - GLEBAS URBANAS",
        value: 16,
        code: "1.2.3.2.1.04.16",
      },
      {
        label: "1.2.3.2.1.04.17 - GLEBAS URBANIZADAS",
        value: 17,
        code: "1.2.3.2.1.04.17",
      },
      {
        label: "1.2.3.2.1.04.18 - GLEBAS RURAIS",
        value: 18,
        code: "1.2.3.2.1.04.18",
      },
      {
        label: "1.2.3.2.1.04.99 - OUTROS BENS DOMINICAIS",
        value: 19,
        code: "1.2.3.2.1.04.99",
      },
    ],
  },
  {
    label: "1.2.3.2.1.05.00 - BENS DE USO COMUM DO POVO",
    value: 18,
    code: "1.2.3.2.1.05.00",
    applyBgOption: false,
    subOptions: [
      {
        label: "1.2.3.2.1.05.01 - RUAS",
        value: 2,
        code: "1.2.3.2.1.05.01",
      },
      {
        label: "1.2.3.2.1.05.02 - PRAÇAS",
        value: 3,
        code: "1.2.3.2.1.05.02",
      },
      {
        label: "1.2.3.2.1.05.03 - ESTRADAS",
        value: 4,
        code: "1.2.3.2.1.05.03",
      },
      {
        label: "1.2.3.2.1.05.04 - PONTES",
        value: 5,
        code: "1.2.3.2.1.05.04",
      },
      {
        label: "1.2.3.2.1.05.05 - VIADUTOS",
        value: 6,
        code: "1.2.3.2.1.05.05",
      },
      {
        label:
          "1.2.3.2.1.05.06 - SISTEMAS DE ESGOTO E/OU DE ABASTECIMENTO DE ÁGUA",
        value: 7,
        code: "1.2.3.2.1.05.06",
      },
      {
        label: "1.2.3.2.1.05.07 - SISTEMAS DE ABASTECIMENTO DE ENERGIA",
        value: 8,
        code: "1.2.3.2.1.05.07",
      },
      {
        label: "1.2.3.2.1.05.08 - REDES DE TELECOMUNICAÇÕES",
        value: 9,
        code: "1.2.3.2.1.05.08",
      },
      {
        label: "1.2.3.2.1.05.09 - BENS DO PATRIMÔNIO CULTURAL",
        value: 10,
        code: "1.2.3.2.1.05.09",
      },
      {
        label: "1.2.3.2.1.05.10 - TÚNEIS",
        value: 11,
        code: "1.2.3.2.1.05.10",
      },
      {
        label: "1.2.3.2.1.05.11 - BARRAGENS",
        value: 12,
        code: "1.2.3.2.1.05.11",
      },
      {
        label: "1.2.3.2.1.05.99 - OUTROS BENS DE USO COMUM DO POVO",
        value: 13,
        code: "1.2.3.2.1.05.99",
      },
    ],
  },
  {
    label: "1.2.3.2.1.06.00 - BENS IMÓVEIS EM ANDAMENTO",
    value: 19,
    code: "1.2.3.2.1.06.00",
    applyBgOption: false,
    subOptions: [
      {
        label: "1.2.3.2.1.06.01 - OBRAS EM ANDAMENTO",
        value: 1,
        code: "1.2.3.2.1.06.01",
      },
      {
        label: "1.2.3.2.1.06.02 - OBRAS PARALISADAS POR DECISÃO JUDICIAL",
        value: 2,
        code: "1.2.3.2.1.06.02",
      },
      {
        label: "1.2.3.2.1.06.03 - OBRAS PARALISADAS POR DECISÃO ADMINISTRATIVA",
        value: 3,
        code: "1.2.3.2.1.06.03",
      },
      {
        label: "1.2.3.2.1.06.05 - ESTUDOS E PROJETOS",
        value: 4,
        code: "1.2.3.2.1.06.05",
      },
    ],
  },
  {
    label: "1.2.3.2.1.07.00 - INSTALAÇÕES",
    value: 20,
    code: "1.2.3.2.1.07.00",
    applyBgOption: false,
    subOptions: [
      {
        label: "",
        value: null,
        code: "",
      },
    ],
  },
  {
    label: "1.2.3.2.1.08.00 - BENFEITORIAS EM PROPRIEDADE DE TERCEIROS",
    value: 21,
    code: "1.2.3.2.1.08.00",
    applyBgOption: false,
    subOptions: [
      {
        label: "",
        value: null,
        code: "",
      },
    ],
  },
  {
    label: "1.2.3.2.1.09.00 - BENS IMÓVEIS - ATIVOS DE CONCESSÃO",
    value: 22,
    code: "1.2.3.2.1.09.00",
    applyBgOption: false,
    subOptions: [
      {
        label: "",
        value: null,
        code: "",
      },
    ],
  },
  {
    label: "1.2.3.2.1.99.00 - DEMAIS BENS IMÓVEIS",
    value: 23,
    code: "1.2.3.2.1.99.00",
    applyBgOption: false,
    subOptions: [
      {
        label: "1.2.3.2.1.99.01 - BENS IMÓVEIS LOCADOS PARA TERCEIROS",
        value: 2,
        code: "1.2.3.2.1.99.01",
      },
      {
        label: "1.2.3.2.1.99.02 - IMÓVEIS EM PODER DE TERCEIROS",
        value: 3,
        code: "1.2.3.2.1.99.02",
      },
      {
        label:
          "1.2.3.2.1.99.03 - MATERIAIS TEMPORARIAMENTE SEPARADOS DE IMÓVEIS",
        value: 4,
        code: "1.2.3.2.1.99.03",
      },
      {
        label: "1.2.3.2.1.99.05 - BENS IMÓVEIS A CLASSIFICAR",
        value: 5,
        code: "1.2.3.2.1.99.05",
      },
      {
        label: "1.2.3.2.1.99.06 - BENS IMÓVEIS A ALIENAR",
        value: 6,
        code: "1.2.3.2.1.99.06",
      },
      {
        label: "1.2.3.2.1.99.99 - OUTROS BENS IMÓVEIS",
        value: 7,
        code: "1.2.3.2.1.99.99",
      },
    ],
  },
];

export const accountCategoriesPcasp = accountCategories.sort(
  (accountA, accountB) => {
    const codeAccountA = accountA.code.replace(".", "");
    const codeAccountB = accountB.code.replace(".", "");

    return codeAccountA.localeCompare(codeAccountB);
  }
);

export const indexItemsApplyBgOptions = accountCategoriesPcasp
  .map((item, index) => {
    if (item.applyBgOption) {
      const getFirstFiveDigitsOfCode = item.code.slice(0, 9);
      return {
        code: getFirstFiveDigitsOfCode,
        index,
      };
    }

    return "";
  })
  .filter((item) => item);
