export const tiposDeObjeto = [
  { label: "1 - Obras e Serviços de Engenharia", value: "1" },
  { label: "2 - Compras e Serviços", value: "2" },
  { label: "3 - Alienação", value: "3" },
];

export const modalidades = [
  {
    label: "1 - Concorrência",
    value: "1",
  },
  {
    label: "2 - Tomada de Preços",
    value: "2",
  },
  {
    label: "3 - Convite",
    value: "3",
  },
  {
    label: "4 - Concurso",
    value: "4",
  },
  {
    label: "5 - Leilão",
    value: "5",
  },
  {
    label: "6 - Dispensa por Valor",
    value: "6",
  },
  {
    label: "7 - Dispensa por outros motivos",
    value: "7",
  },
  {
    label: "8 - Inexigível",
    value: "8",
  },
  {
    label: "9 - Sem Licitação",
    value: "9",
  },
  {
    label: "10 - Pregão Eletrônico",
    value: "10",
  },
  {
    label: "11 - Pregão Presencial",
    value: "11",
  },
  {
    label: "12 - Adesão a Registro de Preço",
    value: "12",
  },
  {
    label: "13 - Chamada Pública",
    value: "13",
  },
  {
    label: "14 - RDC - Regime Diferenciado de Contratações Públicas",
    value: "14",
  },
  {
    label: "15 - Lei Nº 13.303/2016",
    value: "15",
  },
  {
    label: "16 - Lei Nº 13.303/2016 (Art. 29 ou 30)",
    value: "16",
  },
  {
    label: "17 - Internacional (GN 2349-9)",
    value: "17",
  },
  {
    label: "18 - Internacional (GN 2350-9)",
    value: "18",
  },
  {
    label:
      "19 - Contratação Emergencial de Organização (Art. 12, II da Lei Nº 9.454/2011)",
    value: "19",
  },
  {
    label: "20 - Dispensa COVID-19 (Art. 4º da Lei 13.979/2020)",
    value: "20",
  },
  {
    label: "21 - Dispensa (Lei 14.133/21)",
    value: "21",
  },
  {
    label: "22 - Inexigibilidade (Lei 14.133/21)",
    value: "22",
  },
  {
    label: "23 - Concorrência (Lei 14.133/21)",
    value: "23",
  },
  {
    label: "24 - Pregão (Lei 14.133/21)",
    value: "24",
  },
  {
    label: "25 - Concurso (Lei 14.133/21)",
    value: "25",
  },
  {
    label: "26 - Pregão (Medida Provisória 1.047/21)",
    value: "26",
  },
  {
    label: "27 - Dispensa (Medida Provisória 1.047/21)",
    value: "27",
  },
  {
    label: "28 - Internacional Não Competitiva",
    value: "28",
  },
  {
    label: "29 - Licitação Internacional Competitiva",
    value: "29",
  },
  {
    label: "30 - Credenciamento - (Lei Nº 14.133/2021)",
    value: "30",
  },
  {
    label: "31 - Concorrência - Publicidade (Lei Nº 12.232/2010)",
    value: "31",
  },
  {
    label: "32 - Diálogo Competitivo - (Lei Nº 14.133/2021)",
    value: "32",
  },
  {
    label:
      "33 - Alienação de bens, dispensada a licitação - (Lei Nº 14.133/2021)",
    value: "33",
  },
  {
    label: "34 - Leilão - (Lei Nº 14.133/2021)",
    value: "34",
  },
  {
    label: "35 - Adesão a Ata de Registro de Preços - (Lei Nº 14.133/2021)",
    value: "35",
  },
  {
    label: "36 - Licitação Credenciamento - (Lei Nº 8.666/93)",
    value: "36",
  },
];
