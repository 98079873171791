import { CCol, RowForm } from "../../../../components/Grid/CCol";
import MyAutoComplete from "../../../../components/Inputs/MyAutoComplete";
import { RowTituloAba } from "../../../PagesAcessoInterno/PagContaPerfil/StyledComponentsContaPerfil";

export const Agrupamento = () => {
  return (
    <>
      <RowTituloAba style={{ marginTop: "15px" }}>
        <div>
          <span>Agrupamentos</span>
        </div>
      </RowTituloAba>

      <RowForm $borderRadiusType={2} className="mt-1">
        <CCol>
          <MyAutoComplete
            options={[{ label: "teste", value: "teste" }]}
            labelFormat={(item) => `${item.label} - ${item.value}`}
            labelInput="Agrupamento Folha"
            style={{ height: "37px" }}
          />
        </CCol>
      </RowForm>
    </>
  );
};
