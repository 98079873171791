import axios from "axios";

export const fetchRetry = async (url, maxRetries = 3, delay = 1000) => {
  let attempts = 0;
  while (attempts < maxRetries) {
    try {
      return await axios.get(url);
    } catch (error) {
      attempts++;
      if (attempts >= maxRetries) throw error;
      await new Promise((resolve) => setTimeout(resolve, delay));
    }
  }
};
