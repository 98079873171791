import React, { useEffect, useState } from "react";
import { Col, Container } from "react-bootstrap";
import HeaderCadastros from "../../../../components/HeaderCadastros/HeaderCadastros";
import { CCol, RowAninhada, RowForm } from "../../../../components/Grid/CCol";
import InputData from "../../../../components/Grid/InputData";
import FloatingLabelInput from "../../../../components/Grid/FloatingLabelInput";
import MyAutoComplete from "../../../../components/Inputs/MyAutoComplete";
import { FormatCpfPaste } from "../../../../utils/FormatacaoDeDados/FormtCpfPaste";
import styled from "styled-components";
import InputFormatRealBrasileiro from "../../../../components/Grid/InputFormatRealBrasileiro";
import RowSelecaoAbasInferior from "../../../../components/Grid/RowSelecaoAbasInferior";
import HistoricoAba from "./HistoricoAba";
import ProdutoEAquisicaoAba from "./ProdutoEAquisicaoAba";
import ComplementoAba from "./ComplementoAba";
import SaldosAba from "./SaldosAba";
import { getCpfsCnpjsPorCliente } from "../../../../services/gets/getCpfsCnpjsPorCliente";
import { useCurrentClient } from "../../../../hooks/useCurrentClient";
import { useOpenedModals } from "../../../../hooks/useOpenedModals";
import ModalAutoComplete from "../../../../components/Modais/ModalAutoComplete";
import ComponentCadastroCpfCnpj from "../../../../components/ComponentCadastroCpfCnpj";
import { ToastContainer } from "react-toastify";
import { ReturnDataAtual } from "../../../../utils/Gerais/ReturnDataAtual";
import { FormatDateToNA } from "../../../../utils/FormatacaoDeDados/FormatDateToNA";
import api from "../../../../utils/api";
import { useCurrentYear } from "../../../../hooks/useCurrentYear";
import AutoCompleteFichas from "../../../../components/Inputs/AutoCompleteFichas";
import { gerenciaOpcoesOrdemCronologica } from "./gerenciaOpcoesOrdemCronologica";
import { getAllNaturezasDaDespesa } from "../../../../services/gets/getAllNaturezasDaDespesa";
import { gerenciaOpcoesSubelemento } from "./gerenciaOpcoesSubelemento";
import {
  getConveniosPbPorAno,
  getObrasTcePorCliente,
} from "../../../../services/tce/gets";
import { TestButton } from "../../../../utils/TestButton";
import { Icon } from "@iconify/react/dist/iconify.js";

const CColNumeroAndEVB = styled(CCol)`
  @media (min-width: 768px) {
    width: 10%;
  }
`;

const RowInfosAcao = styled(RowAninhada)`
  background-color: #f2f2f2;
  border-radius: 5px;
  padding: 5px 0px;

  p {
    margin: 3px;
    color: #888a8e;
    font-weight: 500;
    font-size: 14px;
  }

  p > span {
    color: #515c70;
  }
`;

const NotaDeEmpenho = () => {
  //contexts
  const { currentYear } = useCurrentYear();
  const { currentClient } = useCurrentClient();

  const [allNaturezasDaDespesa, setAllNaturezasDaDespesa] = useState([]);
  const [optionsCpf, setOptionsCpf] = useState([]);
  const [optionsFichas, setOptionsFichas] = useState([]);
  const [optionsFontesDeRecursos, setOptionsFontesDeRecursos] = useState([]);
  const [optionsSubelementos, setOptionsSubelementos] = useState([]);
  const [loading, setLoading] = useState(false);
  const [optionsOrdemCronologica, setOptionsOrdemCronologica] = useState([
    {
      label: "0 - Outros",
      value: 0,
      disabled: true,
    },
    {
      label: "1 - Fornecimento de Bens",
      value: 1,
      disabled: true,
    },
    {
      label: "2 - Locações",
      value: 2,
      disabled: true,
    },
    {
      label: "3 - Prestação de Serviços",
      value: 3,
      disabled: true,
    },
    {
      label: "4 - Realização de Obras",
      value: 4,
      disabled: true,
    },
  ]);
  const [filteredOptionsFontesDeRecursos, setFilteredOptionsFontesDeRecursos] =
    useState([]);
  const [inputFicha, setInputFicha] = useState("");
  const [licitacoes, setLicitacoes] = useState([]);
  const [licitacoesOptions, setLicitacoesOptions] = useState([]);
  const { openedModals, setOpenedModals } = useOpenedModals();

  const [abaSelecionada, setAbaSelecionada] = useState({
    nomeAba: "Histórico",
    numeroAba: 0,
  });
  const dataAtual = FormatDateToNA(ReturnDataAtual());
  const [valuesNotaEmpenho, setValuesNotaEmpenho] = useState({
    fornecedor: "",
    licitacao: {
      numero: "",
      data: "",
    },
    data: dataAtual,
    tipoDeEmpenho: "1 - Ordinário",
    ordemCronologica: 0,
    fichaId: 0,
    fonteDeRecursoId: 0,
    subelemento: "",
  });
  const [infosFicha, setInfosFicha] = useState({});

  //input states
  const [inputFornecedor, setInputFornecedor] = useState("");
  const [optionsObras, setOptionsObras] = useState([]);
  const [inputValueObras, setInputValueObras] = useState("");
  const [optionsConvenios, setOptionsConvenios] = useState([]);
  const [inputValueConvenios, setInputValueConvenios] = useState("");

  const fetchObras = async () => {
    const obras = await getObrasTcePorCliente(
      currentClient.clienteId,
      currentClient.codigoTCE
    );

    setOptionsObras(obras);
  };

  const fetchConvenios = async () => {
    const convenios = await getConveniosPbPorAno(currentYear);

    setOptionsConvenios(convenios);
  };

  const fetchFontesDeRecursos = async () => {
    try {
      const resp = await api.get(`/fonteDeRecurso/por-ano/${currentYear}`);
      const data = resp.data;
      setOptionsFontesDeRecursos(data);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchCpfs = async (reqDeModal) => {
    const data = await getCpfsCnpjsPorCliente(currentClient.clienteId);

    if (data) {
      setOptionsCpf(
        data.map((item) => ({
          id: item.pessoaId,
          nome: item.nomeCompleto,
          cpf: FormatCpfPaste(item.cpf),
        }))
      );

      if (reqDeModal) {
        const dado = data.reverse()[0];

        setInputFornecedor(
          `${dado.nomeCompleto} - ${FormatCpfPaste(dado.cpf)}`
        );
        setValuesNotaEmpenho((prev) => ({
          ...prev,
          fornecedorId: dado.pessoaId,
        }));

        setOpenedModals((prev) =>
          prev.filter((item) => item.tituloModal !== "CPF/CNPJ")
        );
      }
    }
  };

  const fetchAllNaturezasDaDespesa = async () => {
    const dados = await getAllNaturezasDaDespesa(
      currentClient.clienteId,
      currentYear
    );

    setAllNaturezasDaDespesa(dados);
  };

  const fetchQddFichas = async () => {
    try {
      const resp = await api.get(
        `/qdd/${currentClient.clienteId}/${currentYear}`
      );
      const dados = resp.data;

      const arrayDeFichas = dados.map((item) => {
        const despesaFixadaQdd = item.despesaFixadaQDD;
        const fichaReferente = despesaFixadaQdd.fichasQDD;
        const fonteDeRecursosReferente = despesaFixadaQdd.fonteDeRecurso;
        const acaoGovernamentalInfo = item.acaoGovernamentalInfo;
        const exercicio = optionsFontesDeRecursos.find(
          (item) => item.e === fonteDeRecursosReferente.conta.split(".")[0]
        );

        return {
          pill: `Ficha ${fichaReferente.numeroFicha}`,
          acaoGovernamental: {
            id: acaoGovernamentalInfo.id,
            codigo: acaoGovernamentalInfo.nAcao,
            titulo: acaoGovernamentalInfo.titulo,
          },
          unidadeOrcamentaria: `${acaoGovernamentalInfo.unidadeOrcamentariaId.codigoUnidadeOrcamentaria} - ${acaoGovernamentalInfo.unidadeOrcamentariaId.tituloUnidadeOrcamentaria}`,
          funcao: `${acaoGovernamentalInfo.funcao.valor} - ${acaoGovernamentalInfo.funcao.nome}`,
          tipoDeVinculo: {
            ...acaoGovernamentalInfo.funcao,
          },
          subfuncao: `${acaoGovernamentalInfo.subFuncao.valor} - ${acaoGovernamentalInfo.subFuncao.nome}`,
          elementoDaDespesa: {
            codigo: fichaReferente.naturezaDaDespesa.codigo,
            titulo: fichaReferente.naturezaDaDespesa.nome,
            funcao: fichaReferente.naturezaDaDespesa.funcao,
          },
          fonteDeRecursos: `${fonteDeRecursosReferente.conta.split(".")[1]} - ${
            fonteDeRecursosReferente.titulo
          }`,
          fonteDeRecursosInfos: {
            conta: fonteDeRecursosReferente.conta,
          },
          programa: `${acaoGovernamentalInfo.programaPlanejamentoId.numeroPrograma} - ${acaoGovernamentalInfo.programaPlanejamentoId.tituloPrograma}`,
          ficha: {
            id: fichaReferente.id,
            ficha: fichaReferente.numeroFicha,
          },
          exercicio: `${exercicio.conta} - ${exercicio.titulo}`,
        };
      });

      setOptionsFichas(arrayDeFichas);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchLicitacao = async () => {
    console.log("Entramos em licitações");
    try {
      const response = await api.get(
        `/licitacao/${currentClient.codigoTCE}/vazio/${currentYear}?ultimosAnos=1`
      );
      console.log("LICITACOOOOES: ", response.data);
      setLicitacoes(response.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleAddNewCpf = () => {
    setOpenedModals((prev) => [
      ...prev,
      { tituloModal: "CPF/CNPJ", requisicaoDoModalConcluida: false },
    ]);
  };

  useEffect(() => {
    setLoading(true);
    fetchCpfs();
    fetchFontesDeRecursos();
    fetchAllNaturezasDaDespesa();
    fetchObras();
    fetchConvenios();
    fetchLicitacao();

    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (optionsFontesDeRecursos.length > 0) {
      fetchQddFichas();
    }

    //eslint-disable-next-line
  }, [optionsFontesDeRecursos]);

  useEffect(() => {
    const modalCpfCnpj = openedModals.find(
      (item) => item.tituloModal === "CPF/CNPJ"
    );

    if (modalCpfCnpj && modalCpfCnpj.requisicaoDoModalConcluida) {
      fetchCpfs(true);
    }

    //eslint-disable-next-line
  }, [openedModals]);

  const handleSelectFicha = (option) => {
    console.log(option);
    const { ficha, tipoDeVinculo, ...rest } = option;
    console.log(tipoDeVinculo);
    console.log(rest.fonteDeRecursosInfos);

    //filtrando as fontes que podem ser vinculadas
    const filteredFontes = optionsFontesDeRecursos
      .filter(
        (item) =>
          (item.conta.includes(rest.fonteDeRecursosInfos.conta) &&
            item.funcaoId === tipoDeVinculo.id) ||
          item.conta === rest.fonteDeRecursosInfos.conta
      )
      .map((item) => ({
        label: `${item.conta} - ${item.titulo}`,
        value: item.id,
      }));

    console.log(filteredFontes);

    if (filteredFontes.length > 0) {
      setValuesNotaEmpenho((prev) => ({
        ...prev,
        fonteDeRecursoId: filteredFontes[filteredFontes.length - 1].value,
      }));
    }

    setFilteredOptionsFontesDeRecursos(filteredFontes);

    //setando opcoes de subelemento
    const filteredOptionsSubelementos = gerenciaOpcoesSubelemento(
      rest.elementoDaDespesa.codigo.substring(0, 9),
      allNaturezasDaDespesa
    );
    console.log("\n\n");
    console.log(filteredOptionsSubelementos);
    setOptionsSubelementos(filteredOptionsSubelementos);

    //setando opcoes de Ordem Cronologica

    const { newOptions, opcaoPadrao } = gerenciaOpcoesOrdemCronologica(
      rest.elementoDaDespesa.codigo,
      optionsOrdemCronologica
    );
    console.log(newOptions);
    console.log(opcaoPadrao);
    setOptionsOrdemCronologica(newOptions);

    setValuesNotaEmpenho((prev) => ({
      ...prev,
      ordemCronologica: opcaoPadrao,
    }));

    // ----------------------------------
    setValuesNotaEmpenho((prev) => ({
      ...prev,
      fichaId: ficha.id,
      subelemento: filteredOptionsSubelementos[0].value,
    }));

    setInfosFicha({
      ...rest,
    });
  };

  useEffect(() => {
    if (inputFicha.length === 0) {
      setInfosFicha({});
      setValuesNotaEmpenho((prev) => ({
        ...prev,
        fichaId: 0,
      }));
    }
  }, [inputFicha]);

  useEffect(() => {
    const filteredData = licitacoes.filter((licitacao) =>
      licitacao.participantes.some(
        (participante) =>
          participante.fornecedor.fornecedor === valuesNotaEmpenho.fornecedor
      )
    );

    const updatedData = filteredData.map((licitacao) => ({
      label: `${licitacao.numeroLicitacao} - ${licitacao.descricao}`,
      value: licitacao.numeroLicitacao,
      licitacao: licitacao,
    }));

    console.log("Dados atualizados:", updatedData);
    setLicitacoesOptions(updatedData);
  }, [valuesNotaEmpenho.fornecedor, licitacoes]);

  const autocompleteFilterFuncion = (options) => {
    const onlyNumbers = inputFicha.replace(/[^0-9]/g, "");

    const fichaCorrespondente = options.find(
      (item) => item.ficha.ficha.toString() === onlyNumbers
    );

    const filtragemGeral = options
      .filter(
        (item) =>
          item.label.toLowerCase().includes(inputFicha.toLowerCase()) ||
          item.label
            .toLowerCase()
            .replace(/\./g, "")
            .includes(inputFicha.toLowerCase())
      )
      .filter((item) => item.ficha.ficha.toString() !== onlyNumbers)
      .sort((a, b) => {
        if (a.ficha.ficha < b.ficha.ficha) {
          return -1;
        } else {
          return 1;
        }
      })
      .sort((a, b) => {
        const cleanedAcao = a.acaoGovernamental.codigo.replace(/[^0-9]/g, "");
        const cleanedAcaoB = b.acaoGovernamental.codigo.replace(/[^0-9]/g, "");

        if (
          cleanedAcao.startsWith(onlyNumbers) &&
          !cleanedAcaoB.startsWith(onlyNumbers)
        ) {
          return -1;
        } else if (
          !cleanedAcao.startsWith(onlyNumbers) &&
          cleanedAcaoB.startsWith(onlyNumbers)
        ) {
          return 1;
        }

        return 0;
      });

    console.log(fichaCorrespondente);

    if (fichaCorrespondente) {
      return [fichaCorrespondente, ...filtragemGeral];
    } else {
      return [...filtragemGeral];
    }
  };

  const handleSelectFornecedor = (option) => {
    console.log("AAAAAAAAAA: ", option?.cpf?.replace(/\D/g, ""));
    setValuesNotaEmpenho((prevValues) => ({
      ...prevValues,
      fornecedor: option?.cpf?.replace(/\D/g, ""),
    }));
  };

  return (
    <Container
      id={"pags_responsividade_padding_geral"}
      fluid
      className={"container_conteudo conteudo_pagina"}
    >
      <ModalAutoComplete
        tituloModal={"CPF/CNPJ"}
        Component={<ComponentCadastroCpfCnpj isAModalPage={true} />}
      />

      <ToastContainer />

      <HeaderCadastros
        NomePaginaTitulo={"Nota de Empenho"}
        PaginaSubtitulo={"Cadastro"}
      />
      {loading ? (
        <Col
          md={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Icon height={50} icon="line-md:loading-loop" color="#515c70" />
        </Col>
      ) : (
        <>
          <RowForm className="mt-1">
            <TestButton onClick={() => console.log(valuesNotaEmpenho)}>
              values
            </TestButton>
            <TestButton onClick={() => console.log(licitacoesOptions)}>
              GetLicitacoes
            </TestButton>
            <CColNumeroAndEVB md={1}>
              <FloatingLabelInput
                disabled
                label="Número"
                placeholder="Número"
              />
            </CColNumeroAndEVB>
            <CCol>
              <MyAutoComplete
                disabled={licitacoes.length > 0 ? false : true}
                labelInput="Fornecedor"
                options={[
                  {
                    id: "002423",
                    nome: "testePessoa",
                    cpf: "00.009.643/7564-83",
                  },
                  ...optionsCpf,
                ]}
                inputValue={inputFornecedor}
                setInputValue={setInputFornecedor}
                addNewDataFunction={handleAddNewCpf}
                labelFormat={(item) => `${item.nome} - ${item.cpf}`}
                style={{ height: "37px" }}
                onOptionSelect={(option) => handleSelectFornecedor(option)}
              />
            </CCol>
            <CCol md={2}>
              <FloatingLabelInput
                tipo="input_select"
                new
                placeholder="Licitação"
                options={licitacoesOptions}
                value={valuesNotaEmpenho.licitacao.numero}
                onSelect={(option) => {
                  setValuesNotaEmpenho((prevValues) => ({
                    ...prevValues,
                    licitacao: {
                      numero: option.value,
                      data: option.licitacao,
                    },
                  }));
                }}
              />
            </CCol>
            <CCol md={2}>
              <InputData
                value={valuesNotaEmpenho.data}
                onChange={(e) =>
                  setValuesNotaEmpenho((prev) => ({
                    ...prev,
                    data: e.target.value,
                  }))
                }
              />
            </CCol>
          </RowForm>

          <RowForm style={{ marginTop: "-14px" }}>
            <CCol>
              <AutoCompleteFichas
                onOptionSelect={handleSelectFicha}
                filterFunction={autocompleteFilterFuncion}
                labelInput="Ação"
                options={optionsFichas}
                inputValue={inputFicha}
                setInputValue={setInputFicha}
                rightLabelContent={(item) => item.elementoDaDespesa.codigo}
                labelFormat={(item) =>
                  `${item.acaoGovernamental.codigo} - ${item.acaoGovernamental.titulo}`
                }
                style={{ height: "37px" }}
              />
            </CCol>
          </RowForm>
          {Object.keys(infosFicha).length > 0 && (
            <RowForm
              style={{ marginTop: "-14px", paddingRight: 17, paddingLeft: 17 }}
            >
              <RowInfosAcao>
                <CCol md={7}>
                  <p>
                    Unidade Orçamentária:{" "}
                    <span>{infosFicha.unidadeOrcamentaria}</span>
                  </p>
                  <p>
                    Programa: <span>{infosFicha.programa}</span>
                  </p>
                  <p>
                    Elemento de Despesa:{" "}
                    <span>
                      {infosFicha.elementoDaDespesa.codigo.substring(0, 9)} -{" "}
                      {infosFicha.elementoDaDespesa.titulo}
                    </span>
                  </p>
                  <p>
                    Fonte de Recursos: <span>{infosFicha.fonteDeRecursos}</span>
                  </p>
                </CCol>
                <CCol>
                  <p>
                    Função: <span>{infosFicha.funcao}</span>
                  </p>
                  <p>
                    Subfunção: <span>{infosFicha.subfuncao}</span>
                  </p>
                  <p>
                    Exercício - CNP: <span>{infosFicha.exercicio}</span>
                  </p>
                </CCol>
              </RowInfosAcao>
            </RowForm>
          )}

          <RowForm style={{ marginTop: "-14px" }}>
            <CCol md={8}>
              <FloatingLabelInput
                tipo="input_select"
                new
                label="Exercício / Fonte de Recursos - FR / Complementação Orçamentária -CO"
                value={valuesNotaEmpenho.fonteDeRecursoId}
                onChange={(option) =>
                  setValuesNotaEmpenho((prev) => ({
                    ...prev,
                    fonteDeRecursoId: option.value,
                  }))
                }
                placeholder="Exercício / Fonte de Recursos - FR / Complementação Orçamentária -CO"
                options={filteredOptionsFontesDeRecursos}
              />
            </CCol>
            <CCol md={4}>
              <FloatingLabelInput
                tipo="input_select"
                new
                label="Subelemento de Despesa"
                value={valuesNotaEmpenho.subelemento}
                placeholder="Subelemento de Despesa"
                options={optionsSubelementos}
              />
            </CCol>
          </RowForm>

          {Object.keys(infosFicha).length > 0 && (
            <RowForm style={{ marginTop: "-14px" }}>
              <CCol>
                <FloatingLabelInput
                  as="textarea"
                  tipo="text_area"
                  readOnly
                  disabled
                  style={{ height: "38px", minHeight: "38px" }}
                  label="Função"
                  value={infosFicha.elementoDaDespesa.funcao}
                />
              </CCol>
            </RowForm>
          )}

          <RowForm style={{ marginTop: "-14px" }}>
            <CCol md={4}>
              <FloatingLabelInput
                tipo="input_select"
                new
                label="Ordem Cronológica"
                value={valuesNotaEmpenho.ordemCronologica}
                onChange={(option) =>
                  setValuesNotaEmpenho((prev) => ({
                    ...prev,
                    ordemCronologica: parseInt(option.value),
                  }))
                }
                placeholder="Ordem Cronológica"
                options={optionsOrdemCronologica}
              />
            </CCol>
            <CCol md={4}>
              <FloatingLabelInput
                tipo="input_select"
                new
                label="Tipo de Empenho"
                value={valuesNotaEmpenho.tipoDeEmpenho}
                onChange={(option) =>
                  setValuesNotaEmpenho((prev) => ({
                    ...prev,
                    tipoDeEmpenho: option.value,
                  }))
                }
                placeholder="Tipo de Empenho"
                options={[
                  { label: "1 - Ordinário", value: "1 - Ordinário" },
                  { label: "2 - Estimativo", value: "2 - Estimativo" },
                  { label: "3 - Global", value: "3 - Global" },
                ]}
              />
            </CCol>
            <CCol>
              <InputFormatRealBrasileiro iconReal />
            </CCol>
          </RowForm>

          <RowSelecaoAbasInferior
            abas={["Histórico", "Produto e Aquisição", "Complemento", "Saldos"]}
            abaSelecionada={abaSelecionada}
            setAbaSelecionada={setAbaSelecionada}
          />

          {abaSelecionada.nomeAba === "Histórico" && <HistoricoAba />}
          {abaSelecionada.nomeAba === "Produto e Aquisição" && (
            <ProdutoEAquisicaoAba />
          )}
          {abaSelecionada.nomeAba === "Complemento" && (
            <ComplementoAba
              optionsObras={optionsObras}
              inputValueObras={inputValueObras}
              setInputValueObras={setInputValueObras}
              optionsConvenios={optionsConvenios}
              inputValueConvenios={inputValueConvenios}
              setInputValueConvenios={setInputValueConvenios}
            />
          )}
          {abaSelecionada.nomeAba === "Saldos" && <SaldosAba />}
        </>
      )}

      <div className="p-3"></div>
    </Container>
  );
};

export default NotaDeEmpenho;
