import React from "react";
import {
  CCol,
  CColButtonsAcoes2Icons,
  RowAninhada,
} from "../../../../../components/Grid/CCol";
import { Row } from "react-bootstrap";
import {
  FormControlListaDeInputs,
  PTitulosInputs,
} from "../../../../../styles/StyledComponents/ListaDeInputs";
// import IconAdicionar from "../../../../../components/ListaDeInputs/IconAdicionar";
import FloatingLabelInput from "../../../../../components/Grid/FloatingLabelInput";
import { modalidades } from "../../../Cadastros/Licitacao/menus";

const Participantes = ({ values }) => {
  return (
    <>
      <Row className="row_form mt-1">
        {values.map((item, index) => {
          return (
            <RowAninhada key={index}>
              <CCol md={1} style={{ width: "8em" }}>
                {index === 0 && <PTitulosInputs>Nº Licitação</PTitulosInputs>}
                <FormControlListaDeInputs
                  disabled
                  value={item?.numeroLicitacao || null}
                />
              </CCol>
              <CCol>
                {index === 0 && <PTitulosInputs>Modalidade</PTitulosInputs>}
                <FloatingLabelInput
                  disabled
                  new
                  tipo="input_select"
                  height={"30px"}
                  value={item?.modalidadeLicitacao || null}
                  options={[
                    { label: "Modalidade", value: null },
                    ...modalidades,
                  ]}
                />
              </CCol>
              <CCol md={1} style={{ width: "17.5em" }}>
                {index === 0 && <PTitulosInputs>Participante</PTitulosInputs>}
                <FormControlListaDeInputs
                  disabled
                  value={item?.licitante || null}
                />
              </CCol>
              <CCol md={2}>
                {index === 0 && <PTitulosInputs>Valor</PTitulosInputs>}
                <FormControlListaDeInputs />
              </CCol>
              <CCol md={2}>
                {index === 0 && <PTitulosInputs>Situação</PTitulosInputs>}
                <FormControlListaDeInputs />
              </CCol>
              <CColButtonsAcoes2Icons md={1}>
                {/* {index === 0 && <PTitulosInputs>Ação</PTitulosInputs>}
                <IconAdicionar /> */}
              </CColButtonsAcoes2Icons>
            </RowAninhada>
          );
        })}
      </Row>
    </>
  );
};

export default Participantes;
